import React from 'react';
import Skeleton from 'react-loading-skeleton';
// ARMADO DE SKELETO PRINCIPAL PARA EL HOME.
// ES UN ARRAY DE 4 QUE SE ARMA PARA ABAJO, CON 4 SE CUBRE BIEN TODO.  
const SkeletonCard = () => {
  return (
    <section>
           <span></span>
           <h4 className="card-title">
           <Skeleton height={40} width={`100%`} />
           </h4>
           <span></span>
           <div className="card-image">
                <Skeleton height={300} width={`100%`} />
              </div>
            <ul className="list">
        {Array(2)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <div className="card-image">
              <div style={{ margin: 10, marginleft: 8, marginRight: 8 }}>
                <span></span>
                <span></span>
                <Skeleton height={150} width={`50%`} />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <Skeleton height={150} width={`50%`} />              
                <span></span>
                <span></span>
              </div>
              <div>
              </div>
              </div>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default SkeletonCard;