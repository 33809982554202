import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import imageProfile from "../../icons/fueguito-card-1.png";
import "./DefaultOption.css";

export const DefaultOption = ({ data, onClick, hideOption }) => {
  const [profile, setProfile] = useState({
    src: data?.ds_profile_photo_thumbnail_path,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setProfile({
      src: data?.ds_profile_photo_thumbnail_path || imageProfile,
      errored: false,
    });
  }, [data]); // eslint-disable-line

  return (
    <div className="default--option--main--container" onClick={onClick}>
      <div className="default--option--main--header">
        {data ? (
          <div className="default--option--main--info">
            <div className="agreement--main--container--data--image">
              <img src={profile.src} onError={onErrorProfile} alt="..." />
            </div>
            <div className="agreement--main--container--name">
              <div>
                <strong>{data.ds_full_name}</strong>
              </div>
              <div>{data.ds_nick_name}</div>
            </div>
          </div>
        ) : (
          "Seleccionar Hotmer"
        )}
      </div>
      {!hideOption && (
        <div className="default--option--main--icon">
          <ExpandMoreIcon fontSize="default" />
        </div>
      )}
    </div>
  );
};
