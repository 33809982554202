import React, { useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import fireIcon from "../../icons/bottomNav/fire--bottom--nav.png";
import homeIcon from "../../icons/bottomNav/home--bottom--nav.png";
import searchIcon from "../../icons/bottomNav/search--bottom--nav.png";
import cameraIcon from "../../icons/bottomNav/upload--bottom--nav.png";
import profileIcon from "../../icons/bottomNav/profile--bottom--nav.png";
import { setHeaders } from "../../libs/fetch";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { isHotmerUser } from "../../libs/login";
import { BottomNavItem } from "../BottomNavItem/BottomNavItem";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setScreenPosition,
  setVisibleProfileDrawer,
} from "../../redux/actions";
import "./bottomNav.css";

export const BottomNav = () => {
  const state = useSelector((state) => state.main);
  const { screenPosition } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const [openModalToFinal, setOpenModalToFinal] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");

  const [openModalTryAgain, setOpenModalTryAgain] = useState(false);
  const [loadingAprob, setLoadingAprob] = useState(false);

  const navItems = [
    { id: 0, icon: homeIcon, url: "/" },
    { id: 1, icon: searchIcon, url: "/search" },
    {
      id: 2,
      icon: cameraIcon,
      url: "/createContentInit",
      hotmerUrl: "/uploadContent",
    },
    { id: 3, icon: fireIcon, url: "/fire" },
    { id: 4, icon: profileIcon, url: "/myProfile" },
  ];

  const acceptClick = () => {
    setOpenModalTryAgain(false);
    setShowModal(false);
  };

  const acceptTrending = () => {
    setShowModal((x) => !x);
    setOpenModalToFinal(false);
    localStorage.setItem("idUrl", 2);
    dispatch(setScreenPosition(2));
    history.push("/createContentInit");
  };

  const omitTrending = () => {
    setShowModal((x) => !x);
    setOpenModalToFinal(false);
  };

  const alertModal = (error) => {
    setMessageModal(error);
    setModalMessageButtonText("Aceptar");
    setOpenModalTryAgain(true);
    setShowModal(true);
  };

  const getAuthUploadContent = async (navItem) => {
    setLoadingAprob(true);
    const id = JSON.parse(localStorage.getItem("session")).user.id;
    const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/validate`;
    try {
      const res = await fetch(url, { headers: setHeaders() });
      if (res.status === 200) {
        const response = await res.json();
        if (response.data.status === false) {
          setOpenModalToFinal(true);
          setShowModal((x) => !x);
        } else {
          alertModal(
            "Estamos validando su perfil. Al ser aprobado podra crear contenido en Hotmy"
          );
        }
      } else {
        alertModal("Error inesperado. Intente mas tarde.");
      }
    } catch (error) {
      alertModal("Error inesperado. Intente mas tarde.");
    }
    setLoadingAprob(false);
  };

  const setItemPosition = (navItem) => {
    if (navItem.id === 4) {
      dispatch(setVisibleProfileDrawer());
    } else if (navItem.id === 2) {
      if (isHotmerUser()) {
        localStorage.setItem("idUrl", navItem.id);
        dispatch(setScreenPosition(navItem.id));
        history.push(navItem.hotmerUrl);
      } else {
        getAuthUploadContent(navItem);
      }
    } else {
      localStorage.setItem("idUrl", navItem.id);
      dispatch(setScreenPosition(navItem.id));
      history.push(navItem.url);
    }
  };

  const closeShowModal = () => {
    setOpenModalToFinal(false);
    setOpenModalTryAgain(false);
    setShowModal(false);
  };

  return (
    <div className="bottom--nav--main--container">
      <LoadingScreen loading={loadingAprob} />
      {navItems.map((item) => (
        <BottomNavItem
          key={item.id}
          position={screenPosition}
          item={item}
          onClick={() => setItemPosition(item)}
        />
      ))}
      <CustomModal
        openModal={showModal}
        setOpenModal={closeShowModal}
        discardExternalEvents={true}
      >
        {openModalToFinal && (
          <OptionToTrending
            title={"¿ Querés ser creador de Contenido ?"}
            acceptClick={acceptTrending}
            notAcceptclick={omitTrending}
            showYesNoButtons={true}
          />
        )}

        {openModalTryAgain && (
          <OptionTryAgain
            icon={opsIcon}
            title={messageModal}
            acceptClick={acceptClick}
            buttonText={modalMessageButtonText}
            whatsApp={true}
          />
        )}
      </CustomModal>
    </div>
  );
};
