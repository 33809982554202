/* eslint-disable */
import React from "react";
import { FinishProcess } from "../finishProcess/finishProcess";
import "./stepperMessageRestore.css";

export const StepperMessageRestore = () => {
	return (
		<div className="stepper--acount--main--container">
			<FinishProcess
				title="Validación de Usuario"
				description="El usuario con el que intenta acceder, aún no ha sido validado. Por favor verifique su correo electrónico para confirmar su cuenta."
				urlRedirect="/login"
				buttonTitle="ACEPTAR"
			/>
		</div>
	);
};
