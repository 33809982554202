import React from "react";
import profileDefault from "../../icons/profile/profile-default-img.png";
import { getProfileSession } from "../../libs/comments";
import "./profileImage.css";

export const ProfileImage = () => {
  const { img_profile } = getProfileSession();

  return (
    <div className="profile--image--main--container">
      <img alt="..." src={img_profile || profileDefault} />
    </div>
  );
};
