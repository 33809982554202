import React from "react";
import { ActiveTrade } from "../../components/ActiveTrade/ActiveTrade";
import expandIcon from "../../icons/darkIcons/expand-icon.png";
import "./customTradeSelect.css";

export const CustomTradeSelect = ({
  selectedActiveTrade,
  setSelectedActiveTrade,
  onClick,
}) => {
  return (
    <div className="custom--trade--select--container" onClick={onClick}>
      <ActiveTrade trade={selectedActiveTrade} />
      <div className="custom--trade--select--container--icon">
        <img
          className="custom--trade--select--container--input--icon"
          src={expandIcon}
          alt="..."
        />
      </div>
    </div>
  );
};
