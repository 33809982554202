import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TuneIcon from "@material-ui/icons/Tune";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { setInputSearch } from "../../views/search/Actions";
import { useTranslation } from "react-i18next";

export const InputMainSearch = ({ tab, toggleBottomSearch, applyFilters }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      border: "0.5px solid gray",
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    activatedFilters: {
      color: "#5b2787",
    },
    normalFilters: {
      color: "gray",
    },
    button: {
      width: "150px",
      height: "2.4rem",
      marginTop: "0.1rem",
    },
  }));

  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const { search, flagChangeHotmers, flagChangePacks, toys } = state;
  const { t } = useTranslation("global");
  const classes = useStyles();

  const onChange = (e) => dispatch(setInputSearch(e));

  const apply = (e) => {
    e?.preventDefault();
    applyFilters();
  };

  return (
    <Paper component="form" className={classes.root}>
      <TextField
        placeholder={t("search.input.placeholder")}
        margin="dense"
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        name="data"
        value={search.data}
        onChange={onChange}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SearchIcon />}
        onClick={apply}
        disabled={search.data === "" && (tab === 0 || tab === 1)}
        style={{ marginLeft: "4px", marginTop: "4px" }}
      >
        {t("search.input.button")}
      </Button>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        className={classes.iconButton}
        aria-label="directions"
        onClick={toggleBottomSearch}
      >
        <TuneIcon
          className={
            (tab === 0 && flagChangeHotmers) ||
              (tab === 1 &&
                (flagChangePacks ||
                  (toys && toys.some((x) => x.selected === true))))
              ? classes.activatedFilters
              : classes.normalFilters
          }
        />
      </IconButton>
    </Paper>
  );
};
