import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { setProfileOption } from "../../views/search/Actions";
import { useTranslation } from "react-i18next";

export const ProfileOptions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const { profileOption } = state;
  const { t } = useTranslation("global");
  const onChange = ({ target }) => dispatch(setProfileOption(target.value));

  const radioButtons = [
    { id: 1, value: "relevant", label: t("search.ProfileFilter.filter1") },
    { id: 2, value: "packs", label: t("search.ProfileFilter.filter2") },
    { id: 3, value: "videos", label: t("search.ProfileFilter.filter3") },
    { id: 4, value: "images", label: t("search.ProfileFilter.filter4") },
    { id: 5, value: "contents", label: t("search.ProfileFilter.filter5") },
  ];

  return (
    <Container maxWidth="sm" style={{ paddingTop: "2rem" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ paddingBottom: "0.5rem" }}>
          {t("search.ProfileFilter.titleConteiner")}
        </FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={profileOption}
          onChange={onChange}
        >
          {radioButtons.map(({ id, value, label }) => (
            <FormControlLabel
              key={id}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );
};
