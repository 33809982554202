import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../accordionSets/accordionSets";
import { CustomInputTextIconEdit } from "../../commonComponents/customInputTextIconEdit/customInputTextIconEdit";
import aliasIcon from "../../icons/editProfile/profile.png";
import twitterIconV2 from "../../icons/editProfile/twitterV2.png";
import telegramIcon from "../../icons/editProfile/telegram.png";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import "./accordionPersonalData.css";
import { useTranslation } from "react-i18next";

export const AccordionPersonalData = ({
	panel,
	expanded,
	handleChange,
	// loading,
	personalData,
	handleChangePersonalData,
}) => {
	const { t } = useTranslation("global");
	return (
		<Accordion
			square
			expanded={expanded === panel}
			onChange={handleChange(panel)}
		>
			<AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
				<AccordionHeader
					panel={panel}
					expanded={expanded}
					title={t("editProfile.topic.myData")}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<div className="">
					{/* Le saque esta parte para que no se vea en las creadoras */}
					{/* <CustomInputTextIconEdit
						placeholder={t("editProfile.myData.namePlaceHolder")}
						name="full_name"
						value={personalData.full_name.toLowerCase() || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={nameIcon}
						maxLength="25"
						disabled
					/> */}
					<CustomInputTextIconEdit
						placeholder={t("editProfile.myData.aliasPlaceHolder")}
						name="ds_display_name"
						value={personalData.ds_display_name.toLowerCase() || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={aliasIcon}
						maxLength="18"
						disabled
					/>
					{/* <CustomInputTextIconEdit
						placeholder="Instagram"
						name="ds_instagram"
						value={personalData.ds_instagram.toLowerCase() || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={instagramIcon}
						maxLength="25"
					/> */}
					<CustomInputTextIconEdit
						placeholder="Twitter"
						name="ds_twitter"
						value={personalData.ds_twitter || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={twitterIconV2}
						maxLength="25"
					/>
					{/* <CustomInputTextIconEdit
						placeholder="Whatsapp"
						name="ds_phone_number"
						value={personalData.ds_phone_number || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={cellIcon}
						maxLength="15"
					/> */}
					<CustomInputTextIconEdit
						placeholder="Telegram"
						name="ds_telegram"
						value={personalData.ds_telegram || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={telegramIcon}
						maxLength="25"
					/>
				</div>
			</AccordionDetails>
				<div className="hotmer--profile--data--main--container--message">
					<div className="hotmer--profile--data--main--container--message--text">
					{t("editProfile.myData.footerInfo")}
					</div>
				</div>
		</Accordion>
	);
};
