import React from "react";
import "./iconTab.css";

export const IconTab = ({ icon, name }) => {
  return (
    <div className="custom--icon--tab--profile">
      {name ? <div style={{ marginRight : '0.5rem'}}>{name}</div> : null}
      <img src={icon} alt="..." />
    </div>
  );
};
