import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TuneIcon from "@material-ui/icons/Tune";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileHotmer } from "../ProfileHotmer/ProfileHotmer";
import { createStructuredSelector, createSelector } from "reselect";
import "./DealFilters.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    height: "60px",
    margin: "0.2rem 0",
    // width: "calc(100vw - 10px)",
     width: "100%",
  },
  option: {
    width: "calc(100% - 50px) !important",
    textAlign: "center",
  },
  divider: {
    height: 38,
  },
}));

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        agreements,
        collaborations,
        selectedAgreementDeal,
        selectedAgreementHotmer,
      }) => ({
        agreements,
        collaborations,
        selectedAgreementDeal,
        selectedAgreementHotmer,
      })
    ),
  })
)(
  ({
    propsDeal,
    tab,
    activeStepAgreements,
    activeStepCollaborations,
    openFilters,
  }) => {
    const {
      agreements,
      collaborations,
      selectedAgreementDeal,
      selectedAgreementHotmer,
    } = propsDeal;
    const classes = useStyles();
    let selectedObject = selectedAgreementDeal || selectedAgreementHotmer;

    return (
      <Paper component="form" className={classes.root}>
        <div className={classes.option}>
          {selectedObject ? (
            <div className="deal--filter--main--container">
              <ProfileHotmer
                full_name={selectedObject.ds_full_name}
                image={selectedObject.ds_profile_photo_thumbnail_path}
                imageSize={imageSize}
              />
              <MoreHorizIcon />
            </div>
          ) : tab === 0 ? (
            <CenterMessage message="Acuerdos Comerciales" />
          ) : (
            <CenterMessage message="Solicitud De Colaboracion" />
          )}
        </div>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton className={classes.iconButton} aria-label="directions">
          {(tab === 0 && activeStepAgreements === 0) ||
          (tab === 1 && activeStepCollaborations === 0) ? (
            <TuneIcon
              onClick={() => {
                if (tab === 0 && agreements && agreements.length !== 0)
                  openFilters("agreements");
                if (tab === 1 && collaborations && collaborations.length !== 0)
                  openFilters("collabs");
              }}
            />
          ) : (
            <HelpOutlineIcon onClick={() => openFilters("help")} />
          )}
        </IconButton>
      </Paper>
    );
  }
);

const imageSize = {
  height: "30px",
  width: "30px",
};

const CenterMessage = ({ message }) => (
  <div className="center--msg--main--containner">{message}</div>
);
