/* eslint-disable */
export const generatePresentList = (globalList, currencyCode, id) => {
	const list = globalList.map((element) => {
		return {
			id: element.id_tip,
			selected: element.id_tip == id,
			value: {
				currency: currencyCode,
				price: element.vl_tip_price,
			},
		};
	});

	return list;
};

export const updateSelectionTipList = (list, id) => {
	const newList = list.map((option) => {
		if (id === option.id) {
			return {
				...option,
				selected: true,
			};
		} else {
			return {
				...option,
				selected: false,
			};
		}
	});
	return newList;
};

export const updateCurrencyOptions = (subscribeOptions, currency) => {
	const newList = subscribeOptions.map((option) => {
		const newValues = option.values.map((x) => {
			if (x["currency"] === currency) {
				return {
					...x,
					selected: true,
				};
			} else {
				return {
					...x,
					selected: false,
				};
			}
		});
		return {
			...option,
			values: newValues,
		};
	});
	return newList;
};

export const createJsonPresent = (
	match,
	selectedIdCountry,
	paymentMethodSelected,
	selectedIdAmount,
) => {
	const idHotmer = match.params.id_hotmer;
	const id_content_group = match.params.id_content_group;
	const bodyData = {
		id_country: selectedIdCountry,
		id_type_of_payment_method: paymentMethodSelected,
		id_hotmer: idHotmer,
		id_tip: selectedIdAmount,
		id_content_group: id_content_group,
	};
	return bodyData;
};

export const createJsonPresent_v2 = (
	match,
	selectedIdCountry,
	paymentMethodSelected,
	selectedIdAmount,
	checkedUserMail,
	valueMessegeUser,
) => {
	const idHotmer = match.params.id_hotmer;
	const id_content_group = match.params.id_content_group;
	const bodyData = {
		id_country: selectedIdCountry,
		id_type_of_payment_method: paymentMethodSelected,
		id_hotmer: idHotmer,
		id_tip: selectedIdAmount,
		id_content_group: id_content_group,
		bl_info_send_of_origin: checkedUserMail,
		ds_user_of_origin_comment: valueMessegeUser, 
	};
	return bodyData;
};
