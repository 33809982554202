import { useEffect, useState } from "react";
import { PaymentCard } from "../../commonComponents/paymentCard/paymentCard";
import "./paymentsSection.css";

export const PaymentsSection = ({
	paymentMethod,
	paymentMethodSelected,
	onClickPaymentMethod,
	setDefaultPaymentMethod
}) => {
	const [everyNotAvailable, setEveryNotAvailable] = useState(false);

	const [haveOneDefaultPayment, setHaveOneDefaultPayment] = useState();

	// DEFINO SI TIENE UN METODO DE PAGO POR DEFAULT 

	useEffect(() => {
		if (paymentMethod) {
			const filterPaymentMethodsAvailable =	paymentMethod.filter((x) => x.bl_status_available).map((pm) => pm)
			if
				(filterPaymentMethodsAvailable?.length === 1 ){
					setHaveOneDefaultPayment(true)
				}
		}
	}, [paymentMethod, setDefaultPaymentMethod]);

	useEffect(() => {
		if (paymentMethod) {
			const bool = paymentMethod?.every((x) => !x.bl_status_available);
			setEveryNotAvailable(bool);
		}
	}, [paymentMethod]);

	return (
		<div className="payments--section--main--container">
			<div className="payments--section--main--container--title">
				2 - Seleccione un Método de pago
			</div>
			<div className="payments--section--main--container--options">
				{everyNotAvailable ? (
					<div className="payments--section--main--container--options--alert">
						No hay medio de pago local disponible.
					</div>
				) : (
					<>
						{paymentMethod &&
							paymentMethod
								.filter((x) => x.bl_status_available)
								.map((pm) => {
									return (
										<PaymentCard
											key={pm.id_type_of_payment_method}
											pm={pm}
											paymentMethodSelected={paymentMethodSelected}
											onClickPaymentMethod={onClickPaymentMethod}
											haveOneDefaultPayment={haveOneDefaultPayment}
										/>
									);
								})}
					</>
				)}
			</div>
		</div>
	);
};
