import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { setHeaders } from "../../libs/fetch";
import { useDispatch } from "react-redux";
import { ProfileHotmer } from "../ProfileHotmer/ProfileHotmer";
import { getCollaborations } from "../../redux/deals/Actions";
import "./Deal.css";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "0.2rem 0.7rem",
    borderRadius: "20px",
    fontSize: "10px",
    color: "green",
    borderColor: "green",
  },
  success: {
    color: "green",
    borderColor: "green",
    margin: "0 0.2rem",
  },
  failure: {
    color: "red",
    borderColor: "red",
    margin: "0 0.2rem",
  },
  add: {
    color: "green",
    borderColor: "none",
  },
}));

export const Deal = ({
  deal,
  typeDeal,
  addRequestHotmer,
  selectAgreementHotmer,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const imageSize = {
    height: "70px",
    width: "70px",
  };

  const updateCollaboration = async (paramStatus) => {
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${user.id}/collaboration`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
        body: JSON.stringify({
          id_collaboration: deal.id_collaboration,
          id_status: paramStatus,
          id_type_of_refuse: deal.id_type_of_refuse,
        }),
      });
      if (res.status === 201) {
        const response = await res.json();
        if (response.code === 201) {
          console.log("OK");
          dispatch(getCollaborations(user.rol));
          // return { success: true, message: "Proceso Exitoso. Muchas Gracias" };
        } else {
          console.log("BAD");
          // return { success: false, message: "Proceso Fallido. Intente mas tarde por favor." };
        }
      } else {
        console.log("BAD");
        // return { success: false, message: "Proceso Fallido. Intente mas tarde por favor." };
      }
    } catch (error) {
      console.log(error);
      // return { success: false, message: "Proceso Fallido. Intente mas tarde por favor." };
    }
  };

  return (
    <div
      className="agreement--main--container"
      onClick={() => {
        // if (user.rol !== "hotmers")
        selectAgreementHotmer();
      }}
    >
      <div className="agreement--main--container--data">
        <ProfileHotmer
          full_name={deal.ds_full_name}
          nick_name={deal.ds_nick_name}
          image={deal.ds_profile_photo_thumbnail_path}
          imageSize={imageSize}
          deal={deal}
          typeDeal={typeDeal}
        />
      </div>
      {typeDeal === "addRequest" ? (
        <AddIcon
          className={classes.add}
          fontSize="small"
          onClick={addRequestHotmer}
        />
      ) : typeDeal === "agreement" ? (
        <div style={{ color: deal.id_status === 1 ? "green" : "orange" }}>
          {deal.id_status === 1 ? "Activo" : "Inactivo"}
        </div>
      ) : typeDeal === "agreementHotmer" ? (
        <></>
      ) : (
        <div className="agreement--main--container--options">
          {typeDeal === "request" && user.rol !== "hotmers" && (
            <CancelPresentationIcon fontSize="small" />
          )}
          {typeDeal === "request" && user.rol === "hotmers" && (
            <Fragment>
              {deal.status === "accepted" && (
                <CancelPresentationIcon
                  fontSize="large"
                  color="disabled"
                  // onClick={() => updateCollaboration(2)}   CUANDO CANCELA QUE NO PUEDA EJECUTAR ACCIÓN
                />
              )}
              {deal.status === "pending" && (
                <Fragment>
                  <HighlightOffIcon
                    className={classes.failure}
                    fontSize="large"
                    onClick={() => updateCollaboration(2)}
                  />
                  <CheckCircleOutlineIcon
                    className={classes.success}
                    fontSize="large"
                    onClick={() => updateCollaboration(1)}
                  />
                </Fragment>
              )}
              {deal.status === "rejected" && (
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => {
                    const value = deal.bl_bloqued ? 0 : 3;
                    updateCollaboration(value);
                  }}
                >
                  {deal.bl_bloqued ? "DESBLOQUEAR" : "BLOQUEAR"}
                </Button>
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};
