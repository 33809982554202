import Skeleton from "react-loading-skeleton";

export const SkeletonPack = () => {
  return (
    <section>
      <ul className="list">
        {Array(5)
          .fill()
          .map((_, index) => (
            <li className="card" key={index}>
              <div className="card-image">
                <Skeleton circle={true} height={50} width={50} />
              </div>
              <div className="card-image">
                <Skeleton height={150} />
              </div>
              <h4 className="card-title">
                <Skeleton height={40} />
              </h4>
              <br />
            </li>
          ))}
      </ul>
    </section>
  );
};
