/* eslint-disable */
import React from "react";
import okIcon from "../../icons/ok-green-icon.png";
import "./extractionsInputResult.css";

export const ExtractionsInputResult = ({
  content,
  check,
  symbol,
  value,
  image,
  colored,
}) => {
  return (
    <div
      className="extractions--input--result--main--container"
      style={{ justifyContent: content }}
    >
      <div
        className={`extractions--input--result--main--container--box ${
          colored ? "text--result--colored" : ""
        }`}
      >
        <div className="extractions--input--result--main--container--box--symbol">
          {symbol}
        </div>
        <div className="extractions--input--result--main--container--box--value">
          {value}
        </div>
      </div>
      {image && (
        <div className="extractions--input--result--main--container--image">
          <img crossOrigin="anonymous" src={image} alt="..." />
        </div>
      )}
      {check && (
        <div className="extractions--input--result--main--container--check--ok">
          <div className="extractions--input--result--main--container--check--ok--image">
            <img src={okIcon} alt="..." />
          </div>
        </div>
      )}
    </div>
  );
};
