import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

export const PrivateRoutes = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {

  const pathname = useLocation().pathname.slice(1);

  // console.log("pathname", pathname)

  // OBTIENE LA RUTA SI VINO DE UN CREADOR Y ARMA UN RUTADO ESPECIFICO. 
  // TODO ANULAR TODAS LAS RUTAS RESERVADAS DE LA APP YA EXISTENTES. 

  const getPatchname = () => {
    if (pathname && (pathname!=='/' | pathname!=='/,')) {
      // console.log("pathname validado", pathname)
      const url = "?creator=" + pathname;
      return url
    } else {
      const url = ''
      return url
    }
  }

  // useEffect(() => {
  //   if (location) {
  //     const { pathname } = location;
  //     if (pathname !== (("/search")||("/uploadContent")))
  //     if (pathname === "/") dispatch(setScreenPosition(0));
  //     if (pathname === "/search") dispatch(setScreenPosition(1));
  //     if (pathname === "/createContentInit") dispatch(setScreenPosition(2));
  //     if (pathname === "/uploadContent") dispatch(setScreenPosition(2));
  //     if (pathname === "/fire") dispatch(setScreenPosition(3));
  //     if (
  //       pathname === "/myProfile" ||
  //       pathname === "/deals" ||
  //       pathname.includes("/editProfileHotmer") ||
  //       pathname.includes("/configProfile")
  //     )
  //       dispatch(setScreenPosition(4));
  //   }
  // }, [location]);

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? <Component {...props} /> :
          <Redirect
            to={`/login${getPatchname()}`}
          />
        //isAuthenticated ? <Component {...props} /> : <Redirect to="/proximamente" />
      }
    />
  );
};
