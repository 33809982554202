import { createStore, applyMiddleware, compose, combineReducers } from "redux";
// import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { Reducer as HomeReducer } from "./home/Reducer";
import { Reducer as SearchReducer } from "./search/Reducer";
import { Reducer as FireReducer } from "./fire/Reducer";
import { Reducer as DealReducer } from "./deals/Reducer";
import { mainReducer } from "./Reducer";

// const logger = createLogger();

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  home: HomeReducer,
  search: SearchReducer,
  main: mainReducer,
  fire: FireReducer,
  deal: DealReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      //logger,
      thunk
    )
  )
);
