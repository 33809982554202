import React from "react";
import { HotmerOwnPack } from "../hotmerOwnPack/hotmerOwnPack";
import { LoadingComponent } from "../../commonComponents/loadingComponent/loadingComponent";
import { WrongFetchComponent } from "../../commonComponents/wrongFetchComponent/wrongFetchComponent";
import "./hotmerGridContentPack.css";
import { WrongFetchUploadContent } from "../../commonComponents/wrongFetchUploadContent/wrongFetchUploadContent";

export const HotmerGridContentPack = ({
  loading,
  content,
  onClickRefresh,
  owner,
}) => {
  console.log (owner,"owner")
  if (loading) {
    return (
      <div className="grid--image--main--container--loading">
        <LoadingComponent />
      </div>
    );
  } else if (!loading && !content && owner === 0) {
    return (
      <WrongFetchUploadContent />
    )
    ;
  } else if (!loading && !content) {
    return (
      <div className="grid--image--main--container--loading">
        <WrongFetchComponent onClickRefresh={onClickRefresh} />;
      </div>
    );

  } else if (!loading && content.length === 0) {
    return "Este usuario no posee contenido.";
  } else {
    return (
      <div style={{ width: "100%" }} className="grid--pack--main--container">
        {content.map((pack) => (
          <HotmerOwnPack key={pack.id_pack} pack={pack} owner={owner} />
        ))}
      </div>
    );
  }
};
