import React, { useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import "./packImage.css";

export const PackImageUnauthorized = ({ pack }) => {

  const [myState, setMyState] = useState({
    src: pack.ds_url_thumbnail_pack,
    errored: false,
    
  });

  const onError = () => {
    if (!myState.errored) {
      setMyState({
        src: opsIcon,
        errored: true,
      });
    }
  };

  return (
    <div className="hotmer--pack--search--main--container--content--image">
      <img
        src={myState.src}
        alt="..."
        onError={onError}
      />
    </div>
  );
};
