import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { defineIcon } from "../../libs/search";
import { IconTab } from "../iconTab/iconTab";
import { IconTabPacks } from "../iconTab/iconTabsPacks";
import photo from "../../icons/darkIcons/gallery-icon.png";
import video from "../../icons/darkIcons/video-icon.png";
import pack from "../../icons/degrade/pack-degrade-icon-new.png";
import degPhoto from "../../icons/degrade/gallery-degrade-icon.png";
import degVideo from "../../icons/degrade/video-degrade-icon.png";
import degPack from "../../icons/darkIcons/pack-gray-icon.png";
import { useTranslation } from "react-i18next";
import "./customTabs.css";

export const CustomTabs = ({ tab, handleChange }) => {
  const { t } = useTranslation("global");
  return (
    <div className="custom--tabs--pvp--container">
      <Paper square>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab
            icon={
              <IconTab
                icon={defineIcon(tab, 0, degPhoto, photo)}
                name={t("profile.hotmer.circular.photo")}
              />
            }
          />
          <Tab
            icon={
              <IconTab
                icon={defineIcon(tab, 1, degVideo, video)}
                name={t("profile.hotmer.circular.video")}
              />
            }
          />
          <Tab
            icon={<IconTabPacks icon={defineIcon(tab, 2, degPack, pack)} />}
          />
        </Tabs>
      </Paper>
    </div>
  );
};
