import { hmyApiWithToken } from "../api/hmyApiWithToken"; //siempre usamos esta hmyapi pasa simplificar las cabeceras. 
import { useQuery } from '@tanstack/react-query'
import { shareProfile } from "../interfaces/ShareProfile";

// Determinar ID
// envio la interfaz como un arreglo
// hay que desestructurara la interfaz para enviarle parametros con queryKey

const getProfileItemsToShare = async ({ queryKey }) : Promise <shareProfile> => {
    // const id = '2'
    const [_, idCreator] = queryKey
    const myToken = JSON.parse(localStorage.getItem("session"))?.token;
    const { data } = await hmyApiWithToken(myToken).get<shareProfile>(`/hotmers/${idCreator}/profile/full`)  // incluir solo lo que no esta en la predefault en la ruta general de la api. 
    // const res = await fetch('https://www.random.org/integers/?num=1&min=1&max=500&col=1&base=10&format=plain&rnd=new');
    // data[0].data.img_profile
    return data;  // devolvemos una interfaz del tipo data.
}

const useShare = ( idCreator : number, isOpen : boolean ) => {
    // le tiene que llegar el id de la creadora o el dsname
    const shareProfileHotmer = useQuery(
        ['getItemsToShareByIdCreator', idCreator], getProfileItemsToShare,
        // aca van todos los atributos que se pueden usar en ese useQuery 
        {
            staleTime: 1000 * 60 * 60, //ESTO LO QUE TE DICES ES QUE PIENSE QUE POR UN TIEMPO X 1 HORA TODA LA DATA QUE ESTAMOS TRAYENDO ES FRESCA Y ESTA CACHEADA. 
            refetchOnWindowFocus: true,
            retry: 1,
            enabled: !! isOpen
            // placeholderData aca podemos predefinir todo un set de datos antes de que se muestren los reales, por ejemplo en los tags items que nunca cambian.
            // o los paises con sus banderas, mas el stale time es una terrible herramienta para mostrar todo rapido y no tenes que ir a hacer querys, para datas que no cambiann. 
            // https://cursos.devtalles.com/courses/take/react-query/lessons/39847938-initialdata-y-placeholderdata
        }
    );

    return shareProfileHotmer;
}

export default useShare