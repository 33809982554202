import React from "react";
import "./iconProcedure.css";

export const IconProcedure = ({ icon, description, onClick }) => {
  return (
    <div className="icon--procedure--main" onClick={onClick}>
      <div className="icon--procedure--main--container">
        <div className="icon--procedure--main--container--icon">
          <img
            className={`icon--procedure--main--container--icon--graph
                ${description === "EXTRACCIONES" ? "big--icon" : ""}
                `}
            src={icon}
            alt="..."
          />
        </div>
        <div className="icon--procedure--main--container--description">
          {description}
        </div>
      </div>
    </div>
  );
};
