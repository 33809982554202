import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import "./wrongFetchScreen.css";

export const WrongFetchScreen = ({ onClickRefresh }) => {
  return (
    <div className="wrong--fetch--screen--container">
      <div className="wrong--fetch--screen--container--image">
        <div className="wrong--fetch--screen--container--image--message">
          El contenido no esta disponible.
        </div>
        <div className="wrong--fetch--screen--container--refresh">
          <RefreshIcon fontSize="large" onClick={onClickRefresh} />
        </div>
      </div>
    </div>
  );
};
