import React from "react";
import "./customButtonNext.css";

export const CustomButtonNext = ({ onClick, children, disabled }) => {
  return (
    <button
      className={`custom--button--next--container ${
        disabled ? "disabled--button" : ""
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
