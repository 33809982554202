import { useEffect, useState, useRef } from "react";
import SkeletonCard from "../../components/skeletonCard/SkeletonCard";
import { Reload } from "../../components/Reload/Reload";
import { Hotmer } from "../../components/hotmer/hotmer";
import { HotmerPerformance } from "../../components/HotmerPerformance/HotmerPerformance";
import { NoContent } from "../../components/NoContent/NoContent";
import { Scrollbars } from "react-custom-scrollbars";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { SimpleMessage } from "../../components/SimpleMessage/SimpleMessage";
import { useTranslation } from "react-i18next";
import { PaginationLoading } from "../../components/PaginationLoading/PaginationLoading";
import { HotmerGridExclusivePack } from "../../components/HotmerGridExclusivePack/HotmerGridExclusivePack"
import "./home.css";

export const Home = ({
  post: { loading, error, posts, limit, offset, stopFetch, scrollbarPosition },
  getPosts,
  setScrollbarPosition,
}) => {
  const ref = useRef();
  const [modal, setModal] = useState(false);
  const [t] = useTranslation("global");

  const postsRequest = () => getPosts(limit, offset);

  function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || (document.documentElement.clientHeight + 10)) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const handleScroll = (e) => {
    const { target } = e;
    /* ******************************* */
    target.childNodes.forEach((element) => {
      const viewPort = isInViewport(element);
      if (viewPort === true) {
        if (element.className === "reload--main--container--error") {
        } else {
          setScrollbarPosition(element.id);
        }
      }
    });
    /* ******************************* */
    const { scrollHeight, scrollTop, clientHeight } = target;
    //console.log("RESTA: ", scrollHeight - scrollTop, "HEIGHT: ", clientHeight);
    const bottom = scrollHeight - (scrollTop + 10) <= clientHeight;
    //onst bottom1 = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (stopFetch || loading) return;
    if (bottom) {
      //console.log("Paginado", bottom);
      getPosts(limit, offset);
    }
  };

  /*
  const checkBottom =  (e) => {
    const bottom = (
      (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) &
      (fetching === false) &
      (morePost === true));
      if (bottom) { 
      setFetching(true)
      }
  }
  */

  useEffect(() => {
    if (error) {
      setModal(true);
      setTimeout(() => {
        setModal(false);
      }, 1200);
    }
  }, [error]);

  useEffect(() => {
    if (!posts) postsRequest();
    /* ******************************* */
    if (ref && ref.current) {
      setTimeout(() => {
        const homeContainer = document.getElementById("home--main--container");
        if (homeContainer) {
          const container = document.getElementById(scrollbarPosition);
          if (container && scrollbarPosition) {
            container.scrollIntoView();
          }
        }
      }, 800);
    }
    /* ******************************* */

    // return () => {
    //   console.log("BYE HOME !!!");
    //   const container = document.getElementById("home--main--container--posts");
    //   console.log("CONTAINER: ", container.childNodes);
    // };
  }, [posts]);

  return (
    <div className="home--main--container" id="home--main--container">
      <CustomModal openModal={modal} setOpenModal={setModal}>
        <SimpleMessage message={t("simpleMessage.message")} />
      </CustomModal>
      {loading && offset === 0 && <SkeletonCard />}
      {error && offset === 0 && <Reload onClick={postsRequest} />}
      {posts && (
        <div
          id="home--main--container--posts"
          className="home--main--container--posts"
        >
          <Scrollbars ref={ref} onScroll={handleScroll} universal={true}>
            <HotmerPerformance />
            <div style={{ margin: '1rem 0 0 0'}}>
              <HotmerGridExclusivePack />
            </div>
            {posts.map((post, i) => (
              <Hotmer key={i} myHotmer={post} index={i} />
            ))}
            {loading && <PaginationLoading />}
            {error && <Reload onClick={postsRequest} />}
            {!error && stopFetch && <NoContent />}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};
