export const TYPES = {
  LOAD_REQUEST_USER_EXTRACTIONS: "LOAD_REQUEST_USER_EXTRACTIONS",
  SET_SUCCESS_USER_EXTRACTIONS: "SET_SUCCESS_USER_EXTRACTIONS",
  SET_FAILURE_USER_EXTRACTIONS: "SET_FAILURE_USER_EXTRACTIONS",
  LOAD_REQUEST_SUBMIT_EXTRACTION: "LOAD_REQUEST_SUBMIT_EXTRACTION",
  SET_SUCCESS_SUBMIT_EXTRACTION: "SET_SUCCESS_SUBMIT_EXTRACTION",
  SET_FAILURE_SUBMIT_EXTRACTION: "SET_FAILURE_SUBMIT_EXTRACTION",
  LOAD_REQUEST_EXTRACTION_METHODS: "LOAD_REQUEST_EXTRACTION_METHODS",
  SET_SUCCESS_EXTRACTION_METHODS: "SET_SUCCESS_EXTRACTION_METHODS",
  SET_FAILURE_EXTRACTION_METHODS: "SET_FAILURE_EXTRACTION_METHODS",
  SET_INIT_EXTRACTION: "SET_INIT_EXTRACTION",
  LOAD_REQUEST_PAYMENT_METHODS: "LOAD_REQUEST_PAYMENT_METHODS",
  SET_SUCCESS_PAYMENT_METHODS: "SET_SUCCESS_PAYMENT_METHODS",
  SET_FAILURE_PAYMENT_METHODS: "SET_FAILURE_PAYMENT_METHODS",
};
