import React from "react";
import { useHistory } from "react-router-dom";
import moneyIcon from "../../icons/money-icon-2.jpg";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./buttonPresent.css";

export const ButtonPresent = ({ id_user_hotmer, id_content_group }) => {
	const history = useHistory();
	const handleClick = () => {
		if (id_content_group) {
			history.push(`/hotmerPresent/${id_user_hotmer}/${id_content_group}`);
		} else {
			history.push(`/hotmerPresent/${id_user_hotmer}/${1}`);
		}
	};
	return (
		<div className="button--present--main--container">
			<img
				className="button--present--main--container--img"
				src={moneyIcon}
				alt="..."
			/>
			<DividerSpace space="XXS" />
			<DividerSpace space="XXS" />
			<div onClick={handleClick}>
				<div className="button--present--main--container--text">
					Enviar Regalo
				</div>
			</div>
		</div>
	);
};
