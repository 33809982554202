//import React, { useState, useEffect } from "react";
import React from "react";
import photo from "../../icons/modals/popUp--solo.png";
import "./optionToTrending.css";

export const OptionToTrending = ({
  title,
  acceptClick,
  notAcceptclick,
  disabledButtons,
  showYesNoButtons,
}) => {
  //const [showButtonsYesNo, setShowButtonsYesNo] = useState(null);
  //
  //useEffect(() => {
  //  console.log("showButtonsYesNo: ", showButtonsYesNo);
  //	if (showYesNoButtons = true) {
  //		console.log("showYesNoButtons: ", showYesNoButtons);
  //    setShowButtonsYesNo(true); // showButtons
  //    console.log("showButtonsYesNo: ", true);
  //	}
  //}, [showYesNoButtons]);

  return (
    <div className="open-to-trending--main--container">
      <img
        className="open-to-trending--main--container--background"
        alt="..."
        src={photo}
      />
      <div className="open-to-trending--main--container--content">
        <div className="open-to-trending--main--container--content--title">
          {title}
        </div>
        <div className="open-to-trending--main--container--content--actions">
          {showYesNoButtons ? (
            <>
              <ButtonOption
                disabled={disabledButtons}
                colored={true}
                option="SI"
                onClick={acceptClick}
              />
              <ButtonOption
                disabled={disabledButtons}
                colored={false}
                option="NO"
                onClick={notAcceptclick}
              />
            </>
          ) : (
            <>
              <ButtonOption
                disabled={false}
                colored={true}
                option="Aceptar"
                onClick={acceptClick}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ButtonOption = ({ option, colored, onClick, disabled }) => {
  return (
    <button
      className={`button--option--main--container ${
        colored ? "button--option--colored" : ""
      }`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`
        button--option--main--container--option ${
          disabled ? "button--option--disabled" : ""
        }
        `}
      >
        {option}
      </div>
    </button>
  );
};
