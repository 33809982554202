import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./dropDownDate.css";

export const DropDownDate = ({
	type,
	value,
	onChooseType,
	textColor,
	disabled,
}) => {
	let defaultType = type === "year" ? "Año" : type === "month" ? "Mes" : "Dia";

	//console.log("type: ", type);
	//console.log("value: ", value);

	return (
		<button
			className="dropDownDate--main--container"
			disabled={disabled}
			onClick={() => onChooseType(type)}
		>
			<div
				className="dropDownDate--main--container--value"
				style={{ color: textColor }}
			>
				{value ? value : defaultType}
			</div>
			<div className="dropDownDate--main--container--arrow">
				<ArrowDropDownIcon color="secondary" fontSize="small" />
			</div>
		</button>
	);
};
