import React from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./retiredMoney.css";

export const RetiredMoney = ({ x, y }) => {
	if (x) {
		return (
			<div className="retired--money--main--container">
				<div className="retired--money--main--container--value">
					$ {x.value || 0}{" "}
				</div>
				<DividerSpace space="XS" />
				<div className="retired--money--main--container--currency">
					{x.currency}
				</div>
                <DividerSpace space="M" />
                <div className="retired--money--main--container--value">
					$ {y.value || 0}{" "}
				</div>
				<DividerSpace space="XS" />
				<div className="retired--money--main--container--currency">
					{y.currency}
				</div>
				
			</div>/*SECTION 2*/
		);
	} else {
		return "";
	}
};
