/* eslint-disable */
import { setHeadersNoToken } from "../libs/fetch";
import { TYPES } from "./actionTypes";

export const setLoadingToBecomeHotmer = () => ({
  type: TYPES.SET_LOADING_TO_BECOME_HOTMER,
});

export const setDiscardModalEdit = (data) => ({
  type: TYPES.SET_DISCARD_EDIT_MODAL,
  payload: data,
});

export const setTabFireFromDrawer = (data) => ({
  type: TYPES.SET_TAB_FIRE_FROM_DRAWER,
  payload: data,
});

export const setTabSearch = (data) => ({
  type: TYPES.SET_TAB_SEARCH,
  payload: data,
});

export const setUserHotmerStatus = (data) => ({
  type: TYPES.SET_USER_HOTMER_STATUS,
  payload: data,
});

export const setUserAccountCreation = (data) => ({
  type: TYPES.SET_USER_ACCOUNT_CREATION,
  payload: data,
});

export const setRegisterFirebaseUser = (data) => ({
  type: TYPES.SET_REGISTER_FIREBASE_USER,
  payload: data,
});

export const setLoginFirebaseUser = (data) => ({
  type: TYPES.SET_LOGIN_FIREBASE_USER,
  payload: data,
});

export const loadAuthenticatedUser = () => ({
  type: TYPES.LOAD_AUTHENTICATED_USER,
});

export const setAuthenticatedUser = (data) => ({
  type: TYPES.SET_AUTHENTICATED_USER,
  payload: data,
});

export const setUserLogged = (name) => ({
  type: TYPES.SET_USER_LOGGED,
  payload: name,
});

export const loadRequesthotmersHome = () => ({
  type: TYPES.LOAD_REQUEST_HOTMERS_HOME,
});

export const setScreenPosition = (position) => ({
  type: TYPES.SET_SCREEN_POSITION,
  payload: position,
});

export const setHotmersData = (data) => ({
  type: TYPES.SET_HOTMERS_DATA,
  payload: data,
});

export const setVisibleProfileDrawer = () => ({
  type: TYPES.SET_VISIBLE_PROFILE_DRAWER,
});

export const setVisibleContentDrawer = () => ({
  type: TYPES.SET_VISIBLE_CONTENT_DRAWER,
});

export const setSubmitEditProfile = () => ({
  type: TYPES.SET_SUBMIT_EDIT_PROFILE,
});

export const getDataCountry = () => {
  const url = `${process.env.REACT_APP_INITIAL_V2}/country`;
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_CONUTRY });
    try {
      const res = await fetch(url, {
        headers: setHeadersNoToken(),
      });
      console.log("RES COUNTRY: ", res);
      if (res.status === 200) {
        const response = await res.json();
        console.log("RESPONSE COUNTRY: ", response);
        dispatch({
          type: TYPES.SET_SUCCESS_CONUTRY,
          payload: response.data,
        });
      } else {
        dispatch({ type: TYPES.SET_FAILURE_COUNTRY });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_COUNTRY });
    }
  };
};
