import profileDefault from "../icons/profile/profile-default-img.png";

const orderById = (list) => {
	const finalList = list.sort(function (a, b) {
		if (a.id_comment > b.id_comment) {
			return 1;
		}
		if (a.id_comment < b.id_comment) {
			return -1;
		}
		return 0;
	});
	return finalList;
};

export const mapInitComments = (list) => {
	const orderListByIdComment = orderById(list);
	return orderListByIdComment;
};

export const getProfileSession = () => {
	let profile = JSON.parse(localStorage.getItem("session")).user;
	return profile;
};

export const getUserSession = () => {
	let user = JSON.parse(localStorage.getItem("session")).user;
	return user;
};

export const getExactDate = () => {
	const myDate = new Date();
	const date = myDate.getDate();
	let month = parseInt(myDate.getMonth()) + 1;
	if (month < 10) {
		month = `0${month}`;
	}
	const year = myDate.getFullYear();
	let hours = myDate.getHours();
	if (hours < 10) {
		hours = `0${hours}`;
	}

	let mins = myDate.getMinutes();
	if (mins < 10) {
		mins = `0${mins}`;
	}
	const result = `${date}/${month}/${year} ${hours}/${mins}`;
	return result;
};

export const generateComment = (data, comments, idc, id_comment) => {
	const { img_profile, full_name, id } = getProfileSession();
	const newComment = {
		ds_comment: data.comment,
		ds_full_name: full_name,
		profile_photo_path: img_profile || profileDefault,
		dt_insert: getExactDate(),
		id_user: id,
		id_comment,
		id_content_group: idc,
	};
	return newComment;
};

export const failurePostComment =
	"Ha ocurrido un problema. Intente mas tarde por favor";
