import { TYPES as MAINTYPES } from "./actionTypes";
import { TYPES as PROFILETYPES } from "./profile/profileActionTypes";
import { TYPES as EXTRACTIONSTYPES } from "./extractions/extractionsActionTypes";
import { TYPES as UPLOADTYPES } from "./upload/uploadActionTypes";
import {
  LOAD_REQUEST_PREFERENCES_GENDERS,
  SET_SUCCESS_PREFERENCES_GENDERS,
  SET_FAILURE_PREFERENCES_GENDERS,
  LOAD_REQUEST_OPTIONS_FEATURES,
  SET_SUCCESS_OPTIONS_FEATURES,
  SET_FAILURE_OPTIONS_FEATURES,
} from "./account/accountActionTypes";

import { initialState } from "./initialState";

export const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ******************************** */
    case UPLOADTYPES.SET_NULL_RESPONSE_SUBMIT:
      return {
        ...state,
        responseSubmitContent: null,
      };
    case UPLOADTYPES.LOADING_UPLOAD_CONTENT:
      return {
        ...state,
        loadingSubmitContent: true,
      };
    case UPLOADTYPES.SUCCESS_UPLOAD_CONTENT:
      return {
        ...state,
        loadingSubmitContent: false,
        responseSubmitContent: true,
      };
    case UPLOADTYPES.FAILURE_UPLOAD_CONTENT:
      return {
        ...state,
        loadingSubmitContent: false,
        responseSubmitContent: false,
      };
    /* ******************************** */
    case MAINTYPES.SET_DISCARD_EDIT_MODAL:
      return {
        ...state,
        discardModalEdit: !state.discardModalEdit,
        discardModalEditRoute: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_LOADING_TO_BECOME_HOTMER:
      return {
        ...state,
        loadingToBecomeHotmer: !state.loadingToBecomeHotmer,
      };
    /* ******************************** */
    case MAINTYPES.SET_TAB_FIRE_FROM_DRAWER:
      return {
        ...state,
        tabFireFromDrawer: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_TAB_SEARCH:
      return {
        ...state,
        tabSearch: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_USER_ACCOUNT_CREATION:
      return {
        ...state,
        userAccountCreation: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_USER_HOTMER_STATUS:
      return {
        ...state,
        userHotmerStatus: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_REGISTER_FIREBASE_USER:
      return {
        ...state,
        registerFirebaseUser: action.payload,
      };
    case MAINTYPES.SET_LOGIN_FIREBASE_USER:
      return {
        ...state,
        loginFirebaseUser: action.payload,
      };
    case MAINTYPES.LOAD_AUTHENTICATED_USER:
      return {
        ...state,
        loadingAuthenticatedUser: true,
      };
    case MAINTYPES.SET_AUTHENTICATED_USER:
      return {
        ...state,
        loadingAuthenticatedUser: false,
        authenticatedUser: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_USER_LOGGED:
      return {
        ...state,
        userLogged: !state.userLogged,
        userLoggedData: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.LOAD_REQUEST_USERS_DATA:
      return {
        ...state,
        loadingUsersData: true,
      };
    case MAINTYPES.SET_SUCCESS_USERS_DATA:
      return {
        ...state,
        loadingUsersData: false,
        usersData: action.payload,
      };
    case MAINTYPES.SET_FAILURE_USERS_DATA:
      return {
        ...state,
        loadingUsersData: false,
        usersData: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.LOAD_REQUEST_USER_DATA:
      return {
        ...state,
        loadingUserData: true,
      };
    case MAINTYPES.SET_SUCCESS_USER_DATA:
      return {
        ...state,
        loadingUserData: false,
        userData: action.payload,
      };
    case MAINTYPES.SET_FAILURE_USER_DATA:
      return {
        ...state,
        loadingUserData: false,
        userData: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.LOAD_REQUEST_HOTMERS_HOME:
      return {
        ...state,
        loadinghotmersHome: true,
      };
    /* ******************************** */
    case MAINTYPES.SET_SCREEN_POSITION:
      return {
        ...state,
        screenPosition: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_SUBMIT_EDIT_PROFILE:
      return {
        ...state,
        submitEditProfile: !state.submitEditProfile,
      };
    /* ******************************** */
    case MAINTYPES.SET_HOTMERS_DATA:
      return {
        ...state,
        hotmersData: action.payload,
      };
    /* ******************************** */
    case MAINTYPES.SET_VISIBLE_PROFILE_DRAWER:
      return {
        ...state,
        visibleProfileDrawer: !state.visibleProfileDrawer,
      };
    /* ******************************** */
    case MAINTYPES.SET_VISIBLE_CONTENT_DRAWER:
      return {
        ...state,
        visibleContentDrawer: !state.visibleContentDrawer,
      };
    /* ******************************** */
    case EXTRACTIONSTYPES.LOAD_REQUEST_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: true,
      };
    case EXTRACTIONSTYPES.SET_SUCCESS_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: action.payload,
      };
    case EXTRACTIONSTYPES.SET_FAILURE_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: null,
      };
    /* ******************************** */
    case PROFILETYPES.LOAD_REQUEST_USER_STATISTICS:
      return {
        ...state,
        loadingUserStatistics: true,
      };
    case PROFILETYPES.SET_SUCCESS_USER_STATISTICS:
      return {
        ...state,
        loadingUserStatistics: false,
        userStatistics: action.payload,
      };
    case PROFILETYPES.SET_FAILURE_USER_STATISTICS:
      return {
        ...state,
        loadingUserStatistics: false,
        userStatistics: null,
      };
    /* ******************************** */
    case LOAD_REQUEST_PREFERENCES_GENDERS:
      return {
        ...state,
        loadingPreferencesGenders: true,
      };
    case SET_SUCCESS_PREFERENCES_GENDERS:
      return {
        ...state,
        loadingPreferencesGenders: false,
        preferencesGenders: action.payload,
      };
    case SET_FAILURE_PREFERENCES_GENDERS:
      return {
        ...state,
        loadingPreferencesGenders: false,
        preferencesGenders: null,
      };
    /* ******************************** */
    case LOAD_REQUEST_OPTIONS_FEATURES:
      return {
        ...state,
        loadingOptionsFeatures: true,
      };
    case SET_SUCCESS_OPTIONS_FEATURES:
      return {
        ...state,
        loadingOptionsFeatures: false,
        optionsFeatures: action.payload,
      };
    case SET_FAILURE_OPTIONS_FEATURES:
      return {
        ...state,
        loadingOptionsFeatures: false,
        optionsFeatures: null,
      };
    /* ******************************** */
    case EXTRACTIONSTYPES.LOAD_REQUEST_USER_EXTRACTIONS:
      return {
        ...state,
        loadingUserExtractions: true,
      };
    case EXTRACTIONSTYPES.SET_SUCCESS_USER_EXTRACTIONS:
      return {
        ...state,
        loadingUserExtractions: false,
        userExtractions: action.payload,
      };
    case EXTRACTIONSTYPES.SET_FAILURE_USER_EXTRACTIONS:
      return {
        ...state,
        loadingUserExtractions: false,
        userExtractions: null,
      };
    /* ******************************** */
    case EXTRACTIONSTYPES.SET_INIT_EXTRACTION:
      return {
        ...state,
        initExtraction: action.payload,
      };
    /* ******************************** */
    case EXTRACTIONSTYPES.LOAD_REQUEST_EXTRACTION_METHODS:
      return {
        ...state,
        loadingExtractionMethod: true,
      };
    case EXTRACTIONSTYPES.SET_SUCCESS_EXTRACTION_METHODS:
      return {
        ...state,
        loadingExtractionMethod: false,
        extractionMethod: action.payload,
      };
    case EXTRACTIONSTYPES.SET_FAILURE_EXTRACTION_METHODS:
      return {
        ...state,
        loadingExtractionMethod: false,
        extractionMethod: null,
      };
    /* ******************************** */
    case UPLOADTYPES.LOAD_REQUEST_TOYS:
      return {
        ...state,
        loadingPackTags: true,
      };
    case UPLOADTYPES.SET_SUCCESS_TOYS:
      return {
        ...state,
        loadingPackTags: false,
        packTags: action.payload,
      };
    case UPLOADTYPES.SET_FAILURE_TOYS:
      return {
        ...state,
        loadingPackTags: false,
        packTags: null,
      };
    /* ******************************** */
    case MAINTYPES.LOAD_REQUEST_CONUTRY:
      return {
        ...state,
        loadingDataCountry: true,
      };
    case MAINTYPES.SET_SUCCESS_CONUTRY:
      return {
        ...state,
        loadingDataCountry: false,
        dataCountry: action.payload,
      };
    case MAINTYPES.SET_FAILURE_COUNTRY:
      return {
        ...state,
        loadingDataCountry: false,
        dataCountry: null,
      };
    /* ******************************** */
    default:
      return state;
  }
};
