import React from "react";
import "./dividerSpace.css";

const SPACES = ["XXS", "XS", "S", "M", "L", "X", "XL", "XXL", "XXXL", "XXXXL"];

export const DividerSpace = ({ space }) => {
  const checkSpace = SPACES.includes(space) ? space : SPACES[0];

  return <div className={`divider ${checkSpace}`} />;
};
