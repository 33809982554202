/* eslint-disable */
import React, { useEffect, useState } from "react";
import okIcon from "../../icons/payment/ok-green-icon.png";
import { useLocation, useHistory } from "react-router-dom";
import {
	decodeAndParseJson,
	findByKey,
	mapInitPaymentData,
} from "../../libs/mercadoPago";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./successPayment.css";

export const SuccessPayment = () => {
	const location = useLocation();
	const history = useHistory();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const getStatusPayment = (location) => {
		setTimeout(() => {
			setLoading(true);
			try {
				const list = mapInitPaymentData(location);
				const { status } = findByKey(list, "status");
				const extRefData = findByKey(list, "external_reference");
				const extRef = decodeAndParseJson(extRefData);
				const { id_hotmer, id_pack } = extRef;
				console.log(extRef);
				if (status === "null") {
					history.push(`packDetail/${id_hotmer}/${id_pack}`);
				} else {
					setData(extRef);
				}
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}, 2000);
	};

	const visitMyBuys = () =>
		//history.push("/fire"); si no entraste a fire, no toma la ruta.
		history.push(`/fire`);

	useEffect(() => {
		getStatusPayment(location);
	}, []); // eslint-disable-line

	return (
		<div className="success--payment--main--container">
			<div className="success--payment--main--container--pack">
				<div className="success--payment--main--container--pack--hotmer">
				{/*Su pago fue realizado*/}
				{"Solicitud de Pago Enviada"}
				</div>
			</div>
			<div className="success--payment--main--container--payment">
				<div className="success--payment--main--container--payment--icon">
					<img src={okIcon} alt="..." />
				</div>
				<div className="success--payment--main--container--payment--result">
					{"Luego de Recibir la Aprobación del Pago se desbloqueará dentro de las 24hs"}
				</div>
			</div>
			<div className="aditional-info">
				<div className="aditional-info-title">
					Suscripciones y Packs
					</div>
					<div className="aditional-info-title-container">
					{"Una vez ingresado el pago a Hotmy, se desbloqueara el Contenido"}
					</div>
					<div className="aditional-info-title">
					Regalos - Tips
					</div>
					<div className="aditional-info-title-container">
					{"Gracias por Apoyar a nuestros modelos"}	
					</div>
				
				<div className="my-buys">
						<CustomButtonRegister
						onClick={visitMyBuys}
							>
							Ir a Mis Compras
						</CustomButtonRegister>
					</div>
			</div>
		</div>
	);
};
