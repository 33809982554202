/* eslint-disable */
import React, { useEffect, useState } from "react";
import failureIcon from "../../icons/failure-payment-icon.png";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { useLocation, useHistory } from "react-router-dom";
import {
	findByKey,
	mapInitPaymentData,
	decodeAndParseJson,
} from "../../libs/mercadoPago";
import "./failurePayment.css";

export const FailurePayment = () => {
	const location = useLocation();
	const history = useHistory();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const getStatusPayment = (location) => {
		setLoading(true);
		setTimeout(() => {
			const list = mapInitPaymentData(location);
			const { status } = findByKey(list, "status");
			//const extRefData = findByKey(list, "external_reference");
			//const extRef = decodeAndParseJson(extRefData);
			//const { id_hotmer, id_pack } = extRef;
			if (status === "null") {
				//history.push(`packDetail/${id_hotmer}/${id_pack}`);
				 history.push("/fire");
			} else {
				setData(extRef);
			}
			setLoading(false);
		}, 2000);
	};

	/*const backTo = () =>
		history.push(`packDetail/${data.id_hotmer}/${data.id_pack}`);*/

	const backTo = () =>
	history.push("/fire");

	useEffect(() => {
		getStatusPayment(location);
	}, []); // eslint-disable-line

	if (loading) {
		return <LoadingScreen loading={loading} />;
	} else {
		return (
			<div className="failure--payment--main--container">
				<div className="failure--payment--main--container--pack">
					Transacción no completada
				</div>
				<div className="failure--payment--main--container--payment">
					<div className="failure--payment--main--container--payment--icon">
						<img src={failureIcon} alt="..." />
					</div>
					<div className="failure--payment--main--container--payment--result">
						Compra fallida
					</div>
				</div>
				<div className="failure--payment--main--container--finish">
					<ButtonFinish onClick={backTo} />
				</div>
			</div>
		);
	}
};

const ButtonFinish = ({ onClick }) => {
	return (
		<button onClick={onClick} className="button--finish--main--container">
			Volver
		</button>
	);
};
