/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import editIcon from "../../icons/darkIcons/edit-icon.png";
import trashIcon from "../../icons/darkIcons/trash-icon.png";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import profileDefault from "../../icons/profile/profile-default-img.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import "./commentPost.css";

export const CommentPost = ({
	comment,
	tryRemoveComment,
	loadingRemoveComment,
}) => {
	const [commentData, setCommentData] = useState(null);

	const [loadingProcess, setLoadingProcess] = useState(false);
	const [openModalEditComment, setOpenModalEditComment] = useState(false);

	const [openResultModal, setOpenResultModal] = useState(false);
	const [messageResultModal, setMessageResultModal] = useState("");

	const idUser = JSON.parse(localStorage.getItem("session")).user.id;
	const history = useHistory();

	const tryEditComment = () => {
		setOpenModalEditComment(true);
	};

	const clickProfileComment = () => {
		const { id_user, bl_hotmer, ds_full_name } = comment;
		if (bl_hotmer === "true" || bl_hotmer === true) {
			const idUserSession = getIdUser();
			if (id_user == idUserSession) {
				history.push(`/myProfile/${idUserSession}`);
			} else {
				history.push(`/${ds_full_name}`);
			}
		}
	};

	const updateFirstComment = async (content) => {
		setLoadingProcess(true);
		const { id_user, id_content_group } = comment;
		const bodyContent = JSON.stringify({
			id_content_group,
			ds_comment: content,
		});
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id_user}/content/${id_content_group}/comment`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "PUT",
				body: bodyContent,
			});
			console.log("RES EDIT FIRST COMMENT: ", res);
			if (res.status === 200) {
				setCommentData({ ...commentData, ds_comment: content });
			} else {
				setOpenResultModal(true);
				setMessageResultModal("Error. Intente mas tarde por favor.");
			}
		} catch (error) {
			console.warn("ERROR: ", error);
		}
		setLoadingProcess(false);
	};

	useEffect(() => {
		setCommentData(comment);
	}, [comment]);

	if (!commentData) {
		return <></>;
	} else {
		return (
			<div
				className={`comment--post--main--container ${commentData.id_comment === 0 ? "first--comment" : ""
					}`}
			>
				<div className="comment--post--main--container--data">
					<div
						className={`comment--post--main--container--data--profile ${commentData.bl_hotmer === "true" || commentData.bl_hotmer === true
							? "colored--comment--profile"
							: ""
							}`}
					>
						<img
							onClick={clickProfileComment}
							src={comment.profile_photo_path || profileDefault}
							alt="..."
						/>
					</div>
					<div className="comment--post--main--container--data--text">
						<Typography>{comment.ds_full_name}</Typography>
						<Typography>{commentData.ds_comment}</Typography>
					</div>
				</div>
				<div className="comment--post--main--container--date">
					{commentData.dt_insert}
				</div>
				{commentData.id_comment !== 0 && commentData.id_user == idUser && (
					<div
						className="comment--post--main--container--remove"
						onClick={() => tryRemoveComment(comment)}
					>
						{loadingRemoveComment ? (
							<MiniSpinner />
						) : (
							<img src={trashIcon} alt="..." />
						)}
					</div>
				)}
				{commentData.id_comment == 0 && commentData.id_user == idUser && (
					<div
						className="comment--post--main--container--remove"
						onClick={tryEditComment}
					>
						{loadingProcess ? (
							<MiniSpinner />
						) : (
							<img src={editIcon} alt="..." />
						)}
					</div>
				)}
				{openModalEditComment &&
					<Box>
					 <MiniFormEdit
						currentValue={commentData?.ds_comment}
						closeModal={() => setOpenModalEditComment(false)}
						updateFirstComment={updateFirstComment}
					/> 
					</Box>
				}
				<CustomModal
					openModal={openResultModal}
					setOpenModal={setOpenResultModal}
				>
					<MessageIncompleteData message={messageResultModal} />
				</CustomModal>
			</div>
		);
	}
};

const MiniFormEdit = ({ currentValue, closeModal, updateFirstComment }) => {
	const [data, setData] = useState({
		title: "",
	});

	useEffect(() => {
		setData({ title: currentValue });
	}, [currentValue]);

	const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

	return (
		<div className="mini--form--edit--container">
			<div className="mini--form--edit--container--header">
				<OptionsCancelEdit
					type="button"
					onClick={() => {
						setData({ title: currentValue });
						closeModal();
					}}
				/>
				<div>Editar Comentario</div>
				<OptionsConfirmEdit
					type="button"
					onClick={() => {
						if (data.title === "" || data.title.trim() === currentValue) {
							return;
						}
						closeModal();
						updateFirstComment(data?.title);
						setData({ title: "" });
					}}
				/>
			</div>
			<div className="mini--form--edit--container--body">
				<textarea
					placeholder="..."
					name="title"
					type="text"
					value={data?.title || ""}
					onChange={onChange}
					maxLength="60"
				/>
			</div>
		</div>
	);
};

const OptionsCancelEdit = ({ onClick }) => (
	<Icon type="button" color="primary" onClick={onClick}>
		<CloseIcon />
	</Icon>
);

const OptionsConfirmEdit = ({ onClick }) => (
	<Icon type="button" color="primary" onClick={onClick}>
		<CheckIcon />
	</Icon>
);
