import { TYPES } from './Types';
// import { getIdUser } from "../../libs/login";
import { setHeaders } from '../../libs/fetch';
import { getIdUser } from '../../libs/login';

const mapIds = (list) => list.map((elem) => elem.id_hotmer);

const mapDeals = (list) => {
  return list.map((elem) => {
    const { id_status } = elem;
    const ds_status = id_status == 1 ? 'activo' : 'inactivo';
    const status =
      id_status == 0 ? 'pending' : id_status == 1 ? 'accepted' : 'rejected';
    return {
      ...elem,
      status,
      ds_status,
    };
  });
};

export const setAgreementOption = (value) => ({
  type: TYPES.SET_AGREEMENT_OPTION,
  payload: value,
});

export const setCollaborationOption = (value) => ({
  type: TYPES.SET_COLLABORATION_OPTION,
  payload: value,
});

export const setAgreementStepperOption = (data) => ({
  type: TYPES.SET_AGREEMENT_STEPPER_OPTION,
  payload: data,
});

export const setSelectedHotmers = (data) => ({
  type: TYPES.SET_SELECTED_HOTMERS,
  payload: data,
});

export const decreasePerc = (field) => ({
  type: TYPES.SET_DECREASE_PERCENTAGES,
  payload: field,
});

export const increasePerc = (field) => ({
  type: TYPES.SET_INCREASE_PERCENTAGES,
  payload: field,
});

export const resetPercentages = (data) => ({
  type: TYPES.SET_RESET_PERCENTAGES,
  payload: data,
});

export const setSelectedAgreementHotmerOption = (data) => ({
  type: TYPES.SET_SELECTED_AGREEMENT_HOTMER_OPTION,
  payload: data,
});

export const setSelectedAgreementHotmer = (data) => ({
  type: TYPES.SET_SELECTED_AGREEMENT_HOTMER,
  payload: data,
});

export const setNameParam = (data) => ({
  type: TYPES.SET_NAME_PARAM,
  payload: data,
});

export const setSelectedAgreementDeal = (data) => ({
  type: TYPES.SET_SELECTED_AGREEMENT_DEAL,
  payload: data,
});

export const setAgreementDetail = (data) => ({
  type: TYPES.REQUEST_SUCCESS_AGREEMENT_DETAIL,
  payload: data,
});

export const setSelectedAgreement = (data) => ({
  type: TYPES.SET_SELECTED_AGREEMENT,
  payload: data,
});

export const clearDealsSection = () => ({
  type: TYPES.CLEAR_DEALS_SECTION,
});

export const setHotmerHistory = (data) => ({
  type: TYPES.REQUEST_SUCCESS_HOTMER_HISTORY,
  payload: data,
});

// ASYNC FETCH CALLS !!!
// ---------------------

export const getCollaborations = (rol) => {
  return async (dispatch) => {
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/collaboration`;
    dispatch({ type: TYPES.REQUEST_LOADING_COLLABORATIONS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_COLLABORATIONS,
          payload: mapDeals(response.data),
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_COLLABORATIONS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_COLLABORATIONS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_SUCCESS_COLLABORATIONS });
    }
  };
};

export const getCollaborationsHotmers = (rol, name, limit, offset, extra) => {
  return async (dispatch) => {
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/collaboration/hotmers?hotmer_name=${name}&limit=${limit}&offset=${offset}`;
    if (extra) {
      dispatch({ type: TYPES.REQUEST_LOADING_COLLABORATIONS_HOTMERS_ZERO });
    } else {
      dispatch({ type: TYPES.REQUEST_LOADING_COLLABORATIONS_HOTMERS });
    }
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        console.log(response.data);
        dispatch({
          type: TYPES.REQUEST_SUCCESS_COLLABORATIONS_HOTMERS,
          payload: mapDeals(response.data),
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_COLLABORATIONS_HOTMERS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_COLLABORATIONS_HOTMERS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_COLLABORATIONS_HOTMERS });
    }
  };
};

export const getAgreements = (rol) => {
  return async (dispatch) => {
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/tradeAgreement`;
    dispatch({ type: TYPES.REQUEST_LOADING_AGREEMENTS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_AGREEMENTS,
          payload: mapDeals(response.data),
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_AGREEMENTS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENTS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENTS });
    }
  };
};

export const getAgreementsHotmers = (rol) => {
  return async (dispatch) => {
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/tradeAgreement/hotmers`;
    dispatch({ type: TYPES.REQUEST_LOADING_AGREEMENTS_HOTMERS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_AGREEMENTS_HOTMERS,
          payload: mapDeals(response.data),
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_AGREEMENTS_HOTMERS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENTS_HOTMERS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENTS_HOTMERS });
    }
  };
};

export const createCollaborationAgreement = async (selectedHotmers, rol) => {
  const ids_hotmers = mapIds(selectedHotmers);
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/collaboration`;
  try {
    const res = await fetch(url, {
      headers: setHeaders(),
      method: 'POST',
      body: JSON.stringify({ ids_hotmers }),
    });
    if (res.status === 201) {
      const response = await res.json();
      if (response.code === 201) {
        console.log('OK');
        return { success: true, message: 'Proceso Exitoso. Muchas Gracias' };
      } else {
        console.log('BAD');
        return {
          success: false,
          message: 'Proceso Fallido. Intente mas tarde por favor.',
        };
      }
    } else {
      console.log('BAD');
      return {
        success: false,
        message: 'Proceso Fallido. Intente mas tarde por favor.',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: 'Proceso Fallido. Intente mas tarde por favor.',
    };
  }
};

export const createTradeAgreement = async (
  id_hotmer,
  id_collaboration,
  percentages,
  rol
) => {
  const { subscriptions, packs, present } = percentages;
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/${rol}/${id}/tradeAgreement`;
  try {
    const res = await fetch(url, {
      headers: setHeaders(),
      method: 'POST',
      body: JSON.stringify({
        id_hotmer,
        id_collaboration,
        vl_subscription: subscriptions,
        vl_packs: packs,
        vl_tips: present,
      }),
    });
    if (res.status === 201) {
      const response = await res.json();
      if (response.code === 201) {
        console.log('OK');
        return { success: true, message: 'Proceso Exitoso. Muchas Gracias' };
      } else {
        console.log('BAD');
        return {
          success: false,
          message: 'Proceso Fallido. Intente mas tarde por favor.',
        };
      }
    } else {
      console.log('BAD');
      return {
        success: false,
        message: 'Proceso Fallido. Intente mas tarde por favor.',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: 'Proceso Fallido. Intente mas tarde por favor.',
    };
  }
};

export const getAgreementDetail = (hotmer) => {
  return async (dispatch) => {
    dispatch(setSelectedAgreement(hotmer));
    dispatch({ type: TYPES.SET_SELECTED_AGREEMENT_DEAL, payload: hotmer });
    const session = localStorage.getItem('session');
    const { user } = JSON.parse(session);
    const rol = user.rol;
    const id = getIdUser();
    const initial = process.env.REACT_APP_INITIAL_V2;
    let url = '';
    if (rol == 'agency') {
      url = `${initial}/${rol}/${id}/tradeAgreement/${hotmer.id_trade_agreement}/hotmers/${hotmer.id_hotmer}/statistics`;
      console.log(url);
    } else if (rol == 'photographer') {
      url = `${initial}/${rol}/${id}/tradeAgreement/hotmers/${hotmer.id_hotmer}/packs`;
    } else if (rol == 'manager') {
      url = `${initial}/agency/${id}/tradeAgreement/hotmers/${hotmer.id_hotmer}/packs`;
      //*LE METI EL DE AGENCIA PORQUE EL ENDPOINT DE MANAGER NO
    } else {
      const { ds_type_of_role, id_trade_agreement, id_hotmer } = hotmer;
      url = `${initial}/${rol}/${id}/tradeAgreement/${id_trade_agreement}/${ds_type_of_role}/${id_hotmer}/statistics`;
    }
    dispatch({ type: TYPES.REQUEST_LOADING_AGREEMENT_DETAIL });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch(setAgreementDetail(response.data));
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_AGREEMENT_DETAIL,
          payload: [],
        });
      } else {
        console.log('BAAAADDDD 200000000000');
        dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENT_DETAIL });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_AGREEMENT_DETAIL });
    }
  };
};

export const getHotmerHistory = (hotmer) => {
  return async (dispatch) => {
    // dispatch({ type: TYPES.SET_SELECTED_AGREEMENT_DEAL, payload: hotmer });
    const session = localStorage.getItem('session');
    const { user } = JSON.parse(session);
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/${user.rol}/${id}/tradeAgreement/hotmers/${hotmer.id_hotmer}/statistics`;
    dispatch({ type: TYPES.REQUEST_LOADING_HOTMER_HISTORY });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch(setHotmerHistory(response.data));
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_HOTMER_HISTORY,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_HOTMER_HISTORY });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_HOTMER_HISTORY });
    }
  };
};
