import React from "react";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import "./videoContent.css";

export const VideoContent = ({ selectedVideo }) => {
	if (selectedVideo) {

		return (
			<div className="video--content--main--container">
				<Player
					autoPlay={true}
					fluid={true}
					width="100%"
					poster={selectedVideo.data[0].ds_url_thumbnail}
					src={
						selectedVideo.data
							? selectedVideo.data[0].ds_url
							: selectedVideo.ds_url
					}
				>
					<BigPlayButton position="center" />
				</Player>
			</div>
		);
	} else {
		return <></>;
	}
};
