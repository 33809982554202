import DealHotmerHistoryClon from '../DealHotmerHistoryClon/DealHotmerHistoryClon';
import { Topic } from '../Topic/Topic';
import { connect } from 'react-redux';
import { DealPack } from '../DealPack/DealPack';
import { RoundedNoContent } from '../RoundedNoContent/RoundedNoContent';
import { createStructuredSelector, createSelector } from 'reselect';
import './AgreementDealSelected.css';

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({ loadingAgreementDetail, errorAgreementDetail, agreementDetail }) => ({
        loadingAgreementDetail,
        errorAgreementDetail,
        agreementDetail,
      })
    ),
  })
)(({ propsDeal }) => {
  const { loadingAgreementDetail, errorAgreementDetail, agreementDetail } =
    propsDeal;
  const session = localStorage.getItem('session');
  const { user } = JSON.parse(session);

  if (loadingAgreementDetail) {
    return <div className="agreement--deal--main--container">Loading...</div>;
  } else if (errorAgreementDetail) {
    return <div className="agreement--deal--main--container">Error...</div>;
  } else if (
    agreementDetail &&
    user.rol != 'photographer' &&
    agreementDetail.rates
  ) {
    const { rates, subscriptions, packs, tips } = agreementDetail;

    return (
      <div className="agreement--deal--main--container">
        <TopicDetail title="Tarifas Aplicadas al Perfil">
          <RowData>
            <Topic
              title="Suscripciones"
              val={rates.vl_subscription}
              color="#000000"
            />
            <Topic title="Packs" val={rates.vl_pack} color="#000000" />
            <Topic title="Regalos" val={rates.vl_tip} color="#000000" />
          </RowData>
        </TopicDetail>
        {agreementDetail.profits && (
          <TopicDetail title="Ganancias Totales">
            <Topic
              title={`Int ${agreementDetail.profits.international.ds_currency}`}
              val={agreementDetail.profits.international.vl_value}
              color="#000000"
            />
            <Topic
              title={`Local ${agreementDetail.profits.local.ds_currency}`}
              val={agreementDetail.profits.local.vl_value}
              color="#000000"
            />
          </TopicDetail>
        )}
        <TopicDetail title="Resumen Suscripciones">
          <RowData>
            <Topic
              title="Suscripciones"
              val={subscriptions.vl_units_sales}
              color="#000000"
            />
            <Topic
              title="Reclamos"
              val={subscriptions.vl_units_reclamos || 0}
              color="#000000"
            />
            <Topic
              title="Cancelaciones"
              val={subscriptions.vl_units_cancelled}
              color="#000000"
            />
          </RowData>
          {subscriptions.profits && (
            <>
              <Topic
                title={`Int ${subscriptions.profits.international.ds_currency}`}
                val={subscriptions.profits.international.vl_value}
                color="#000000"
              />
              <Topic
                title={`Local ${subscriptions.profits.local.ds_currency}`}
                val={subscriptions.profits.local.vl_value}
                color="#000000"
              />
            </>
          )}
        </TopicDetail>
        <TopicDetail title="Resumen Packs">
          <RowData>
            <Topic title="Ventas" val={packs.vl_units_sales} color="#000000" />
            <Topic
              title="Reclamos"
              val={packs.vl_units_reclamos || 0}
              color="#000000"
            />
            <Topic
              title="Cancelaciones"
              val={packs.vl_units_cancelled}
              color="#000000"
            />
          </RowData>
          {packs.profits && (
            <>
              <Topic
                title={`Int ${packs.profits.international.ds_currency}`}
                val={packs.profits.international.vl_value}
                color="#000000"
              />
              <Topic
                title={`Local ${packs.profits.local.ds_currency}`}
                val={packs.profits.local.vl_value}
                color="#000000"
              />
            </>
          )}
        </TopicDetail>
        <TopicDetail title="Resumen Regalos">
          <RowData>
            <Topic title="Ventas" val={tips.vl_units_sales} color="#000000" />
            <Topic
              title="Reclamos"
              val={tips.vl_units_reclamos || 0}
              color="#000000"
            />
            <Topic
              title="Cancelaciones"
              val={tips.vl_units_cancelled}
              color="#000000"
            />
          </RowData>
          {tips.profits && (
            <>
              <Topic
                title={`Int ${tips.profits.international.ds_currency}`}
                val={tips.profits.international.vl_value}
                color="#000000"
              />
              <Topic
                title={`Local ${tips.profits.local.ds_currency}`}
                val={tips.profits.local.vl_value}
                color="#000000"
              />
            </>
          )}
        </TopicDetail>
      </div>
    );
  } else if (
    agreementDetail &&
    user.rol != 'photographer' &&
    agreementDetail.rates_of_packs
  ) {
    return <DealHotmerHistoryClon />;
  } else if (agreementDetail && user.rol === 'photographer') {
    return (
      <div
        className={`agreement--deal--main--container ${
          agreementDetail.length === 0 ? 'empty--container' : ''
        }`}
      >
        {agreementDetail.length === 0 ? (
          <RoundedNoContent title="Aun no creo un Pack con tu Acuerdo Comercial" />
        ) : (
          <div>
            {agreementDetail.map((pack) => (
              <DealPack key={pack.ds_title} pack={pack} />
            ))}
          </div>
        )}
      </div>
    );
  } else if (
    agreementDetail &&
    user.rol === 'agency' &&
    agreementDetail.length === 0
  ) {
    return (
      <div className="agreement--deal--main--container">
        <RoundedNoContent title="No hay estatdisticas disponibles" />;
      </div>
    );
  } else {
    return (
      <div className="agreement--deal--main--container">
        <RoundedNoContent title="Sin estadisticas disponibles" />
      </div>
    );
  }
});

const TopicDetail = ({ title, children }) => {
  return (
    <div className="topic--detail--main--container">
      <div className="topic--detail--main--container--header">{title}</div>
      <div className="topic--detail--main--container--body">{children}</div>
    </div>
  );
};

const RowData = ({ children }) => {
  return <div className="row--data--main--container">{children}</div>;
};
