/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import gpsIcon from "../../icons/content/gps-icon.png";
import phoneIcon from "../../icons/grayIcons/phone-icon-gray.png";
import typeIcon from "../../icons/content/type-icon.png";
import dataIcon from "../../icons/content/datos.png";
import genderIcon from "../../icons/content/gender-icon.png";
import usuarioIcon from "../../icons/content/usuario-icon.png";
import instagramIcon from "../../icons/instagram.png";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { Conditions } from "../../components/conditions/conditions";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { CustomButtonNext } from "../../commonComponents/customButtonNext/customButtonNext";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";

import nubeGreenOk from "../../icons/modals/nube--green--ok.png";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";

import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomInputTextIcon } from "../../commonComponents/customInputTextIcon/customInputTextIcon";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { useSelector, useDispatch } from "react-redux";
import { CustomInputTextIconForModal } from "../../commonComponents/customInputTextIconForModal/customInputTextIconForModal";
import {
  setLoadingToBecomeHotmer,
  setScreenPosition,
  setUserAccountCreation,
} from "../../redux/actions";
import {
  conditionsMessage,
  constantDataRoles,
  createNewUserData,
  createProfile,
  getIdGender,
  getIdRole,
  incompleteJson,
  incompleteMessage,
  initialStateData,
  mapInitGenderHotmer,
} from "../../libs/account";
import "./createHotmerContent.css";

export const CreateHotmerContent = ({ handleNext }) => {
  const state = useSelector((state) => state.main);
  const { userAccountCreation, preferencesGenders } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  const [preferences, setPreferences] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalGender, setOpenModalGender] = useState(false);
  const [openModalRole, setOpenModalRole] = useState(false);
  const [openModalToFinal, setOpenModalToFinal] = useState(false);

  // Modal para mostrar mensaje final al usuario
  // cuando completa todo el formulario de alta hotmer
  const [modalMessageResult, setModalMessageResult] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  const [openModalMessageResult, setOpenModalMessageResult] = useState(false);
  const [modalIcon, setModalIcon] = useState(null);
  const acceptClick = async () => {
    window.location.href = "/";
  };

  const [buttonNextDisabled, setButtonNextDisabled] = useState(null);
  const [bloquear, setBloquear] = useState(false);

  const [userData, setUserData] = useState(initialStateData);
  const [checked, setChecked] = useState(false);

  const onChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });

  const onChangeCheck = () => setChecked((x) => !x);
  const changeCheckBox = () => setChecked((x) => !x);

  const openModalConditions = () => {
    setModalMessage(conditionsMessage);
    setOpenModal(true);
  };

  const functionOpenModalGender = () => setOpenModalGender(true);
  const functionOpenModalRole = () => setOpenModalRole(true);

  // const handleChangeGender = (e) => {
  // setUserData({ ...userData, [e.target.name]: e.target.value });
  // setOpenModalGender((x) => !x);
  // };

  const handleChangeGender = (option) => {
    // console.log(option);
    setUserData({ ...userData, gender: option.value });
    setOpenModalGender((x) => !x);
  };

  const handleChangeRole = (option) => {
    // console.log(option);
    setUserData({ ...userData, id_role: option.value });
    setOpenModalRole((x) => !x);
  };

  const acceptTrending = () => {
    setOpenModalToFinal(false);
    handleNext();
  };
  const omitTrending = () => {
    setOpenModalToFinal(false);
    dispatch(setScreenPosition(0));
    history.push("/");
  };

  const handleSubmit = async () => {
    if (incompleteJson(userData) || !checked) {
      setModalMessage(incompleteMessage);
      setOpenModal(true);
    } else {
      console.log(userData);
      dispatch(setLoadingToBecomeHotmer());
      const newState = { ...userAccountCreation, userData };
      dispatch(setUserAccountCreation(newState));
      const idGender = getIdGender(preferences, userData);
      const idRole = getIdRole(constantDataRoles, userData);
      const newUserData = createNewUserData(userData, idGender, idRole);
      console.log("NEW FINAL JSON: ", newUserData);
      try {
        const userFinalJson = createProfile(userAccountCreation, newUserData);
        console.log("USER FINAL JSON: ", userFinalJson);
        const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/create`;
        const res = await axios.post(url, userFinalJson, {
          headers: setHeaders(),
        });
        console.log("RESPONSE CREATE HOTMER: ", res);
        if (res.data.code === 200) {
          dispatch(setLoadingToBecomeHotmer());
          setButtonNextDisabled(true);
          /*
					let session = JSON.parse(localStorage.getItem("session"));
					let user = session.user;
					let profile = JSON.stringify({
						id_stat: -1,
						ds_gender: userData.gender,
						ds_region: "",
						ds_address: userData.address,
						ds_country: "",
						ds_twitter: "",
						id_profile: -1,
						ds_locality: "",
						ds_instagram: userData.instagram,
						ds_document_id: "",
						ds_display_name: userData.nameHotmer.toLocaleLowerCase(),
						id_type_of_gender: 2,
						ds_url_img_cover_art: null,
						ds_url_video_presentation: null,
					});
					user.hotmer = true;
					user.profile = profile;
					localStorage.setItem(
						"session",
						JSON.stringify({ ...session, user: user }),
					);
					handleNext();
					*/

          setModalMessageResult(
            "Datos enviados correctamente. Procederemos a validar los mismos."
          );
          setModalMessageButtonText("ACEPTAR");
          setOpenModalMessageResult(true);
          setModalIcon(nubeGreenOk);
        } else {
          console.log("RES NO TRUE: ", res.data);
          dispatch(setLoadingToBecomeHotmer());
        }
      } catch (error) {
        console.log("ERROR: ", error);
        dispatch(setLoadingToBecomeHotmer());
      }
    }
  };

  useEffect(() => {
    if (preferencesGenders) {
      const list = mapInitGenderHotmer(preferencesGenders);
      console.log(list);
      setPreferences(list);
    }
  }, [preferencesGenders]);

  useEffect(() => {
    if (buttonNextDisabled != null) {
      setBloquear(buttonNextDisabled);
    }
  }, [buttonNextDisabled]);

  return (
    <div className="create--hotmer--content--main--container">
      <DividerSpace space="L" />
      <div className="create--hotmer--content--main--container--title">
        Creador de Contenido
      </div>
      <DividerSpace space="M" />
      <div className="create--hotmer--content--main--container--inputs">
        <CustomInputTextIcon
          placeholder="Nombre de Usuario"
          name="nameHotmer"
          type="text"
          value={userData.nameHotmer.toLowerCase().trim()}
          onChange={onChange}
          image={usuarioIcon}
          limit={30}
        />
        <CustomInputTextIcon
          placeholder="Nombre y Apellido"
          name="fullName"
          type="text"
          value={userData.fullName.toLowerCase()}
          onChange={onChange}
          image={dataIcon}
          limit={50}
        />

        <CustomInputTextIcon
          placeholder="Dirección"
          name="address"
          type="text"
          value={userData.address}
          onChange={onChange}
          image={gpsIcon}
          limit={50}
          visible={false}
        />

        <CustomInputTextIcon
          placeholder="541512340000"
          name="phoneNumber"
          type="text"
          value={userData.phoneNumber}
          onChange={onChange}
          image={phoneIcon}
          limit={15}
          visible={true}
        />

        <CustomInputTextIconForModal
          placeholder="Género"
          name="gender"
          type="text"
          value={userData.gender}
          image={genderIcon}
          onClick={() => functionOpenModalGender()}
        />
        <CustomInputTextIconForModal
          placeholder="Tipo"
          name="id_role"
          type="text"
          value={userData.id_role}
          image={typeIcon}
          onClick={() => functionOpenModalRole()}
        />
        <CustomInputTextIcon
          placeholder="Instagram"
          name="instagram"
          type="text"
          value={userData.instagram.toLowerCase().trim()}
          onChange={onChange}
          image={instagramIcon}
          limit={30}
        />
      </div>
      <DividerSpace space="XXS" />
      <Conditions
        checked={checked}
        onChangeCheck={onChangeCheck}
        changeCheckBox={changeCheckBox}
        onClick={openModalConditions}
      />
      <DividerSpace space="XS" />
      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <MessageIncompleteData message={modalMessage} />
      </CustomModal>

      <CustomModal
        openModal={openModalGender}
        setOpenModal={setOpenModalGender}
      >
        <MobileWheelSelector
          title={"GÉNERO"}
          type="onlyText"
          data={preferences}
          idList={"GÉNEROS"}
          chooseElement={handleChangeGender}
        />
      </CustomModal>
      <CustomModal openModal={openModalRole} setOpenModal={setOpenModalRole}>
        <MobileWheelSelector
          title={"ROLES"}
          type="onlyText"
          data={constantDataRoles}
          idList={"ROLID"}
          chooseElement={handleChangeRole}
        />
      </CustomModal>
      <CustomModal
        openModal={openModalToFinal}
        setOpenModal={setOpenModalToFinal}
        discardExternalEvents={true}
      >
        <OptionToTrending
          title={"¿ Querés ser creador de Contenido ?"}
          acceptClick={acceptTrending}
          notAcceptclick={omitTrending}
        />
      </CustomModal>

      <CustomModal
        openModal={openModalMessageResult}
        setOpenModal={setOpenModalMessageResult}
        discardExternalEvents={true}
      >
        <OptionTryAgain
          icon={modalIcon}
          acceptClick={acceptClick}
          discardExternalEvents={true}
          title={modalMessageResult}
          buttonText={modalMessageButtonText}
          whatsApp={true}
        />
      </CustomModal>

      <CustomButtonNext disabled={bloquear} onClick={handleSubmit}>
        CONFIRMAR
      </CustomButtonNext>
      <DividerSpace space="XS" />
    </div>
  );
};
