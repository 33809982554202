import { TopicUpgrade } from "../Topic/TopicUpgrade";
import ShareIcon from "@material-ui/icons/Share";
import { useHistory } from "react-router-dom";
import "./PacksOfCreators.css";

export const PacksOfCreators = ({ pack }) => {
  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);
  const history = useHistory();
  const go = () => history.push(`/packView/${pack.id_hotmer}/${pack.id_pack}`);
  const goEditPack = () => history.push(`/packUpdate/${pack.id_hotmer}/${pack.id_pack}`);

  const sharePack = (e) => {
    e.preventDefault();
    if (!navigator.share) {
      alert("Navegador no soporta la Web Share API");
      return;
    }
    navigator
      .share({
        url: process.env.PUBLIC_URL + "/packView/" + user?.id + "/" + pack?.id_pack,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  return (
    <div className="parent-grid-Pack-of-creators">
      <div className="parent-grid-header">
        <div className="hotmer--pack--search--main--container--content--image" style={{ marginTop: '0.6rem' }}>
          <img src={pack?.ds_url_thumbnail} alt="..." />
        </div>
        <div className="" style={{ color: 'white', marginTop: '0.6rem' }}>
          <TopicUpgrade title="Titulo : " val={pack?.ds_title} color={null} />
          <TopicUpgrade title="Descripcion: " val={pack?.ds_description} color="#ffffff" />
          <TopicUpgrade title="Precio: " val={`USD ${pack?.vl_price}`} color={null} />
          {pack?.currency &&
            <TopicUpgrade title="Precio Local: " val={`${pack?.ds_currency_code} ${pack?.currency}`} color={null} />
          }
          <TopicUpgrade title="Fotos : " val={pack?.count_image} color="#ffffff" />
          <TopicUpgrade title="Videos : " val={pack?.count_video} color="#ffffff" />
          <TopicUpgrade title="Id pack: " val={pack?.id_pack} color="#ffffff" />
        </div>
      </div>
      <div>
      </div>
      <div className="parent-grid-Pack-of-creators-shares">
        <button className="parent-grid-Pack-of-creators-shares-items"
          onClick={go}>
          VER
        </button>
        <button className="parent-grid-Pack-of-creators-shares-items"
          onClick={goEditPack}>
          EDITAR
        </button>
        <button className="parent-grid-Pack-of-creators-shares-items"
          onClick={sharePack}>
          <ShareIcon fontSize="large" onClick={sharePack} />
        </button>
      </div>
    </div>
  );
};
