import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { OpsSmallFire } from "../opsSmallFire/opsSmallFire";
import { HotmerWishPack } from "../hotmerWishPack/hotmerWishPack";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { setScreenPosition, setTabSearch } from "../../redux/actions";
import { deletePack } from "../../redux/fire/Actions";
import { useTranslation } from "react-i18next";

export const GridWishPacks = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.fire);

  const history = useHistory();
  const { t } = useTranslation("global");
  const [openModal, setOpenModal] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null);
  const [title, setTitle] = useState(t("fire.modal.title"));
  const [disabledButtons, setDisabledButtons] = useState(false);
  const { loadingWishPacks, errorWishPacks, wishPacks } = state;

  const redirectToSearch = () => {
    history.push(`/search`);
    dispatch(setScreenPosition(1));
    dispatch(setTabSearch(1));
    localStorage.setItem("idUrl", 1);
  };

  const removeFollowPack = (id_hotmer, id_pack) => {
    const packs = wishPacks.filter((x) => x.id_pack !== id_pack);
    dispatch({ type: "REQUEST_SUCCESS_WISH_PACKS", payload: packs });
  };

  const removePack = async (id_hotmer, id_pack) => {
    setDisabledButtons(true);
    setTitle(t("fire.modal.processing"));
    const { success } = await deletePack(id_hotmer, id_pack);
    if (success) {
      removeFollowPack(id_hotmer, id_pack);
      setTitle(t("fire.modal.successfulProccess"));
    } else {
      setTitle(t("fire.modal.failureProccess"));
    }
    setTimeout(() => {
      setOpenModal(false);
      setSelectedPack(null);
      setDisabledButtons(false);
      setTitle(t("fire.modal.title"));
    }, 700);
  };

  const accept = () => removePack(selectedPack.id_hotmer, selectedPack.id_pack);

  const toggleModal = ({ id_hotmer, id_pack }) => {
    setOpenModal((x) => !x);
    setSelectedPack({ id_hotmer, id_pack });
    setDisabledButtons(false);
  };

  return (
    <Grid item xs={12}>
      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <OptionToTrending
          title={title}
          acceptClick={accept}
          disabledButtons={disabledButtons}
        />
      </CustomModal>
      <Grid container justifyContent="center" spacing={0}>
        {loadingWishPacks ? (
          <PartialSpinner />
        ) : errorWishPacks ? (
          <OpsSmallFire
            title={t("fire.modal.title")}
            description={t("fire.modal.description")}
            onClick={() => {}}
          />
        ) : wishPacks && wishPacks.length !== 0 ? (
          wishPacks.map((pack, i) => (
            <HotmerWishPack
              key={i}
              pack={pack}
              owner={1}
              onClick={() => toggleModal(pack)}
            />
          ))
        ) : (
          <OpsSmallFire
            title={t("fire.wish.packs.noContent.title")}
            description={t("fire.wish.packs.noContent.description")}
            onClick={redirectToSearch}
          />
        )}
      </Grid>
    </Grid>
  );
};
