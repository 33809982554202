import React from "react";
// import google from "../../icons/login-logo-google-color.svg";
// import mainIcon from "../../icons/main-logo.png";
// import facebook from "../../icons/login-logo-facebook.svg";
// import { useHistory } from "react-router-dom";
import "./faqs.css";

export const Faqs = () => {
	// const history = useHistory();
	// const handleBack = () => history.push("/login");

	return (
			 <div className="faqs">

				<div className="faqs__container__main">
				{/* <!-- COMIENZO DE CONTAINER DE SECCION faqs --> */}
				<div className="faqs__header__main">
					Faqs
				</div>
				<section className="ds-faq-section">
					<div className="ds-faq-container">
						<div className="wrap-collabsible">
							<input id="questionA" className="toggle" type="checkbox"/>
							<label htmlFor="questionA" className="ds-faq-toggle-wrapper">Que es hotmy?</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									Hotmy es una aplicación destinada a aquellas personas que quieran darle valor a su contenido Multimedia, con la generan ingresos. Estos ingresos pueden ser en moneda local o Internacional u$s.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionB" className="toggle" type="checkbox"/>
							<label htmlFor="questionB" className="ds-faq-toggle-wrapper">Quiénes pueden ser Creadores de Contenido?</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									Únicamente personas mayores de edad según la ley de cada país. Que validen conrrectamente su identidad.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionC" className="toggle" type="checkbox"/>
							<label htmlFor="questionC" className="ds-faq-toggle-wrapper">Quienes pueden registrarse?</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									No hay limitaciones para registrarse, todos pueden ser parte de hotmy.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionD" className="toggle" type="checkbox"/>
							<label htmlFor="questionD" className="ds-faq-toggle-wrapper">
							Hay que pagar para ser creador Contenido?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									Crearte el perfil es gratuito, no pagas creación de cuenta ni mantenimiento de la misma. 
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionE" className="toggle" type="checkbox"/>
							<label htmlFor="questionE" className="ds-faq-toggle-wrapper">
							¿Qué comisión se queda Hotmy ?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									Hotmy comisiona solo el % 20 de las transferencias para el mantenimiento de las pasarelas de pago, el  <b>80%</b>  restante queda para el Creador de Contenido.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionF" className="toggle" type="checkbox"/>
							<label htmlFor="questionF" className="ds-faq-toggle-wrapper">
							Puedo Extraer mis Dolares a Moneda Local?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
										La aplicacion permite la conversion instantanea de Dolares a Moneda local (*). No hagas mas procedimientos engorrosos, todo en una misma aplicación.
									</p>
									<p className="kfds-font-secondary">
										(*) . Unicamente vigente para el Territorio de la Republica Argentina. Moneda Local hace referencia a Pesos Argentinos.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionG" className="toggle" type="checkbox"/>
							<label htmlFor="questionG" className="ds-faq-toggle-wrapper">
							¿Cuándo puedo retirar el dinero?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
									Una vez ingresado el pago por suscripción o venta de pack, se vera reflejado en Dinero a Liquidar, pasado los  <b>7</b> (*)  días, se verá reflejado en Dinero Disponible, una vez alcanzado el límite mínimo de extracción podes realizar la extracción.
									</p>
									<p className="kfds-font-secondary">
									(*). Promoción Vigente del 5/10 al 31/12/2021
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionH" className="toggle" type="checkbox"/>
							<label htmlFor="questionH" className="ds-faq-toggle-wrapper">
							Cuanto es el minimo de retiro?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
								Siendo Creador de Contenido : Abrir el menú lateral + Mi dinero + Extraer Dinero + seleccionar el tipo de Moneda .Debajo veras el detalle de cual es el valor minimo de extracción.
									</p>
								</div>
							</div>
						</div>
						<div className="wrap-collabsible">
							<input id="questionJ" className="toggle" type="checkbox"/>
							<label htmlFor="questionJ" className="ds-faq-toggle-wrapper">
							Sabias que en hotmy tenes asesoramiento exclusivo si sos creador de contenido?
							</label>
							<div className="collapsible-content">
								<div className="content-inner">
									<p className="kfds-font-secondary">
										Tenemos una linea directa para atender tus consultas, no estas solo, te acompañamos para que ganes mas.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				{/* <!-- FIN DE CONTAINER SECCION faqs --> */}
				</div>
				<footer className="footer">
					<a href="https://www.hotmy.net/terminos-condiciones">Terminos y Condiciones</a>
					<a href="https://www.hotmy.net/faq">Faqs</a>
				</footer> 
				{/* <!-- FIN DE SECCION faqs --> */}
			 </div >      
			);
	};
