/* eslint-disable */
export const initialAboutMe = {
  about: "",
};

export const initialPersonalData = {
  full_name: "",
  ds_display_name: "",
  ds_instagram: "",
  ds_twitter: "",
  ds_phone_number: "",
  ds_telegram: "",
};

export const initialPersonalFeatures = {
  height: { description: "" },
  weight: { description: "" },
  eye: { description: "" },
  hair: { description: "" },
  skin: { description: "" },
  chest: { description: "" },
  tatoo: { description: "" },
  piercing: { description: "" },
};

export const compareStates = (json1, json2) => {
  const js1 = JSON.stringify(json1).replace(/ /g, "");
  const js2 = JSON.stringify(json2).replace(/ /g, "");
  return js1 === js2;
};

export const subscriptions = [
  {
    id: 0,
    headerTitleCount: "1",
    headerTitleText: "Mes",
    title: "Valor x 1 Mes",
    publicTitle: "Valor Publicado x 1 Mes",
    description: "1 Mes de Suscripción",
    name: "vl_month1_price",
    discount: 0,
    vl_price: "",
    vl_old_price: "",
  },
  {
    id: 1,
    headerTitleCount: "3",
    headerTitleText: "Meses",
    title: "Valor x 3 Meses",
    publicTitle: "Valor Publicado x 3 Meses",
    description: "3 Meses de Suscripción",
    name: "vl_month3_price",
    discount: 5,
    vl_price: "",
    vl_old_price: "",
  },
  {
    id: 2,
    headerTitleCount: "6",
    headerTitleText: "Meses",
    title: "Valor x 6 Meses",
    publicTitle: "Valor Publicado x 6 Meses",
    description: "6 Meses de Suscripción",
    name: "vl_month6_price",
    discount: 10,
    vl_price: "",
    vl_old_price: "",
  },
  {
    id: 3,
    headerTitleCount: "12",
    headerTitleText: "Meses",
    publicTitle: "Valor Publicado x 12 Meses",
    description: "12 Meses de Suscripción",
    name: "vl_month12_price",
    discount: 15,
    vl_price: "",
    vl_old_price: "",
  },
];

export const mapInitSubscriptions = (data, subscriptionsList) => {
  let json = {};
  for (let key in data) {
    if (key.includes("vl_month")) {
      json = { ...json, [key]: data[key] };
    }
  }
  const values = Object.values(json);
  const finalList = subscriptionsList.map((element, i) => {
    return {
      ...element,
      vl_tax: data.vl_tax,
      ds_currency: data.ds_currency,
      vl_conversion_rate: data.vl_conversion_rate,
      vl_price: parseFloat(values[i]).toFixed(2),
      vl_old_price: parseFloat(values[i]).toFixed(2),
    };
  });
  // console.log("SUSCRIPCIONES: ", finalList);
  return finalList;
};

export const updateSubscriptions = (e, suscriptionList) => {
  const { name, value } = e.target;
  const newList = suscriptionList.map((element) => {
    if (element.name == name) {
      return {
        ...element,
        vl_price: value,
      };
    } else {
      return element;
    }
  });
  return newList;
};

export const subscriptionMessages = [
  {
    id: 0,
    title: "Valor: ",
    description: "Importe valor base.",
  },
  {
    id: 1,
    title: "Moneda país: ",
    description: "Conversión al tipo de cambio de la moneda oficial del país.",
  },
  {
    id: 2,
    title: "Gastos financieros: ",
    description:
      "Costos de plataformas de pago (Mercadopago - Paypal - otros).",
  },
  {
    id: 3,
    title: "",
    description:
      "Los valores pueden variar en el momento de realizar la extracción por el tipo de cambio.",
  },
];

export const mapInitPersonalData = (personalDataJson) => {
  let json = {};
  for (let key in personalDataJson) {
    const value = personalDataJson[key];
    json[key] = value === null ? "" : value;
  }
  return json;
};

const tryToFindFieldHeight = (list, field) => {
  let jsonResult;
  let height = {};
  try {
    jsonResult = list.find((x) => x !== null && x[field]);
  } catch (error) {
    jsonResult = null;
  }
  if (!jsonResult) {
    height = {
      description: "",
      old_id: -1,
      new_id: -1,
    };
  } else {
    height = {
      description: jsonResult.ds_cm + " cm / " + jsonResult.ds_ft + " ft",
      old_id: jsonResult[field],
      new_id: jsonResult[field],
    };
  }
  return height;
};

const tryToFindFieldWeight = (list, field) => {
  let jsonResult;
  let weight = {};
  try {
    jsonResult = list.find((x) => x !== null && x[field]);
  } catch (error) {
    jsonResult = null;
  }
  if (!jsonResult) {
    weight = {
      description: "",
      old_id: -1,
      new_id: -1,
    };
  } else {
    weight = {
      description: jsonResult.ds_kg + " kg / " + jsonResult.ds_lbs + " lbs",
      old_id: jsonResult[field],
      new_id: jsonResult[field],
    };
  }
  return weight;
};

const tryToFindField = (list, field) => {
  let jsonResult;
  let json = {};
  try {
    jsonResult = list.find((x) => x[field]);
  } catch (error) {
    jsonResult = null;
  }
  if (!jsonResult) {
    json = {
      description: "",
      old_id: -1,
      new_id: -1,
    };
  } else {
    json = {
      description: jsonResult.ds_name,
      old_id: jsonResult[field],
      new_id: jsonResult[field],
    };
  }
  return json;
};

const tryToFindFieldBoolean = (list, field) => {
  // console.log("field: ", field);
  let jsonResult;
  let json = {};
  try {
    jsonResult = list.find(
      (x) => x[`${field}`] && typeof x[field] == "boolean"
    );
  } catch (error) {
    jsonResult = null;
  }
  if (!jsonResult) {
    json = {
      description: "No",
      old_id: 2,
      new_id: 2,
    };
  } else {
    // console.log("jsonResult: ", jsonResult);
    json = {
      description: jsonResult[field] ? "Si" : "No",
      old_id: jsonResult[field] == true ? 1 : 2,
      new_id: jsonResult[field] == true ? 1 : 2,
    };
  }
  return json;
};

export const mapInitPersonalFeatures = (features) => {
  const height = tryToFindFieldHeight(features, "id_type_of_height");
  const weight = tryToFindFieldWeight(features, "id_type_of_weight");

  const eye = tryToFindField(features, "id_type_of_eye_color");
  const hair = tryToFindField(features, "id_type_of_hair");
  const skin = tryToFindField(features, "id_type_of_skin_tone");
  const chest = tryToFindField(features, "id_type_of_chest");

  const tatoo = tryToFindFieldBoolean(features, "bl_tatoo");
  const piercing = tryToFindFieldBoolean(features, "bl_piercing");

  const json = {
    height,
    weight,
    eye,
    hair,
    skin,
    chest,
    tatoo,
    piercing,
  };
  // console.log("MY FEATURES: ", json);
  return json;
};

export const createFeatureOptions = (options, param) => {
  let resultOptions = [];
  if (param === "height") {
    resultOptions = options.map((opt) => {
      return {
        name: param,
        value: opt.cm + " cm / " + opt.ft + " ft",
        id: opt.id_type_of_height,
      };
    });
  } else if (param === "weight") {
    resultOptions = options.map((opt) => {
      return {
        name: param,
        value: opt.kg + " kg / " + opt.lbs + " lbs",
        id: opt.id_type_of_weight,
      };
    });
  } else if (param === "skin") {
    resultOptions = options.map((opt) => {
      return {
        name: param,
        value: opt.tone + " / " + opt.description,
        id: opt.id_type_of_skin_tone,
      };
    });
  } else {
    resultOptions = options.map((opt) => {
      return {
        name: param,
        value: Object.values(opt)[0],
        id: Object.values(opt)[1],
      };
    });
  }
  return resultOptions;
};

export const getVisitorPreferences = () =>
  JSON.parse(localStorage.getItem("session")).user.preference;

export const getProfileImage = () =>
  JSON.parse(localStorage.getItem("session")).user.img_profile;

export const getSelectedIdGenders = (editPreferences) => {
  return editPreferences
    .filter((x) => x.checked == true)
    .map((x) => x.id_type_of_gender);
};

const jsonTransform = (structure) => JSON.stringify(structure);

export const compareAboutMe = (a, b) => jsonTransform(a) == jsonTransform(b);

export const comparePersonalData = (dataFetch, personalData) => {
  const countFields = Object.keys(dataFetch).length;
  let countEquals = 0;
  for (var key in personalData) {
    if (dataFetch[key] == personalData[key]) {
      countEquals++;
    }
  }
  if (countFields == countEquals) {
    return 0;
  } else {
    return 1;
  }
};

export const getIdFeatures = (personalFeatures) => {
  // console.log("PARA ENVIAR: ", personalFeatures);
  let finalJson = {};
  if (personalFeatures.chest.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_chest: personalFeatures.chest.new_id,
    };
  }
  if (personalFeatures.eye.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_eye_color: personalFeatures.eye.new_id,
    };
  }
  if (personalFeatures.hair.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_hair: personalFeatures.hair.new_id,
    };
  }
  if (personalFeatures.height.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_height: personalFeatures.height.new_id,
    };
  }
  if (personalFeatures.piercing.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_piercing: personalFeatures.piercing.new_id,
    };
  }
  if (personalFeatures.skin.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_skin_tone: personalFeatures.skin.new_id,
    };
  }
  if (personalFeatures.tatoo.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_tatoo: personalFeatures.tatoo.new_id,
    };
  }
  if (personalFeatures.weight.new_id > 0) {
    finalJson = {
      ...finalJson,
      id_type_of_weight: personalFeatures.weight.new_id,
    };
  }

  // console.log("PARA ENVIAR: ", finalJson);
  return finalJson;
};

export const validateFeatures = (personalFeatures) => {
  const countFields = Object.keys(personalFeatures).length;

  let countEquals = 0;
  for (var key in personalFeatures) {
    const { old_id, new_id } = personalFeatures[key];
    // console.log(key, " --- ", personalFeatures[key]);
    if (old_id == new_id) {
      countEquals++;
    }
  }
  if (countFields == countEquals) {
    return 0;
  } else {
    return 1;
  }
};

export const getSubscriptionsPrice = (id_subscription_price, list) => {
  let finalJson = { id_subscription_price };
  let emptyFields = 0;
  list.forEach((x) => {
    if (x.vl_price == "") {
      emptyFields++;
    }
    finalJson = {
      ...finalJson,
      [x.name]: x.vl_price,
    };
  });

  const result = { finalJson, emptyFields };
  return result;
};

const sortNumberList = (list) => list.sort((a, b) => a - b);

const applyStringify = (param) => JSON.stringify(param);

export const validateGenders = (oldPreferences, newPreferences) => {
  const list1 = applyStringify(sortNumberList(oldPreferences));
  const list2 = applyStringify(sortNumberList(newPreferences));
  return list1 == list2;
};

export const validateProfile = (profile) => {
  if (profile.changed == true) {
    return 1;
  } else {
    return 0;
  }
};

export const validateSubscriptions = (list) => {
  const resultChanges = list.some(
    (p) => parseFloat(p.vl_price) != parseFloat(p.vl_old_price)
  );

  const resultEmptys = list.some((p) => p.vl_price == "");
  if (resultChanges || resultEmptys) {
    return 1;
  } else {
    return 0;
  }
};

/* ******************************************************************* */

export const createFormDataUpdate = (
  profile,
  profileFile,
  cover,
  coverFile,
  userData,
  aboutMe,
  editPreferences,
  personalData,
  personalFeatures,
  featuresStats,
  id_subscription_price,
  suscriptions
) => {
  let formData = new FormData();
  let countChanges = 0;

  if (profile && validateProfile(profile) == 1) {
    formData.append("file_profile", profileFile);
    countChanges++;
  }

  if (cover && validateProfile(cover) == 1) {
    formData.append("file_cover", coverFile);
    countChanges++;
  }

  if (aboutMe && !compareAboutMe({ about: userData.about }, aboutMe)) {
    formData.append("about", aboutMe.about);
    countChanges++;
  }

  let emptySubs = 0;
  if (
    suscriptions &&
    id_subscription_price &&
    validateSubscriptions(suscriptions) == 1
  ) {
    const result = getSubscriptionsPrice(id_subscription_price, suscriptions);
    emptySubs = result.emptyFields;
    formData.append("subscription_price", JSON.stringify(result.finalJson));
    countChanges++;
  }

  if (
    personalData &&
    comparePersonalData(userData.my_personal_data, personalData) == 1
  ) {
    formData.append("my_personal_data", JSON.stringify(personalData));
    countChanges++;
  }

  let idPreferences = 0;
  if (editPreferences) {
    idPreferences = getSelectedIdGenders(editPreferences);
    if (!validateGenders(userData.gender_preference, idPreferences)) {
      formData.append("gender_preference", idPreferences);
      countChanges++;
    }
  }

  if (featuresStats) {
    if (validateFeatures(personalFeatures) == 1) {
      const idFeatures = getIdFeatures(personalFeatures);
      const x = JSON.stringify(idFeatures);
      // console.log("idFeatures: ", idFeatures);
      formData.append("stat", x);
      countChanges++;
    }
  }

  // console.log("CANTIDAD DE CAMBIOS: ", countChanges);

  const result = countChanges == 0 ? null : formData;
  const jsonResponse = {
    result,
    idPreferences,
    emptySubs,
  };

  if (jsonResponse.result) {
    for (var p of jsonResponse.result) {
      let key = p[0];
      let value = p[1];
    }
  }
  // console.log("RESPONSE VALIDATE: ", jsonResponse);
  return jsonResponse;
};

export const updateLsImage = (response, profile, personalData) => {
  let myLs3 = JSON.parse(localStorage.getItem("session"));
  let myUser3 = myLs3.user;
  let newImgProfile = "";
  if (personalData) {
    const { full_name, ds_display_name } = personalData;
    myUser3 = {
      ...myUser3,
      full_name: ds_display_name,
    };
  }
  if (profile && profile.changed) {
    newImgProfile = response.data.data.find((x) => x.process == "profile_image")
      .data.url_profile;
    myUser3 = {
      ...myUser3,
      img_profile: newImgProfile,
    };
  }
  myLs3.user = myUser3;
  localStorage.setItem("session", JSON.stringify(myLs3));
};

export const updateVisitorLsImage = (response, profile, personalData) => {
  let full_name;
  let myLs3 = JSON.parse(localStorage.getItem("session"));
  let myUser3 = myLs3.user;
  let newImgProfile = null;
  if (personalData) {
    const { ds_display_name } = personalData;
    full_name = ds_display_name;
    myUser3 = {
      ...myUser3,
      full_name: full_name,
    };
  }

  if (profile && profile.changed) {
    try {
      newImgProfile = response.data.data.find(
        (x) => x.process == "profile_image"
      ).data.url_profile;
    } catch (error) {}

    if (newImgProfile == null) {
      newImgProfile = response.data.data.find(
        (x) => x.process == "profile_image"
      ).status.url_profile_images.url_profile;
    }
    myUser3 = {
      ...myUser3,
      img_profile: newImgProfile,
    };
  }
  myLs3.user = myUser3;
  localStorage.setItem("session", JSON.stringify(myLs3));
};

/* ******************************************************************* */
export const handleImage = (e) => {
  const file = e.target.files[0];
  if (file) {
    const previewFile = URL.createObjectURL(file);
    return { file, previewFile };
  } else {
    return;
  }
};

/* ******************************************************************* */

export const createVisitorFormDataUpdate = (
  profile,
  profileFile,
  userData,
  editPreferences,
  personalData
) => {
  let formData = new FormData();
  let countChanges = 0;

  if (profile && validateProfile(profile) == 1) {
    formData.append("file_profile", profileFile);
    countChanges++;
  }

  let idPreferences = 0;
  if (editPreferences) {
    idPreferences = getSelectedIdGenders(editPreferences);
    if (!validateGenders(userData.gender_preference, idPreferences)) {
      formData.append("gender_preference", idPreferences);
      countChanges++;
    }
  }

  if (personalData) {
    const name = JSON.parse(localStorage.getItem("session")).user.full_name;
    if (personalData.ds_display_name !== name) {
      formData.append("my_personal_data", JSON.stringify(personalData));
      countChanges++;
    }
  }

  const result = countChanges == 0 ? null : formData;
  const jsonResponse = {
    result,
    idPreferences,
  };

  return jsonResponse;
};

/* ******************************************************************* */
