import { useState } from "react";
import { Topic } from "../Topic/Topic";
import ShareIcon from "@material-ui/icons/Share";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./DealPack.css";

export const DealPack = ({ pack }) => {
  const [additional, setAdditional] = useState(false);
  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "grid",
      gridTemplateColumns: "3fr 1fr 60px",
    },
    grid: {
      position: "relative",
      padding: "0.8rem 1rem",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  //const sharePack = () => console.log(pack);
  // https://localhost:3000/packView/57/42
  const sharePack = (e) => {
    e.preventDefault();
    setOpen(false);
    if (!navigator.share) {
      alert("Navegador no soporta la Web Share API");
      return;
    }
    navigator
      .share({
        // title: "Hotmy",
        // text: "Te quiero compartir mi pack de Hotmy.app",
        //url: document.location.href,
        url: process.env.PUBLIC_URL + "/packView/" + user.id +"/" + pack?.id_pack,
        //console.log(process.env.REACT_APP_INITIAL_V2 + "/hotmerProfile/" + id);
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  return (
    <div className="deal--pack--main--container">
      <div
        className={`deal--pack--main--container--icon ${
          additional ? "inverted--icon" : ""
        }`}
      >
        <ExpandMoreIcon
          fontSize="mdall"
          onClick={() => setAdditional((x) => !x)}
        />
      </div>
      <div className="deal--pack--main--container--data">
        <div className="deal--pack--search--main--container--content--image">
          <img src={pack?.ds_url_thumbnail} alt="..." />
        </div>
        <div className="deal--pack--main--container--data--data">
          <Topic title={pack?.ds_title} val="" />
          <Topic title="Tarifa : " val={`${pack?.vl_rate}%`} />
          <Topic title="Precio : " val={`USD ${pack?.vl_price}`} />
          <Topic title="Ventas : " val={pack?.vl_sales} />
        </div>
        <div className="deal--pack--main--container--data--profits">
          <Topic title="GANANCIAS" val="" />
          <Topic title="USD" val={pack?.profits?.international?.vl_value} />
          <Topic title="ARS" val={pack?.profits?.local?.vl_value} />
        </div>
      </div>
      <div
        className={`deal--pack--main--container--additional ${
          additional ? "open--additional" : ""
        }`}
      >
        <div className={classes.root}>
          <Grid className={classes.grid} container spacing={3}>
            <Grid container item xs={6}>
              <Topic
                title="Fotos : "
                val={pack?.additional?.vl_images}
                color="#ffffff"
              />
            </Grid>
            <Grid container item xs={6}>
              <Topic
                title="Alta : "
                val={pack?.additional?.dt_upload_date || "-"}
                color="#ffffff"
              />
            </Grid>
            <Grid container item xs={6}>
              <Topic
                title="Videos : "
                val={pack?.additional?.vl_videos}
                color="#ffffff"
              />
            </Grid>
            <Grid container item xs={6}>
              <Topic
                title="Baja : "
                val={pack?.additional?.dt_deleted_date || "-"}
                color="#ffffff"
              />
            </Grid>
            <Grid container item xs={6}>
              <Topic
                title="Deseados : "
                val={pack?.additional?.vl_desired}
                color="#ffffff"
              />
            </Grid>
            <Grid container item xs={6}>
              <Topic
                title="Reclamos : "
                val={pack?.additional?.vl_claims}
                color="#ffffff"
              />
            </Grid>
          </Grid>
          <div className="deal--pack--main--container--ids">
            <Topic
              title="pack? : "
              val={pack?.additional?.id_pack}
              color="#ffffff"
            />
            <Topic
              title="Acuerdo : "
              val={pack?.additional?.id_trade_agreement}
              color="#ffffff"
            />
          </div>
          <button className="deal--pack--main--container--share"
          onClick={sharePack}>
            <ShareIcon fontSize="large" onClick={sharePack} />
          </button>
        </div>
      </div>
    </div>
  );
};
