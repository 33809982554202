import { Fragment, useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import imageProfile from "../../icons/fueguito-card-1.png";
import SkeletonCard from "../skeletonCard/SkeletonCard";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { Deal } from "../Deal/Deal";
import { Reload } from "../Reload/Reload";
import { connect } from "react-redux";
import { NoContent } from "../NoContent/NoContent";
import { Scrollbars } from "react-custom-scrollbars";
import { PaginationLoading } from "../PaginationLoading/PaginationLoading";
import { DealSearchHotmers } from "../DealSearchHotmers/DealSearchHotmers";
import { createStructuredSelector, createSelector } from "reselect";
import {
  setSelectedHotmers,
  getCollaborationsHotmers,
} from "../../redux/deals/Actions";
import "./ColaborationsHotmers.css";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";

export default connect(
  createStructuredSelector({
    propDeals: createSelector(
      [({ deal }) => deal],
      ({
        loadingCollaborationsHotmers,
        errorCollaborationsHotmers,
        collaborationsHotmers,
        selectedHotmers,
        nameParam,
        limit,
        offset,
        stopFetch,
      }) => ({
        loadingCollaborationsHotmers,
        errorCollaborationsHotmers,
        collaborationsHotmers,
        selectedHotmers,
        nameParam,
        limit,
        offset,
        stopFetch,
      })
    ),
  }),
  (dispatch) => ({
    setSelectedHotmers: (list) => dispatch(setSelectedHotmers(list)),
    getCollaborationsHotmers: (rol, name, limit, offset) =>
      dispatch(getCollaborationsHotmers(rol, name, limit, offset)),
  })
)(
  ({
    propDeals,
    setSelectedHotmers,
    getCollaborationsHotmers,
    loadingCreateCollaboration,
  }) => {
    const {
      loadingCollaborationsHotmers,
      errorCollaborationsHotmers,
      collaborationsHotmers,
      selectedHotmers,
      nameParam,
      limit,
      offset,
      stopFetch,
    } = propDeals;

    const session = localStorage.getItem("session");
    const { user } = JSON.parse(session);

    const matchId = (id, ID) => id == ID;
    const searchHotmer = (aList, ID) =>
      aList.find(({ id_hotmer }) => matchId(id_hotmer, ID));
    const dropElem = (aList, ID) =>
      aList.filter(({ id_hotmer }) => !matchId(id_hotmer, ID));

    const addRequestHotmer = (hotmer) => {
      const element = searchHotmer(selectedHotmers, hotmer.id_hotmer);
      if (!element && selectedHotmers.length < 5) {
        setSelectedHotmers([...selectedHotmers, hotmer]);
      }
    };

    const removeHotmer = ({ id_hotmer }) =>
      setSelectedHotmers(dropElem(selectedHotmers, id_hotmer));

    const collabRequest = () =>
      getCollaborationsHotmers(user.rol, nameParam, limit, offset);

    const handleScroll = ({ target }) => {
      const { scrollHeight, scrollTop, clientHeight } = target;
      const bottom = scrollHeight - scrollTop <= clientHeight;
      if (stopFetch || loadingCollaborationsHotmers) return;
      if (bottom) getCollaborationsHotmers(user.rol, nameParam, limit, offset);
    };

    return (
      <div className="colaborations--hotmers--main--container">
        {loadingCreateCollaboration ? (
          <SkeletonCard />
        ) : (
          <Fragment>
            <DealSearchHotmers />
            <div className="colaborations--hotmers--main--container--list">
              {loadingCollaborationsHotmers && offset === 0 && <SkeletonCard />}
              {errorCollaborationsHotmers && offset === 0 && (
                <Reload onClick={() => {}} />
              )}
              {collaborationsHotmers && (
                <Scrollbars onScroll={handleScroll} universal={true}>
                  {collaborationsHotmers.map((hotmer) => (
                    <Deal
                      key={hotmer.id_hotmer}
                      deal={hotmer}
                      typeDeal="addRequest"
                      addRequestHotmer={() => addRequestHotmer(hotmer)}
                      selectAgreementHotmer={() => {}}
                    />
                  ))}
                  {loadingCollaborationsHotmers && <PaginationLoading />}
                  {errorCollaborationsHotmers && (
                    <Reload onClick={collabRequest} />
                  )}
                  {!errorCollaborationsHotmers && stopFetch && <NoContent />}
                </Scrollbars>
              )}
            </div>
            <Divider variant="middle" />
            <div className="colaborations--hotmers--main--container--selected">
              <div className="colaborations--hotmers--main--container--selected--list">
                {selectedHotmers.length === 0 ? (
                  <div>Aun no has Agregado a ningun Hotmer</div>
                ) : (
                  <Fragment>
                    {selectedHotmers.map((hotmer) => (
                      <Hotmer
                        key={hotmer.id_hotmer}
                        hotmer={hotmer}
                        onClick={() => removeHotmer(hotmer)}
                      />
                    ))}
                  </Fragment>
                )}
              </div>
              {selectedHotmers.length !== 0 && (
                <div className="colaborations--hotmers--main--container--selected--count">
                  Total {selectedHotmers.length} de 5
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
);

const Hotmer = ({ hotmer, onClick }) => {
  const [profile, setProfile] = useState({
    src: hotmer.ds_profile_photo_thumbnail_path,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setProfile({
      src: hotmer.ds_profile_photo_thumbnail_path || imageProfile,
      errored: false,
    });
  }, [hotmer]); // eslint-disable-line

  return (
    <div className="hotmer--add--request--container">
      <div className="hotmer--add--request--container--image">
        <img onError={onErrorProfile} src={profile.src} alt="..." />
      </div>
      <CancelPresentationIcon fontSize="small" onClick={onClick} />
    </div>
  );
};
