import React from "react";
import iconImage from "../../icons/darkIcons/gallery-icon.png";
import { useHistory } from "react-router-dom";
import { LoadingComponent } from "../../commonComponents/loadingComponent/loadingComponent";
import { WrongFetchComponent } from "../../commonComponents/wrongFetchComponent/wrongFetchComponent";
import { WrongFetchUploadContent } from "../../commonComponents/wrongFetchUploadContent/wrongFetchUploadContent";
import "./hotmerGridContentImage.css";

export const HotmerGridContentImage = ({
	loading,
	content,
	id_hotmer,
	onClickRefresh,
	owner
}) => {
	const history = useHistory();

	if (loading) {
		return (
			<div className="grid--image--main--container--loading">
				<LoadingComponent />
			</div>
		);
	} else if (!loading && !content && owner === 0) {
		return (
			<WrongFetchUploadContent />
		)
			;
	} else if (!loading && !content) {
		return (
			<div className="grid--image--main--container--loading">
				<WrongFetchComponent onClickRefresh={onClickRefresh} />;
			</div>
		);
	} else if (!loading && content.length === 0) {
		return "Este usuario no posee imagenes";
	} else {
		return (
			<div
				className={`grid--image--main--container 
          ${content.length === 1
						? "one--column"
						: content.length === 2
							? "two--columns"
							: ""
					}
        `}
			>
				{content.map((upload, i) => {
					return (
						<div
							key={i}
							className="hotmer--profile--main--container--photo"
							onClick={() => {
								history.push(`/hotmerContent/${id_hotmer}`);
								console.log("HOTMER PROFILE: ", upload.id_content_group);
								localStorage.setItem(
									"id_content_group",
									upload.id_content_group,
								);
							}}
						>
							<img
								alt="..."
								className="hotmer--profile--main--container--photo--image"
								src={upload.data[0].ds_url_thumbnail}
							/>
							{upload.data.length > 1 && (
								<img
									className="hotmer--profile--main--container--photo--icon"
									src={iconImage}
									alt="..."
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	}
};
