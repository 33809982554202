import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import "./wrongFetchTagsScreen.css";

export const WrongFetchTagsScreen = ({ onClickRefresh, param }) => {
  return (
    <div className="wrong--fetch--tags--screen--container">
      <div className="wrong--fetch--tags--screen--container--image--message">
        {`Error: Presione para recuperar ${param}.`}
      </div>
      <div className="wrong--fetch--tags--screen--container--refresh">
        <div className="wrong--fetch--tags--screen--container--refresh--icon">
          <RefreshIcon fontSize="small" onClick={onClickRefresh} />
        </div>
      </div>
    </div>
  );
};
