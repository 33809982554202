import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import packIcon from "../../icons/degrade/pack-degrade-icon-new.png";
import profileIcon from "../../icons/darkIcons/profile-icon.png";
import profileDegIcon from "../../icons/degrade/profile-degrade-icon.png";
import packDegradeIcon from "../../icons/darkIcons/pack-gray-icon.png";
import { IconTab } from "../../components/iconTab/iconTab";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { IconTabPacks } from "../../components/iconTab/iconTabsPacks";

// LOS SEARCHS DE TABS

const theme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        "&:hover": {
          backgroundColor: "#ECECEC",
          color: "#ECECEC"
        },
        "&.Mui-selected": {
          backgroundColor: "#ECECEC",
        }
      },
    }
  }
});

export const SearchTabs = ({ tab, handleChange, defineIcon }) => {
  return (
    <Paper>
      <ThemeProvider theme={theme}>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab
            icon={
              <IconTab icon={defineIcon(tab, 0, profileDegIcon, profileIcon)} />
            }
          />
          <Tab
            icon={
              <IconTabPacks icon={defineIcon(tab, 1, packDegradeIcon, packIcon)} />
            }
          />
        </Tabs>
      </ThemeProvider>
    </Paper>
  );
};
