import { useSelector } from "react-redux";
import "./DealsHelpView.css";

export const DealsHelpView = ({
  tab,
  activeStepAgreements,
  activeStepCollaborations,
  agreementStepperOption,
  toggleSheet,
}) => {
  const {} = useSelector((state) => state.deal);
  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  return (
    <div className="Help--View--main--container">
      <div className="Help--View--main--container--text">
        {user.rol === "hotmers" &&
          tab === 0 &&
          activeStepAgreements > 0 &&
          "**FOTOGRAFOS** Comisión: Porcentaje que el Fotógrafo recibe de los Packs que hayas aplicado el acuerdo comercial. Pack Aplicados : Cantidad de packs con el acuerdo comercial aplicado. Pack Inactivos : Packs que fueron eliminados y tenían un acuerdo comercial.Packs Activos : Pack que están activos con el Acuerdo Comercial.Unidades Vendidas : Son las cantidad de packs vendidos con ese acuerdo. Cancelaciones : Son las cancelaciones de compra del usuario. Reclamos : Son los reclamos que recibidos por los usuarios del Pack correspondiente. Tarifas Aplicadas : Porcentajes de comisión que recibe la agencia por los ingresos de tu perfil. **AGENCIA** Resumen Suscripciones : Son las estadísticas de las suscripciones de tu perfil. Resumen Packs : Son las estadísticas de tus Packs. Resumen Regalos : Son las estadísticas de los regalos."}
        {user.rol !== "hotmers" &&
          tab === 1 &&
          activeStepCollaborations > 0 &&
          "Solicitud de Colaboración : Son Solicitudes que envía una Agencia, Manager o Fotógrafo hacia uno o varios creadores, para pedir la autorización de colaborar, y luego poder crear un Acuerdo Comercial."}
        {user.rol !== "hotmers" &&
          tab === 0 &&
          activeStepAgreements > 0 &&
          agreementStepperOption == true &&
          "Acuerdos Comerciales :Son acuerdos entre el Creador y una Agencia/ Manager o Fotógrafo, donde acuerdan porcentajes de comisión sobre el Perfil o Packs."}
        {user.rol !== "hotmers" &&
          tab === 0 &&
          activeStepAgreements > 0 &&
          user.rol === "agency" &&
          agreementStepperOption == false &&
          "Visualizacion de estadisticas historicas de tu perfil agencia con el hotmer seleccionado de la lista de acuerdos comerciales."}
        {user.rol !== "hotmers" &&
          tab === 0 &&
          activeStepAgreements > 0 &&
          user.rol === "photographer" &&
          agreementStepperOption == false &&
          "Visualizacion de datos, packs y estadisticas historicas de los acuerdos comerciales de tu perfil fotografo con el hotmer seleccionado."}
      </div>
      <div className="Help--View--main--container--close">
        <CloseBtn onClick={toggleSheet} />
      </div>
    </div>
  );
};

const CloseBtn = ({ onClick }) => (
  <button className="close--help--view" onClick={onClick}>
    CERRAR
  </button>
);
