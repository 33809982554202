/* eslint-disable */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FireTabs } from "../../components/fireTabs/fireTabs";
import { HeaderTab } from "./HeaderTab";
import { getIdUser } from "../../libs/login";
import { PacksSection } from "../../components/PacksSection/PacksSection";
import { useTranslation } from "react-i18next";
import { HotmersSection } from "../../components/HotmersSection/HotmersSection";
import * as FireActions from "../../redux/fire/Actions";
// import SkeletonCardFireMainHotmer from "../../components/skeletonCard/SkeletonCard-Fire-Hotmers";
// import SkeletonCardFirePacks from "../../components/skeletonCard/SkeletonCard-Fire-Packs";
import "./fire.css";

export const FireView = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.fire);
  const { tabFireFromDrawer } = state;
  const [tab, setTab] = useState(0);
  const { t } = useTranslation("global");

  const handleChange = (event, newTab) => setTab(newTab);

  useEffect(() => {
    if (tabFireFromDrawer) {
      setTab(tabFireFromDrawer);
    }
  }, [tabFireFromDrawer]); // eslint-disable-line

  useEffect(() => {
    const idUser = getIdUser();
    dispatch(FireActions.getSubscribedHotmers(idUser));
    dispatch(FireActions.getWishHotmers(idUser));
    dispatch(FireActions.getSubscribedPacks(idUser));
    dispatch(FireActions.getWishPacks(idUser));
  }, []); // eslint-disable-line

  return (
    <div className="fire--main--container">
      <FireTabs
        tab={tab}
        handleChange={handleChange}
        first={t("fire.content.title")}
        second={t("fire.wish.title")}
      />
      <HeaderTab tab={tab} type={"HOTMERS"} />
      <HotmersSection tab={tab} />
      <HeaderTab tab={tab} type={"PACKS"} />
      <PacksSection tab={tab} />
    </div>
  );
};
