/* eslint-disable */
import { useEffect, useState, Fragment } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import { TopBar } from "../../components/topBar/topBar";
import { PackView } from "../packView/packView";
import { BottomNav } from "../../components/bottomNav/bottomNav";
import { MyProfile } from "../myProfile/myProfile";
import { PackUpdate } from "../packUpdate/packUpdate";
import { PackDetail } from "../packDetail/packDetail";
import { Extractions } from "../extractions/extractions";
import { CommentsPost } from "../commentsPost/commentsPost";
import { HotmerContent } from "../hotmerContent/hotmerContent";
import { HotmerProfile } from "../hotmerProfile/hotmerProfile";
import { HotmerProfilebyNickName } from "../hotmerProfile/hotmerProfilebyNickName";
import { ConfigProfile } from "../configProfile/configProfile";
import { ConfigVisitor } from "../configVisitor/configVisitor"; // PERFIL DE VISITANTE // tiene hardcodeado que use la misma ruta que el configprofile
import { UploadContent } from "../uploadContent/uploadContent";
import { HotmerPresent } from "../hotmerPresent/hotmerPresent";
import { HotmerSuscribe } from "../hotmerSuscribe/hotmerSuscribe";
import { SuccessPayment } from "../successPayment/successPayment";
import { FailurePayment } from "../failurePayment/failurePayment";
import { AccountCreation } from "../accountCreation/accountCreation";
import { EditProfileHotmer } from "../editProfileHotmer/editProfileHotmer";
import { CreateContentInit } from "../createContentInit/createContentInit";
import { CreateRegisterInit } from "../createRegisterInit/createRegisterInit";
import { DrawerRightProfile } from "../../components/drawerRightProfile/drawerRightProfile";
import { EditProfileVisitor } from "../editProfileVisitor/editProfileVisitor";
// Toast
import 'react-toastify/dist/ReactToastify.css';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { FireView } from "../fire/FireView";
import { SearchView } from "../search/SearchView";
import Deals from "../Deals/Deals";
import Home from "../home/Container";
import { useSelector, useDispatch } from "react-redux";
import { setScreenPosition } from "../../redux/actions";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import "./dashboardRoutes.css";
// import { displaySuccess } from "../../components/toast/toast";

export const DashboardRoutes = () => {
  const dispatch = useDispatch();
  const { responseSubmitContent } = useSelector((state) => state.main);
  const location = useLocation();
  const [openResult, setOpenResult] = useState(false);
  const [messageModalUpload, setMessageModalUpload] = useState("");
  const [iconModalUpload, setIconModalUpload] = useState("");

  const onFinalClick = () => {
    setOpenResult(false);
    dispatch({ type: "SET_NULL_RESPONSE_SUBMIT" });
  };

  useEffect(() => {
    if (responseSubmitContent !== null) {
      if (responseSubmitContent === true) {
        // setIconModalUpload(okIcon);
        // setMessageModalUpload("Contenido subido correctamente");
        // displaySuccess("Contenido subido correctamente")
        console.log("Contenido subido")
      } else {
        setIconModalUpload(opsIcon);
        setMessageModalUpload(
          `Se produjo un error al subir el contenido. Es posible que sus archivos excedan el limite.
           Si persiste contacte a support@hotmy.net o a nuestra linea exclusiva de WhatsApp`
        );
        setOpenResult(true);
      }
    }
  }, [responseSubmitContent]);

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      if (pathname === "/") dispatch(setScreenPosition(0));
      if (pathname === "/search") dispatch(setScreenPosition(1));
      if (pathname === "/createContentInit") dispatch(setScreenPosition(2));
      if (pathname === "/uploadContent") dispatch(setScreenPosition(2));
      if (pathname === "/fire") dispatch(setScreenPosition(3));
      if (
        pathname === "/myProfile" ||
        pathname === "/deals" ||
        pathname.includes("/editProfileHotmer") ||
        pathname.includes("/configProfile")
      )
        dispatch(setScreenPosition(4));
    }
  }, [location]);

  return (
    <Fragment>
      <TopBar />
      <div className="dashboard--main--container--views">
        <Switch>
          <Route path="/configProfile/:id_hotmer" component={ConfigProfile} />
          <Route path="/configVisitor/:id_hotmer" component={ConfigVisitor} />
          <Route path="/hotmerContent/:id_hotmer" component={HotmerContent} />
          <Route path="/hotmerProfile/:id_hotmer" component={HotmerProfile} />

          <Route
            path="/editProfileHotmer/:id_hotmer"
            component={EditProfileHotmer}
          />
          <Route
            path="/editProfileVisitor/:id_hotmer"
            component={EditProfileVisitor}
          />
          <Route
            path="/hotmerPresent/:id_hotmer/:id_content_group"
            component={HotmerPresent}
          />
          <Route path="/hotmerSuscribe/:id_hotmer" component={HotmerSuscribe} />
          <Route
            path="/commentsPost/:id_hotmer/:id_content_group"
            component={CommentsPost}
          />
          <Route path="/packView/:id_hotmer/:id_pack" component={PackView} />
          <Route
            path="/packDetail/:id_hotmer/:id_pack"
            component={PackDetail}
          />
          <Route
            path="/packUpdate/:id_hotmer/:id_pack"
            component={PackUpdate}
          />
          <Route path="/accountCreation/:id_user" component={AccountCreation} />
          <Route path="/uploadContent" component={UploadContent} />
          <Route path="/extractions" component={Extractions} />
          <Route path="/createContentInit" component={CreateContentInit} />
          <Route path="/createRegisterInit" component={CreateRegisterInit} />
          <Route path="/myProfile" component={MyProfile} />
          <Route path="/successTransaction" component={SuccessPayment} />
          <Route path="/cancelTransaction" component={FailurePayment} />
          <Route path="/deals" component={Deals} />
          <Route path="/fire" component={FireView} />
          <Route path="/search" component={SearchView} />
          {/* Tiene que estar a lo ultimo por prioridad de rutas      */}
          <Route path="/:ds_nick_name" component={HotmerProfilebyNickName} />
          <Route path="/:ds_nick_name/packs" component={HotmerProfile} />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </div>
      <CustomModal
        openModal={openResult}
        setOpenModal={setOpenResult}
        discardExternalEvents={true}
      >
        <OptionTryAgain
          icon={iconModalUpload}
          title={messageModalUpload}
          acceptClick={onFinalClick}
          buttonText={"ACEPTAR"}
          whatsApp={true}
        />
      </CustomModal>
      {/* Tooltip Generals */}
      {/* <ToastContainer /> */}
      <DrawerRightProfile />
      <BottomNav />
    </Fragment>
  );
};
