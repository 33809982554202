import React from 'react';
import Skeleton from 'react-loading-skeleton';
// ARMADO DE SKELETO PRINCIPAL PARA EL SEARCH.  
const SkeletonViewPack = () => {
  return (
    <section>
            <span></span>
           <h4 className="card-title">
           <Skeleton height={80} width={`100%`} />
           </h4>
           <span></span>
           <h4 className="card-title">
           <div style={{ margin:6, marginleft: 6, marginRight: 6 }}>
           <Skeleton height={70} width={`100%`} />
           </div>
           </h4>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <div className="card-image">
              <div style={{ margin: 10, marginleft: 8, marginRight: 8 }}>
                <span></span>
                <span></span>
                <Skeleton height={300} width={`100%`} />
                <span></span>
                <span></span>
                <div style={{ margin: 40, marginleft: 8, marginRight: 8 }}></div>
                <Skeleton height={300} width={`100%`} />
              </div>
              <div>
              </div>
              </div>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default SkeletonViewPack;