import { datadogRum } from '@datadog/browser-rum';

export const dataDogRumStart = () => {
    datadogRum.init({
        applicationId: 'b8c6297e-0428-453f-b203-e79838550f15',
        clientToken: 'pubcbe321f8a1275593cbac85b22ffe3af1',
        site: 'us5.datadoghq.com',
        service: 'hotmyapp',
        env: 'prod',
        sessionSampleRate: 100,
        // parseInt(process?.env.REACT_APP_DATADOG_SAMPLE_RATE, 10) ?
        //     parseInt(process?.env.REACT_APP_DATADOG_SAMPLE_RATE, 10)
        //     : 0, // RANGO DE GRABADO.
        premiumSampleRate: 100,
        // parseInt(process?.env.REACT_APP_DATADOG_RECORD_REPLAY_SESSIONS, 10) ?
        //     parseInt(process?.env.REACT_APP_DATADOG_RECORD_REPLAY_SESSIONS, 10)
        //     : 0, // RANGO DE REPLAY SESSION
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow'
    });

    try {
        const idUser = JSON.parse(localStorage.getItem("session")).user.id;
        const dsNameUser = JSON.parse(localStorage.getItem("session")).user.full_name;
        const isHotmer = JSON.parse(localStorage.getItem("session")).user.hotmer;
        const versionApp = localStorage.getItem("hmyAppVersion") ? localStorage.getItem("hmyAppVersion")  : 'no identificada' ;

        // Seteo las variables de usuario logueado solo inicio el datarum cuando esta logueado o tiene sesion.

        datadogRum.setUser(
            {
                id: idUser,
                name: dsNameUser,
                isCreator: isHotmer,
                version: versionApp,
            }
        )

        datadogRum.startSessionReplayRecording();
    } catch (error) {
        console.log("dataRum Error", error)
    }


}
