/* eslint-disable */
import React, { useEffect, useState } from "react";
import okIcon from "../../icons/payment/ok-green-icon.png";
import { setHeaders } from "../../libs/fetch";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { SuccessScreen } from "../successScreen/successScreen";
import { CurrencyOption } from "../../commonComponents/currencyOption/currencyOption";
import { CustomButtonBuy } from "../../commonComponents/customButtonBuy/customButtonBuy";
import { PaymentsSection } from "../../components/paymentsSection/paymentsSection";
import { CardPriceTitleSelection } from "../../commonComponents/cardPriceTitleSelection/cardPriceTitleSelection";
import {
	//createJsonPresent,
	createJsonPresent_v2,
	generatePresentList,
	updateSelectionTipList,
} from "../../libs/present";
import "./hotmerPresent.css";
import { Switch, TextField, FormControl,FormGroup , FormControlLabel} from "@material-ui/core";

export const HotmerPresent = ({ match }) => {
	const [loadingHotmerPresent, setLoadingHotmerPresent] = useState(false);
	const [hotmerPresent, setHotmerPresent] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
	const [symbol, setSymbol] = useState("$");
	const [value, setValue] = useState(null);
	const [presentTipList, setPresentTipList] = useState(null);
	const [selectedIdAmount, setSelectedIdAmount] = useState(1);
	const [selectedIdCountry, setSelectedIdCountry] = useState(null);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [successScreen, setSuccessScren] = useState(false);
	const [currency, setCurrency] = useState(null);
	const [checked, setChecked] = React.useState(true);
	const [valueMessegeUser, setValueMessegeUser] = useState("");

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const chooseCurrency = (currency) => setCurrency(currency);

	const sendPresent = async () => {
		const bodyData = createJsonPresent_v2(
			match,
			selectedIdCountry,
			paymentMethodSelected,
			selectedIdAmount,
			checked, //switch de usuario y mail
			valueMessegeUser, //mensaje que envia el usuario.
		);
		console.log("selectedIdCountry: ", selectedIdCountry);
		console.log("BODY: ", bodyData);
		console.log("checked: ", checked);
		console.log("valueMessegeUser: ", valueMessegeUser);
		
		try {
			setLoadingSubmit(true);
			const url = `${process.env.REACT_APP_INITIAL_V2}/payment/direct/tip`; //se actualizo a una version 2 de codigo
			console.log("URL: ", url);
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: JSON.stringify(bodyData),
			});
			console.log("RES PRESENT: ", res);
			if (res.status === 201) {
				const response = await res.json();
				console.log("RESPONSE PRESENT: ", response.data);
				if (paymentMethodSelected == 1) {
					console.log("MERCADO PAGO");
					window.location.href = response.data.uri;
				}
				if (paymentMethodSelected == 2) {
					console.log("MERCADO PAYPAL");
					window.location.href = response.data.uri;
				}
			}
		} catch (error) {
			console.log(error);
		}
		setLoadingSubmit(false);		
	};

	const getHotmerProfile = async (idHotmer) => {
		setLoadingHotmerPresent(true);
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/gift/tip/preview/payment`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			if (res.status === 200) {
				const response = await res.json();
				console.log("RESPONSE: ", response.data);
				setHotmerPresent(response.data);
				setPresentTipList(

					generatePresentList(
						response.data.local_price.tip_prices,
						response.data.local_price.currency.ds_currency_code,
						2,
					),
				);
				setSelectedIdAmount(2);
				setSelectedIdCountry(response.data.local_price.currency.id_country);
				setCurrency(response.data.local_price.currency.ds_currency_code);
				setSymbol(response.data.local_price.currency.ds_symbol);
				setValue(response.data.local_price.vl_pack_price);
			}
		} catch (error) {
			console.log("MURIO...");
		}
		setLoadingHotmerPresent(false);
	};

	const onClickPaymentMethod = (typeMethod) => {
		setPaymentMethodSelected(typeMethod);
	};

	const selectOption = (id) => {
		setSelectedIdAmount(id);
		const list = updateSelectionTipList(presentTipList, id);
		setPresentTipList(list);
	};

	const handleChange1 = (event) => {
		setValueMessegeUser(event.target.value);
		console.log("valores", valueMessegeUser);
	  };

	useEffect(() => {
		setPaymentMethodSelected(null);
		if (currency) {
			if (currency === "USD") {
				setCurrency(hotmerPresent.global_price.currency.ds_currency_code);
				setSymbol(hotmerPresent.global_price.currency.ds_symbol);
				setValue(hotmerPresent.global_price.vl_pack_price);
				setPaymentMethod(hotmerPresent.global_price.payment_method);
				setPresentTipList(
					generatePresentList(
						hotmerPresent.global_price.tip_prices,
						"USD",
						selectedIdAmount,
					),
				);
				setSelectedIdCountry(hotmerPresent.global_price.currency.id_country);
			} else {
				setCurrency(hotmerPresent.local_price.currency.ds_currency_code);
				setSymbol(hotmerPresent.local_price.currency.ds_symbol);
				setValue(hotmerPresent.local_price.vl_pack_price);
				setSelectedIdCountry(hotmerPresent.local_price.currency.id_country);
				setPaymentMethod(hotmerPresent.local_price.payment_method);
				setPresentTipList(
					generatePresentList(
						hotmerPresent.local_price.tip_prices,
						"ARS",
						selectedIdAmount,
					),
				);
			}
		}
	}, [currency]); // eslint-disable-line

	useEffect(() => {
		getHotmerProfile(match.params.id_hotmer);
	}, []); // eslint-disable-line

	if (successScreen) {
		return (
			<SuccessScreen
				name={hotmerPresent.display_name}
				title={`Enviar Regalo`}
				icon={okIcon}
				titlePayment={"Pago realizado"}
			/>
		);
	} else if (loadingHotmerPresent || loadingSubmit) {
		return <LoadingScreen loading={loadingHotmerPresent || loadingSubmit} />;
	} else {
		return (
			<div className="hotmer--present--main--container">
				{hotmerPresent && (
					<>
						<div className="hotmer--present--main--container--hotmer">
							<div className="hotmer--present--main--container--hotmer--subscription">
								{`1 - Seleccione el tipo de regalo para: `}
							</div>
							<DividerSpace space="XXS" />
							<div className="hotmer--present--main--container--hotmer--name">
								{`${hotmerPresent ? hotmerPresent.ds_display_name : "..."}`}
							</div>
						</div>
						<div className="hotmer--present--main--container--data">
							<div className="hotmer--present--main--container--data--currency">
								<div className="hotmer--present--main--container--data--currency--title">
									Moneda
								</div>
									{hotmerPresent.global_price.currency.ds_currency_code !==
									hotmerPresent.local_price.currency.ds_currency_code && (
									<CurrencyOption
										option={hotmerPresent.local_price.currency.ds_currency_code}
										currency={currency}
										chooseCurrency={chooseCurrency}
									/>
								)}
									<CurrencyOption
										option={hotmerPresent.global_price.currency.ds_currency_code}
										currency={currency}
										chooseCurrency={chooseCurrency}
									/>
							</div>
							<div className="hotmer--present--main--container--data--value">
								{presentTipList &&
									presentTipList.map((optionTip) => {
										return (
											<CardPriceTitleSelection
												key={optionTip.id}
												id={optionTip.id}
												selected={optionTip.selected}
												title={"Regalar"}
												value={optionTip.value}
												selectOption={selectOption}
											/>
										);
									})}
							</div>
						</div>
						<div className="hotmer--present--main--container--detail--payment">
							<PaymentsSection
								onClickPaymentMethod={onClickPaymentMethod}
								paymentMethodSelected={paymentMethodSelected}
								paymentMethod={paymentMethod}
							/>
						</div>
						<div className="payments--section--main--container--title">
							3 - Completar Datos Opcionales.
						</div>
						<div className="hotmer--present--main--container--data--container">
							<div className="hotmer--present--main--container--data--container--items">
								<FormGroup>
									<FormControlLabel 
									control=
											{<Switch 
											//defaultChecked={checkedUserMail} 
											checked={checked}
											onChange={handleChange}
											inputProps={{ 'aria-label': 'controlled' }}
											/>
									} 
									label="Enviar Usuario y Mail al Creador" />
								</FormGroup>
							<div>
								<div className="hotmer--present--main--container--data--container--items--FielText">
									<FormControl fullWidth sx={{ m: 1 }} variant="outlined">				
									<TextField id="outlined-basic" 
									label="Mensaje al Creador (Opcional)" 
									variant="outlined" 
									size="medium"
									color="secondary"
									inputProps={{ maxLength: 50 }}
									//onChange={messegeUser(event.target.value)}
									value={valueMessegeUser}
        							onChange={handleChange1}
									//defaultValue="Gracias por tu Contenido"
									/>
								</FormControl>
								</div>
							</div>
							</div>
						</div>
						<div className="hotmer--present--main--container--buy">
							<CustomButtonBuy
								onClick={sendPresent}
								disabled={!paymentMethodSelected}
							>
								IR A PAGAR
							</CustomButtonBuy>
						</div>
					</>
				)}
			</div>
		);
	}
};
