import { useTranslation } from "react-i18next";

export const HeaderTab = ({ tab, type }) => {
  const { t } = useTranslation("global");

  const title = () => {
    if (type === "HOTMERS") {
      if (tab === 0) {
        return t("fire.content.hotmers.title");
      } else {
        return t("fire.wish.hotmers.title");
      }
    } else {
      if (tab === 0) {
        return t("fire.content.packs.title");
      } else {
        return t("fire.wish.packs.title");
      }
    }
  };

  return <div className="header--tab--container">{title()}</div>;
};
