import React from "react";
import "./customUploadInputText.css";

export const CustomUploadInputText = ({
  name,
  value,
  onChange,
  type,
  placeholder,
  limit,
  textPosition,
  disabled,
}) => {
  const POSITIONS = ["start", "center", "end"];

  const getTextPosition = () => {
    if (textPosition) {
      const index = POSITIONS.findIndex((p) => p === textPosition);
      return POSITIONS[index];
    } else {
      return POSITIONS[0];
    }
  };
  return (
    <div className="custom--upload--input--text--container">
      {/* <div className="custom--upload--input--text--container--title">
        {title}
      </div> */}
      <div className="custom--upload--input--text--container--box">
        <input
          className={`custom--upload--input--text--container--box--input ${
            getTextPosition() === "start"
              ? "text--start"
              : getTextPosition() === "center"
              ? "text--center"
              : "text--end"
          }`}
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          maxLength={25}
          disabled={disabled}
        />
      </div>
      <div className="custom--upload--input--text--container--limit">
        {limit}
      </div>
    </div>
  );
};
