/* eslint-disable */
import React from "react";
import { BlockedContentProfile } from "../blockedContentProfile/blockedContentProfile";
import { HotmerGridContentImage } from "../hotmerGridContentImage/hotmerGridContentImage";
import { HotmerGridContentVideo } from "../hotmerGridContentVideo/hotmerGridContentVideo";
import { HotmerGridContentPack } from "../hotmerGridContentPack/hotmerGridContentPack";
import { HotmerGridExclusivePack } from "../HotmerGridExclusivePack/HotmerGridExclusivePack"
import "./multimediaHotmer.css";

export const MultimediaHotmer = ({
  tab,
  handleChange,
  loadingImages,
  images,
  loadingVideos,
  videos,
  loadingPacks,
  packs,
  reproduceVideoContent,
  id_hotmer,
  onClickRefreshImages,
  onClickRefreshVideos,
  onClickRefreshPacks,
  owner,
  hotmerProfile,
}) => {

  //console.log("quien es owner: ", owner);

  if (owner == 1) {
    return (
      <div className="multimedia--hotmer--main--container">
        {tab === 0 && (
          <>
            {hotmerProfile && hotmerProfile.bl_subscriber_hotmer ? (
              <HotmerGridContentImage
                loading={loadingImages}
                content={images}
                id_hotmer={id_hotmer}
                onClickRefresh={onClickRefreshImages}
              />
            ) : (
              <BlockedContentProfile
                hotmerProfile={hotmerProfile}
                id={id_hotmer}
              />
            )}
          </>
        )}
        {tab === 1 && (
          <>
            {hotmerProfile && hotmerProfile.bl_subscriber_hotmer ? (
              <HotmerGridContentVideo
                loading={loadingVideos}
                content={videos}
                reproduceVideoContent={reproduceVideoContent}
                onClickRefresh={onClickRefreshVideos}
              />
            ) : (
              <BlockedContentProfile
                hotmerProfile={hotmerProfile}
                id={id_hotmer}
              />
            )}
          </>
        )}

        {tab === 2 && (
          <div className="multimedia--hotmer--main--container--content">
            <HotmerGridExclusivePack
              loading={loadingPacks}
              content={packs}
            />
            <HotmerGridContentPack
              loading={loadingPacks}
              content={packs}
              onClickRefresh={onClickRefreshPacks}
              owner={owner}
            />
          </div>
        )}
      </div>
    );
  } else if (owner == 0) {
    return (
      <div className="multimedia--hotmer--main--container">
        {tab === 0 && (
          <HotmerGridContentImage
            loading={loadingImages}
            content={images}
            id_hotmer={id_hotmer}
            onClickRefresh={onClickRefreshImages}
            owner={owner}
          />
        )}
        {tab === 1 && (
          <HotmerGridContentVideo
            loading={loadingVideos}
            content={videos}
            reproduceVideoContent={reproduceVideoContent}
            onClickRefresh={onClickRefreshVideos}
            owner={owner}
          />
        )}
        {tab === 2 && (
          <HotmerGridContentPack
            loading={loadingPacks}
            content={packs}
            onClickRefresh={onClickRefreshPacks}
            owner={owner}
          />
        )}
      </div>
    );
  }
};
