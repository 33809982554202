import React from "react";
import "./successScreen.css";

export const SuccessScreen = ({ name, title, icon, titlePayment }) => {
  return (
    <div className="success--screen--main--container">
      <div className="success--screen--main--container--pack">
        <div className="success--screen--main--container--pack--hotmer">
          {name}
        </div>
        <div className="success--screen--main--container--pack--title">
          {title}
        </div>
      </div>
      <div className="success--screen--main--container--payment">
        <div className="success--screen--main--container--payment--icon">
          <img src={icon} alt="..." />
        </div>
        <div className="success--screen--main--container--payment--result">
          {titlePayment}
        </div>
        {/* {!title.includes("Enviar Regalo") && ( */}
        <div className="success--screen--main--container--payment--message">
          Una vez acreditado el pago, se desbloqueatá el contenido
        </div>
        {/* )} */}
      </div>
    </div>
  );
};
