import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  // const [progress, setProgress] = React.useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= props.value ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        style={{ color: "#DD0065" }}
        {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );

}

export default CircularProgressWithLabel

// export default function CircularWithValueLabel( ) {
//   const [progress, setProgress] = React.useState(10);

// //   React.useEffect(() => {
// //     const timer = setInterval(() => {
// //       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
// //     }, 800);
// //     return () => {
// //       clearInterval(timer);
// //     };
// //   }, []);

//   return <CircularProgressWithLabel value={progress} />;
// }
