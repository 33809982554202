export const getUserName = (user) => {
	const email = user.email;
	const name = email.substring(0, email.indexOf("@"));
	return name;
};

export const getUserDevice = () => {
	const widthScreen = window.screen.width;
	if(widthScreen === null || widthScreen === undefined){   
		return "phone";
	}  
	if (widthScreen < 480) {
		return "phone";
	} else if (widthScreen >= 480 && widthScreen < 991) {
		return "tablet";
	} else if (widthScreen >= 991) {
		return "desktop";
	}
};

export const headers = {
	"Content-Type": "application/json",
};

export const msgEmailPassword =
	"Ingrese email y contraseña correctamente por favor.";
export const msgWrongLogin = "Algo anda mal. Intente mas tarde por favor.";

export const initialUserLogin = {
	email: "",
	password: "",
	showPassword: false,
	// email: "aviladami@gmail.com",
	// password: "123456",
};
