import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Topic } from '../Topic/Topic';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RoundedNoContent } from '../RoundedNoContent/RoundedNoContent';
import { createStructuredSelector, createSelector } from 'reselect';
import './DealHotmerHistoryClon.css';

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    width: '100%',
    background: 'gray',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
    width: '100%',
    color: '#fff',
  },
}));

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({ loadingAgreementDetail, errorAgreementDetail, agreementDetail }) => ({
        loadingAgreementDetail,
        errorAgreementDetail,
        agreementDetail,
      })
    ),
  })
)(({ propsDeal }) => {
  const { loadingAgreementDetail, errorAgreementDetail, agreementDetail } =
    propsDeal;

  const classes = useStyles();

  return (
    <div className="deal--hotmer--history--main--container">
      {loadingAgreementDetail && <div>Loading...</div>}
      {errorAgreementDetail && <div>Error...</div>}
      {agreementDetail && agreementDetail.length === 0 && (
        <RoundedNoContent title="Sin estadisticas disponibles" />
      )}
      {agreementDetail && agreementDetail.rates_of_packs && (
        <>
          {agreementDetail.summary && (
            <Accordion className={classes.root}>
              <AccordionSummary
                className={classes.header}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Ganancias Totales
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <br />
                  <Topic
                    title="Vendidas"
                    val={agreementDetail.summary.vl_units_sales || 0}
                    color="#000000"
                  />
                  <Topic
                    title="Reclamos"
                    val={agreementDetail.summary.vl_units_refuse}
                    color="#000000"
                  />
                  <Topic
                    title="Cancelaciones"
                    val={agreementDetail.summary.vl_units_cancelled}
                    color="#000000"
                  />
                  <Topic
                    title="Tarifa mas utilizada"
                    val={agreementDetail.summary.vl_rate_max}
                    color="#000000"
                  />
                  <br />
                  <Topic
                    title={`Moneda Internacional (${agreementDetail.summary.summary_profits[1].ds_currency}) : `}
                    val={agreementDetail.summary.summary_profits[1].vl_value}
                    color="#000000"
                  />
                  <Topic
                    title={`Moneda Local (${agreementDetail.summary.summary_profits[0].ds_currency}) : `}
                    val={agreementDetail.summary.summary_profits[0].vl_value}
                    color="#000000"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <br />
          {agreementDetail.rates_of_packs.map((rate, index) => (
            <Fragment key={index}>
              <Accordion className={classes.root}>
                <AccordionSummary
                  className={classes.header}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    {rate.vl_rate} % de Comision
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <br />
                    <Topic
                      title="Packs Aplicados: "
                      val={rate.vl_applied}
                      color="#000000"
                    />
                    <Topic
                      title="Packs Inactivos: "
                      val={rate.vl_inactive}
                      color="#000000"
                    />
                    <Topic
                      title="Packs Activos: "
                      val={rate.vl_active}
                      color="#000000"
                    />
                    <br />
                    <Topic
                      title="Unidades Vendidas: "
                      val={rate.vl_units_sales}
                      color="#000000"
                    />
                    <Topic
                      title="Cancelaciones: "
                      val={rate.vl_units_cancelled}
                      color="#000000"
                    />
                    <Topic
                      title="Reclamos: "
                      val={rate.vl_units_refuse}
                      color="#000000"
                    />
                    <br />
                    {rate.profits_rates && (
                      <>
                        <Topic
                          title={`Moneda Internacional (${rate.profits_rates[1].ds_currency}): `}
                          val={rate.profits_rates[1].vl_value}
                          color="#000000"
                        />
                        <Topic
                          title={`Moneda Local (${rate.profits_rates[0].ds_currency}): `}
                          val={rate.profits_rates[0].vl_value}
                          color="#000000"
                        />
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />
            </Fragment>
          ))}
        </>
      )}
    </div>
  );
});
