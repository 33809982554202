import React from "react";
import { DividerSpace } from "../dividerSpace/dividerSpace";
import "./cardPriceTitle.css";

export const CardPriceTitle = ({ title, symbol, value }) => {
  return (
    <div className="card--price--title--main--container">
      <div className="card--price--title--main--container--header">{title}</div>
      <div className="card--price--title--main--container--detail">
        <div className="card--price--title--main--container--detail--currency">
          {symbol}
        </div>
        <DividerSpace space="XXS" />
        <div className="card--price--title--main--container--detail--value">
          {value}
        </div>
      </div>
    </div>
  );
};
