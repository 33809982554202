import { TYPES } from "./Types";
import { initialState } from "./InitialState";

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_LOADING_POSTS:
      return {
        ...state,
        loading: true,
        error: false,
        stopFetch: false,
      };
    case TYPES.REQUEST_SUCCESS_POSTS:
      if (state.posts) {
        return {
          ...state,
          loading: false,
          error: false,
          posts: [...state.posts, ...action.payload],
          offset: state.offset + state.limit,
          stopFetch: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          error: false,
          posts: action.payload,
          offset: state.offset + state.limit,
        };
      }
    case TYPES.REQUEST_FAILURE_POSTS:
      return {
        ...state,
        loading: false,
        error: true,
        stopFetch: true,
      };
    case TYPES.STOP_POSTS_FETCH:
      return {
        ...state,
        loading: false,
        stopFetch: true,
      };
    case TYPES.SET_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };
    default:
      return state;
  }
};
