import { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { setAgreementOption } from "../../redux/deals/Actions";
import { ButtonFilters } from "../ButtonFilters/ButtonFilters";

export const AgreementsFilter = ({ close }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.deal);
  const { agreementOption } = state;
  const [option, setOption] = useState(agreementOption);

  const onChange = ({ target }) => setOption(target.value);

  const radioButtons = [
    { id: 1, value: "", label: "Todos" },
    { id: 2, value: "activo", label: "Activos" },
    { id: 3, value: "inactivo", label: "Inactivos" },
  ];

  const applyFilter = () => {
    dispatch(setAgreementOption(option));
    close();
  };

  return (
    <Container style={{ paddingTop: "2rem" }}>
      <FormLabel component="legend" style={{ paddingBottom: "0.5rem" }}>
        ORDENAR POR
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={option}
        onChange={onChange}
      >
        {radioButtons.map(({ id, value, label }) => (
          <FormControlLabel
            key={id}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
      <div
        style={{
          width: "98vw",
          textAlign: "center",
          padding: "1rem 2rem 1.5rem 0",
        }}
      >
        <ButtonFilters onClick={applyFilter}>APLICAR FILTROS</ButtonFilters>
      </div>
    </Container>
  );
};
