import React from "react";
import "./currencyOption.css";

export const CurrencyOption = ({ option, currency, chooseCurrency }) => {
 
  return (
    <div
      className={`currency--option--main--container ${
        option === currency ? "selected--option--currency" : ""
      }`}
      onClick={() => chooseCurrency(option)}
    >
      {option}
    </div>
  );
};
