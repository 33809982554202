import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { Preferences } from "../preferences/preferences";
import { CreateAccount } from "../createAccount/createAccount";
import { getDataCountry } from "../../redux/actions";
// import { getPreferencesGenders } from "../../redux/account/accountActions";
import { FinishProcess } from "../finishProcess/finishProcess";
import "./stepperAccountRegister.css";

export const StepperAccountRegister = () => {
	const dispatch = useDispatch();
	const [userForm, setUserForm] = useState(null);
	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () =>
		setActiveStep((prevActiveStep) => prevActiveStep + 1);

	// const handleBack = () =>
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);

	useEffect(() => {
		// dispatch(getPreferencesGenders());
		dispatch(getDataCountry());
	}, []); // eslint-disable-line

	return (
		<div className="stepper--acount--main--container">
			{activeStep === 0 ? (
				<CreateAccount handleNext={handleNext} setUserForm={setUserForm} />
			// ) : activeStep === 1 ? (
			// 	<Preferences
			// 		userForm={userForm}
			// 		handleNext={handleNext}
			// 		handleBack={handleBack}
			// 	/>
			) : (
				<FinishProcess
					title={"Validación de cuenta"}
					description={
						"Por favor verifique su correo electrónico para confirmar su cuenta. Revise su correo no deseado"
					}
					urlRedirect="/login"
					buttonTitle="ACEPTAR"
				/>
			)}
		</div>
	);
};
