import { useEffect, useState } from "react";
//import Grid from "@material-ui/core/Grid";
import opsIcon from "../../icons/fetch/ooops-icon.png";
//import fireIcon from "../../icons/profile/fire-profile-icon.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
//import { makeStyles } from "@material-ui/core/styles";
import "./HotmerGridItem.css";

/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100vw",
  },
  paper: {
    padding: "0",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridElement: {
    textAlign: "center",
    position: "relative",
    width: "160px",
    height: "160px",
    margin: "10px",
    overflow: "hidden",
  },
}));*/

export const HotmerGridItem = ({ hc }) => {
  const history = useHistory();
  const [hotmer, setHotmer] = useState(null);
  const [profile, setProfile] = useState({
    src: hc.profile_photo_path,
    errored: false,
  });

  const handleClickHotmer = ({ id , ds_display_name}) => {
    const uid = getIdUser();
    const url = id == uid ? `/myProfile/${uid}` : `/${ds_display_name}`;
    history.push(url);
  };

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  const renderSwitch = (param) => {
    switch(param) {
        case 1:
          return (
            ""
          )
          case 2:
          return (
            "Fotógrafo"
          )
          case 3:
          return (
            "Manager"
          )
          case 4:
            return (
              "Agencia"
            )
      default:
          return (
            <div></div>
          )
    }
  }

  

  useEffect(() => {
    setHotmer(hc);
    setProfile({
      src: hc.profile_photo_path || profileDefault,
      errored: false,
    });
  }, [hc]); // eslint-disable-line

  //const classes = useStyles();
  // console.log("hotmer",hotmer);
  if (hotmer) {
    return (
        <div className="profile--grid--content--main--container"
        onClick={() => handleClickHotmer(hotmer, hotmer?.ds_display_name)}>
            {/* <div className="profile--grid--content--main--container--element--data--image"> */}
              <img
                className="profile--grid--content--main--container--element--data--image--rounded"
                src={profile.src}
                onError={onErrorProfile}
                alt="..."
                //onClick={() => handleClickHotmer(hotmer)}
              />
              <div className="profile--grid--content--main--container--displayName">
                {hotmer.ds_display_name}
                <div className="profile--grid--content--main--container--displayName-2">
                {renderSwitch(hotmer.id_type_of_role)}
                </div>
              {/* </div>
            </div> */}
            </div>
          {/* </div> */}
        </div>
    );
  } else {
    return <></>;
  }
};
