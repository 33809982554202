export const generateSubscriptionList = (global, local) => {
	let finalList = [];

	const j1 = {
		currency: global.currency.ds_currency_code,
		price: global.vl_month1_price,
		selected: true,
		symbol: global.currency.ds_symbol,
		discount: global.vl_month1_discount,
		bestValue: global.vl_month1_best_value,
	};

	const j2 = {
		currency: local.currency.ds_currency_code,
		price: local.vl_month1_price,
		selected: false,
		symbol: local.currency.ds_symbol,
		discount: local.vl_month1_discount,
		bestValue: local.vl_month1_best_value,
	};

	const a = {
		id: 1,
		selected: true,
		title: "1 Mes",
		values: [j1, j2],
	};

	finalList.push(a);
	/* ************************************************ */

	const j3 = {
		currency: global.currency.ds_currency_code,
		price: global.vl_month3_price,
		selected: true,
		symbol: global.currency.ds_symbol,
		discount: global.vl_month3_discount,
		bestValue: global.vl_month3_best_value,
	};

	const j4 = {
		currency: local.currency.ds_currency_code,
		price: local.vl_month3_price,
		selected: false,
		symbol: local.currency.ds_symbol,
		discount: local.vl_month3_discount,
		bestValue: local.vl_month3_best_value,
	};

	const b = {
		id: 3,
		selected: false,
		title: "3 Meses",
		values: [j3, j4],
	};
	finalList.push(b);
	/* ************************************************ */

	const j5 = {
		currency: global.currency.ds_currency_code,
		price: global.vl_month6_price,
		selected: true,
		symbol: global.currency.ds_symbol,
		discount: global.vl_month6_discount,
		bestValue: global.vl_month6_best_value,
	};

	const j6 = {
		currency: local.currency.ds_currency_code,
		price: local.vl_month6_price,
		selected: false,
		symbol: local.currency.ds_symbol,
		discount: local.vl_month6_discount,
		bestValue: local.vl_month6_best_value,
	};

	const c = {
		id: 6,
		selected: false,
		title: "6 Meses",
		values: [j5, j6],
	};
	finalList.push(c);

	/* ************************************************ */

	const j7 = {
		currency: global.currency.ds_currency_code,
		price: global.vl_month12_price,
		selected: true,
		symbol: global.currency.ds_symbol,
		discount: global.vl_month12_discount,
		bestValue: global.vl_month12_best_value,
	};

	const j8 = {
		currency: local.currency.ds_currency_code,
		price: local.vl_month12_price,
		selected: false,
		symbol: local.currency.ds_symbol,
		discount: local.vl_month12_discount,
		bestValue: local.vl_month12_best_value,
	};

	const d = {
		id: 12,
		selected: false,
		title: "12 Meses",
		values: [j7, j8],
	};
	finalList.push(d);

	return finalList;
};

export const initialStateSubscription = [
	{
		id: 1,
		selected: true,
		title: "1 Mes",
		values: [
			{ currency: "USD", price: 15, selected: false },
			{ currency: "ARS", price: 150, selected: true },
		],
	},
	{
		id: 2,
		selected: false,
		title: "3 Meses",
		values: [
			{ currency: "USD", price: 40, selected: false },
			{ currency: "ARS", price: 400, selected: true },
		],
	},
	{
		id: 3,
		selected: false,
		title: "6 Meses",
		values: [
			{ currency: "USD", price: 65, selected: false },
			{ currency: "ARS", price: 650, selected: true },
		],
	},
	{
		id: 4,
		selected: false,
		title: "12 Meses",
		values: [
			{ currency: "USD", price: 80, selected: false },
			{ currency: "ARS", price: 800, selected: true },
		],
	},
];

export const updateCurrencyOptions = (subscribeOptions, currency) => {
	const newList = subscribeOptions.map((option) => {
		const newValues = option.values.map((x) => {
			if (x["currency"] === currency) {
				return {
					...x,
					selected: true,
				};
			} else {
				return {
					...x,
					selected: false,
				};
			}
		});
		return {
			...option,
			values: newValues,
		};
	});
	return newList;
};

export const updateSelectionOptions = (subscribeOptions, id) => {
	const newList = subscribeOptions.map((option) => {
		if (id === option.id) {
			return {
				...option,
				selected: true,
			};
		} else {
			return {
				...option,
				selected: false,
			};
		}
	});
	return newList;
};

export const getIdTypeSubscription = (subscribeOptions) =>
	subscribeOptions.find((x) => x.selected).id;

export const alertMessageSubscription = () =>
	alert("Error, Comuniquese con el administrador por favor. Gracias.");

export const createJsonSuscribe = (
	hotmerProfile,
	currency,
	paymentMethodSelected,
	match,
	subscribeOptions,
) => {
	let id_country;
	if (currency === "ARS") {
		id_country = hotmerProfile.local_price.currency.id_country;
	} else {
		id_country = hotmerProfile.global_price.currency.id_country;
	}
	const bodyJson = JSON.stringify({
		id_type_of_payment_method: paymentMethodSelected,
		id_hotmer: match.params.id_hotmer,
		id_month: subscribeOptions.find((x) => x.selected).id,
		id_country,
	});
	return bodyJson;
};
