export const initialState = {
  loadingSubscribedHotmers: false,
  errorSubscribedHotmers: false,
  subscribedHotmers: null,
  /* ************************************ */
  loadingWishHotmers: false,
  errorWishHotmers: false,
  wishHotmers: null,
  /* ************************************ */
  loadingSubscribedPacks: false,
  errorSubscribedPacks: false,
  subscribedPacks: null,
  /* ************************************ */
  loadingWishPacks: false,
  errorWishPacks: false,
  wishPacks: null,
};
