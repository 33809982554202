import Icon from "@material-ui/core/Icon";
import "./EditProfileOption.css";

export const EditProfileOption = ({ children, onClick }) => (
  <div className="edit--option--main--container">
    <Icon color="primary" onClick={onClick}>
      {children}
    </Icon>
  </div>
);
