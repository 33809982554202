import React from "react";
import { CustomPreferenceCheck } from "../../commonComponents/customPreferenceCheck/customPreferenceCheck";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { WrongFetchTagsScreen } from "../../commonComponents/wrongFetchTagsScreen/wrongFetchTagsScreen";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../accordionSets/accordionSets";
import "./accordionPreferences.css";
import { useTranslation } from "react-i18next";

export const AccordionPreferences = ({
  panel,
  expanded,
  handleChange,
  loading,
  editPreferences,
  handleChangeCheck,
  onClickRefreshGenders,
}) => {
  const { t } = useTranslation("global");
  return (
    <Accordion
      square
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <AccordionHeader
          panel={panel}
          expanded={expanded}
          // title={"Preferencias"}
          title={t("editProfile.topic.preferences")}

        />
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <div className="edit--options--preferences--loading">
            <PartialSpinner />
          </div>
        ) : !loading && !editPreferences ? (
          <div className="edit--options--preferences--error">
            <WrongFetchTagsScreen
              param={"Géneros"}
              onClickRefresh={onClickRefreshGenders}
            />
          </div>
        ) : (
          <div className="edit--options--preferences--user">
            {editPreferences.map((option) => {
              return (
                <CustomPreferenceCheck
                  editionUser="editionUser"
                  key={option.id_type_of_gender}
                  title={option.gender}
                  name={option.gender}
                  checked={option.checked}
                  onChangeCheck={handleChangeCheck}
                />
              );
            })}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
