import React, { useState, useEffect } from "react";
import "./uploadFilesAdd.css";

export const UploadFilesAdd = ({
  handleChangeAdd,
  selectedType,
  multiple,
  icon,
  // disabled,
  title = '',
  className = 'upload--files--add--main--container'
}) => {
  const [acceptedTypes, setAcceptedTypes] = useState(null);

  useEffect(() => {
    if (selectedType) {
      if (selectedType.id === 0) {
        setAcceptedTypes("image/*");
      } else if (selectedType.id === 1) {
        setAcceptedTypes("video/*");
      } else {
        setAcceptedTypes("image/*,video/*");
      }
    }
  }, [selectedType]);
  
  return (
    <div className={`${ className }`} >
      <div className="upload--files--add--main--container--frame">        
        <img className="upload--edit--option--container--icon" src={icon} alt="..." />
        {title == '' ? (
          <></>
        ):(
          <div className="upload--edit--option--container--title">{title}</div>
        )}
        <input
            type="file"
            multiple={multiple}
            className="upload--files--add--main--container--frame--input"
            onChange={handleChangeAdd}
            // disabled={disabled}
            accept={acceptedTypes}
          />
        
      </div>
    </div>


  );
};