/* eslint-disable */
import React from "react";
import "./extractionsInput.css";

export const ExtractionsInput = ({
	name,
	value,
	beforeSymbol,
	afterSymbol,
	onChange,
	type,
	colorText,
	fontSize,
	placeholder,
	textPosition,
	coloredBorder,
	disabled,
	maxWidth,
	maxLength,
}) => {
	const POSITIONS = ["start", "center", "end"];

	const getTextPosition = () => {
		if (textPosition) {
			const index = POSITIONS.findIndex((p) => p === textPosition);
			return POSITIONS[index];
		} else {
			return POSITIONS[0];
		}
	};

	return (
		<div
			className="extractions--input--main--container"
			style={{
				justifyContent: textPosition,
				border: coloredBorder ? `1px solid ${colorText}` : "",
			}}
		>
			{beforeSymbol && value && (
				<div className="extractions--input--main--container--before">
					{beforeSymbol}
				</div>
			)}
			<div
				className="extractions--input--main--container--box"
				style={{ maxWidth: value === "" ? "100%" : maxWidth }}
			>
				<input
					style={{
						color: colorText,
						fontSize: fontSize,
						// maxWidth: maxWidth
					}}
					className={`extractions--input--main--container--box--input ${
						getTextPosition() === "start"
							? "text--start"
							: getTextPosition() === "center"
							? "text--center"
							: "text--end"
					}`}
					placeholder={placeholder}
					name={name}
					maxLength={maxLength}
					type={type}
					//value={beforeSymbol + " " + value + " " + afterSymbol}
					value={value}
					onChange={(e) => {
						if (name == "amount") {
								const val = e.target.value;
								if (val === "") {
									e.target.value = 0;
									onChange(e);
								} else {
									const regExp = /^\d+(\.?)?(\d{1,2})?$/;
									let regValidation = regExp.test(val);
									if (regValidation === true) {
										const badValue = val.includes("e");
										const badOrder = val[0] == "0";
										if (badValue || badOrder) {
											var pointNum = parseFloat(val);
											e.target.value = pointNum;
										}
										onChange(e);
									}
								}
						} else {
							onChange(e);
						}
					}}
					disabled={disabled}
				/>
			</div>
			{afterSymbol && value && (
				<div className="extractions--input--main--container--after">
					{afterSymbol}
				</div>
			)}
		</div>
	);
};
