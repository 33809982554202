/* eslint-disable */
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { CommentPost } from "../../components/commentPost/commentPost";
import { ProfileImage } from "../../components/profileImage/profileImage";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { ButtonAddComment } from "../../components/buttonAddComment/buttonAddComment";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import {
	generateComment,
	mapInitComments,
	failurePostComment,
	getUserSession,
} from "../../libs/comments";
import "./commentsPost.css";

export const CommentsPost = ({ match }) => {
	const history = useHistory();

	const [selectedComment, setSelectedComment] = useState(null);
	const [openModalRemoveComment, setOpenModalRemoveComment] = useState(false);
	const [loadingRemoveComment, setLoadingRemoveComment] = useState(false);

	const [openResultModal, setOpenResultModal] = useState(false);
	const [messageResultModal, setMessageResultModal] = useState("");
	const [loadingData, setLoadingData] = useState(false);
	const [loadingPublish, setLoadingPublish] = useState(false);
	const [comments, setComments] = useState(null);

	const [data, setData] = useState({ comment: "" });

	const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

	const getCommentsPost = async (idh, idc) => {
		setLoadingData(true);
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idh}/content/${idc}/comment`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			const response = await res.json();
			console.log("COMMENTS RES: ", res);
			console.log("COMMENTS RESPONSE: ", response);
			if (response.code === 200) {
				setComments(mapInitComments(response.data));
			}
			setLoadingData(false);
		} catch (error) {
			setLoadingData(false);
			console.log("ERROR: ", error);
		}
	};

	const publishComment = async () => {
		setLoadingPublish(true);
		const { comment } = data;
		const idh = match.params.id_hotmer;
		const idc = match.params.id_content_group;
		const json = { id_content_group: idc, ds_comment: comment };
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idh}/content/${idc}/comment`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: JSON.stringify(json),
			});
			const response = await res.json();
			if (res.status === 201 && response.code === 201) {
				const newComment = generateComment(
					data,
					comments,
					idc,
					response.data.id_comment,
				);
				// console.log("RES: ", res);
				// console.log("RESPONSE: ", response);
				setComments([...comments, newComment]);
				// console.log("PUBLICACION COMENTADA CORRECTAMENTE");
			} else {
				setMessageResultModal(failurePostComment);
				setOpenResultModal(true);
			}
			setLoadingPublish(false);
		} catch (error) {
			console.log("ERROR: ", error);
			setMessageResultModal(failurePostComment);
			setOpenResultModal(true);
			setLoadingPublish(false);
		}
		setData({ comment: "" });
	};

	const onClickRefreshComments = () => {
		const idh = match.params.id_user_hotmer;
		const idc = match.params.id_content_group;
		getCommentsPost(idh, idc);
	};

	const confirmRemoveComment = async () => {
		setLoadingRemoveComment(true);
		console.log(selectedComment);
		const { id_comment, id_content_group, id_user } = selectedComment;
		try {
			const idUser = JSON.parse(localStorage.getItem("session")).user.id;
			const url = `${process.env.REACT_APP_INITIAL_V2}/users/${id_user}/content/${id_content_group}/comment/${id_comment}`;
			const res = await fetch(url, { headers: setHeaders(), method: "DELETE" });
			// console.log("RES REMOVE COMMENT: ", res);
			if (res.status === 200) {
				const newComments = comments.filter((c) => c.id_comment !== id_comment);
				// console.log("NEW COMMENTS: ", newComments);
				setComments(newComments);
			} else {
				setOpenResultModal(true);
				setMessageResultModal("Error al eliminar comentario. Intente mas tarde, si el error persiste comuniquese con support@hotmy.net.");
			}
		} catch (error) {
			setOpenResultModal(true);
			setMessageResultModal("Error. Intente mas tarde por favor.");
			console.warn("ERROR: ", error);
		}
		setSelectedComment(null);
		setLoadingRemoveComment(false);
	};

	const applyOption = () => {
		confirmRemoveComment();
		setOpenModalRemoveComment(false);
	};

	const cancelOption = () => {
		setOpenModalRemoveComment(false);
		setSelectedComment(null);
	};

	const tryRemoveComment = (comment) => {
		// console.log(id_comment, id_content_group, id_user);
		setSelectedComment(comment);
		setOpenModalRemoveComment(true);
	};

	useEffect(() => {
		if (openResultModal) {
			setTimeout(() => {
				setOpenResultModal(false);
			}, 2000);
		}
	}, [openResultModal]);

	useEffect(() => {
		const idh = match.params.id_hotmer;
		const idc = match.params.id_content_group;
		getCommentsPost(idh, idc);
	}, []);

	let name = getUserSession().full_name || "";

	if (loadingData) {
		return <LoadingScreen loading={loadingData} />;
	} else if (!loadingData && !comments) {
		return <WrongFetchScreen onClickRefresh={onClickRefreshComments} />;
	} else {
		return (
			<div className="comments--post--main--container">
				<div className="comments--post--main--container--header">
					<div
						onClick={() => history.goBack()}
						className="comments--post--main--container--header--arrow"
					>
						<ArrowBackIcon
							style={{ height: "30px", width: "30px", color: "#000" }}
						/>
					</div>
					<div className="comments--post--main--container--header--title">
						Comentarios
					</div>
				</div>
				<div className="comments--post--main--container--list">
					{comments.map((comment, i) => (
						<CommentPost
							key={i}
							loadingRemoveComment={loadingRemoveComment}
							comment={comment}
							tryRemoveComment={tryRemoveComment}
	
						/>
					))}
				</div>
				<div className="comments--post--main--container--add">
					<div className="comments--post--main--container--add--profile">
						<ProfileImage />
					</div>
					<div className="comments--post--main--container--add--text">
						<InputAddComment
							placeholder={`Comentar como ${name}`}
							name="comment"
							type="text"
							value={data.comment}
							onChange={onChange}
							maxLength={"30"}
						/>
					</div>
					<div className="comments--post--main--container--add--button">
						<ButtonAddComment
							loading={loadingPublish}
							disabled={data.comment == ""}
							onClick={publishComment}
						/>
					</div>
				</div>
				<CustomModal
					openModal={openResultModal}
					setOpenModal={setOpenResultModal}
				>
					<MessageIncompleteData message={messageResultModal} />
				</CustomModal>
				<CustomModal
					openModal={openModalRemoveComment}
					setOpenModal={setOpenModalRemoveComment}
				>
					<OptionToTrending
						title={"Está seguro que desea eliminar esta comentario ?"}
						acceptClick={applyOption}
						notAcceptclick={cancelOption}
						// disabledButtons={disabledButtons}
					/>
				</CustomModal>
			</div>
		);
	}
};

const InputAddComment = ({
	placeholder,
	name,
	value,
	onChange,
	type,
	limit,
}) => {
	return (
		<div className="input-add--comment--main--container">
			<textarea
				className="input-add--comment--main--container--input"
				name={name}
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				maxLength={limit}
			/>
		</div>
	);
};
