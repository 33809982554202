/* eslint-disable */
import React, { useState, useEffect } from "react";
import fireIcon from "../../icons/dark-fire.png";
import profileIcon from "../../icons/person-logo.png";
import ubicationIcon from "../../icons/content/ubicacion-icon.png";
import { Conditions } from "../../components/conditions/conditions";
//import { DatePicker } from "../../commonComponents/datePicker/datePicker";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomInputTextIcon } from "../../commonComponents/customInputTextIcon/customInputTextIcon";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { CustomInputTextIconForModal } from "../../commonComponents/customInputTextIconForModal/customInputTextIconForModal";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoadingToBecomeHotmer,
  setScreenPosition,
} from "../../redux/actions";
import { firebase } from "../../firebase/firebase-config";
// import {
// 	firebase,
// 	googleAuthProvider,
// 	facebookAuthProvider,
// } from "../../firebase/firebase-config";
// import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
// import { useHistory } from "react-router-dom";
import { setHeadersNoToken } from "../../libs/fetch";
import { hotmyVersion } from '../../../src/constants/constants'
// import {
// 	getAvailableDays,
// 	getAvailableYears,
// 	getAvailableMonths,
// } from "../../libs/fetch";

// import {
//   setLoginFirebaseUser,
//   setAuthenticatedUser,
//   loadAuthenticatedUser,
//   // setScreenPosition,
// } from "../../redux/actions";
import {
  conditionsMessage,
  // incompleteJson,
  incompleteJsonExcept,
  incompleteMessage,
  initialMiniState,
  mapInitCountries,
  createBodyClon /*esto se usa para armar el body*/,
} from "../../libs/account";
import "./miniForm.css";

export const MiniForm = ({ miniData, handleNext }) => {
  const state = useSelector((state) => state.main);
  // const { preferencesGenders, loginFirebaseUser } = state;
  const dispatch = useDispatch();
  const { dataCountry } = state;
  // const [loginFirebaseUser,setLoginFirebaseUser] = useState(null);
  // const [openModalSelection, setOpenModalSelection] = useState(false);
  // const [selectedType, setSelectedType] = useState(null);
  // const [titleType, setTitleType] = useState(null);
  // const [optionsList, setOptionsList] = useState(null);
  // const [optionsYears, setOptionsYears] = useState(null);
  // const [optionsMonths, setOptionsMonths] = useState(null);
  // const [mayChild, setMayChild] = useState(null);
  // const [optionsDays, setOptionsDays] = useState(null);
  /* *********************************** */
  //const [selectedDate, setSelectedDate] = useState(new Date("2000/11/25"));
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loadingCreation, setLoadingCreation] = useState(false);
  let [userData, setUserData] = useState(initialMiniState);
  const [checked, setChecked] = useState(true); //por default lo ponemos en true porque acepto lo anterior.
  const [modalMessage, setModalMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const [openModalPicker, setOpenModalPicker] = useState(false);
  const [openModalCountry, setOpenModalCountry] = useState(false);
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {
    console.log("Entre: ");
    console.log("referralCode 0: ", referralCode);
    if// (referralCode == "") 
    (referralCode === null)
    {
      getReferralCodeParam();
    }
  }, []); // ante el cambio de estado del referral ejecutar el get

  const getReferralCodeParam = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referralCodeParam = urlParams.get("codRef");
    if (
      referralCodeParam == null ||
      referralCodeParam == "" ||
      referralCodeParam == undefined ||
      referralCodeParam == "null"
    ) {
      setReferralCode("");
    } else {
      setReferralCode(referralCodeParam);
      setUserData({ ...userData, codRef: referralCodeParam });
    }
  };

  const onChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });

  const onChangeCheck = () => setChecked((x) => !x);
  const changeCheckBox = () => setChecked((x) => !x);

  const openModalConditions = () => {
    setModalMessage(conditionsMessage);
    setOpenModal(true);
  };

  const functionOpenModalCountry = () => setOpenModalCountry(true);

  const createBodyClon = (miniData, loginFirebaseUser) => {
    const json = {
      // birthDay: miniData.birthDay,
      id_country: miniData.nationality.id_country,
      codRef: miniData.codRef,
      // id_type_of_genders: list,
      // name: loginFirebaseUser.name,
      firebase_token: loginFirebaseUser.firebase_token,
      // device: loginFirebaseUser.device,
      is_new_user: loginFirebaseUser.is_new_user,
    };
    console.log("JSON POST BODY CLON: ", json);
    return JSON.stringify(json);
  };

  let userData2 = { ...userData };

  const handleSubmit = async () => {
    // const newBirthDay = date.year + "/" + date.month + "/" + date.day;
    // let userData2 = {
    // 	...userData,
    // 	birthDay: newBirthDay,
    // };

    if (
      incompleteJsonExcept(userData2, "codRef") ||
      // incompleteJson(date) ||
      !checked
    ) {
      setModalMessage(
        incompleteMessage + " (El código de referido no es obligatorio)"
      );
      setOpenModal(true);
    } else {
      // console.log("USER DATA 1: ", userData2);
      // console.log (setMiniData.data);
      // setMiniData(userData2);
      //const jsonPost = creationBody2(jsonResponse);
      // handleNext(); esto lo meto despues
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          console.log("Entra a llamada de Creacion Final");
          creationFinal(idToken);
          // Send token to your backend via HTTPS
          // ...
        })
        .catch(function (error) {
          // Handle error
        });

      // console.log("Minidata",miniData);
      // console.log("bodyJsonMinidata",loadAuthenticatedUser.data);
      // console.log("bodyJsonMinidata",setAuthenticatedUser.data);
      // 	const bodyJson = createBodyClon(
      // 	userData2,
      // 	loginFirebaseUser
      // );
      // console.log("bodyJson",bodyJson);

      // try {
      // 	dispatch(setLoadingToBecomeHotmer());
      // 	const url = `${process.env.REACT_APP_AUTH}/login`;
      // 	const res = await fetch(url, {
      // 		headers: setHeadersNoToken(),
      // 		method: "POST",
      // 		credentials: "include",
      // 		body: bodyJson,
      // 	});
      // 	console.log("RES CREATE: ", res);
      // 	if (res.status === 201) {
      // 		const response = await res.json();
      // 		console.log("RESPONSE CREATE: ", response);
      // 		localStorage.setItem("session", JSON.stringify(response.data));
      // 		dispatch(setLoadingToBecomeHotmer());
      // 		dispatch(setScreenPosition(0));
      // 		window.location.href = "/";
      // 		// handleNext();
      // 	}
      // } catch (error) {
      // 	// dispatch(setLoadingToBecomeHotmer());
      // 	console.log("CATCH ERROR: ", error);
      // }
      // dispatch(setLoadingToBecomeHotmer());
    }
  };
  /**/

  const creationFinal = async (idToken) => {
    const bodyJson = {
      firebase_token: idToken,
      id_country: userData2.nationality.id_country,
      codRef: userData2.codRef,
      is_new_user: true,
    };
    const json = JSON.stringify(bodyJson);
    console.log(bodyJson);
    //	JSON.stringify(bodyJson); //lo páso a json
    console.log(json);
    dispatch(setLoadingToBecomeHotmer());
    try {
      const url = `${process.env.REACT_APP_AUTH}/login`;
      const res = await fetch(url, {
        headers: setHeadersNoToken(),
        method: "POST",
        credentials: "include",
        body: json,
      });
      console.log("RES CREATE: ", res);
      if (res.status === 201) {
        const response = await res.json();
        console.log("RESPONSE CREATE: ", response);
        localStorage.setItem("session", JSON.stringify(response.data));
        localStorage.setItem("hmyAppVersion", hotmyVersion);
        dispatch(setLoadingToBecomeHotmer());
        dispatch(setScreenPosition(0));
        window.location.href = "/";
        handleNext();
      }
    } catch (error) {}
    dispatch(setLoadingToBecomeHotmer());
  };

  // const onChooseType = (type) => {
  // 	setSelectedType(type);
  // }

  const chooseCountry = (c, title) => {
    setSelectedCountry(c);
    setUserData({ ...userData, nationality: c });
    setOpenModalCountry(false);
  };

  // const onChooseOption = (option, title) => {
  // 	console.log("option: ", option);
  // 	console.log("title: ", title);

  // 	if (title === "year") {
  // 		setDate({ ...date, [title]: option.value, day: "", month: "" });
  // 		const mth = getAvailableMonths(option);
  // 		console.log("mth: ", mth);
  // 		const { arrayMonths, maybeChild } = mth;
  // 		setOptionsMonths(arrayMonths);
  // 		setMayChild(maybeChild);
  // 	} else if (title === "month") {
  // 		setDate({ ...date, [title]: option.value, day: "" });
  // 		const availableDays = getAvailableDays(date.year, option.value, mayChild);
  // 		setOptionsDays(availableDays);
  // 	} else {
  // 		setDate({ ...date, [title]: option.value });
  // 	}
  // 	setOpenModalSelection(false);
  // };

  useEffect(() => {
    if (dataCountry) {
      setCountries(mapInitCountries(dataCountry));
    }
  }, [dataCountry]);

  // useEffect(() => {
  // 	if (selectedType) {
  // 		console.log("TYPE: ", selectedType);
  // 		if (selectedType === "year") {
  // 			setTitleType({ type: "Año", name: "year" });
  // 			setOptionsList(optionsYears);
  // 		} else if (selectedType === "month") {
  // 			setTitleType({ type: "Mes", name: "month" });
  // 			setOptionsList(optionsMonths);
  // 		} else if (selectedType === "day") {
  // 			setTitleType({ type: "Dia", name: "day" });
  // 			setOptionsList(optionsDays);
  // 		}
  // 		setOpenModalSelection(true);
  // 	}
  // }, [selectedType]);

  // useEffect(() => {
  // 	if (!openModalSelection) {
  // 		setSelectedType(null);
  // 	}
  // }, [openModalSelection]);

  // useEffect(() => {
  // 	const availableYears = getAvailableYears();
  // 	setOptionsYears(availableYears);
  // }, []);

  return (
    <div className="mini--form--main--container">
      <LoadingScreen loading={loadingCreation} />
      <div className="mini--form--main--container--inputs">
        <div className="mini--form--main--container--inputs--header">
          <img
            src={fireIcon}
            alt="..."
            className="mini--form--main--container--inputs--header--image"
          />
          <DividerSpace space="XS" />
          <div className="mini--form--main--container--inputs--header--title">
            Crear Nueva Cuenta
          </div>
        </div>
        <DividerSpace space="X" />
        {/*  */}
        <CustomInputTextIconForModal
          placeholder="Pais de Residencia"
          name="nationality"
          type="text"
          value={userData.nationality.ds_diminutive || ""}
          onChange={onChange}
          image={ubicationIcon}
          onClick={() => functionOpenModalCountry()}
          //limit={3}
        />
        <DividerSpace space="L" />
        <CustomInputTextIcon
          placeholder="Código Ref. Opcional"
          name="codRef"
          type="text"
          value={userData.codRef || ""}
          onChange={onChange}
          image={profileIcon}
          limit={10}
        />
        {/* ********************************* */}
        <DividerSpace space="L" />
        <Conditions
          checked={checked}
          onChangeCheck={onChangeCheck}
          changeCheckBox={changeCheckBox}
          onClick={openModalConditions}
        />
        <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
          <MessageIncompleteData message={modalMessage} />
        </CustomModal>
      </div>
      <CustomModal
        openModal={openModalCountry}
        setOpenModal={setOpenModalCountry}
        // name="birthDay"
      >
        {countries && (
          <MobileWheelSelector
            title={"PAISES"}
            type="onlyText"
            data={countries}
            idList={"PAISES"}
            chooseElement={chooseCountry}
          />
        )}
      </CustomModal>
      <div className="mini--form--main--container--main--button">
        <CustomButtonRegister onClick={handleSubmit}>
          CREAR CUENTA
        </CustomButtonRegister>
      </div>
    </div>
  );
};
