export const TYPES = {
  SET_USER_ACCOUNT_CREATION: "SET_USER_ACCOUNT_CREATION",
  SET_OPTIONS_STATS: "SET_OPTIONS_STATS",
  SET_USER_HOTMER_STATUS: "SET_USER_HOTMER_STATUS",
  LOAD_REQUEST_USERS_DATA: "LOAD_REQUEST_USERS_DATA",
  SET_SUCCESS_USERS_DATA: "SET_SUCCESS_USERS_DATA",
  SET_FAILURE_USERS_DATA: "SET_FAILURE_USERS_DATA",
  LOAD_REQUEST_USER_DATA: "LOAD_REQUEST_USER_DATA",
  SET_SUCCESS_USER_DATA: "SET_SUCCESS_USER_DATA",
  SET_FAILURE_USER_DATA: "SET_FAILURE_USER_DATA",
  SET_USER_LOGGED: "SET_USER_LOGGED",
  SET_REGISTER_FIREBASE_USER: "SET_REGISTER_FIREBASE_USER",
  SET_LOGIN_FIREBASE_USER: "SET_LOGIN_FIREBASE_USER",
  LOAD_AUTHENTICATED_USER: "LOAD_AUTHENTICATED_USER",
  SET_AUTHENTICATED_USER: "SET_AUTHENTICATED_USER",
  LOAD_REQUEST_HOTMERS_HOME: "LOAD_REQUEST_HOTMERS_HOME",
  SET_SCREEN_POSITION: "SET_SCREEN_POSITION",
  SET_VISIBLE_PROFILE_DRAWER: "SET_VISIBLE_PROFILE_DRAWER",
  SET_VISIBLE_CONTENT_DRAWER: "SET_VISIBLE_CONTENT_DRAWER",
  SET_TAB_FIRE_FROM_DRAWER: "SET_TAB_FIRE_FROM_DRAWER",
  SET_TAB_SEARCH: "SET_TAB_SEARCH",
  SET_HOTMERS_DATA: "SET_HOTMERS_DATA",
  SET_DISCARD_EDIT_MODAL: "SET_DISCARD_EDIT_MODAL",
  SET_LOADING_TO_BECOME_HOTMER: "SET_LOADING_TO_BECOME_HOTMER",
  SET_SUBMIT_EDIT_PROFILE: "SET_SUBMIT_EDIT_PROFILE",
  LOAD_REQUEST_CONUTRY: "LOAD_REQUEST_CONUTRY",
  SET_SUCCESS_CONUTRY: "SET_SUCCESS_CONUTRY",
  SET_FAILURE_COUNTRY: "SET_FAILURE_COUNTRY",
};
