/* eslint-disable */
//import React, { useState, useEffect } from "react"; SI HABILITAMOS EL TEMA DEL CHECKEO DE STATISCS
import React, { useState } from "react";
//import english from "../../icons/language/usa.png";
//import spanish from "../../icons/language/espana.png";
import editIcon from "../../icons/profile/edit-icon.png";
import heartIcon from "../../icons/profile/heart-icon.png";
import drawerBox from "../../icons/drawerProfile/drawer--box--profile.jpg";
import profileIcon from "../../icons/profile/profile-icon.png";
import settingsIcon from "../../icons/profile/setting-icon.png";
import WhatsAppIcon2 from "../../icons/grayIcons/drawerProfile-icon-item-whatsGray2.svg";
import DealIcon from "../../icons/grayIcons/drawerProfile-icon-item-deal-gray.png";
import InstagramIcon from "../../icons/grayIcons/instagram2.png";
// import YoutubeIcon from "../../icons/grayIcons/youtube2.png";
import TelegramIcon from "../../icons/grayIcons/telegram.png"
import FastHelpIcon from "../../icons/grayIcons/help-web-button.png"
import userGrayImg2 from "../../icons/drawerProfile/background--visitor.jpg";
import ExtactionIcon from "../../icons/profile/extraction-icon.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import hotmerRoseImg2 from "../../icons/drawerProfile/background--hotmer-3.jpg";
import profileErrorImg from "../../icons/profile/profile-error-img.png";
import suscriptionsIcon from "../../icons/profile/suscriptions.png";
//import { getIdUser } from "../../libs/login";
//import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
//import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
//import { MoneyAmountUser } from "../moneyAmountUser/moneyAmountUser";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  setScreenPosition,
  setTabFireFromDrawer,
  setVisibleProfileDrawer,
} from "../../redux/actions";
import "./drawerRightProfile.css";
//import { LanguageImage } from "../LanguageImage/LanguageImage";

export const DrawerRightProfile = () => {
  const { t, i18n } = useTranslation("global");
  const history = useHistory();
  const state = useSelector((state) => state.main);
  const { visibleProfileDrawer } = state;
  const dispatch = useDispatch();
  //const { t } = useTranslation("global");

  /*const [loadingStatisticsUser, setLoadingStatisticsUser] = useState(false);
  const [statisticsUser, setStatisticsUser] = useState(null);
  const [counter, setCounter] = useState(0);
  const [activeCounter, setActiveCounter] = useState(false);*/

  let user;
  try {
    user = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const [myStateProfile, setMyStateProfile] = useState({
    src: user.img_profile ? user.img_profile : profileDefault,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!myStateProfile.errored) {
      setMyStateProfile({
        src: profileErrorImg,
        errored: true,
      });
    }
  };

  const closeDrawer = (e) => {
    const id = e.target.id;
    if (id === "drawer--right--profile--main--container") {
      dispatch(setVisibleProfileDrawer());
    }
  };

  const clickFirstOption = (bool) => {
    dispatch(setVisibleProfileDrawer());
    dispatch(setScreenPosition(4));
    localStorage.setItem("idUrl", 4);
    if (bool) {
      history.push(`/myProfile`);
    } else {
      history.push(`/editProfileVisitor/${user.id}`);
    }
  };

  const clickSecondOption = (bool) => {
    if (bool) {
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(4));
      localStorage.setItem("idUrl", 4);
      history.push(`/editProfileHotmer/${user.id}`);
    } else {
      dispatch(setTabFireFromDrawer(0));
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(3));
      localStorage.setItem("idUrl", 3);
      history.push("/fire");
    }
  };

  const clickThirdOption = (bool) => {
    if (bool) {
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(4));
      localStorage.setItem("idUrl", 4);
      history.push("/extractions");
    } else {
      dispatch(setTabFireFromDrawer(1));
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(3));
      localStorage.setItem("idUrl", 3);
      history.push("/fire");
    }
  };

  /*const clickConfigProfile = () => {
    dispatch(setVisibleProfileDrawer());
    dispatch(setScreenPosition(4));
    localStorage.setItem("idUrl", 4);
    history.push(`/configProfile/${user.id}`);
  };*/

  const clickConfigProfile = (bool) => {
    if (bool) {
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(4));
      localStorage.setItem("idUrl", 4);
      history.push(`/configProfile/${user.id}`);
    } else {
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(4));
      localStorage.setItem("idUrl", 4);
      // history.push(`/configVisitor/${user.id}`);
      history.push(`/configVisitor/${user.id}`);
    }
  };

  const clickWhatsAppSupport = (bool) => {
    if (bool) {
      // SOLO VOY POR HOTMER
      dispatch(setVisibleProfileDrawer());
      dispatch(setScreenPosition(5));
      localStorage.setItem("idUrl", 5);
      //ABRO VENTANA NUEVA EN EXPLORADOR
      window.open(
        `https://wa.me/5491168714284?text=Me%20gustaría%20recibir%20asesoramiento.%20Mi%20Nombre%20de%20usuario%20es%20${user.full_name}%20(${user.id}),%20Les%20dejo%20mi%20consulta%20:`,
        "_blank"
      );
    } else {
    }
  };

  /* LLAMADA A ESTADISTICAS
  const getStatisticsUser = async () => {
    setLoadingStatisticsUser(true);
    try {
      const idUser = getIdUser();
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idUser}/statistics`;
      const res = await fetch(url, { headers: setHeaders() });
      const response = await res.json();
      if (res.status == 200 && response.code == 200) {
        setStatisticsUser(response.data[0]);
      }
      setLoadingStatisticsUser(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setLoadingStatisticsUser(false);
    }
  };
  */
  /* ESTE CODIGO VERIFICABA EL TEMA DE LA ACTUALIZACION
    useEffect(() => {
    if (user.hotmer) {
      let interval = null;
      if (activeCounter) {
        interval = setInterval(() => {
          setCounter((counter) => counter + 1);
          getStatisticsUser();
          // console.log(
          //   "ACTUALIZA CADA 10 SEGUNDOS, SOLO PARA QUE SE VEA EL INTERVAL. DESPUES SE CAMBIA EL TIEMPO"
          // );
        }, 60000 * 3);
      } else if (!activeCounter && counter !== 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [activeCounter, counter]); // eslint-disable-line

  useEffect(() => {
    if (user.hotmer) {
      setActiveCounter(true);
      getStatisticsUser();
    }
    
    return () => {
      setStatisticsUser(null)
    }

  }, []); // eslint-disable-line
*/
  return (
    <div
      id="drawer--right--profile--main--container"
      onClick={closeDrawer}
      className={`drawer--right--profile--main--container ${visibleProfileDrawer ? "visible--main--container" : ""
        }`}
    >
      <div
        id="drawer--right--profile--container"
        className={`drawer--right--profile--container ${visibleProfileDrawer ? "visible--container" : ""
          }`}
      >
        <div className="drawer--right--profile--container--real">
          <img
            alt="..."
            src={user?.hotmer ? hotmerRoseImg2 : userGrayImg2}
            className="drawer--right--profile--container--image"
          />
        </div>
        <div className="drawer--right--profile--container--options--user">
          <div className="drawer--right--profile--container--options--user--image">
            <div className="drawer--right--profile--container--options--user--image--box">
              <img alt="..." src={drawerBox} />
            </div>
            <div className="drawer--right--profile--container--options--user--image--photo">
              <div
                className={`drawer--right--profile--container--options--user--image--photo--circular--color ${user.hotmer ? "border--mix--color" : ""
                  }`}
                onClick={() => clickSecondOption(user?.hotmer)}
              >
                <img
                  alt="..."
                  src={myStateProfile.src}
                  onError={onErrorProfile}
                />
              </div>
            </div>
            <div className="drawer--right--profile--container--options--user--image--data">
              <div className="drawer--right--profile--container--options--user--image--name">
                {user && user.full_name ? user.full_name : "No available name"}
              </div>
              <div
                className={`drawer--right--profile--container--options--user--image--type ${user.hotmer ? "hotmer--type" : ""
                  }`}
              >
                Hotmer
              </div>
            </div>
          </div>
          <hr className="l-sidebar__menu__divider"></hr>
          <div className="drawer--right--profile--container--options--user--icons">
            <OptionIconProfile
              icon={!user.hotmer ? editIcon : profileIcon}
              onClick={() => clickFirstOption(user.hotmer)}
              //description={!user.hotmer ? "Editar" : "Mi perfil"}
              description={!user.hotmer ? (t("draweProfile.icons.edit")) : (t("draweProfile.icons.myProfile"))}
            />
            <OptionIconProfile
              icon={!user.hotmer ? suscriptionsIcon : editIcon}
              onClick={() => clickSecondOption(user.hotmer)}
              //description={!user.hotmer ? "Suscripciones" : "Editar"}
              description={!user.hotmer ? (t("draweProfile.icons.subscription")) : (t("draweProfile.icons.edit"))}
            />
            <OptionIconProfile
              icon={!user.hotmer ? heartIcon : ExtactionIcon}
              onClick={() => clickThirdOption(user.hotmer)}
              description={!user.hotmer ? (t("draweProfile.icons.wishList")) : (t("draweProfile.icons.extractions"))}
            />
            {user.hotmer && (
              <OptionIconProfile
                icon={DealIcon}
                onClick={() => {
                  dispatch(setVisibleProfileDrawer());
                  history.push(`/deals`);
                }}
                description={t("draweProfile.icons.deals")}
              />
            )}

            {/*BLOQUE DE CODIGO ESPECIFICO PARA HOTMERS -- NO COMPARTE LA LOGICA ANTERIOR*/}
            {/*{user.hotmer ? (*/}
            <div
              className="options--user--icon"
              onClick={clickWhatsAppSupport}
            >
              <img
                src={WhatsAppIcon2}
                alt="..."
                className="options--user--icon--image"
              />
              <DividerSpace space="M" />
              <div className="options--user--icon--description">
                {t("draweProfile.icons.advice")}
                {/* Asesoramiento */}
              </div>
            </div>
            {/*} ) : (
              <div></div>
           )}*/}
            <OptionIconProfile
              icon={!user.hotmer ? settingsIcon : settingsIcon}
              onClick={() => clickConfigProfile(user.hotmer)}
              description={!user.hotmer ? (t("draweProfile.icons.settings")) : (t("draweProfile.icons.settings"))}
            />
            <hr className="l-sidebar__menu__divider"></hr>
            {/*<div>
              <LanguageImage
                src={spanish}
                onClick={() => {
                  //console.log("DDDDD");
                  i18n.changeLanguage("es");
                  localStorage.setItem("language", "es");
                }}
              />
              <LanguageImage
                src={english}
                onClick={() => {
                  //console.log("DDDDD");
                  i18n.changeLanguage("en");
                  localStorage.setItem("language", "en");
                }}
              />
            </div>
            */}
            <div className="drawer--right--profile--container--options--user--social--media--text">
              {t("draweProfile.icons.FollowSocialMedia")}
            </div>

            <div>
              <div className="social-list-wrap">
                <ul className="social-list list-inline list-unstyled">
                  <li className="list-inline-item">
                    <a href="https://t.me/Hotmy_app" target="_blank" title="telegram">
                      <img
                        src={TelegramIcon}
                        alt="Telegram"
                        className="options--user--icon--image---social"
                      />
                    </a>
                    <a href="https://www.hotmy.net/faq" target="_blank" title="Help">
                      <img
                        src={FastHelpIcon}
                        alt="Help"
                        className="options--user--icon--image---social"
                      />
                    </a>
                    <a href="https://www.instagram.com/hotmy_official/" target="_blank" title="Instagram">
                      <img
                        src={InstagramIcon}
                        alt="instagram"
                        className="options--user--icon--image---social"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*
            <div>
               <div>
              Seguinos en nuestras Redes
              </div>
              <div>
              <img
                  src={InstagramIcon}
                  alt="..."
                  className="options--user--icon--image"
                />
              </div>
            </div>
            /}
          </div>
          {/*user.hotmer && (
            <div className="drawer--right--profile--container--options--user--extra">
              <MoneyDrawer
                loadingStatisticsUser={loadingStatisticsUser}
                statisticsUser={statisticsUser}
              />
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
};
/*
const MoneyDrawer = ({ loadingStatisticsUser, statisticsUser }) => {
  if (loadingStatisticsUser) {
    return <PartialSpinner />;
  } else if (!loadingStatisticsUser && !statisticsUser) {
    return <></>;
  } else {
    return (
      <div className="money--drawer--main--container">
        <MoneyAmountUser
          title="Dinero Disponible"
          quantity={`$ ${statisticsUser.money_avalible || 0.0}`}
          colored={true}
        />
        <MoneyAmountUser
          title="Dinero a Liquidar"
          quantity={`$ ${statisticsUser.money_settle || 0.0}`}
          colored={false}
        />
      </div>
    );
  }
};
*/
const OptionIconProfile = ({ icon, description, onClick }) => {
  return (
    <div className="options--user--icon" onClick={onClick}>
      <img src={icon} alt="..." className="options--user--icon--image" />
      <DividerSpace space="M" />
      <div className="options--user--icon--description">{description}</div>
    </div>
  );
};
