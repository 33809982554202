import axios from "axios";
import { setHeaders, setFormDataHeaders } from "../../libs/fetch";
import { setInitialToys } from "../../libs/search";
import { TYPES } from "./uploadActionTypes";
import { displaySuccess } from "../../components/toast/toast";

export const getPackTags = () => {
  const url = `${process.env.REACT_APP_INITIAL_V2}/packs/tags`;
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_TOYS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.code === 200) {
        const packToysList = setInitialToys(response.data);
        dispatch({
          type: TYPES.SET_SUCCESS_TOYS,
          payload: packToysList,
        });
      } else {
        dispatch({ type: TYPES.SET_FAILURE_TOYS });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_TOYS });
    }
  };
};

export const submitHotmerContentFiles = (finalUrl, formDataServer2 , messageInProgress, messageFinish ) => {
  return async (dispatch) => {
    dispatch({ type: TYPES.LOADING_UPLOAD_CONTENT });
    try {
      displaySuccess(messageInProgress)
      const res = await axios.post(finalUrl, formDataServer2, {
        headers: setFormDataHeaders(),
      });
      if (res.status === 200) {
        dispatch({
          type: TYPES.SUCCESS_UPLOAD_CONTENT,
        });
        displaySuccess(messageFinish)
      } else {
        dispatch({ type: TYPES.FAILURE_UPLOAD_CONTENT });
      }
    } catch (error) {
      dispatch({ type: TYPES.FAILURE_UPLOAD_CONTENT });
    }
  };
};

export const setNullResponseSubmit = () => {
  return (dispatch) => {
    dispatch({ type: TYPES.SET_NULL_RESPONSE_SUBMIT });
  };
};
