import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { blueGrey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./conditions.css";

const GreenCheckbox = withStyles({
  root: {
    color: blueGrey[400],
    "&$checked": {
      color: blueGrey[600],
    },
  },
})((props) => <Checkbox color="default" {...props} />);

export const Conditions = ({
  checked,
  onChangeCheck,
  changeCheckBox,
  onClick,
}) => {
  return (
    <div className="terms--and--conditions">
      <GreenCheckbox checked={checked} onChange={onChangeCheck} onClick={changeCheckBox} />
      <div className="terms--and--conditions--text">
        <div onClick={changeCheckBox}>Acepto</div>
        <DividerSpace space="XS" />
        <div className="terms--and--conditions--text--red" onClick={onClick}>
          Términos & Condiciones
        </div>
      </div>
    </div>
  );
};
