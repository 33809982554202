
import { LanguageImage } from "../LanguageImage/LanguageImage";
//import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import english from "../../icons/language/usa.png";
import spanish from "../../icons/language/espana.png";
import italia from "../../icons/language/italia.png";
// import {
//   Accordion
// } from "../accordionSets/accordionSets";
// import { LanguageImageDefault } from "../LanguageImage/LanguageImageDefault";

export const AccordionIdioms = ({
  panel,
  expanded,
  handleChange,
  // loading,
  //	personalData,
  //*	handleChangePersonalData,
}) => {
  const { t, i18n } = useTranslation("global");
  // const languageDefault = localStorage.getItem("language");
  //console.log("language",languageDefault);
  return (
    <>
      Elegi tu idioma:
      <LanguageImage
        src={spanish}
        //languageDefault={languageDefault}
        onClick={() => {
          //console.log("DDDDD");
          i18n.changeLanguage("es");
          localStorage.setItem("language", "es");
        }}
      />
      <LanguageImage
        src={english}
        //languageDefault={languageDefault}
        onClick={() => {
          //console.log("DDDDD");
          i18n.changeLanguage("en");
          localStorage.setItem("language", "en");
        }}
      />
      <LanguageImage
        src={italia}
        //languageDefault={languageDefault}
        onClick={() => {
          //console.log("DDDDD");
          i18n.changeLanguage("it");
          localStorage.setItem("language", "it");
        }}
      />
    </>
  )
};

