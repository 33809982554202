/* eslint-disable */
import React, { useEffect, useState } from "react";
import editIcon from "../../icons/darkIcons/edit-icon.png";
import { DividerSpace } from "../dividerSpace/dividerSpace";
import "./customInputPriceUpload.css";

export const CustomInputPriceUpload = ({
	placeholder,
	title,
	publicTitle,
	name,
	disabled,
	value,
	taxes,
	// discount,
	onChange,
	type,
	icon,
	colored,
	maxLength,
}) => {
	const [calculatedValue, setCalculatedValue] = useState(null);
	/* ******************************************************* */
	const [calculatedDolarFinal, setCalculatedDolarFinal] = useState("...");
	const [calculatedArsFinal, setCalculatedArsFinal] = useState(null);

	const calculateRecargo = (value, param) =>
		(parseFloat(value * (param / 100)) + parseFloat(value)).toFixed(2);

	useEffect(() => {
		if (taxes.vl_tax == 0) {
			setCalculatedValue(value * taxes.vl_conversion_rate);
			setCalculatedDolarFinal(value);
			setCalculatedArsFinal(value * taxes.vl_conversion_rate);
		} else {
			setCalculatedValue(value * taxes.vl_conversion_rate);
			const recargo = calculateRecargo(value, taxes.vl_tax);
			setCalculatedDolarFinal(recargo);
			setCalculatedArsFinal(recargo * taxes.vl_conversion_rate);
		}
	}, [value]); // eslint-disable-line
	return (
		<div
			className={`custom--input--price--upload
    ${
			taxes.ds_currency_hotmer === "USD"
				? "custom--input--price--upload--center"
				: ""
		}`}
		>
			{/* ********************************************* */}
			<div className="custom--input--price--upload--title">{`${title}`}</div>
			{/* ********************************************* */}
			<div className="custom--input--price--upload--texts">
				<div
					className={`custom--input--price--upload--texts--edit ${
						colored ? "colored--input--price" : ""
					}`}
				>
					<div className="custom--input--price--upload--texts--edit--currency">
						USD
					</div>
					<div className="custom--input--price--upload--texts--edit--input">
						<input
							placeholder={placeholder}
							name={name}
							type={type}
							disabled={disabled}
							value={value}
							maxLength={maxLength}
							onChange={(e) => {
								const val = e.target.value;
								if (val === "") {
									e.target.value = 0;
									onChange(e);
								} else {
									const regExp = /^\d+(\.?)?(\d{1,2})?$/;
									let regValidation = regExp.test(val);
									if (regValidation === true) {
										const badValue = val.includes("e");
										const badOrder = val[0] == "0";
										if (badValue || badOrder) {
											var pointNum = parseFloat(val);
											e.target.value = pointNum;
										}
										onChange(e);
									}
								}
							}}
						/>
					</div>
					{icon && (
						<div className="custom--input--price--upload--texts--edit--icon">
							<img src={editIcon} alt="..." />
						</div>
					)}
				</div>
				{taxes.ds_currency_hotmer !== "USD" && (
					<div className="custom--input--price--upload--text--calculate">
						<div className="custom--input--price--upload--text--calculate--currency">
							{taxes.ds_currency_hotmer}
						</div>
						<div className="custom--input--price--upload--text--calculate--value">
							{calculatedValue
								? `${taxes.ds_symbol_hotmer} ${calculatedValue.toFixed(2)}`
								: ""}
						</div>
					</div>
				)}
			</div>
			{/* ********************************************* */}
			<div className="custom--input--price--upload--costs">
				{`+ Gastos financieros ${taxes.vl_tax} %`}
			</div>
			{/* ********************************************* */}
			<DividerSpace space="M" />
			{/* ********************************************* */}
			<div className="custom--input--price--upload--public--title">
				{publicTitle}
			</div>
			{/* ********************************************* */}
			<div className="custom--input--price--upload--texts2">
				<div
					className={`custom--input--price--upload--texts2--edit ${
						colored ? "colored--input--price" : ""
					}`}
				>
					<div className="custom--input--price--upload--texts2--edit--currency">
						USD
					</div>
					<div className="custom--input--price--upload--texts2--edit--input">
						<input
							// placeholder={"..."}
							name={name}
							type={"number"}
							disabled={true}
							value={calculatedDolarFinal ? calculatedDolarFinal : ""}
							maxLength={5}
							onChange={(e) => {
								const regExp = /^[1-4]\d{0,5}(.\d{0,2})?$/;
								if (e.target.value === "" || regExp.test(e.target.value)) {
									onChange(e);
								}
							}}
						/>
					</div>
				</div>
				{taxes.ds_currency_hotmer !== "USD" && (
					<div className="custom--input--price--upload--text--calculate">
						<div className="custom--input--price--upload--text--calculate--currency">
							{taxes.ds_currency_hotmer}
						</div>
						<div className="custom--input--price--upload--text--calculate--value">
							{calculatedArsFinal ? `$ ${calculatedArsFinal.toFixed(2)}` : ""}
						</div>
					</div>
				)}
			</div>
			{/* ********************************************* */}
		</div>
	);
};
