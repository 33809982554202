import dayjs from "dayjs";
import { useState } from "react";
import warningYellow from "../../icons/a_news/performance/warning--yellow.svg";
import ShareIcon from "@material-ui/icons/Share";
import CircularProgressWithLabel from "./CircularProgressWithLabel"
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import usePerformanceCreator from "../../hooks/usePerformanceCreator";
import { PerformanceCreator } from '../../interfaces/PerformanceCreator';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from "react-router-dom";
import { ShareInfoOfProfile } from "../../components/Share/ShareInfoOfProfile";
import "./HotmerPerformance.css";
import useShare from "../../hooks/useShare";

export const HotmerPerformance = () => {

    const isCreator = JSON.parse(localStorage?.getItem("session"))?.user?.hotmer;
    const idCreator = JSON.parse(localStorage.getItem("session"))?.user.id;
    const { data: hotmerPerformance, isFetching: isLoadingHotmerPerformance, } = usePerformanceCreator(isCreator, idCreator)

    let completedProfile = 0

    const [open, setOpen] = useState<boolean>(false);

    const { data: hotmerShare, isFetching: isLoadingHotmerShare, } = useShare(idCreator, open)

    const history = useHistory();

    const handledClickEdit = () => {
        history.push(`/editProfileHotmer/${idCreator}`);
    }

    const handledClickUploadContent = () => {
        history.push(`/uploadContent`);
    }

    const profilecompletedProfile = (hotmerPerformance: PerformanceCreator) => {

        // verified complete portrait in the profile. 
        if (hotmerPerformance?.data?.bl_have_cover_art) {
            completedProfile += 25
        }

        // verified complete quantity of photos. 
        if (hotmerPerformance?.data?.total_image >= parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PHOTO)) {
            completedProfile += 25
        } else {
            completedProfile += ((hotmerPerformance?.data?.total_image) / parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PHOTO) * 100)
        }

        // verified complete quantity of videos. 
        if (hotmerPerformance?.data?.total_video >= parseInt(process.env.REACT_APP_PERFORMANCE_QTY_VIDEO)) {
            completedProfile += 25
        } else {
            completedProfile += ((hotmerPerformance?.data?.total_video) / parseInt(process.env.REACT_APP_PERFORMANCE_QTY_VIDEO) * 100)
        }

        // verified complete quantity of packs. 
        if (hotmerPerformance?.data?.total_pack >= parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PACKS)) {
            completedProfile += 25
        } else {
            completedProfile += ((hotmerPerformance?.data?.total_pack) / parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PACKS) * 100)
        }

        return completedProfile
    }

    const dateLastUpload = (date1: Date, date2: Date) => {
        if (date1 > date2) {
            return date1
        } else {
            return date2
        }
    }

    if (isLoadingHotmerPerformance) {
        return (
            <div style={{ height: "30px", margin: '1rem' }}>
                <MiniSpinner />
            </div>
        )
    } else {
        return (

            <>
                {/* // Presentacion */}
                {hotmerPerformance?.data &&
                    <>
                        <div className="performance-grid-main-conteiner">
                            <Card style={{ margin: '0.5rem 0px' }}>
                                <CardHeader
                                    title={
                                        <>
                                            <span style={{ fontSize: '1.2rem' }}>
                                                Hola
                                            </span>
                                            <span style={{ fontSize: '1.2rem', color: '#DD0065', textTransform: 'capitalize' }}>
                                                {` ${hotmerPerformance.data.display_name}`}
                                            </span>
                                        </>
                                    }
                                    avatar={
                                        <Avatar
                                            src={hotmerPerformance?.data.profile_photo_thumbnail}
                                            alt="Profile"
                                        >
                                        </Avatar>
                                    }
                                    style={{ padding: '0.5rem' }}
                                />
                                <Divider />
                                <CardContent>
                                    <Typography variant="body2">
                                        Tenemos algunas sugerencias para vos que mejorarían tus ventas y la performance dentro de la aplicación
                                    </Typography>
                                </CardContent>
                            </Card>

                            {profilecompletedProfile(hotmerPerformance) && completedProfile < 100 &&
                                <Card style={{ margin: '0.5rem 0px' }}>
                                    <CardHeader
                                        style={{ padding: '0.5rem', alignContent: 'center', justifyContent: 'center', background: 'lightyellow' }}
                                        title={
                                            <span style={{ fontSize: '1.2rem' }}>
                                                Perfil incompleto
                                            </span>}
                                        avatar={
                                            <img className='performance-grid-icons' src={warningYellow} alt="Warning"/>
                                        }
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Typography gutterBottom variant="subtitle2" component="div" style={{ color: 'gray' }}>
                                            ¡No pierdas posicionamiento, completa tu perfil y subí de nivel!
                                        </Typography>
                                        <div className="performance-grid-subscriptions">
                                            {/* <div className="performance-grid-subscriptions-info"> */}
                                            <div className="div1">
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold', color: 'gray' }}>
                                                    Pendientes de subir:
                                                </Typography>
                                            </div>
                                            <div className="div2">
                                                <List>

                                                    {!hotmerPerformance?.data?.bl_have_cover_art &&
                                                        <ListItem style={{ display: 'list-item' }}>Foto de portada.</ListItem>}
                                                    {hotmerPerformance?.data?.total_image < parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PHOTO) &&
                                                        <ListItem>

                                                            <span style={{ display: 'list-item', fontWeight: 'bold', marginLeft: '0.2rem', marginRight: '0.2rem', color: '#DD0065' }}>
                                                                {parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PHOTO) - (hotmerPerformance?.data?.total_image)}
                                                            </span>fotos.
                                                        </ListItem>
                                                    }
                                                    {hotmerPerformance?.data?.total_video < parseInt(process.env.REACT_APP_PERFORMANCE_QTY_VIDEO) &&
                                                        <ListItem>

                                                            <span style={{ display: 'list-item', fontWeight: 'bold', marginLeft: '0.2rem', marginRight: '0.2rem', color: '#DD0065' }}>
                                                                {parseInt(process.env.REACT_APP_PERFORMANCE_QTY_VIDEO) - (hotmerPerformance?.data?.total_video)}
                                                            </span> videos.</ListItem>
                                                    }
                                                    {hotmerPerformance?.data?.total_pack < parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PACKS) &&
                                                        <ListItem>

                                                            <span style={{ display: 'list-item', fontWeight: 'bold', marginLeft: '0.2rem', marginRight: '0.2rem', color: '#DD0065' }}>
                                                                {parseInt(process.env.REACT_APP_PERFORMANCE_QTY_PACKS) - (hotmerPerformance?.data?.total_pack)}
                                                            </span>
                                                            packs.</ListItem>
                                                    }
                                                </List>
                                            </div>
                                            {/* </div> */}

                                            {/* <div className="performance-grid-subscriptions-circular"> */}
                                            <div className="div3">
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold', color: 'gray' }} >
                                                    Completado:
                                                </Typography>
                                            </div>

                                            <div className="div4" style={{ borderLeft: 'solid 1px lightgray' }}>
                                                <CircularProgressWithLabel value={completedProfile} />
                                            </div>

                                            {/* </div> */}
                                        </div>
                                    </CardContent>
                                    <Divider />
                                    <CardActions style={{ justifyContent: 'flex-end' }}>
                                        {!hotmerPerformance?.data?.bl_have_cover_art &&
                                            <Button size="small" style={{ color: 'gray' }} onClick={handledClickEdit}>Editar perfil </Button>
                                        }
                                        <Button size="small" style={{ color: '#DD0065' }} onClick={handledClickUploadContent}> Subir contenido </Button>
                                    </CardActions>
                                </Card>
                            }
                            {/* Si tiene Subscripciones Activas y hace rato no carga (o sea la fecha de ultima carga es mayor a 10 dias, mostrar) */}
                            {(hotmerPerformance?.data?.subscriptions?.total_subscriptions_active)
                                && (
                                    (dayjs()
                                        .diff((dateLastUpload(hotmerPerformance?.data?.content?.dt_last_image_uploaded,
                                            hotmerPerformance?.data?.content?.dt_last_video_uploaded)), "day"))
                                    > parseInt(process.env.REACT_APP_PERFORMANCE_DAY_LIMIT_SUBSCRIPTION))
                                ?
                                <Card style={{ margin: '0.5rem 0px' }}>
                                    <CardHeader
                                        style={{ padding: '0.5rem', alignContent: 'center', justifyContent: 'center', background: 'lightyellow' }}
                                        title={
                                            <span style={{ fontSize: '1.2rem' }}>
                                                Subscripciones
                                            </span>}
                                        avatar={
                                            <img className='performance-grid-icons' src={warningYellow} alt="Warning" />
                                        }
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Typography variant="body2">
                                            Tenés
                                            <span style={{ fontWeight: 'bold', color: '#DD0065' }}>{' '}
                                                {hotmerPerformance?.data?.subscriptions?.total_subscriptions_active}
                                            </span> subscripciones activas y tu ultima fecha de carga fue {' '}
                                            <span style={{ fontWeight: 'bold' }}>{dayjs(dateLastUpload(hotmerPerformance?.data?.content?.dt_last_image_uploaded,
                                                hotmerPerformance?.data?.content?.dt_last_video_uploaded)).format('DD/MM/YYYY')}
                                            </span>,
                                            hace {' '}
                                            <span style={{ fontWeight: 'bold', color: '#DD0065' }}>{
                                                dayjs()
                                                    .diff((dateLastUpload(hotmerPerformance?.data?.content?.dt_last_image_uploaded,
                                                        hotmerPerformance?.data?.content?.dt_last_video_uploaded)), "day")}
                                                {' '}
                                            </span>
                                            días que no actualizas tu contenido. ¡Subí nuevo contenido! Y evitá reclamos.
                                        </Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardActions style={{ justifyContent: 'flex-end' }}>
                                        <Button size="small" style={{ color: '#DD0065' }} onClick={handledClickUploadContent}> Subir contenido </Button>
                                    </CardActions>
                                </Card>
                                : null
                            }
                            {((hotmerPerformance?.data?.covers) &&
                                (dayjs().diff(hotmerPerformance?.data?.covers?.dt_last_cover_art_uploaded, "day"))
                                > parseInt(process.env.REACT_APP_PERFORMANCE_DAY_LIMIT_PORTRAIT)
                            ) ?
                                <Card style={{ margin: '0.5rem 0px' }}>
                                    <CardHeader
                                        style={{ padding: '0.5rem', alignContent: 'center', justifyContent: 'center', background: 'lightyellow' }}
                                        title={
                                            <span style={{ fontSize: '1.2rem' }}>
                                                Actualiza tu portada
                                            </span>}
                                        avatar={
                                            <img className='performance-grid-icons' src={warningYellow}  alt="Warning"/>
                                        }
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Typography variant="body2">
                                            La ultima actualizacion de tu portada fue hace
                                            <span style={{ fontWeight: 'bold', marginLeft: '0.2rem', marginRight: '0.2rem', color: '#DD0065' }}>
                                                {(dayjs().diff(hotmerPerformance?.data?.covers?.dt_last_cover_art_uploaded, "day"))}
                                            </span>dias.
                                        </Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardActions style={{ justifyContent: 'flex-end' }}>
                                        <Button size="small" color="secondary" onClick={handledClickEdit}>Editar perfil</Button>
                                    </CardActions>
                                </Card>
                                : null
                            }
                            <Card style={{ margin: '0.5rem 0px' }}>
                                <CardHeader
                                    style={{ padding: '0.5rem', alignContent: 'center', justifyContent: 'center' }}
                                    title={
                                        <span style={{ fontSize: '1.2rem' }}>
                                            Compartir perfil
                                        </span>}
                                    avatar={
                                        <ShareIcon
                                            className="performance-grid-icons"
                                            fontSize="small"
                                        />
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    <Typography variant="body2">
                                        Aumenta tus compras compartiendo tu perfil en tus redes sociales. ¿Sabías que si dejas tu link en la Bio de Instagram o Telegram podés generar
                                        <span style={{ fontWeight: 'bold', marginLeft: '0.2rem', marginRight: '0.2rem' }}>40%</span>
                                        más que tu facturación actual?
                                    </Typography>
                                </CardContent>
                                <Divider />
                                <CardActions style={{ justifyContent: 'flex-end' }}>
                                    <Button size="small" style={{ color: '#007CDE' }}
                                        onClick={() => setOpen((open) => !open)}
                                    >
                                        Compartir
                                    </Button>
                                </CardActions>
                            </Card>
                            <Card style={{ margin: '0.5rem 0px' }}>
                                <CardHeader
                                    style={{ padding: '0.5rem', alignContent: 'center', justifyContent: 'center' }}
                                    title={
                                        <span style={{ fontSize: '1.2rem' }}>
                                            Comparti y gana 10% mas!
                                        </span>}
                                    avatar={
                                        <ShareIcon
                                            className="performance-grid-icons"
                                            fontSize="small"
                                        />
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    <Typography variant="body2">
                                    Cuando tus amigos ganan, ¡vos también! Si una creadora se anota con tu link, podés ganar
                                    hasta 10% más sin afectar su facturación
                                    </Typography>
                                </CardContent>
                                <Divider />
                                <CardActions style={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        size="small" style={{ color: '#007CDE' }}
                                        onClick={() => setOpen((open) => !open)}
                                    >
                                        Compartir
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                        {/* // COMPONENTE PARA COMPARTIR.  */}
                        {open && !isLoadingHotmerShare ?

                            <ShareInfoOfProfile
                                profileInfoToShare={
                                    hotmerShare?.data
                                }
                                helper={true}
                                showHelperContent={false}
                                openDrawerShareProfile={open}
                                cerrar={() => setOpen(false)}
                            />
                            : null
                        }
                        {open && isLoadingHotmerShare ?
                            <div style={{ height: "30px", margin: '1rem' }}>
                                <MiniSpinner />
                            </div>
                            : null
                        }
                    </>
                }
            </>
        )

    }

}