import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#a64bf4",
    color: "#fff",
    borderRadius: "5px",
  },
}));

export const ButtonFilters = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      // color="secondary"
      onClick={onClick}
      className={classes.button}
    >
      {children}
    </Button>
  );
};
