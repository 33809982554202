import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomModal = ({
	openModal,
	setOpenModal,
	discardExternalEvents,
	fullScreen,
	children,
}) => {
	const handleClose = () => setOpenModal(false);

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={openModal}
				TransitionComponent={Transition}
				keepMounted
				disableBackdropClick={discardExternalEvents}
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				{children}
			</Dialog>
		</div>
	);
};
