import React from "react";
import "./uploadPackToy.css";

export const UploadPackToy = ({ packToy, selectToy }) => {
  return (
    <div
      onClick={() => selectToy(packToy.id_tag)}
      className={`upload--content--main--container--body--content--inputs--toys--item ${
        packToy.selected ? "selected--pack--toy" : ""
      }`}
    >
      {packToy.ds_name}
    </div>
  );
};
