/* eslint-disable */
import React, { useEffect, useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import profileDefaultCover from "../../icons/profile/profile-default-cover-hotmer.png";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { CustomTabs } from "../../components/customTabs/customTabs";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { VideoContent } from "../../components/videoContent/videoContent";
//import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import SkeletonCard from "../../components/skeletonCard/SkeletonCard-Hotmer-Profile";
import { useTranslation } from "react-i18next";
import { MultimediaHotmer } from "../../components/multimediaHotmer/multimediaHotmer";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { CustomModalVideo } from "../../commonComponents/customModalVideo/customModalVideo";
import { HotmerProfileData } from "../../components/hotmerProfileData/hotmerProfileData";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import "./hotmerProfile.css";

export const HotmerProfile = ({ match }) => {
  const history = useHistory();

  const { t } = useTranslation("global");
  const [tab, setTab] = useState(0);
  const [modalMessage, setModalMessage] = useState("");
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [title, setTitle] = useState("Desea eliminar el pack ?");

  const [loadingHotmerProfile, setLoadingHotmerProfile] = useState(false);
  const [hotmerProfile, setHotmerProfile] = useState(null);

  const [loadingImages, setLoadingImages] = useState(false);
  const [images, setImages] = useState(null);

  const [loadingVideos, setLoadingVideos] = useState(false);
  const [videos, setVideos] = useState(null);

  const [loadingPacks, setLoadingPacks] = useState(false);
  const [packs, setPacks] = useState(null);

  const [profile, setProfile] = useState(null);
  const [cover, setCover] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const onErrorProfile = () => {
    if (!profile.errored) setProfile({ src: opsIcon, errored: true });
  };

  const onErrorCover = () => {
    if (!cover.errored) setCover({ src: opsIcon, errored: true });
  };

  const handleChange = (event, newTab) => setTab(newTab);

  const getHotmerProfile = async (idHotmer) => {
    setLoadingHotmerProfile(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/profile/full`;
      const res = await fetch(url, { headers: setHeaders() });
      const response = await res.json();
      // console.log("RES PROFILE: ", res);
      // console.log("RESPONSE PROFILE: ", response.data);
      if (response.code === 200) {
        setHotmerProfile(response.data);
        setProfile({
          src: response.data.img_profile || profileDefault,
          errored: false,
        });
        setCover({
          src: response.data.img_cover_art || profileDefaultCover,
          errored: false,
        });
      }
      setLoadingHotmerProfile(false);
    } catch (error) {
      console.log("MURIO...");
      setLoadingHotmerProfile(false);
    }
  };

  const getHotmerImages = async (idHotmer) => {
    try {
      setLoadingImages(true);
      const url = `${
        process.env.REACT_APP_INITIAL_V2
      }/hotmers/${idHotmer}/content/image?limit=${10}&offset=${0}`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES IMAGES: ", res);
      // console.log("RESPONSE IMAGES: ", response.data);
      if (res.status === 200) {
        setImages(response.data);
      } else if (res.status === 204) {
        setImages([]);
      } else {
        setImages(null);
      }
      setLoadingImages(false);
    } catch (error) {
      console.log(error);
      setImages(null);
      setLoadingImages(false);
    }
  };

  const getHotmerVideos = async (idHotmer) => {
    try {
      setLoadingVideos(true);
      const url = `${
        process.env.REACT_APP_INITIAL_V2
      }/hotmers/${idHotmer}/content/video?limit=${10}&offset=${0}`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      console.log("RES VIDEO: ", res);
      if (res.status === 200) {
        const response = await res.json();
        setVideos(response.data);
        // console.log("RESPONSE VIDEO: ", response);
      } else if (res.status === 204) {
        setVideos([]);
      } else {
        setVideos(null);
      }
      setLoadingVideos(false);
    } catch (error) {
      // console.log("ERROR VIDEO: ", error);
      setVideos(null);
      setLoadingVideos(false);
    }
  };

  const getHotmerPacks = async (idHotmer) => {
    try {
      setLoadingPacks(true);
      const url = `${
        process.env.REACT_APP_INITIAL_V2
      }/hotmers/${idHotmer}/content/packs?limit=${10}&offset=${0}`;

      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES PACK: ", res);
      // console.log("RESPONSE PACK: ", response.data);
      if (res.status === 200) {
        setPacks(response.data.filter((x) => !x.bl_buyer));
      } else if (res.status === 204) {
        setPacks([]);
      } else {
        setPacks(null);
      }
      setLoadingPacks(false);
    } catch (error) {
      console.log(error);
      setPacks(null);
      setLoadingPacks(false);
    }
  };

  const clickAddHotmerToWishListUser = async () => {
    const id = hotmerProfile.id_user_hotmer;
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      if (res.status === 200) {
        setHotmerProfile({
          ...hotmerProfile,
          bl_follow_hotmer: !hotmerProfile.bl_follow_hotmer,
        });
      } else {
        // console.log("FAIL CLICK FOLLOW HOTMER");
      }
    } catch (error) {
      console.log("FAIL CLICK FOLLOW HOTMER");
      console.log(error);
    }
  };

  const removePackFromAdds = (id_hotmer, id_pack) => {
    const list = packs.map((x) => {
      if (x.id_pack == id_pack) {
        return {
          ...x,
          bl_follow: false,
        };
      } else {
        return x;
      }
    });
    setPacks(list);
  };

  const removePack = async (id_hotmer, id_pack) => {
    setDisabledButtons(true);
    setTitle("Procesando...");
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id_hotmer}/pack/${id_pack}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      const response = await res.json();
      if (response.code === 200) {
        setTitle("Eliminación exitosa.");
        removePackFromAdds(id_hotmer, id_pack);
        setTimeout(() => {
          setOpenModalRemove(false);
          setTitle("Desea eliminar el pack ?");
        }, 400);
      } else {
        setTitle("Error: Intente mas tarde por favor.");
        setTimeout(() => {
          setOpenModalRemove(false);
          setTitle("Desea eliminar el pack ?");
        }, 400);
      }
    } catch (error) {
      setTitle("Error: Intente mas tarde por favor.");
      console.log("FALLO AGREGAR PACK A DESEADOS");
      setTimeout(() => {
        setOpenModalRemove(false);
        setTitle("Desea eliminar el pack ?");
      }, 400);
    }
    setSelectedPack(null);
  };

  const applyOption = () =>
    removePack(selectedPack.id_hotmer, selectedPack.id_pack);

  const cancelOption = () => {
    setOpenModalRemove(false);
    setSelectedPack(null);
  };

  const onClickRefreshImages = () => getHotmerImages(match.params.id_hotmer);
  const onClickRefreshVideos = () => getHotmerVideos(match.params.id_hotmer);
  const onClickRefreshPacks = () => getHotmerPacks(match.params.id_hotmer);

  const reproduceVideoContent = (content) => {
    setTimeout(() => setOpenVideoModal((x) => !x), 50);
    setSelectedVideo(content);
  };

  const handleCloseView = () => {
    reproduceVideoContent(null);
  };

  useEffect(() => {
    // VALIDAR NUEVAMENTE IF ENTRAR ACA O MY PROFILE
    const { id_hotmer } = match.params;
    const idUserSession = getIdUser();
    if (id_hotmer == idUserSession) {
      // const drawer111 = document.getElementById("drawer--right--profile--main--container");
      // drawer111.remove();
      history.push(`/myProfile`);
    } else {
      setLoadingHotmerProfile(true);
      getHotmerProfile(match.params.id_hotmer);
      getHotmerImages(match.params.id_hotmer);
      getHotmerVideos(match.params.id_hotmer);
      getHotmerPacks(match.params.id_hotmer);
    }
    return () => {
      setHotmerProfile(null);
      setImages(null);
      setVideos(null);
      setPacks(null);
    };
  }, []); // eslint-disable-line

  // const getOptionHotmer = (value) =>
  //   value ? t("button.present.title") : t("button.subscribe.title");

  const clickOptionHotmer = (booleanValue, id) => {
    const url = booleanValue
      ? `/hotmerPresent/${id}/${hotmerProfile.id_content_group_cover_art}`
      : `/hotmerSuscribe/${id}`;
    history.push(`${url}`);
  };

  const clickPresent = (id) => {
    history.push(`/hotmerPresent/${id}/${hotmerProfile.id_content_group_cover_art}`);
  };

  if (loadingHotmerProfile) {
    return (
      <div className="home--main--container">
        <SkeletonCard loading={loadingHotmerProfile} />;
      </div>
    );
  } else if (!loadingHotmerProfile && !hotmerProfile) {
    return <div>{"Actualmente no hay contenido para mostrar."}</div>;
  } else {
    return (
      <div className="hotmer--profile--main--container">
        <div className="hotmer--profile--main--container--data">
          <HotmerProfileData
            loadingHotmerProfile={loadingHotmerProfile}
            hotmerProfile={hotmerProfile}
            profile={profile}
            onErrorProfile={onErrorProfile}
            clickAdd={clickAddHotmerToWishListUser}
            id={match.params.id_hotmer}
            id_content_group={hotmerProfile.id_content_group}
            cover={cover}
            onErrorCover={onErrorCover}
            clickOptionHotmer={clickOptionHotmer}
            tab={tab}
            handleChange={handleChange}
            // getOptionHotmer={getOptionHotmer}
            clickPresent={clickPresent}
          />
        </div>
        <div className="hotmer--profile--main--container--tabs">
          <CustomTabs tab={tab} handleChange={handleChange} />
        </div>
        <div className="hotmer--profile--main--container--multimedia">
          <MultimediaHotmer
            tab={tab}
            handleChange={handleChange}
            loadingImages={loadingImages}
            images={images}
            loadingVideos={loadingVideos}
            videos={videos}
            loadingPacks={loadingPacks}
            packs={packs}
            reproduceVideoContent={reproduceVideoContent}
            owner={1}
            hotmerProfile={hotmerProfile}
            id_hotmer={match.params.id_hotmer}
            onClickRefreshImages={onClickRefreshImages}
            onClickRefreshVideos={onClickRefreshVideos}
            onClickRefreshPacks={onClickRefreshPacks}
          />
        </div>
        <CustomModal
          openModal={openModalRemove}
          setOpenModal={setOpenModalRemove}
        >
          <OptionToTrending
            title={title}
            acceptClick={applyOption}
            notAcceptclick={cancelOption}
            disabledButtons={disabledButtons}
          />
        </CustomModal>

        <CustomModalVideo
          openModalVideo={openVideoModal}
          setOpenModalVideo={setOpenVideoModal}
          handleCloseView={handleCloseView}
        >
          <VideoContent selectedVideo={selectedVideo} />
        </CustomModalVideo>

        <CustomModal openModal={openModalAdd} setOpenModal={setOpenModalAdd}>
          <MessageIncompleteData message={modalMessage} />
        </CustomModal>
      </div>
    );
  }
};
