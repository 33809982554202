/* eslint-disable */
import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
// Iconos 
import google from "../../icons/login-logo-google-color.svg";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import mainIcon from "../../icons/main-logo.png";
import passwordIconWhite from "../../icons/whiteIcons/login-password-white-2.png"
// import facebook from "../../icons/login-logo-facebook.svg";
//import emailIcon from "../../icons/whiteIcons/login-email-white.png";
//import passwordIcon from "../../icons/whiteIcons/login-password-white.png";
import { SwipperContent } from "../../components/swipperContent/swipperContent";
import eyeIcon from "../../icons/ojo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { setHeadersNoToken } from "../../libs/fetch";
import { useSelector, useDispatch } from "react-redux";
import {
  firebase,
  googleAuthProvider,
  facebookAuthProvider,
} from "../../firebase/firebase-config";
import {
  setLoginFirebaseUser,
  setAuthenticatedUser,
  loadAuthenticatedUser,
  setScreenPosition,
} from "../../redux/actions";
import {
  getUserDevice,
  initialUserLogin,
  msgEmailPassword,
  msgWrongLogin,
} from "../../functions/login";
import { hotmyVersion } from '../../../src/constants/constants'
import "./login.css";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: "30px"
      // boxShadow: "11px 11px 30px 0px rgba(166,75,244,0.51)" Sombra Violeta 
      // boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);"
    }
  }
}));

export const Login = () => {
  const state = useSelector((state) => state.main);
  const { loginFirebaseUser, loadingAuthenticatedUser } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  //  campos password-----------------------------------------------------------
  const [showPassword, setShowPassword] = useState(false);
  // ---------------------------------------------------------------------------
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  const [openModalTryAgain, setOpenModalTryAgain] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [user, setUser] = useState(initialUserLogin);
  const [creadorName, setCreadorName] = useState("");

  const onChange = (e) => {
    //console.log("event: ", e.target.value );
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  //const handleCreate = () => history.push("/register");
  const handleCreate = () => {
    //getReferralCodeParam();
    const referralCodeNumber = getReferralCodeParam();
    let url = "/register";
    if (referralCode != null || referralCode != "") {
      url += "?codRef=" + referralCodeNumber;
    }
    history.push(url);
  };

  const handleCreateFacebookGmail = () => {
    const referralCodeNumber = getReferralCodeParam();
    console.log("Referal: ", referralCodeNumber);
    let url = "/createRegisterInit";
    if (referralCode != null || referralCode != "") {
      url += "?codRef=" + referralCodeNumber;
      console.log("url: ", url);
    }
    history.push(url);
  };

  const acceptClick = () => {
    setOpenModalTryAgain(false);
    setMessageModal("");
  };

  const getReferralCodeParam = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referralCodeParam = urlParams.get("codRef");
    if (referralCodeParam === null) {
      setReferralCode("");
    } else {
      setReferralCode(referralCodeParam);
    }
    return referralCodeParam;
    //console.log("Referral Param: ", referralCodeParam);
  };

  // DIALOG 
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // DIALOG 
  const passwordRestoreLink = () => history.push("/passwordRestore");
  const faqsLink = () => history.push("/faqs");

  // "  const handledGoogleLogin1 = () =>{
  //     setOpenModalTryAgain1(true);
  //     setMessageModal("Estamos teniendo inconvenientes con Google, actualmente la sincronización no esta disponible. Por favor intente mas tarde.");
  //     setModalMessageButtonText("cerrar");
  //     //setOpenModalTryAgain(true);
  //   }

  const handleGoogleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(async ({ user, additionalUserInfo }) => {
        console.log("FIREBASE USER: ", user);
        console.log("FIREBASE ADITTIONAL: ", additionalUserInfo);
        const firebase_token = await user.getIdToken();
        dispatch(
          setLoginFirebaseUser({
            name: user.email,
            firebase_token: firebase_token,
            device: getUserDevice(),
            is_new_user: additionalUserInfo.isNewUser,
          })
        );
      })
      .catch((err) => console.log("ERROR FIREBASE LOGIN: ", err));
  };

  // const handleFacebookLogin = () => {
  //   firebase
  //     .auth()
  //     .signInWithPopup(facebookAuthProvider)
  //     .then(async ({ user }) => {
  //       const firebase_token = await user.getIdToken();
  //       dispatch(
  //         setLoginFirebaseUser({
  //           firebase_token: firebase_token,
  //           device: getUserDevice(),
  //         })
  //       );
  //     });
  // };

  const handleSubmit = async () => {
    try {
      const creds = await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
      const token = await creds.user.getIdToken();

      if (token) {
        dispatch(
          setLoginFirebaseUser({
            name: user.email,
            firebase_token: token,
            device: getUserDevice(),
            is_new_user: false,
          })
        );
      } else {
        localStorage.removeItem("session");
      }
    } catch (error) {
      setOpenModalTryAgain(true);
      setMessageModal(msgEmailPassword);
      setModalMessageButtonText("INTENTAR DE NUEVO");
      console.log(error);
      console.log(error);
    }
  };

  const updateNewUser = (loginFirebaseUser) => {
    let updateUser = loginFirebaseUser;
    updateUser = {
      ...updateUser,
      is_new_user: !updateUser.is_new_user,
    };
    return updateUser;
  };

  const registerLoginFirebaseUser = async () => {
    const url = `${process.env.REACT_APP_AUTH}/login`;
    try {
      setLoadingSubmit(true);
      const res = await fetch(url, {
        headers: setHeadersNoToken(),
        credentials: "include",
        method: "POST",
        body: JSON.stringify(loginFirebaseUser),
      });
      console.log("Res" , res)
      const response = await res?.json();
      console.log("RES LOGIN: ", res);
      console.log("RESPONSE LOGIN: ", response);
      if (res.status === 401) {
        history.push("/messageProcess");
      }
      if (response.success == true) {
        if (response.data.missing_data && response.data.missing_data == true) {
          const userUpdate = updateNewUser(loginFirebaseUser);
          dispatch(setAuthenticatedUser(userUpdate));
          history.push("/createRegisterInit");
        } else {
          dispatch(setScreenPosition(0));
          dispatch(setAuthenticatedUser(null));
          // agency, photographer, hotmers
          // response.data.user.rol = "photographer";
          localStorage.setItem("session", JSON.stringify(response.data));
          localStorage.setItem("hmyAppVersion", hotmyVersion);
          history.push("/");
        }
      } else {
        console.log("TODO MALLLLLL");
      }
    } catch (error) {
      setOpenModalTryAgain(true);
      dispatch(setAuthenticatedUser(null));
      setModalMessageButtonText("ACEPTAR");
      setMessageModal(msgWrongLogin);
      console.log("ERORR 2: ", error);
    }
    setLoadingSubmit(false);
    dispatch(setLoginFirebaseUser(null));
  };

  useEffect(() => {

    if (loginFirebaseUser !== null) {
      if (loginFirebaseUser.is_new_user == true) {
        //history.push("/createRegisterInit");
        handleCreateFacebookGmail();
      } else {
        registerLoginFirebaseUser();
      }
    }
  }, [loginFirebaseUser]);

  const getCreatorParameter = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const creatorCodeParam = urlParams?.get("creator");
    creatorCodeParam ? setOpen(true) : setOpen(false)
    setCreadorName(creatorCodeParam) // set the name of creator
  };

  useEffect(() => {
    getCreatorParameter();
  }, []);

  const checkEnter = (event) => {
    if (event.keyCode === 13) { // Verifica si la tecla presionada es Enter (código 13)
      handleSubmit(); // Llama a la función de submit
    }
  }

  return (
    <div className="login">
      <div className="login__header__main"></div>

      <div className="login__container__main">
        <CustomModal
          openModal={openModalTryAgain}
          setOpenModal={setOpenModalTryAgain}
          discardExternalEvents={true}
        >
          <OptionTryAgain
            icon={opsIcon}
            title={messageModal}
            acceptClick={acceptClick}
            buttonText={modalMessageButtonText}
            whatsApp={false}
          />
        </CustomModal>
        {/* CUSTOM MODAL2 */}
        <div className="login__part_1">
          <header>
            <div className="login__header">
              <img src={mainIcon} tabIndex="0" alt="logo" />
            </div>
          </header>
          {loadingSubmit && <LoadingScreen loading={loadingSubmit} />}
          <form className="login__container--form" action="">
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Email es Requerido"
              tabIndex="1"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                name="email"
                type="email"
                placeholder="Escribe tu email"
                autoComplete="username"
                value={user.email}
                onChange={onChange}
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>
            <div
              className="wrap-input101 validate-input"
              data-validate="Password is required"
              tabIndex="2"
            >
              <span className="label-input101">Contraseña</span>
              <div className="grid-container">
                <div className="image-icon-conteiner">
                  <img
                    className="image-candado-login"
                    src={passwordIconWhite}
                    alt="password"
                  />
                </div>
                <div>
                  <input
                    className="bcontainer101b"
                    placeholder="Escribe tu contraseña"
                    name="password"
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    value={user.password}
                    onChange={onChange}
                    onKeyDown={event => checkEnter(event)}
                  />
                </div>
                <div className="image-icon-conteiner"
                  onClick={() => setShowPassword(!showPassword)}>
                  <img
                    className="image-candado-login"
                    src={eyeIcon}
                    alt="showPassword"
                  />
                </div>
              </div>
              <span className="focus-input101" data-symbol="&#xf190;"></span>
            </div>
            <div className="login__container__form--forgotPassword">
              <div tabIndex="3" onClick={passwordRestoreLink}>
                ¿Olvidaste tu Contraseña?
              </div>
            </div>
            {/* <!-- fin de formulario --> */}
          </form>
          {/* <!-- fin de login__part_1 --> */}
          <div className="login__containar__button">
            <button
              className="button-17"
              role="button"
              tabIndex="4"
              onClick={handleSubmit}
            >
              INGRESAR
            </button>
          </div>
        </div>

        <div className="login__part_2">
          <p className="login__containar--conditional--title">
            <span>Sincroniza tus cuentas  <b>Gratis</b>  con: </span>
          </p>
          <div className="login__containar--social-media">
            {/* <div
              className="login100-social-item--facebook bg1"
              onClick={handleFacebookLogin}
              tabIndex="5"
            >
              <img src={facebook} alt="Logo Fecebook" />
              <div className="icon-facebook">
                Iniciar Sesión con  <b>Facebook</b>
              </div>
            </div> */}
            <div
              className="login100-social-item--google"
              onClick={handleGoogleLogin}
              tabIndex="6"
            >
              <img src={google} alt="Logo Google" />
              <div className="icon-google">
                Iniciar Sesión con  <b>Google</b>
              </div>
            </div>
          </div>

          <div className="login__containar--conditional">
            <p className="login__containar--conditional--title">
              {" "}
              Al sincronizar tu cuenta estas aceptando los{" "}
            </p>
            <a
              href="https://www.hotmy.net/terminos-condiciones"
              className="login__containar--conditional--title--link"
            >
              Terminos y Condiciones
            </a>
          </div>
          <div className="login__containar--createAccount">
            <p className="login__container--register">
              Si no tienes ninguna cuenta
            </p>
            <div
              className="login__container--register--link"
              onClick={handleCreate}
            >
              Regístrate Gratis
            </div>
          </div>
        </div>
      </div>
      {/* <!-- FIN DE SECCION LOGIN CONTAINER--> */}
      <footer className="footer">
        <a href="https://www.hotmy.net/terminos-condiciones">Terminos y Condiciones</a>
        <a onClick={faqsLink}>Faqs</a>
      </footer>
      {/* <!-- FIN DE SECCION LOGIN --> */}
      {open &&
        <div className="rounded">
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.root}
          >
            <div>
              <div className="dialog-conteiner">
                <SwipperContent
                  cerrar={() => handleClose()}
                  creatorName={creadorName}
                ></SwipperContent>
              </div>
            </div>
          </Dialog>
        </div>
      }
      {/* <!-- FIN DE SECCION DIALOG --> */}
    </div >
  );
};
