/* eslint-disable */
import React, { useEffect, useState } from "react";
// import { PublicRoutes } from "./components/publicRoutes/publicRoutes";
// import { OnboardingRoutes } from "./views/onboardingRoutes/onboardingRoutes";
import { Login } from "./views/login/login";
import { firebase } from "./firebase/firebase-config";
import { useDispatch } from "react-redux";
import { getLastUrl } from "./libs/fetch";
import { PrivateRoutes } from "./components/privateRoutes/privateRoutes";
import { LoadingScreen } from "./commonComponents/loadingScreen/loadingScreen";
import { DashboardRoutes } from "./views/dashboardRoutes/dashboardRoutes";
import { Faqs } from "./views/faqs/faqs.js";
import { useTranslation } from "react-i18next";
//import { ClonePreferences } from "./views/clonePreferences/clonePreferences";
import { setScreenPosition } from "./redux/actions";
import { CreateRegisterInit } from "./views/createRegisterInit/createRegisterInit";
import { StepperMessageRestore } from "./views/stepperMessageRestore/stepperMessageRestore";
import { StepperPasswordRestore } from "./views/stepperPasswordRestore/stepperPasswordRestore";
import { StepperAccountRegister } from "./views/stepperAccountRegister/stepperAccountRegister";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { dataDogRumStart } from './datadog/dataDog'
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
//import { LoginTemporal } from "./views/loginTemporal/loginTemporal"; ///AGREGADOTEMPORAL

export const App = () => {
  const { t, i18n } = useTranslation("global");
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  const setAvailableArea = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const dispatch = useDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  console.log("updateAvailable", updateAvailable)

  useEffect(() => {
    // revisar si este check update no lo puedo hacer por numero de version, en la base de datos. Comparando las versiones que tienen y actualizando el service worker cuando ejecutes con una version vieja a la actual del sistema.
    // Check for updates on component mount
    checkForUpdates();
    // Listen for service worker updates
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data === 'update_available') {
          setUpdateAvailable(true);
        }
      });
    }
  }, []);

  const checkForUpdates = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update().then(() => {
          console.log('Service worker updated');
        });
      });
    }
  };

  const handleUpdateClick = () => {
    // Prompt service worker to update
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload(); // Refresh the page
    }

    window.location.reload();
  };

  // ################################################################
  // INIT: CODIGO PARA QUE LEVANTE EL CARTEL DE INSTALAR PWA
  // ################################################################
  /*
  const promptToggle = (element, toAdd, toRemove) => {
    element.classList.add(toAdd);
    element.classList.remove(toRemove);
  };
	
  // Declare general function to get or set status into storage
  const statusPrompt = {
    get: () => {
      return localStorage.getItem('statusPrompt') || null;
    },
    set: (status) => {
      localStorage.setItem('statusPrompt', status);
      return;
    }
  }
	
  window.onload = (e) => { 

    // Declare init HTML elements
    const prompt = document.querySelector('#prompt');
    const buttonAdd = document.querySelector('#buttonAdd');
    const buttonCancel = document.querySelector('#buttonCancel');
	
    // Add all cats from array into list
            	
    let deferredPrompt;
    window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Show prompt modal if user previously not set to dismissed or accepted
    if(!statusPrompt.get()) {
      // Change status prompt
      promptToggle(prompt, 'show', 'hide');
    }
    });
	
    // Add event click function for Cancel button
    buttonCancel.addEventListener('click', (e) => {
    // Change status prompt
    promptToggle(prompt, 'hide', 'show');
    // Set status prompt to dismissed
    statusPrompt.set('dismissed');
    });
	
    // Add event click function for Add button
    buttonAdd.addEventListener('click', (e) => {
    // Change status prompt
    promptToggle(prompt, 'hide', 'show');
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        statusPrompt.set('accepted');
        console.log('User accepted the A2HS prompt');
      } else {
        statusPrompt.set('dismissed');
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
      });
    });
  };
  */
  // ################################################################
  // FIN: CODIGO PARA QUE LEVANTE EL CARTEL DE INSTALAR PWA
  // ################################################################

  useEffect(() => {
    setAvailableArea();
    window.addEventListener("resize", setAvailableArea);
    return () => window.removeEventListener("resize", setAvailableArea);
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user === null) {
        //console.log("NOOOOOO ESTAS LOGUEADO !!!");
        setIsLoggedIn(false);
        localStorage.removeItem("session");
      } else {
        //console.log("SIIIIII ESTAS LOGUEADO !!!");
        setIsLoggedIn(true);
        dispatch(setScreenPosition(getLastUrl()));
      }
      setChecking(false);
    });

    try {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    } catch (error) {
      i18n.changeLanguage(i18n.language);
    }

    // PRENDER DATADOG.

    try {
      const session = localStorage.getItem("session");

      if (session) {

        dataDogRumStart();

      }
    } catch (error) {
      console.log("error", error)
    }

  }, []); // eslint-disable-line

  if (checking) {
    return <LoadingScreen loading={isLoggedIn} />;
  }

  return (
    <Router>
      <div className="app--main--container">
        {updateAvailable && (
          <div className="update-prompt">
            <p>Nueva version de la App disponible.</p>
            <button onClick={handleUpdateClick}>Actualizar Ahora</button>
          </div>
        )}
        <Switch>
          {/* <PublicRoutes
            isAuthenticated={isLoggedIn}git 
            component={OnboardingRoutes}
            path="/login"
          /> */}
          <Route path="/messageProcess" component={StepperMessageRestore} />
          {/*<Route path="/preferences" component={ClonePreferences} />*/}
          <Route path="/faqs" component={Faqs} />
          <Route path="/createRegisterInit" component={CreateRegisterInit} />
          <Route path="/passwordRestore" component={StepperPasswordRestore} />
          <Route path="/register" component={StepperAccountRegister} />
          <Route path="/login" component={Login} />
          {/*<Route path="/proximamente" component={LoginTemporal} /> {/*AGREGADOTEMPORAL*/}
          <PrivateRoutes
            isAuthenticated={isLoggedIn}
            component={DashboardRoutes}
            path="/"
          />

        </Switch>
      </div>
    </Router>
  );
};
