import React, { useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import { useHistory } from "react-router-dom";
import profileDefault from "../../icons/profile/profile-default-img.png";
import { PackContent } from "../packContent/packContent";
import { useTranslation } from "react-i18next";
import "./hotmerWishPack.css";

export const HotmerWishPack = ({ pack, onClick }) => {
  const { t } = useTranslation("global");
  const history = useHistory();

  const [profile, setProfile] = useState({
    src: pack.profile_photo_thumbnail_path || profileDefault,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  const visitHotmerPorfile = () =>
  history.push(`/${pack?.ds_display_name}`);

  return (
    <div className="hotmer--wish--pack--main--container">
      <div className="hotmer--wish--pack--main--container--profile">
        <img src={profile.src} 
        onError={onErrorProfile} 
        alt="..."
        onClick={visitHotmerPorfile} />
      </div>
      <div className="hotmer--wish--pack--main--container--header">
        <div className="hotmer--wish--pack--main--container--header--top">
          {pack.ds_display_name}
        </div>
        <div className="hotmer--wish--pack--main--container--header--bottom">
          <div className="hotmer--wish--pack--main--container--header--bottom--left">
            {pack.ds_title.toUpperCase()}
          </div>
          <div
            className="hotmer--wish--pack--main--container--header--bottom--right"
            onClick={onClick}
          >
            {t("common.pack.standar.remove")}
          </div>
        </div>
      </div>
      <PackContent pack={pack} owner={2} />
    </div>
  );
};
