import { useState } from "react";
import fireIcon from "../../icons/whiteIcons/fire-icon.png";
import profileDefaultCover from "../../icons/profile/profile-default-cover-home.jpg";
import packIcon from "../../icons/whiteIcons/packsIconWhite.png";
import imageIcon from "../../icons/whiteIcons/photoIconWhite.png";
import videoIcon from "../../icons/whiteIcons/videoIconWhite.png";
import shareIconDegrade from "../../icons/degrade/share.png";
import commentsIcon from "../../icons/whiteIcons/comments-icon.png";
import verifiedIcon from "../../icons/verified-icon.png";
import editIcon from "../../icons/grayIcons/editIconMyProfile.png";
import DealIcon from "../../icons/grayIcons/drawerProfile-icon-item-deal-gray.png";
import suscriptorsIcon from "../../icons/whiteIcons/suscriptors-icon.png";
import { IconTopic } from "../../components/iconTopic/iconTopic";
import { IconProcedure } from "../../components/iconProcedure/iconProcedure";
import { LoadingComponent } from "../../commonComponents/loadingComponent/loadingComponent";
import { WrongFetchComponent } from "../../commonComponents/wrongFetchComponent/wrongFetchComponent";
import { DotsOptions } from "../../components/dotsOptions/dotsOptions";
import { ShareInfoOfProfile } from "../../components/Share/ShareInfoOfProfile";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./myProfileData.css";

export const MyProfileData = ({
	hotmerProfile,
	loadingUserStatistics,
	userStatistics,
	myStateProfile,
	// onErrorProfile,
	// handleClick,
	onClickRefreshStatistics,
	// myStateCover,
	// onErrorCover,
	cover,
}) => {

	const { t } = useTranslation("global");
	const history = useHistory();
	const [open, setOpen] = useState(false)
	const handleClickDeals = () => {
		history.push(`/deals`);
	};
	const handleClickEdit = () => {
		const id = JSON.parse(localStorage.getItem("session")).user.id;
		history.push(`/editProfileHotmer/${id}`);
	}
	let user;
	try {
		user = JSON.parse(localStorage.getItem("session")).user || "";
	} catch (error) {
		window.location.href = "/login";
	}

	if (loadingUserStatistics) {
		return (
			<div className="my--profile--main--container--header">
				<LoadingComponent />;
			</div>
		);
	} else if (!loadingUserStatistics && !userStatistics && !cover) {
		return (
			<div className="my--profile--main--container--header">
				<WrongFetchComponent onClickRefresh={onClickRefreshStatistics} />;
			</div>
		);
	} else {
		return (
			<>
				<div className="my--profile--main--container--header">
					<div className="my--profile--main--container--header--top">
						<div className="my--profile--main--container--header--top--left">
							<div className="my--profile--main--container--header--top--left--name">
								{(user && user.full_name) || ""}
							</div>
							<div className="my--profile--main--container--header--top--left--icon">
								<img src={verifiedIcon} alt="..." />
							</div>
						</div>
						<div className="my--profile--main--container--header--profile">
							<img
								src={myStateProfile.src}
								alt="..."
								className="my--profile--main--container--header--profile--image"
								onClick={() => handleClickEdit()}
							/>
						</div>
						<div className="my--profile--main--container--header--top--options">
							<DotsOptions
								id={user?.id}
								hotmerUser={user?.full_name}
							/>
						</div>
					</div>
					<div className="edit--profile--main--container--header--body">
						<img
							className="edit--profile--main--container--header--body--cover--myprofile"
							src={cover || profileDefaultCover}
							alt="..."
						/>
						<div className="edit--profile--main--container--header--body--float--edit">
							<IconProcedure
								icon={editIcon}
								onClick={() => handleClickEdit()}
							/>
						</div>
					</div>

					{userStatistics && (
						<div className="my--profile--main--container--icons--stadistics">
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={imageIcon}
									value={userStatistics.total_image}
								/>
							</div>
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={videoIcon}
									value={userStatistics.total_video}
								/>
							</div>
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={packIcon}
									value={userStatistics.total_pack}
								/>
							</div>
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={commentsIcon}
									value={userStatistics.total_comments_received}
								/>
							</div>
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={fireIcon}
									value={userStatistics.total_likes} />
							</div>
							<div className="my--profile--main--container--icons--stadistics--item">
								<IconTopic
									icon={suscriptorsIcon}
									value={userStatistics.total_follows}
								/>
							</div>
						</div>
					)}
					{/* FIN seccion de iconos */}
					<div className="my--profile--main--container--icons">
						<IconProcedure
							icon={editIcon}
							description={t("myProfile.actionItems.edit")}
							onClick={() => handleClickEdit()}
						/>
						<div className="my--profile--main--container--icons--divider" />
						<IconProcedure
							icon={DealIcon}
							description={t("myProfile.actionItems.deals")}
							onClick={() => handleClickDeals()}
						/>
						<div className="my--profile--main--container--icons--divider" />
						<IconProcedure
							icon={shareIconDegrade}
							description={t("myProfile.actionItems.shareProfile")}
							onClick={() => setOpen((open) => !open)}
						/>
					</div>
				</div>
				<ShareInfoOfProfile
					profileInfoToShare={hotmerProfile}
					helper={true}
					showHelperContent={false}
					openDrawerShareProfile={open}
					cerrar={() => setOpen(false)}
				/>
			</>
		);
	}

};
