import videoIconBlack from '../../../src/icons/a_news/content/video_B.svg'
import photoIconBlack from "../../../src/icons/a_news/content/image_B.svg";
import Typography from "@material-ui/core/Typography";
import './PackContentSummary.css'

interface packContentSummaryInterface {
    quantityPhotos?: number
    quantityVideos?: number
    alignLeft?: boolean
}

export const PackContentSummary = ({ quantityPhotos, quantityVideos, alignLeft }: packContentSummaryInterface) => {

    return (
        <>
            <div className="pack-content-quantity-main">
                {((quantityPhotos && quantityVideos !== null)
                    && (quantityPhotos && quantityVideos !== 0)) ?
                    <div>
                            <div className='pack-content-quantity-videos-photos'
                                // {`${alignLeft ? 'pack-content-quantity-videos--or--photos--noCentered' : 'pack-content-quantity-videos-photos'}`}
                                >
                                <div className=''>
                                    <img className='grid-icons'
                                        alt='FotoIcon'
                                        src={photoIconBlack}
                                    />
                                </div>
                                <div>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    {quantityPhotos} Fotos
                                </Typography>
                                </div>
                                <div> {`+`} </div>
                                <div className=''>
                                    <img className='grid-icons'
                                        alt='VideoIcon'
                                        src={videoIconBlack}
                                    />
                                </div>
                                <div>
                                <Typography style={{ fontSize: '0.8rem' }} >
                                    {quantityVideos} Videos
                                </Typography>
                                </div>
                            </div>
                        </div>
                    :
                    <div>
                        {quantityPhotos !== 0 &&
                            <div className={`${alignLeft ? 'pack-content-quantity-videos--or--photos--noCentered' : 'pack-content-quantity-videos-or-photos'}`}>
                                <img className='grid-icons'
                                    alt='VideoIcon'
                                    src={photoIconBlack}
                                >
                                </img>
                                <Typography style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}>
                                    {quantityPhotos} Fotos
                                </Typography>
                            </div>}
                        {quantityVideos !== 0 &&
                            <div className={`${alignLeft ? 'pack-content-quantity-videos--or--photos--noCentered' : 'pack-content-quantity-videos-or-photos'}`}>
                                <img className='grid-icons'
                                    alt='VideoIcon'
                                    src={videoIconBlack}
                                >
                                </img>
                                <Typography style={{ fontSize: '0.8rem' , marginLeft: '0.5rem' }} >
                                    {quantityVideos} Videos
                                </Typography>
                            </div>}
                    </div>
                }
            </div>
        </>
    )

}
