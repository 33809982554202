import { makeStyles } from '@material-ui/core/styles';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles((theme) => ({
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  roundedStyles: {
    border: '1px solid black',
    borderRadius: '50%',
    margin: '1rem 0',
  },
}));

export const RoundedNoContent = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.noContent}>
      <ClearRoundedIcon className={classes.roundedStyles} />
      <div>{title}</div>
    </div>
  );
};
