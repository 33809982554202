import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { useSelector, useDispatch } from "react-redux";
import {
  setNameParam,
  setSelectedHotmers,
  getCollaborationsHotmers,
} from "../../redux/deals/Actions";
import "./DealSearchHotmers.css";

export const DealSearchHotmers = () => {
  const dispatch = useDispatch();
  const { nameParam } = useSelector((state) => state.deal);
  const onChange = ({ target: { value } }) => dispatch(setNameParam(value));

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const onClickHotmers = () => {
    //dispatch(setSelectedHotmers([]));
    dispatch(getCollaborationsHotmers(user.rol, nameParam, 10, 0, 1));
  };

  return (
    <div className="colaborations--hotmers--main--container--search">
      <div className="colaborations--hotmers--main--container--search--title">
        Busca a tu creador de contenido para enviarle la solicitud de
        Colaboracion y asi luego poder crear un acuerdo comercial.
      </div>
      <div className="colaborations--hotmers--main--container--search--apply">
        <TextField
          id="outlined-size-small"
          name="name"
          value={nameParam}
          variant="outlined"
          size="small"
          onChange={onChange}
          style={{ background: "white", borderColor: "white", width: "65%" }}
        />
        <div className="colaborations--hotmers--main--container--search--button">
          <CustomButtonRegister onClick={onClickHotmers} disabled={false}>
            <SearchIcon />
          </CustomButtonRegister>
        </div>
      </div>
    </div>
  );
};
