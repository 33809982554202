/* eslint-disable */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { firebase } from "../../firebase/firebase-config";
import { setHeaders } from "../../libs/fetch";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { setLoginFirebaseUser } from "../../redux/actions";
import { AccordionPaymentMethod } from "../../components/accordionPaymentMethod/accordionPaymentMethod";
import { AccordionIdioms } from "../../components/accordionIdioms/accordionIdioms";
import { getPaymentAccounts } from "../../redux/extractions/extractionsActions";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../components/accordionSets/accordionSets";
import { useTranslation } from "react-i18next";
import { hotmyVersion } from "../../constants/constants";
import "./configProfile.css";

const useStyles = makeStyles((theme) => ({
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1, 0, 1),
	},
	heroButtons: {
		marginTop: theme.spacing(1),
	},
}));



export const ConfigProfile = ({ match }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t, i18n } = useTranslation("global");
	const [expanded, setExpanded] = useState("");
	const [notifications, setNotifications] = useState([]);

	const handleChange = (panel) => (event, newExpanded) =>
		setExpanded(newExpanded ? panel : false);

	let user;
	try {
		user = JSON.parse(localStorage.getItem("session")).user || "";
	} catch (error) {
		window.location.href = "/login";
	}

	const clickWhatsAppSupport = () => {

		try {
			window.open(
				`https://wa.me/5491168714284?text=Me%20gustaría%20dar%20de%20baja%20mi%20cuenta.%20Mi%20nombre%20de%20usuario%20es%20${user.full_name}%20(${user.id}),%20Les%20dejo%20mis%20motivos%20:%20`,
				"_blank"
			);
		} catch {
			console.log("no abrio")
		}
	};

	const redirectToMain = () => {
		history.push(`/`);
		// Me lleva al login cuando hago logout 
	};

	const logout = async () => {
		try {
			const url = `${process.env.REACT_APP_AUTH}/logout`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
			});
			if (res.status === 200) {
				dispatch(setLoginFirebaseUser(null));
				await firebase.auth().signOut();
				localStorage.removeItem("idUrl");
				localStorage.removeItem("hmyAppVersion");
				redirectToMain()
			} else {
				console.log("Todavia No cerro sesion !!!");
			}
		} catch (error) {
			console.log(error);
			console.log("Todavia No cerro sesion !!!");
		}
	};

	const cancelLogout = () => setExpanded("");

	const borrame = async () => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_INITIAL_V2}/admin/delete/user`,
				{
					headers: setHeaders(),
					method: "POST",
				},
			);
			console.log("RES BORRAR: ", res);
			const response = await res.json();
			console.log("RESPONSE BORRAR: ", response);
			console.log(response);
		} catch (error) {
			console.log("ERROR: ", error);
		}
	};

	useEffect(() => {
		setNotifications([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
		dispatch(getPaymentAccounts());
	}, []);

	return (
		<div className="config--profile--main--container">
			<Accordion
				square
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>

				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<div
						className={`header--accordion--title ${expanded === "panel1" ? "expanded--accordion" : ""
							}`}
					>
						{t("configuration.items.idioms")}
					</div>
				</AccordionSummary>

				<AccordionDetails>

					<AccordionIdioms>
					</AccordionIdioms>
					{/*}
					<div>
						{notifications.map((notif) => {
							return <div key={notif}>{`Notification Number: ${notif}`}</div>;
						})}
					</div>
					*/}
				</AccordionDetails>
			</Accordion>

			<AccordionPaymentMethod
				panel={"panel2"}
				expanded={expanded}
				handleChange={handleChange}
				onClickRefreshPaymentMethod={() => { }}
			/>

			<Accordion
				square
				expanded={expanded === "panel3"}
				onChange={handleChange("panel3")}
			>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<div
						className={`header--accordion--title ${expanded === "panel3" ? "expanded--accordion" : ""
							}`}
					>
						{t("configuration.items.logout")}
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className={classes.heroContent}>
						<Container maxWidth="sm">
							<div className={classes.heroButtons}>
								<Grid container spacing={2} justifyContent="center">
									<Grid item>
										<Button
											variant="contained"
											color="default"
											onClick={logout}
										>
											Confirmar cierre de sesión
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="outlined"
											color="default"
											onClick={cancelLogout}
										>
											Aún no deseo salir
										</Button>
									</Grid>
								</Grid>
							</div>
						</Container>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion
				square
				expanded={expanded === "panel4"}
				onChange={handleChange("panel4")}
			>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<div
						className={`header--accordion--title ${expanded === "panel4" ? "expanded--accordion" : ""
							}`}
					>
						{t("configuration.items.closeAccount")}
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className={classes.heroContent}>
						<Container maxWidth="sm">
							<div className={classes.heroButtons}>
								<Grid container spacing={2} justifyContent="center">
									<Grid item>
										<Button
											variant="contained"
											color="default"
											onClick={clickWhatsAppSupport}
										>
											Quiero dar de Baja mi Cuenta.
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="outlined"
											color="default"
											onClick={cancelLogout}
										>
											Aún no deseo salir
										</Button>
									</Grid>
								</Grid>
							</div>
						</Container>
					</div>
				</AccordionDetails>
			</Accordion>
			<div className="hotmy--version">
				Version : {hotmyVersion}
			</div>
			<DividerSpace space="XS" />
			{/* <button type="button" onClick={borrame}>
				Eliminar Cuenta
			</button> */}
		</div>
	);
};
