import AddIcon from "@material-ui/icons/Add";
import SkeletonCard from "../skeletonCard/SkeletonCard";
import { Deal } from "../Deal/Deal";
import { Reload } from "../Reload/Reload";
import { connect } from "react-redux";
import { CircularButton } from "../CircularButton/CircularButton";
import { createStructuredSelector, createSelector } from "reselect";
import "./ColaborationsRequests.css";

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        loadingCollaborations,
        errorCollaborations,
        collaborationOption,
        collaborations: colls,
      }) => ({
        loadingCollaborations,
        errorCollaborations,
        colls: colls?.filter((x) => {
          if (collaborationOption === "") {
            return colls;
          } else {
            return x.status === collaborationOption;
          }
        }),
      })
    ),
  })
)(({ propsDeal, handleStepCollaborations }) => {
  const { loadingCollaborations, errorCollaborations, colls } = propsDeal;

  const chooseAgreementHotmer = async (hotmer) => console.log(hotmer);

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);
  const  referral2 = user.profile.referral_code;

	const shareReferralCode = (e) => {
		e.preventDefault();
		if (!navigator.share) {
			alert("Navegador no soporta la Web Share API");
			return;
		}
		navigator
			.share({
				// title: "Hotmy",
				// text: "Instala Hotmy y mira el mejor contenido",
				url: process.env.PUBLIC_URL + "/login?codRef=" + referral2,
			})
			.then(() => console.log("Successful share"))
			.catch((error) => console.log("Error sharing", error));
	};

	const onChange = (e) => {};



  return (
    <div className={"colaborations--main--container"}>
      {loadingCollaborations && <SkeletonCard />}
      {errorCollaborations && <Reload onClick={() => {}} />}
      {colls &&
        colls.length !== 0 &&
        colls.map((req) => (
          <Deal
            key={req.id_hotmer}
            deal={req}
            typeDeal="request"
            selectAgreementHotmer={() => chooseAgreementHotmer(req)}
          />
        ))}
      {colls && colls.length === 0 && (
        <div className="collaborations--create--option">
          {user.rol !== "hotmers" && (
            <CircularButton onClick={() => handleStepCollaborations(1)}>
              <AddIcon fontSize="small" />
            </CircularButton>
          )}
          {user.rol === "hotmers" && (
            <div className="agreements--create--option--container">
              <div className="collaborations--create--option--title">
              Aun no tienes Solicitudes de Colaboración. 
              <p></p>
              Una Agencia / Fotógrafo debe tener interes en tí. 
              <p></p>
              Si no esta en la aplicación, invitalo con tu link de referidos.  
            </div>
            <div className="collaborations--create--option--share">
              <button className="collaborations--create--option--title--button collaborations--create--option--title--button--container"
              onClick={shareReferralCode}
              >
                Compartir Link
              </button>
            </div>
            </div>
            )}
          {(user.rol === "photographer" ||  user.rol === "agency" ||  user.rol === "manager") && (
            <div className="agreements--create--option--container">
              <div className="collaborations--create--option--title">
                Aun no has iniciado Solicitudes de Colaboración.
                <p></p>
                Si el creador que te interesa, no esta en la aplicación, invitalo con tu link de referidos.  
                </div>
                <div className="collaborations--create--option--share">
                <button className="collaborations--create--option--title--button collaborations--create--option--title--button--container"
                onClick={shareReferralCode}
                >
                  Compartir Link
                </button>
              </div>
            </div>  
          )}
       </div>
    )}
    </div>
  );
});