import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "absolute",
		right: 0,
		top: 0,
		width: "50px",
		height: "50px",
		background: "transparent",
		border: "none",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

export const CustomModalVideo = ({
	id,
	openModalVideo,
	fullScreen,
	setOpenModalVideo,
	discardExternalEvents,
	children,
	handleCloseView,
}) => {
	const classes = useStyles();

	const handleCloseVideoDialog = () => {
		handleCloseView();
		//setOpenModalVideo(false);
	};

	return (
		<Dialog
			id={id}
			onClose={handleCloseVideoDialog}
			aria-labelledby="customized-dialog-title"
			open={openModalVideo}
			fullScreen={fullScreen}
			//keepMounted
			disableBackdropClick={discardExternalEvents}
		>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleCloseVideoDialog}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			{children}
		</Dialog>
	);
};
