import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./discardEditProfile.css";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

export const DiscardEditProfile = ({ discardChanges, cancelModal }) => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Abandonar Edición
        </Typography>
        <Typography
          component="h5"
          align="center"
          color="textSecondary"
          paragraph
        >
          Desea salir de la edición ? Puede volver cuando usted lo desee.
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={discardChanges}
              >
                Si
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={cancelModal}>
                No
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
