import AddIcon from "@material-ui/icons/Add";
import SkeletonCard from "../skeletonCard/SkeletonCard";
import { Deal } from "../Deal/Deal";
import { Reload } from "../Reload/Reload";
import { connect } from "react-redux";
import { CircularButton } from "../CircularButton/CircularButton";
import { getAgreements, getAgreementDetail } from "../../redux/deals/Actions";
import { createStructuredSelector, createSelector } from "reselect";
import "./Agreements.css";

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        loadingAgreements,
        errorAgreements,
        agreements,
        agreementOption,
        collaborations: colls,
      }) => ({
        loadingAgreements,
        errorAgreements,
        agreements: agreements?.filter((x) => {
          if (agreementOption === "") {
            return agreements;
          } else {
            return x.ds_status === agreementOption;
          }
        }),
        colls,
      })
    ),
  }),
  (dispatch) => ({
    getAgreements: (rol) => dispatch(getAgreements(rol)),
    getAgreementDetail: (hotmer) => dispatch(getAgreementDetail(hotmer)),
  })
)(({ propsDeal, handleStepAgreements, getAgreementDetail }) => {
  const { loadingAgreements, errorAgreements, agreements, colls } = propsDeal;

  const accept = ({ status }) => status == "accepted";
  const disabledBtn = colls && !colls.some((x) => accept(x));

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);
  const  referral2 = user.profile.referral_code;

	const shareReferralCode = (e) => {
		e.preventDefault();
		if (!navigator.share) {
			alert("Navegador no soporta la Web Share API");
			return;
		}
		navigator
			.share({
				// title: "Hotmy",
				// text: "Instala Hotmy y mira el mejor contenido",
				url: process.env.PUBLIC_URL + "/login?codRef=" + referral2,
			})
			.then(() => console.log("Successful share"))
			.catch((error) => console.log("Error sharing", error));
	};

	const onChange = (e) => {};



  return (
    <div className="agreements--main--container">
      {loadingAgreements && <SkeletonCard />}
      {errorAgreements && <Reload onClick={() => {}} />}
      {agreements &&
        agreements.length !== 0 &&
        agreements.map((deal, i) => (
          <Deal
            key={i}
            deal={deal}
            typeDeal="agreement"
            selectAgreementHotmer={() => {
              handleStepAgreements(1);
              getAgreementDetail(deal);
            }}
          />
        ))}
      {agreements && agreements.length == 0 && (
        <div className="agreements--create--option">
          {user.rol !== "hotmers" && (
            <CircularButton
              disabled={disabledBtn}
              onClick={() => handleStepAgreements(1)}
            >
              <AddIcon fontSize="small" />
            </CircularButton>
          )}
          {user.rol === "hotmers" && (
           <div className="agreements--create--option--container">
           <div className="agreements--create--option--title">
            Aun no tienes Acuerdos Comerciales. 
            <p></p>
            Una Agencia / Fotógrafo debe tener interes en tí. 
            <p></p>
            Si no esta en la aplicación, invitalo con tu link de referidos.  
            </div>
            <div className="agreements--create--option--share">
            <button className="agreements--create--option--title--button agreements--create--option--title--button--container"
            onClick={shareReferralCode}
            >
              Compartir Link
            </button>
            </div>
          </div>
          )}
           {(user.rol === "photographer" ||  user.rol === "agency" ||  user.rol === "manager") && (
           <div className="agreements--create--option--container">
           <div className="agreements--create--option--title">
            Aun no tienes Acuerdos Comerciales. 
            <p></p>
            Inicia tu acuerdo comercial desde la pantalla de Solicitudes.
            <p></p>
            Si el creador que te interesa, no esta en la aplicación, invitalo con tu link de referidos.  
            </div>
            <div className="agreements--create--option--share">
            <button className="agreements--create--option--title--button agreements--create--option--title--button--container"
            onClick={shareReferralCode}
            >
              Compartir Link
            </button>
            </div>
          </div>
          )}
        </div>
      )}
    </div>
  );
});
