import { Reload } from "../Reload/Reload";
import { NoContent } from "../NoContent/NoContent";
import { Scrollbars } from "react-custom-scrollbars";
import { PackSearch } from "../PackSearch/PackSearch";
import { SkeletonPack } from "../skeletonCard/SkeletonPack";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";
import { getPacks } from "../../views/search/Actions";
import Divider from "@material-ui/core/Divider";
import { useSelector, useDispatch } from "react-redux";
import { PaginationLoading } from "../PaginationLoading/PaginationLoading";
import { HotmerGridExclusivePack } from "../HotmerGridExclusivePack/HotmerGridExclusivePack"
import "./packGridContent.css";

export const PackGridContent = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const {
    loadingPacks,
    errorPacks,
    packs,
    packsLimit,
    packsOffset,
    stopPacksFetch,
    search,
    toys,
    packOption,
    flagChangePacks,
  } = state;

  const handleScroll = ({ target }) => {
    const { scrollHeight, scrollTop, clientHeight } = target;
    if (stopPacksFetch || loadingPacks) return;
    const bottom = scrollHeight - (scrollTop + 10) <= clientHeight;
    if (bottom)
      dispatch(
        getPacks(
          search,
          toys,
          packOption,
          packsLimit,
          packsOffset,
          flagChangePacks
        )
      );
  };

  const packsRequest = () =>
    dispatch(
      getPacks(
        search,
        toys,
        packOption,
        packsLimit,
        packsOffset,
        flagChangePacks
      )
    );

  return (
    <div className="pack--grid--content--main--container">
      <div className="pack--exclusive--conteiner">
      <HotmerGridExclusivePack />
      <Divider />
      </div>
      
      {loadingPacks && packsOffset === 0 && <SkeletonPack />}
      {!packs && !errorPacks && stopPacksFetch && <NoContent />}
      {errorPacks && packsOffset === 0 && (
        <>
          <Reload onClick={packsRequest} />
          <SimpleMessage message={"Error"} />
        </>
      )}
      {packs && (
        <Scrollbars onScroll={handleScroll}>
          {packs.map((pack) => (
            <PackSearch key={pack.id_pack} pack={pack} />
          ))}
          {loadingPacks && <PaginationLoading />}
          {errorPacks && <Reload onClick={packsRequest} />}
          {!errorPacks && stopPacksFetch && <NoContent />}
        </Scrollbars>
      )}
    </div>
  );
};