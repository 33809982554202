/* eslint-disable */
import React from "react";
//import trashIcon from "../../icons/darkIcons/trash-icon.png";
import IconButton from "@material-ui/core/IconButton";
//import blackHeartIcon from "../../icons/darkIcons/black-heart-icon.png";
import fireIcon from "../../icons/darkIcons/fire-icon.png";
import uploadIcon1 from "../../icons/whiteIcons/icon-upload-white.png";
import DeleteIcon from "@material-ui/icons/Delete";
import timeIcon from "../../icons/darkIcons/reloj-calculo-upload.png";
//import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { UploadFilesAdd } from "../../commonComponents/uploadFilesAdd/uploadFilesAdd";
import { UploadEditOption } from "../../components/uploadEditOption/uploadEditOption";
import { UploadImagePreview } from "../../components/uploadImagePreview/uploadImagePreview";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import {
  existenceBigVideo,
  getCountFilesByParam,
  maxPhotoAllowed,
  maxVideoAllowed,
  maxPackPhotoAllowed,
  maxPackVideoAllowed,
  updateList,
} from "../../libs/upload";
import "./uploadHandleContent.css";

export const UploadHandleContent = ({
  handleChangeAdd,
  removeSelection,
  selectedType,
  filesPreview = [],
  choosePrevFile,
  choosePortadaFile,
  confirmSubmitUpload,
  alertFinalConfirm,
  alertRestrictionsContent,
}) => {
  const disabledByContent = () => {
    let countPhoto = getCountFilesByParam(filesPreview, "image");
    let countVideo = getCountFilesByParam(filesPreview, "video");
    let allVideos = filesPreview.every((fp) => fp.type == "video");
    let noPortadaFile = filesPreview.every((fp) => fp.portadaFile == false);
    let someBigVideo = existenceBigVideo(filesPreview);
    if (selectedType) {
      if (selectedType.id === 0) {
        return countPhoto > maxPhotoAllowed || noPortadaFile;
      } else if (selectedType.id === 1) {
        return someBigVideo || countVideo > maxVideoAllowed;
      } else {
        return (
          allVideos ||
          someBigVideo ||
          countPhoto > maxPackPhotoAllowed ||
          countVideo > maxPackVideoAllowed ||
          noPortadaFile
        );
      }
    }
  };

  const showUploadTime = () => {
    let total = 0;
    let uploadMegas = 0;
    let uploadTime = 0;
    let diminutiveTime = "min";
    const uploadRate = 2;

    if (filesPreview != undefined) {
      filesPreview.forEach(function (elem) {
        total += elem.size;
      });

      uploadMegas = parseFloat(total).toFixed(2);
      uploadTime = uploadMegas / uploadRate;

      if (uploadTime < 1) {
        uploadTime = (uploadMegas / uploadRate) * 60;
        diminutiveTime = "seg";
      }
    }

    return parseFloat(uploadTime).toFixed(2) + " " + diminutiveTime;
  };

  const selectMultipleFiles = () => {
    let ua = navigator.userAgent.toLowerCase();
    console.log("let",ua);
    if ((ua.indexOf("safari") != -1)||((ua.indexOf("Safari") != -1))) {
      if ((ua.indexOf("chrome") > -1) || ((ua.indexOf("Chrome") > -1) || (ua.indexOf("Firefox") > -1))) {
        // Chrome
        //console.log("Chrome");
        return true;
      } else {
        // Safari
        //console.log("SAFARI");
        return false;
      }
    }
  };

  return (
    <div>
      <div className="upload--content--main--container--body--2">
        <div className="upload--content--main--container--body--2--icons">
          <UploadEditOption title="Primera Foto" icon={fireIcon} />
          <UploadFilesAdd
            handleChangeAdd={handleChangeAdd}
            multiple={selectMultipleFiles()}
            //multiple={true}
            selectedType={selectedType}
            icon={uploadIcon1}
            title="Subir"
          />
          <div className="upload--content--main--container-delete">
            {filesPreview != undefined ? (
              filesPreview?.length > 0 ? (
              <IconButton aria-label="delete"
               onClick={removeSelection}>
                <DeleteIcon 
                    alt="Eliminar Contenido"
                    />
              </IconButton>
                // <img
                //   src={trashIcon}
                //   alt="Eliminar Contenido"
                //   onClick={removeSelection}
                // />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="upload--content--main--container--body--2--grid">
          {filesPreview != undefined || filesPreview != null ? (
            filesPreview.map((f, i) => (
              <UploadImagePreview
                key={i}
                file={f}
                index={i}
                choosePrevFile={choosePrevFile}
                choosePortadaFile={choosePortadaFile}
              />
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="upload--content--main--container--body--2--alert margin--button--div">
          {alertFinalConfirm}
        </div>
        {/* Dejamos sin efecto el calculo de subida de tiempo */}
        {/* <div className="upload--content--footer--time">
          <img src={timeIcon} alt="..." />
          <div>Tiempo estimado: {showUploadTime()}</div>
        </div> */}
        {/* <div className="upload--content--main--container--body--3--content">
          {alertRestrictionsContent}
        </div> */}
        <div className="upload--content--main--container--body--2--confirm">
          <CustomButtonRegister
            disabled={!filesPreview.length > 0}
            onClick={confirmSubmitUpload}
          >
            CONFIRMAR
          </CustomButtonRegister>
        </div>
      </div>
    </div>
  );
};
