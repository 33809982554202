import { setHeaders } from "./fetch";
import { getIdUser } from "./login";

/* eslint-disable */
export const initialStateUpload = {
  type: "",
  title: "",
  description: "",
  price: "5",
};

export const createUploadServerPhoto = (data, files, portadaFileName) => {
  const formDataServer = new FormData();
  if (files != undefined) {
    formDataServer.append("cover", portadaFileName);
    for (let i = 0; i < files?.length; i++) {
      formDataServer.append("file" + [i], files[i]);
    }
    formDataServer.append("files", files?.length);
  } else {
    formDataServer.append("files", 0);
  }
  formDataServer.append("description", data.description);
  return formDataServer;
};

export const createUploadServerVideo = (data, files) => {
  const formDataServer = new FormData();

  if (files != undefined) {
    for (let i = 0; i < files?.length; i++) {
      formDataServer.append("file" + [i], files[i]);
    }
    formDataServer.append("files", files?.length);
  } else {
    formDataServer.append("files", 0);
  }

  formDataServer.append("description", data.description);
  return formDataServer;
};

export const countImagesFiles = (files) => {
  if (!files) {
    return 0;
  }
  let arrayImages = [];
  for (let i = 0; i < files?.length; i++) {
    if (files[i].type.includes("image")) {
      arrayImages.push(files[i]);
    }
  }
  const count = arrayImages.length;
  // console.log("COUNT IMAGES: ", count);
  return count;
};

const getSelectedIds = (list) => {
  console.log(list);
  const newList1 = list.filter((x) => x.selected == true);
  const newList2 = newList1.map((x) => x.id_tag);
  return newList2;
};

export const createUploadServerPack = (
  data,
  packToysState,
  files,
  portadaFileName,
  id_trade_agreement
) => {
  const formDataServer = new FormData();
  let idToys = [];
  let dataTitle = "";
  let dataDescription = "";
  let dataPrice = 0;
  let countImage = 0;
  let countVideo = 0;

  if (packToysState != undefined) {
    idToys = getSelectedIds(packToysState);
  }

  if (data != undefined) {
    dataTitle = data.title;
    dataDescription = data.description;
    dataPrice = data.price;
  }

  if (files != undefined && portadaFileName != null) {
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes("image")) {
        if (files[i]?.name == portadaFileName) {
          formDataServer.append("thumbnail", files[i]);
        }
        formDataServer.append("image" + [countImage], files[i]);
        countImage++;
      } else if (files[i].type.includes("video")) {
        const videoSize = files[i].size / 1024;
        if (videoSize > process.env.REACT_APP_STATS_MAX_SIZE) {
          return null;
        } else {
          formDataServer.append("video" + [countVideo], files[i]);
          countVideo++;
        }
      }
    }
  }

  formDataServer.append("images", countImage);
  formDataServer.append("videos", countVideo);
  formDataServer.append("title", dataTitle);
  formDataServer.append("description", dataDescription);
  formDataServer.append("price", dataPrice);
  formDataServer.append("tags", idToys);
  formDataServer.append("id_agreement_commercial", id_trade_agreement);

  return formDataServer;
};

export const incompleteDataUpload = ({ type, title, description, price }) => {
  if (type === "") {
    return true;
  } else {
    if (type === "" || title === "" || description === "" || type === "") {
      return true;
    } else {
      return false;
    }
  }
};

export const getFile = (e) => e.target.files[0];

export const appendFiles = (json, files) => {
  const newState = {
    ...json,
    file1: files.file1,
    file2: files.file2,
  };
  return newState;
};

export const appendFile = (json, file) => {
  const newState = {
    ...json,
    file3: file,
  };
  return newState;
};

export const updateList = (list, id) => {
  const newList = list.map((x) => {
    if (x.id_tag === id) {
      return {
        ...x,
        selected: !x.selected,
      };
    } else {
      return x;
    }
  });
  return newList;
};
export const reInitialize = (list) => {
  const newList = list.map((x) => {
    return {
      ...x,
      selected: false,
    };
  });
  return newList;
};

export const options = [
  { id: 0, description: "Fotos" },
  { id: 1, description: "Video" },
  { id: 2, description: "Pack" },
];

export const getFiles = (e) => e.target.files;

export const getLength = (list) => {
  let size = 0;
  if (list != undefined) {
    size = list.length;
  }
  return size;
};

export const getExtensions = (files) => {
  let extensions = [];
  if (files != undefined) {
    const length = getLength(files);
    for (let i = 0; i < length; i++) {
      const extension = files[i].type;
      extensions.push(extension);
    }
  }
  return extensions;
};

export const hasExtension = (list, param) => {
  return list.some((x) => x.includes(param));
};

export const blockedPackSubmit = (data, packToysState, files) => {
  return (
    data.type === "" ||
    data.title === "" ||
    data.description === "" ||
    // packToysState.every((x) => !x.selected) ||
    data.price === "" ||
    files === null
  );
};

export const blockedPhotoSubmit = (data, files) => {
  if (data.type === "" || data.description === "" || files === null) {
    return true;
  } else {
    return false;
  }
};

export const blockedVideoSubmit = (data, files) => {
  return data.title === "" || data.description === "" || !files;
};

export const updateEndpoint = (path) =>
  `${process.env.REACT_APP_UPLOAD}/upload/content/${path}`;

export const alertWarning = () =>
  alert(
    "Porfavor completa los campos en pantalla y adjunta los archivos correspondientes"
  );

export const removeFiles = (files, filesPreview) => {
  let newFiles = [];
  let newFilesPreview = [];
  const lengthFilePreview = filesPreview.length;

  if (lengthFilePreview > 0) {
    for (let i = 0; i <= lengthFilePreview - 1; i++) {
      if (filesPreview[i].selectedFile === false) {
        newFiles.push(files[i]);
        newFilesPreview.push(filesPreview[i]);
        /* ****************************** */
        // console.log(`FILE ${i}`, files[i]);
        // console.log(`PREVIEW ${i}`, filesPreview[i]);
        //console.log(`PREVIEW ${i} Size`, (filesPreview[i].size / 1024));
        /* ****************************** */
      }
    }
  }

  const jsonResult = {
    newFiles: newFiles,
    newFilesPreview: newFilesPreview,
  };

  return jsonResult;
};

export const updatePrevs = (filesPreview, index) => {
  const list = filesPreview.map((x, i) => {
    if (i === index) {
      return {
        ...x,
        selectedFile: !x.selectedFile,
      };
    } else {
      return x;
    }
  });
  return list;
};

export const updatePortada = (filesPreview, name) => {
  const list = filesPreview.map((fp) => {
    if (fp?.name == name || fp.file == name) {
      return {
        ...fp,
        portadaFile: !fp.portadaFile,
      };
    } else {
      return {
        ...fp,
        portadaFile: false,
      };
    }
  });
  // console.log(("ELEGIR PORTADA: ", list));
  return list;
};

export const updatePreviews = (filesPreview, newFiles) => {
  const newPreviews = createPreviews(newFiles, 1);
  const finalPreviews = [...filesPreview, ...newPreviews];
  return finalPreviews;
};

export const createPreviews = (files, step) => {
  // console.log("FILES CREATE: ", files);
  let previewFileList = [];
  const length = getLength(files);
  for (let i = 0; i <= length - 1; i++) {
    let myFile = files[i];
    //console.log(`POSITION ${i}:`, myFile);
    const size = myFile?.size / 1024 / 1024;
    const type = myFile?.type.includes("image") ? "image" : "video";
    const currentFile = URL.createObjectURL(myFile);
    myFile = {
      ...myFile,
      file: currentFile,
      type: type,
      size: size,
      selectedFile: false,
      name: myFile?.name ? myFile?.name : `unknoun ${+i}`,
      portadaFile: step === 0 && i < 1,
    };
    previewFileList.push(myFile);
  }
  return previewFileList;
};

export const successUploadMessage =
  "El contenido se ha subido correctamente a Hotmy.";

export const failureUploadMessage = "Error 02: Intente mas tarde por favor.";

export const handlePreview = (files, state) => {
  let newState = state;
  if (files[0]) {
    try {
      const currentFile = URL.createObjectURL(files[0]);
      newState = { ...state, src: currentFile };
      return newState;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getDataFiles = (files) => {
  let countImages = 0;
  let countVideos = 0;

  const length = files?.length;
  for (let i = 0; i < length; i++) {
    if (files[i].type.includes("image")) {
      countImages++;
    } else {
      countVideos++;
    }
  }
  return { countImages, countVideos };
};

export const handleInitialFiles = (e, files) => {
  if (e.target.files) {
    const newFiles = e.target.files;
    const lenNewFiles = newFiles.length;
    if (!files) {
      let list = new DataTransfer();
      for (let i = 0; i <= lenNewFiles - 1; i++) {
        const newFile = newFiles[i];
        list.items.add(newFile);
      }
      let myFileList = list.files;
      return myFileList;
    } else {
      let list = new DataTransfer();
      const myOldFiles = files;
      const lenOldFiles = files.length;
      for (let i = 0; i < lenOldFiles; i++) {
        const oldFile = myOldFiles[i];
        list.items.add(oldFile);
      }
      for (let i = 0; i <= lenNewFiles - 1; i++) {
        const newFile = newFiles[i];
        list.items.add(newFile);
      }
      let myFileList = list.files;
      return myFileList;
    }
  } else {
    return null;
  }
};

export const handleAddInitialFiles = (e) => {
  const newFiles = e.target.files;
  const lenNewFiles = newFiles.length;
  let list = new DataTransfer();
  for (let i = 0; i < lenNewFiles; i++) {
    const newFile = newFiles[i];
    list.items.add(newFile);
  }
  let myFileList = list.files;
  return myFileList;
};

// MINIMO Y MAXIMO DE FOTOS
export const minPhotoAllowed = process.env.REACT_APP_STATS_PHOTO_COUNT_MIN;
export const maxPhotoAllowed = process.env.REACT_APP_STATS_PHOTO_COUNT_MAX;

// MINIMO Y MAXIMO DE VIDEOS
export const minVideoAllowed = process.env.REACT_APP_STATS_VIDEO_COUNT_MIN;
export const maxVideoAllowed = process.env.REACT_APP_STATS_VIDEO_COUNT_MAX;

// MINIMO Y MAXIMO DE FOTOS Y VIDEOS EN PACKS
export const minPackPhotoAllowed = process.env.REACT_APP_PACK_PHOTO_COUNT_MIN;
export const maxPackPhotoAllowed = process.env.REACT_APP_PACK_PHOTO_COUNT_MAX;
export const minPackVideoAllowed = process.env.REACT_APP_PACK_VIDEO_COUNT_MIN;
export const maxPackVideoAllowed = process.env.REACT_APP_PACK_VIDEO_COUNT_MAX;

//console.log("minPackPhotoAllowed",minPackPhotoAllowed);
//console.log("maxPackPhotoAllowed",maxPackPhotoAllowed);
//console.log("minPackVideoAllowed",minPackVideoAllowed);
//console.log("maxPackVideoAllowed",maxPackVideoAllowed);
// PACKS COMBINATION
  //CANTIDAD DE FOTOS QUE TIENE QUE CARGAR SIN VIDEO 
export const minPackPhotoWithoutVideoAllowed = process.env.REACT_APP_PACK_PHOTO_WITHOUT_VIDEO;
//# CANTIDAD DE FOTOS QUE TIENE QUE CARGAR CON VIDEO MAYOR A 2
export const minPackPhotoWithVideoMayorTwo = process.env.REACT_APP_PACK_PHOTO_WITH_VIDEO_MAYOR_2;
// CANTIDAD DE FOTOS QUE TIENE QUE CARGAR CON VIDEO MAYOR A 0
export const minPackPhotoWithVideoMayorZero = process.env.REACT_APP_PACK_PHOTO_WITH_VIDEO_1;
//console.log("minPackPhotoWithVideoMayorZero",minPackPhotoWithVideoMayorZero);
//console.log("minPackPhotoWithVideoMayorTwo",minPackPhotoWithVideoMayorTwo);
//console.log("minPackPhotoWithoutVideoAllowed",minPackPhotoWithoutVideoAllowed);
// MINIMO Y MAXIMO PERMITIDO PARA EL PRECIO DE UN PACK
export const minPackPrice = process.env.REACT_APP_PACK_PRICE_MIN;
export const maxPackPrice = process.env.REACT_APP_PACK_PRICE_MAX;

//export const maxVideopackAllowed = process.env.REACT_APP_STATS_VIDEO_COUNT_MAX_PACK;

export const alertPhotoMessage = `Cantidad máxima permitida ${maxPhotoAllowed} fotos.`;

export const alertPhotoWithoutVideoMessage = `La cantidad mínima de fotos , en un pack sin videos, es de ${minPackPhotoWithoutVideoAllowed} fotos .`; 
export const alertPhotoWithVideoMayorTwoMessage = `La cantidad mínima de fotos en un pack con 2 o mas videos es de ${minPackPhotoWithVideoMayorTwo} fotos.`; 
export const alertPhotoWithVideoMayorZero = `La cantidad mínima de fotos en un pack con 1 video es de ${minPackPhotoWithVideoMayorZero} fotos.`; 

export const alertVideoMessage = `Cantidad máxima permitida ${maxVideoAllowed} videos.`; //DEFINIR
 
export const alertPackMessage = `Cantidad minima es de fotos es ${minPackPhotoWithVideoMayorZero} y ${minPackVideoAllowed} videos.`;

export const alertMessagePack = `Recuerde completar todos los campos por favor. No olvide que el precio mínimo del pack es de USD ${minPackPrice} y máximo USD ${maxPackPrice}.`;

export const getCountFilesByParam = (list, param) => {
  const countFiles = list.filter((x) => x.type == param);
  return countFiles.length;
};

export const existenceBigVideo = (list) => {
  const max_size_megabytes = process.env.REACT_APP_STATS_MAX_SIZE / 1024;
  // Verificamos si existe algun archivo que exceda el maximo permitido en megas
  let someBigVideo = list.some((fp) => {
    return fp.type == "video" && fp.size > max_size_megabytes;
  });
  return someBigVideo;
};

/* ************************************************************* */
const mapActiveTrades = (list) => {
  const defaultOption = {
    ds_full_name: "Sin acuerdo comercial",
    ds_nick_name: "",
    ds_type_of_role: "",
    id_collaboration: null,
    id_trade_agreement: null,
    id_type_of_rol: -1,
    id_user: -1,
    status: -1,
    vl_rate: 0,
  };
  let finalList = [...list, defaultOption];
  finalList = finalList.reverse();
  return finalList;
};
/* ************************************************************* */
const getValidationPacks = async () => {
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/tradeAgreement/packs`;
  try {
    const res = await fetch(url, {
      headers: setHeaders(),
    });
    if (res.status === 200) {
      const response = await res.json();
      if (response.success === true) {
        return mapActiveTrades(response.data.trades_active);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getValidationUser = async () => {
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/tradeAgreement/validate`;
  try {
    const res = await fetch(url, {
      headers: setHeaders(),
    });
    if (res.status === 200) {
      const response = await res.json();
      if (response.code === 200 && response.data.status === true) {
        const responsePacks = await getValidationPacks();
        return responsePacks;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
