import React from "react";
import expandIcon from "../../icons/darkIcons/expand-icon.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { CustomInputPriceSuscription } from "../../commonComponents/customInputPriceSuscription/customInputPriceSuscription";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../accordionSets/accordionSets";
import "./accordionSubscriptions.css";
import { useTranslation } from "react-i18next";

export const AccordionSubscriptions = ({
	panel,
	expanded,
	handleChange,
	// loading,
	suscriptions,
	subExpanded,
	handleSubChange,
	handleChangeSubscription,
	subscriptionMessages,
	tax,
}) => {
	const { t } = useTranslation("global");
	return (
		<Accordion
			square
			expanded={expanded === panel}
			onChange={handleChange(panel)}
		>
			<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
				<AccordionHeader
					panel={panel}
					expanded={expanded}
					title={t("editProfile.topic.subscriptions")}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<div className="edit--options--preferences--suscriptions">
				<div className="edit--options--preferences--suscriptions--important">
						Recomendamos dejar los valores predeterminados para lograr mayores ventas
				</div>
					<div className="edit--options--preferences--suscriptions--list">
						{suscriptions.map((sl) => {
							return (
								<Accordion
									key={sl.id}
									// square
									expanded={subExpanded === sl.name}
									onChange={handleSubChange(sl.name)}
								>
									<AccordionSummary
										aria-controls="panel2d-content"
										id="panel2d-header"
									>
										<div
											className={`header--sub--accordion--title ${
												subExpanded === sl.name
													? "expanded--sub--accordion"
													: ""
											}`}
										>
											<div className="header--sub--accordion--title--count">
												{sl.headerTitleCount}
											</div>
											<DividerSpace space="XS" />
											<div className="header--sub--accordion--title--text">
												{sl.headerTitleText}
											</div>
											<img
												src={expandIcon}
												alt="..."
												className="header--sub--accordion--title--icon"
											/>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div className="edit--options--preferences--suscriptions--list--item">
											<CustomInputPriceSuscription
												title={sl.title}
												publicTitle={sl.publicTitle}
												placeholder="..."
												name={sl.name}
												value={sl.vl_price}
												discount={sl.discount}
												tax={sl.vl_tax}
												ds_currency={sl.ds_currency}
												vl_conversion_rate={sl.vl_conversion_rate}
												onChange={handleChangeSubscription}
												type="text"
												icon={true}
												colored={false}
												disabled={false}
												maxLength={4}
											/>
										</div>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>
					<div className="edit--options--preferences--suscriptions--important">
						IMPORTANTE
					</div>
					<div className="edit--options--preferences--suscriptions--messages">
						{subscriptionMessages.map((element) => {
							return (
								<div
									key={element.id}
									className="edit--options--preferences--suscriptions--messages--item"
								>
									<div className="edit--options--preferences--suscriptions--messages--item--title">
										{element.title}
									</div>
									<div className="edit--options--preferences--suscriptions--messages--item--description">
										{element.description}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
