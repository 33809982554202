import React from "react";
import "./contentTypeProfile.css";

export const ContentTypeProfile = ({ icon, value }) => {
	return (
		<div className="home--main--container--hotmers--element--mask--item">
			<img
				className="home--main--container--hotmers--element--mask--item--image--non--click"
				alt="..."
				src={icon}
			/>
			<div className="home--main--container--hotmers--element--mask--item--value">
				{value}
			</div>
		</div>
	);
};
