import { useTranslation } from "react-i18next";
import "./NoContent.css";

export const NoContent = () => {
  const [t] = useTranslation("global");

  return (
    <div className="reload--main--container--error">
      {t("noMoreContent.title")}
    </div>
  );
};
