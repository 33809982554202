/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import editIcon from "../../icons/darkIcons/edit-icon.png";
import StyledDemo from "../../components/imageCrop/index";
import profileDefault from "../../icons/profile/profile-default-img.png";
import profileDefaultCover from "../../icons/profile/profile-default-cover.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
//import { UploadFilesAdd } from "../../commonComponents/uploadFilesAdd/uploadFilesAdd";
import { UploadFilesProfilePortrait } from "../../commonComponents/uploadFilesProfilePortrait/uploadFilesProfilePortrait";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { setScreenPosition } from "../../redux/actions";
import { EditProfileOption } from "../../components/EditProfileOption/EditProfileOption";
import { AccordionPreferences } from "../../components/accordionPreferences/accordionPreferences";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { useSelector, useDispatch } from "react-redux";
import { AccordionPersonalDataUser } from "../../components/accordionPersonalDataUser/accordionPersonalDataUser";
import { setFormDataHeaders, setHeaders } from "../../libs/fetch";
import { mapInitPreferences, updateOptionList } from "../../libs/preferences";
import { useTranslation } from "react-i18next";
import {
  handleImage,
  createVisitorFormDataUpdate,
  updateVisitorLsImage,
} from "../../libs/editProfile";
import "./editProfileVisitor.css";

export const EditProfileVisitor = () => {
  const state = useSelector((state) => state.main);
  const { loadingPreferencesGenders } = state;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  let myUser;
  try {
    myUser = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const [modalCrop, setModalCrop] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [userData, setUserData] = useState(null);
  const [modalIcon, setModalIcon] = useState(null);
  const [modalMessageResult, setModalMessageResult] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  const [openModalMessageResult, setOpenModalMessageResult] = useState(false);
  const [modalMessageData, setModalMessageData] = useState("");
  const [modalIncompleteData, setModalIncompleteData] = useState(false);
  const [loadingPrefenrences, setLoadingPrefenrences] = useState(false);
  const [editPreferences, setEditPreferences] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profile, setProfile] = useState(null);
  const [personalData, setPersonalData] = useState({ ds_display_name: "" });

  const onErrorProfile = () => {
    if (!profile.errored)
      setProfile({ src: opsIcon, errored: true, changed: false });
  };

  const handleProfileImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setModalCrop(true);
      const f = e.target.files[0];
      let imageDataUrl = await readFile(f);
      setNewFile(f);
      const prevF = URL.createObjectURL(f);
      setProfile((actual) => {
        return { ...profile, src: actual.src, changed: false };
      });
      setProfileFile(null);
      setImageSrc(imageDataUrl);
    }
  };

  const onChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });

  const handleChangeCheck = (e) =>
    setEditPreferences(updateOptionList(e, editPreferences));

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  const getGendersAndInit = async (list) => {
    setLoadingPrefenrences(true);
    const url = `${process.env.REACT_APP_INITIAL_V2}/gender`;
    try {
      const res = await fetch(url);
      const response = await res.json();
      if (response.code == 200) {
        setEditPreferences(mapInitPreferences(list, response.data));
      }
      setLoadingPrefenrences(false);
    } catch (error) {
      setLoadingPrefenrences(false);
      console.log("ERROR: ", error);
    }
  };

  const getProfileData = async () => {
    setLoadingData(true);
    try {
      const idUser = JSON.parse(localStorage.getItem("session")).user.id;
      const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/configuration`;
      const res = await fetch(url, { headers: setHeaders() });
      if (res.status === 200) {
        const response = await res.json();
        console.log("EDIT USER DATA: ", response);
        setSelectedType({ id: 0, description: "Fotos" });
        setUserData(response.data);
        // console.log("EDIT USER INFORMATION: ", response.data);
        setProfile({
          src: response.data.ds_url_img_profile || profileDefault,
          errored: false,
          changed: false,
        });
        getGendersAndInit(response.data.gender_preference);
        setPersonalData({ ds_display_name: response.data.ds_display_name });
      } else {
      }
      setLoadingData(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setLoadingData(false);
      setProfile({ src: profileDefault, errored: false, changed: false });
    }
  };

  const processUpdate = async (
    profile,
    profileFile,
    userData,
    editPreferences,
    personalData
  ) => {
    const { result, idPreferences } = createVisitorFormDataUpdate(
      profile,
      profileFile,
      userData,
      editPreferences,
      personalData
    );
    if (!result) {
      localStorage.setItem("idUrl", 0);
      dispatch(setScreenPosition(0));
      history.push("/");
    } else if (idPreferences.length == 0) {
      setModalIncompleteData(true);
      setModalMessageData("Debe seleccionar al menos una preferencia.");
    } else if (personalData && personalData.ds_display_name.length == 0) {
      setModalIncompleteData(true);
      setModalMessageData("Debe completar el campo 'ALIAS o NickName'.");
    } else {
      setLoadingData(true);
      try {
        const id = getIdUser();
        const url = `${process.env.REACT_APP_INITIAL_V2}/users/${id}/profile`;
        const res = await axios.post(url, result, {
          headers: setFormDataHeaders(),
        });
        if (res.status === 201) {
          if (res.data.code === 201) {
            setLoadingData(false);
            updateVisitorLsImage(res, null, personalData);
            localStorage.setItem("idUrl", 0);
            dispatch(setScreenPosition(0));
            history.push("/");
          } else {
            setAlertError(
              "Error 200: No se pudo actualizar el perfil, intente mas tarde, si el error persiste comuniquese con support@hotmy.net.",
              "Incorrect Code"
            );
          }
        } else {
          setAlertError(
            "Error 100: Intente mas tarde por favor.",
            "Incorrect Status"
          );
        }
      } catch (error) {
        setAlertError("Error 01: Intente mas tarde por favor.", error);
      }
    }
    setLoadingData(false);
  };

  const processEditProfile = async (
    profile,
    profileFile,
    userData,
    editPreferences,
    personalData
  ) => {
    const { result } = createVisitorFormDataUpdate(
      profile,
      profileFile,
      userData,
      editPreferences,
      personalData
    );
    if (!result) {
    } else {
      try {
        const id = getIdUser();
        const url = `${process.env.REACT_APP_INITIAL_V2}/users/${id}/profile`;
        const res = await axios.post(url, result, {
          headers: setFormDataHeaders(),
        });
        if (res.status === 201) {
          if (res.data.code === 201) {
            updateVisitorLsImage(res, profile, null);
          } else {
            setAlertError(
              "Error 200: No se pudo actualizar el perfil, intente mas tarde, si el error persiste comuniquese con support@hotmy.net.",
              "Incorrect Code"
            );
          }
        } else {
          setAlertError(
            "Error 100: Intente mas tarde por favor.",
            "Incorrect Status"
          );
        }
      } catch (error) {
        setAlertError("Error 01: Intente mas tarde por favor.", error);
      }
    }
  };

  const setAlertError = (message, msgError) => {
    setModalIcon(opsIcon);
    setModalMessageResult(message);
    setModalMessageButtonText("ACEPTAR");
    console.log(msgError);
    setOpenModalMessageResult(true);
  };

  const acceptClick = () => setOpenModalMessageResult(false);
  const onClickRefresh = () => getProfileData();
  const refreshGenders = () => getGendersAndInit(userData.gender_preference);
  const setImageFromCrop = (f, cropImage) => {
    setProfile({ ...profile, src: cropImage, changed: true });
    setProfileFile(cropImage);
    processEditProfile(
      { src: cropImage, changed: true },
      cropImage,
      null,
      null,
      null
    );
  };

  const closeCropImage = () => {
    setProfile((actualImage) => {
      return { ...profile, src: actualImage.src, changed: false };
    });
    setProfileFile(null);
    setModalCrop(false);
  };

  const handleChangePersonalData = (e) => {
    setPersonalData({ ...personalData, [e.target.name]: e.target.value });
  };

  const onClickCancel = () => {
    dispatch(setScreenPosition(0));
    localStorage.setItem("idUrl", 0);
    history.push("/");
  };

  const confirm = () => {
    processUpdate(null, null, userData, editPreferences, personalData);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  if (loadingData) {
    return <LoadingScreen loading={loadingData} />;
  } else if (!userData && !loadingData) {
    return <WrongFetchScreen onClickRefresh={onClickRefresh} />;
  } else {
    return (
      <div className="edit--profile--visitor--main--container">
        <div className="edit--profile--visitor--main--container--cancel">
          <EditProfileOption onClick={onClickCancel}>
            <CloseIcon />
          </EditProfileOption>
        </div>
        <div className="edit--profile--visitor--main--container--accept">
          <EditProfileOption onClick={confirm}>
            <CheckIcon />
          </EditProfileOption>
        </div>
        <div className="edit--profile--visitor--main--container--header">
          <div className="edit--profile--visitor--main--container--header--title">
            {t("editProfile.topic.editProfile")}
          </div>
          <StyledDemo
            initialTypeCrop={"profile"}
            setImageFromCrop={setImageFromCrop}
            modalCrop={modalCrop}
            setModalCrop={setModalCrop}
            newFile={newFile}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            closeCropImage={closeCropImage}
          />
          <div className="edit--profile--visitor--main--container--header--float">
            <div className="edit--profile--visitor--main--container--header--float--img">
              <img
                src={profile ? profile.src : profileDefault}
                onError={onErrorProfile}
                alt="..."
              />
              <div className="edit--profile--visitor--main--container--header--float--img--edit">
                {/* <UploadFilesAdd
                  handleChangeAdd={handleProfileImage}
                  selectedType={selectedType}
                  multiple={false}
                  disabled={false}
                  icon={editIcon}
                /> */}
                <UploadFilesProfilePortrait
                  handleChangeAdd={handleProfileImage}
                  selectedType={selectedType}
                  multiple={false}
                  disabled={false}
                  icon={editIcon}
                /> 

              </div>
            </div>
          </div>
          <div className="edit--profile--visitor--main--container--header--body">
            <img
              className="edit--profile--visitor--main--container--header--body--cover"
              src={profileDefaultCover}
              alt="..."
            />
          </div>
        </div>
        <CustomModal
          openModal={openModalMessageResult}
          setOpenModal={setOpenModalMessageResult}
          discardExternalEvents={true}
        >
          <OptionTryAgain
            icon={modalIcon}
            acceptClick={acceptClick}
            discardExternalEvents={true}
            title={modalMessageResult}
            buttonText={modalMessageButtonText}
            whatsApp={true}
          />
        </CustomModal>
        <CustomModal
          openModal={modalIncompleteData}
          setOpenModal={setModalIncompleteData}
          discardExternalEvents={false}
        >
          <MessageIncompleteData message={modalMessageData} />
        </CustomModal>
        <AccordionPreferences
          panel={"panel1"}
          expanded={expanded}
          handleChange={handleChange}
          // loading={loadingPreferencesGenders}
          editPreferences={editPreferences}
          handleChangeCheck={handleChangeCheck}
          loading={loadingPrefenrences}
          onClickRefreshGenders={refreshGenders}
        />
        <AccordionPersonalDataUser
          panel={"panel2"}
          expanded={expanded}
          handleChange={handleChange}
          personalData={personalData}
          handleChangePersonalData={handleChangePersonalData}
        />
      </div>
    );
  }
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
