import React from "react";
import ops1Image from "../../icons/fetch/ops-image1.png";
import lupaIcon from "../../icons/darkIcons/lupa-icon.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./opsBigFire.css";

export const OpsBigFire = ({ title, description, onClick }) => {
  return (
    <div className="ops--big--fire--main--container">
      <DividerSpace space="M" />
      <div className="ops--big--fire--main--container--image">
        <img src={ops1Image} alt="..." />
      </div>
      <DividerSpace space="XS" />
      <div className="ops--big--fire--main--container--title">{title}</div>
      <DividerSpace space="L" />
      <div className="ops--big--fire--main--container--search">
        <LupaOption description={description} onClick={onClick} />
      </div>
    </div>
  );
};

const LupaOption = ({ description, onClick }) => {
  return (
    <div className="lupa--option--main--container" onClick={onClick}>
      <div className="lupa--option--main--container--icon">
        <img src={lupaIcon} alt="..." />
      </div>
      <div className="lupa--option--main--container--description">
        {description}
      </div>
    </div>
  );
};
