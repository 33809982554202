import { useState } from 'react';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Card } from "@material-ui/core";
import { Swiper, SwiperSlide } from 'swiper/react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router";
import { PackPrice } from '../../components/PackPrice/PackPrice';
import { PackContentSummary } from '../../components/PackContentSummary/PackContentSummary';
import { PackAvatarCreator } from '../../components/PackAvatarCreator/PackAvatarCreator'
import { PackTags } from "../../components/packTags/packTags";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "./ExclusivePacks.css";
import logoHotmyNuevo from "../../../src/icons/a_news/general/logo100px.png"
import { PackExclusive } from '../../interfaces/PackExclusive';

interface exclusivePackProps {
  idPackToShow?: number
  open: boolean
  cerrarExclusivePacks: () => void
  listOfPacks: PackExclusive
}

export const ExclusivePacks = ({
  idPackToShow,
  open,
  cerrarExclusivePacks,
  listOfPacks
}: exclusivePackProps) => {

  const [idPackCurrently, setIdPackCurrently] = useState<number>(null);
  const [idHotmerCurrently, setIdHotmerCurrently] = useState<number>(null);

  const handleSlideChange = (index: number) => {
    setIdPackCurrently(listOfPacks?.data[index]?.id_pack);
    setIdHotmerCurrently(listOfPacks?.data[index]?.id_hotmer);
  };

  const history = useHistory();

  const clickBuyPack = (idCreator: number, idPack: number) => {
    history.push(`/packDetail/${idCreator}/${idPack}`);
  }

  return (
    <BottomSheet
      open={open}
      onDismiss={cerrarExclusivePacks}
      blocking={true}
      footer={
        <div className='button--buy--conteiner'>
          <div className="mask--button--freeAccess"
            onClick={() => clickBuyPack(idHotmerCurrently, idPackCurrently)}
          >
            <Typography
              style={{
                margin: "0.5rem 0",
                color: 'white',
                fontSize: '1.2rem',
                letterSpacing: '1px',
              }}>
              COMPRAR
            </Typography>
          </div>
        </div>
      }
    >
      <>
        <div className="background-conteiner-exclusive-promo--conteiner">
          <Card
            className='card-exclusive-packs-conteiner background-conteiner-exclusive-promo-part-card'
            style={{
              background: 'transparent'
            }}>
            <Swiper
              onSlideChange={(swiper) => handleSlideChange(swiper.realIndex)}
              initialSlide={idPackToShow}
              className="swiper backgrount img-fluid-2"
              id='swipper-exclusive-promotion'
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              // nextbutton='.swiper-button-next'
              // prevbutton='.swiper-button-prev'
              centeredSlides={true}
              spaceBetween={30}
              slidesPerView={1}
              // virtual
              navigation
              loop={true}
              autoplay={{
                "delay": 3000,
                "disableOnInteraction": true
              }}
            >
              {listOfPacks?.data?.map((hotmers, index) => (
                <div key={index}>
                  <SwiperSlide
                    className='swiper-slide'
                    key={index}
                    virtualIndex={index}
                  >
                    <PackAvatarCreator
                      arrayCreators={hotmers?.creator_list}
                      showPackPremium={true}
                    />
                    {/* <Card> */}
                    <div className='pack--image--conteiner'>
                      <img
                        key={hotmers.ds_title}
                        src={hotmers.ds_url_thumbnail_pack}
                        alt={hotmers.ds_title}
                        className="img-fluid-2"
                        height='300px'
                        width='100%'
                        loading='lazy'
                      />
                    </div>
                    {/* <div className="swiper-button-next"></div> */}
                    {/* <div className='container-components'> */}
                    {/* <div> */}
                    <PackTags packTags={hotmers?.tags} />
                    <Divider />
                    {/* <div className='grid-hotmer-name-icon'> */}
                    <div className='parent'>
                      <div className='pack--details--conteiner'>
                        <Typography
                          className='pack--details--conteiner--title'
                          style={{
                            fontWeight: 'bold',
                            // fontSize: '1rem'
                          }}>
                          {hotmers.ds_title}
                        </Typography>
                        <Typography className='pack--details--conteiner--subtitle'
                          style={{
                            fontSize: '0.8rem',
                            letterSpacing: '1.5px'
                          }}>
                          {hotmers.ds_description}
                        </Typography>
                      </div>
                      <PackPrice
                        discount={hotmers?.vl_discount}
                        haveDiscount={hotmers?.vl_discount !== 0}
                        price={hotmers?.vl_price}
                      />

                      <div className='pack--content--summary--conteiner--subtitle'>
                        <Typography className='pack--content--summary--conteiner--subtitle--text'>Contenido</Typography>
                        <PackContentSummary
                          quantityPhotos={hotmers?.count_image}
                          quantityVideos={hotmers?.count_video}
                        />
                      </div>

                      <Divider />

                      <div className='hotmy--logo--conteiner'>
                        <img
                          src={logoHotmyNuevo}
                          alt={logoHotmyNuevo}
                        />
                      </div>

                    </div>
                  </SwiperSlide >
                </div>
              ))}
            </Swiper>
          </Card>
        </div>
      </>
      <div className='background-conteiner-exclusive-promo-part2'>
      </div>
    </BottomSheet >
  );
};


// const hotmerProfilePromotion =
//   [
//     {
//       "tags": [
//         1,
//         2
//       ],
//       "id_pack": 785,
//       "currency": 4276.8,
//       "ds_title": "Pack",
//       "vl_price": 12.96,
//       "id_hotmer": 8,
//       "count_image": 10,
//       "count_video": 0,
//       "vl_discount": 10,
//       "creator_list": [
//         {
//           "id_creator": 20,
//           "ds_nick_name": "rocio",
//           "ds_profile_photo_thumbnail_path": "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//         },
//         {
//           "id_creator": 18360,
//           "ds_nick_name": "luna",
//           "ds_profile_photo_thumbnail_path": "https://hmymediaserviceprod.ddns.net/uploads/18360/thumbnail/JDJ5JDEwJHRudUEveENsRGtXSFJHZnlPMHU1RS4xcC5Dei5JeTBBWGd0ajNkMDdidW1Ya1R1SHhIUnlh.jpg"
//         }
//       ],
//       "ds_description": "Me gusta jugar con vos",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//       "tags": [
//         1,
//         2
//       ],
//       "creator_list": [
//         {
//           "id_creator": 78,
//           "ds_nick_name": "Carla la perversa",
//           "ds_profile_photo_thumbnail_path": "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//         }
//       ],
//       "id_pack": 495,
//       "currency": 3564,
//       "ds_title": "444",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 10,
//       "count_video": 0,
//       "vl_discount": 5,
//       "ds_description": "4444",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJENPMG9FclhYeG5XVGczcnZpZjgzMi55VG5GRldTbGs2ZmFDUWQ4R0thcHRjM0ZXUHBzaFcy.jpg"
//     },
//     {
//       "tags": [],
//       "id_pack": 494,
//       "currency": 3564,
//       "ds_title": "borrar",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 12,
//       "count_video": 0,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "carga el tamaño en log",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJEhkYTc5MnY0Z0dISWdQSy5NczcydE9KZ29NSXlGTEFwTVZuUHhwR1R3WGlLWWFjeFJXUy55.jpg"
//     },
//     {
//       "tags": [],
//       "id_pack": 492,
//       "currency": 3564,
//       "ds_title": "a",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 10,
//       "count_video": 0,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "a",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJE5Qc0FyUWlwbnF1bzEuelNDUDZMUE9VbENvWlptcFlSQ0o0T2J5YXd4d2dMVjlOUXNlLjBx.jpg"
//     },
//     {
//       "tags": [
//         3
//       ],
//       "id_pack": 470,
//       "currency": 7128,
//       "ds_title": "sadf",
//       "vl_price": 21.6,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 2,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "asdfdd",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJEZWVGlEZTFSeGExSjkvUHk3SHNKdGV5UFN2T2IvaUtGRWxOT24xSnJFYW1McWEwc1JqYklH.jpg"
//     },
//     {
//       "tags": [],
//       "id_pack": 468,
//       "currency": 3564,
//       "ds_title": "edfg",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 2,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "dsfgdfsg",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJDJTdUZmNVUyTGdObkZTMHo0Mm4vMS5CVWJFWm5RTTRXaUNLTDMyT2NxV3QubUJDRzdpTjBX.jpg"
//     },
//     {
//       "tags": [
//         1
//       ],
//       "id_pack": 467,
//       "currency": 3564,
//       "ds_title": "ee",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 0,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "eee",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJE1WbzBqc1lzenMyeTRNWGx5Nk81Si52eDNKYkxkT1BDcFRGNTd0QVhOQlZ3Ri9Yci9zVGVD.jpg"
//     },
//     {
//       "tags": [
//         1
//       ],
//       "id_pack": 466,
//       "currency": 3564,
//       "ds_title": "wert",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 2,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "wert",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJEhtRFAwRU9uTXF3cndoaEo0RFhmZHVmR1prSUdFTVRna1V3bFU5LlZtWmgxVy9WeFJLeUxx.jpg"
//     },
//     {
//       "tags": [
//         2
//       ],
//       "id_pack": 465,
//       "currency": 3564,
//       "ds_title": "fasdf",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 2,
//       "count_video": 1,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "asdfsadf",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJG1EeTZmWENKTmY5MU9OTVJJTGpjS3VodXZiQkNqbm12dTdjR3FvdlBWSWpaTUZRTEViNVJD.jpg"
//     },
//     {
//       "tags": [
//         2
//       ],
//       "id_pack": 424,
//       "currency": 3564,
//       "ds_title": "Y",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 3,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "Ghk",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJHVHUmROZEppNmc1eDZITEpzcjlKeHVlUk90Q2w5TDc4OTJFY1M5cEtMU1Y3VzI2MTIyU1M2.jpg"
//     },
//     {
//       "tags": [
//         1,
//         2
//       ],
//       "id_pack": 411,
//       "currency": 3564,
//       "ds_title": "Mq2m",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 2,
//       "count_video": 0,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "Mqmwm",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFYuYXkwOTdILkx4NGdmMUZDQ2x2OGU5dWR2NW5IdGNaLzZPUFpwMDRBUExLN3NNdDRjcmIu.jpg"
//     },
//     {
//       "tags": [
//         1,
//         2
//       ],
//       "id_pack": 406,
//       "currency": 3564,
//       "ds_title": "Pruebita",
//       "vl_price": 10.8,
//       "id_hotmer": 8,
//       "count_image": 1,
//       "count_video": 0,
//       "vl_discount": 0,
//       "creator_list": null,
//       "ds_description": "1444",
//       "ds_display_name": "carlamolerok1234",
//       "ds_currency_code": "ARS",
//       "ds_url_img_profile": "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//       "ds_url_thumbnail_pack": "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJHlTZGdpN2kyNGZUOE14eXh5aUZhZi5JeUdTVnRhMWNPMmFGVE9iZUtKMmxiTkE2M09POXp5.jpg"
//     }
//   ]