import React from "react";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";
import "./extractionTitle.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  containerPaper: {
    borderRadius: "10px",
    background: "#393939",
  },
  typographyTitle: {
    padding: "10px 15px",
    color: "#fafafa",
    textAlign: "left",
    fontSize: "18px",
  },
  typographySubTitle: {
    padding: "5px 15px 10px 15px",
    color: "#e6e6e6",
    width: "250px",
    textAlign: "left",
    fontSize: "13px",
  },
}));

export const ExtractionTitle = ({
  title,
  helper,
  icon,
  secondTitle,
  popperTitle,
  popperMessage,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <div className="extraction--title--main--container">
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.containerPaper}>
              <Typography className={classes.typographyTitle}>
                {popperTitle}
              </Typography>
              <Typography className={classes.typographySubTitle}>
                {popperMessage}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div className="extraction--title--main--container--title">{title}</div>
      {helper && (
        <div
          className="extraction--title--main--container--helper"
          onClick={handleClick("bottom-end")}
        >
          {/* <img src={icon} alt="..." /> */}
          <HelpOutlineIcon fontSize="medium" color="action"/>
        </div>
      )}
      {secondTitle && (
        <div className="extraction--title--main--container--second">
          {secondTitle}
        </div>
      )}
    </div>
  );
};
