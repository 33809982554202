import { TYPES } from "./Types";
import { initialState } from "./InitialState";

const firstInitialState = { ...initialState };

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_AGREEMENT_STEPPER_OPTION:
      return {
        ...state,
        agreementStepperOption: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_AGREEMENT_OPTION:
      return {
        ...state,
        agreementOption: action.payload,
      };
    case TYPES.SET_COLLABORATION_OPTION:
      return {
        ...state,
        collaborationOption: action.payload,
      };
    /* ********************************************* */
    case TYPES.REQUEST_LOADING_COLLABORATIONS:
      return {
        ...state,
        loadingCollaborations: true,
        errorCollaborations: false,
        collaborations: null,
      };
    case TYPES.REQUEST_SUCCESS_COLLABORATIONS:
      return {
        ...state,
        loadingCollaborations: false,
        errorCollaborations: false,
        collaborations: action.payload,
      };
    case TYPES.REQUEST_FAILURE_COLLABORATIONS:
      return {
        ...state,
        loadingCollaborations: false,
        errorCollaborations: true,
        collaborations: null,
      };
    /* ********************************************* */
    case TYPES.REQUEST_LOADING_COLLABORATIONS_HOTMERS_ZERO:
      return {
        ...state,
        loadingCollaborationsHotmers: true,
        errorCollaborationsHotmers: false,
        collaborationsHotmers: null,
        // stopFetch: false,
        stopFetch: true,
        offset: 0,
      };
    case TYPES.REQUEST_LOADING_COLLABORATIONS_HOTMERS:
      return {
        ...state,
        loadingCollaborationsHotmers: true,
        errorCollaborationsHotmers: false,
        stopFetch: false,
      };
    case TYPES.REQUEST_SUCCESS_COLLABORATIONS_HOTMERS:
      if (state.collaborationsHotmers) {
        return {
          ...state,
          loadingCollaborationsHotmers: false,
          errorCollaborationsHotmers: false,
          collaborationsHotmers: [
            ...state.collaborationsHotmers,
            ...action.payload,
          ],
          offset: state.offset + state.limit,
          stopFetch: false,
        };
      } else {
        return {
          ...state,
          loadingCollaborationsHotmers: false,
          errorCollaborationsHotmers: false,
          collaborationsHotmers: action.payload,
          offset: state.offset + state.limit,
        };
      }
    case TYPES.REQUEST_FAILURE_COLLABORATIONS_HOTMERS:
      return {
        ...state,
        loadingCollaborationsHotmers: false,
        errorCollaborationsHotmers: true,
        stopFetch: true,
      };
    case TYPES.STOP_FETCH_COLLABORATIONS_HOTMERS:
      return {
        ...state,
        loadingCollaborationsHotmers: false,
        stopFetch: true,
      };
    case TYPES.SET_NAME_PARAM:
      return {
        ...state,
        nameParam: action.payload,
      };
    /* ********************************************* */
    case TYPES.REQUEST_LOADING_AGREEMENTS:
      return {
        ...state,
        loadingAgreements: true,
        errorAgreements: false,
        agreements: null,
      };
    case TYPES.REQUEST_SUCCESS_AGREEMENTS:
      return {
        ...state,
        loadingAgreements: false,
        errorAgreements: false,
        agreements: action.payload,
      };
    case TYPES.REQUEST_FAILURE_AGREEMENTS:
      return {
        ...state,
        loadingAgreements: false,
        errorAgreements: true,
        agreements: null,
      };
    /* ********************************************* */
    case TYPES.REQUEST_LOADING_AGREEMENTS_HOTMERS:
      return {
        ...state,
        loadingAgreementshotmers: true,
        errorAgreementsHotmers: false,
        agreementsHotmers: null,
      };
    case TYPES.REQUEST_SUCCESS_AGREEMENTS_HOTMERS:
      return {
        ...state,
        loadingAgreementshotmers: false,
        errorAgreementsHotmers: false,
        agreementsHotmers: action.payload,
      };
    case TYPES.REQUEST_FAILURE_AGREEMENTS_HOTMERS:
      return {
        ...state,
        loadingAgreementshotmers: false,
        errorAgreementsHotmers: true,
        agreementsHotmers: null,
      };
    /* ********************************************* */
    case TYPES.SET_SELECTED_HOTMERS:
      return {
        ...state,
        selectedHotmers: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_DECREASE_PERCENTAGES:
      return {
        ...state,
        percentages: {
          ...state.percentages,
          [action.payload]: state.percentages[action.payload] - 5,
        },
      };
    case TYPES.SET_INCREASE_PERCENTAGES:
      return {
        ...state,
        percentages: {
          ...state.percentages,
          [action.payload]: state.percentages[action.payload] + 5,
        },
      };
    case TYPES.SET_RESET_PERCENTAGES:
      return {
        ...state,
        percentages: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_SELECTED_AGREEMENT_HOTMER:
      return {
        ...state,
        selectedAgreementHotmer: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_SELECTED_AGREEMENT_HOTMER_OPTION:
      return {
        ...state,
        selectedAgreementHotmerOption: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_SELECTED_AGREEMENT:
      return {
        ...state,
        selectedAgreement: action.payload,
      };
    /* ********************************************* */
    case TYPES.SET_SELECTED_AGREEMENT_DEAL:
      return {
        ...state,
        selectedAgreementDeal: action.payload,
      };
    case TYPES.REQUEST_LOADING_AGREEMENT_DETAIL:
      return {
        ...state,
        loadingAgreementDetail: true,
        errorAgreementDetail: false,
        agreementDetail: null,
      };
    case TYPES.REQUEST_SUCCESS_AGREEMENT_DETAIL:
      return {
        ...state,
        loadingAgreementDetail: false,
        errorAgreementDetail: false,
        agreementDetail: action.payload,
      };
    case TYPES.REQUEST_FAILURE_AGREEMENT_DETAIL:
      return {
        ...state,
        loadingAgreementDetail: false,
        errorAgreementDetail: true,
        agreementDetail: null,
      };
    /* ********************************************* */
    case TYPES.REQUEST_LOADING_HOTMER_HISTORY:
      return {
        ...state,
        loadingHotmerHistory: true,
        errorHotmerHistory: false,
        hotmerHistory: null,
      };
    case TYPES.REQUEST_SUCCESS_HOTMER_HISTORY:
      return {
        ...state,
        loadingHotmerHistory: false,
        errorHotmerHistory: false,
        hotmerHistory: action.payload,
      };
    case TYPES.REQUEST_FAILURE_HOTMER_HISTORY:
      return {
        ...state,
        loadingHotmerHistory: false,
        errorHotmerHistory: true,
        hotmerHistory: null,
      };
    /* ********************************************* */
    case TYPES.CLEAR_DEALS_SECTION:
      return firstInitialState;
    /* ********************************************* */
    default:
      return state;
  }
};
