import React from "react";
import i18next from "i18next";
import ReactDOM from "react-dom";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_it from "./translations/it/global.json";
import { App } from "./App";
import { ToastContainer } from 'react-toastify';
import { store } from "./redux/store";
import { Provider } from "react-redux";
// REACT QUERY 
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// FIN DE REACT QUERY 
import { I18nextProvider } from "react-i18next";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    it: {
      global: global_it,
    }
  },
});

const queryClient = new QueryClient()

ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
        <ToastContainer />
      </I18nextProvider>
    </Provider>
    </QueryClientProvider>,
    document.getElementById("root")

  // </React.StrictMode>,

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
