/* eslint-disable */
import React, { useState, useEffect } from "react";
import { MiniForm } from "../miniForm/miniForm";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { getDataCountry } from "../../redux/actions";
// import { ClonePreferences } from "../clonePreferences/clonePreferences";
import { useSelector, useDispatch } from "react-redux";
import {
  getOptionsFeatures,
  getPreferencesGenders,
} from "../../redux/account/accountActions";
import "./createRegisterInit.css";
// import { FinishProcess } from "../finishProcess/finishProcess";

export const CreateRegisterInit = () => {
  const state = useSelector((state) => state.main);
  const { loadingToBecomeHotmer, loginFirebaseUser } = state;
  const dispatch = useDispatch();

  // const [fecNac, setFecNac] = useState("");
  const [miniData, setMiniData] = useState(null);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  useEffect(() => {
    if (loginFirebaseUser === null) {
      window.location.href = "/login";
    } else {
      dispatch(getPreferencesGenders());
      dispatch(getOptionsFeatures());
      dispatch(getDataCountry());
    }
  }, []);

  return (
    <div className="create--content--main--container">
      <LoadingScreen loading={loadingToBecomeHotmer} />
      {activeStep === 0 ? (
        <MiniForm
          handleNext={handleNext}
          miniData={miniData}
          //setMiniData={setMiniData}
          // setFecNac={setFecNac}
        />
      ) : (
        // ) : (
        // 	<ClonePreferences miniData={miniData} fecNac={fecNac} />
        // )
        <FinishProcess
          title={"FINALIZASTE EL PROCESO CORRECTAMENTE"}
          description={
            "Tu cuenta fue validada, sincroniza tu cuenta en el login para ingresar a la aplicación."
          }
          urlRedirect="/login"
          buttonTitle="ACEPTAR"
        />
      )}
    </div>
  );
};
