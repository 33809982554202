import React from "react";
import { useHistory } from "react-router-dom";
import darkFireIcon from "../../icons/dark-fire.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { useTranslation } from "react-i18next";
import "./buttonSubscribe.css";

export const ButtonSubscribe = ({ id_user_hotmer }) => {
  const history = useHistory();
  const { t } = useTranslation("global");

  const handleClick = () => history.push(`/hotmerSuscribe/${id_user_hotmer}`);

  return (
    <div className="button--subscribe--main--container">
      <img
        className="button--subscribe--main--container--img"
        src={darkFireIcon}
        alt="..."
      />
      <DividerSpace space="XXS" />
      <div
        className="button--subscribe--main--container--text"
        onClick={handleClick}
      >
        {t("button.subscribe.title")}
      </div>
    </div>
  );
};
