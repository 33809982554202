export const getUserHotmerStatus = (response) => response.data.user["hotmer"];

export const generateSessionUser = (response) => {
  const userObj = response.data.user;
  delete userObj["hotmer"];
  response.data.user = userObj;
  const finalData = response.data;
  return finalData;
};

export const isHotmerUser = () => {
  let result = false;
  try {
    const session = JSON.parse(localStorage.getItem("session"));
    result = session.user.hotmer;
  } catch (error) {
    localStorage.removeItem("session");
  }
  return result;
};

export const getIdUser = () => {
  const session = JSON.parse(localStorage.getItem("session"));
  const idUser = session.user.id;
  return idUser;
};
