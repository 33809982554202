/* eslint-disable */
import React, { useState } from "react";
import backIcon from "../../icons/darkIcons/back--icon.png";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { DestinyAccount } from "../../components/destinyAccount/destinyAccount";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { ActivityContainer } from "../../components/activityContainer/activityContainer";
import {
	createPathParams,
	initialChecks,
	setUpFiltersParams,
} from "../../libs/extractions";
import "./extractionsActivity.css";

export const ExtractionsActivity = ({
	f,
	setF,
	filtersParams,
	setFiltersParams,
	handleStep,
	loadingListActivity,
	setLoadingListActivity,
	listActivity,
	setListActivity,
	limitOffset,
	setLimiteOffset,
}) => {
	const [showSeeMore, setShowSeeMore] = useState(true);

	const getMoreActivities = async () => {
		const actualUrl = createPathParams(filtersParams);
		const id = getIdUser();
		const base = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/activity`;
		const limit = `limit=${limitOffset.limit}`;
		const offset = `offset=${limitOffset.offset}`;
		const url = `${base}?${limit}&${offset}&${actualUrl}`;

		try {
			setLoadingListActivity(true);
			const res = await fetch(url, { headers: setHeaders() });
			if (res.status === 200) {
				const response = await res.json();
				console.log("RESPONSE ACTIVITY: ", response.data);
				setListActivity([...listActivity, ...response.data.activity]);
				if (response.data.next == null) {
					setShowSeeMore(false);
				} else {
					setLimiteOffset({ limit: 2, offset: response.data.next.offset });
				}
			} else if (res.status === 204) {
				setShowSeeMore(false);
			}
		} catch (error) {
			console.log(error);
		}
		setLoadingListActivity(false);
	};

	const removeFilter = async (element) => {
		let finalList = [];
		if (element.type === "check") {
			if (element.description === "Suscripciones") {
				setF({ ...f, checkedB: false });
			}
			if (element.description === "Packs") {
				setF({ ...f, checkedC: false });
			}
			if (element.description === "Regalos") {
				setF({ ...f, checkedD: false });
			}
			if (element.description === "Extracciones") {
				setF({ ...f, checkedE: false });
			}
			if (element.description === "Referido") {
				setF({ ...f, checkedF: false });
			}
			finalList = filtersParams.map((option) => {
				if (option.idType == element.idType) {
					return {
						...option,
						selected: false,
					};
				} else {
					return option;
				}
			});
		} else {
			finalList = filtersParams.map((option) => {
				if (option.type === "date") {
					return {
						...option,
						selected: false,
						value: null,
					};
				} else {
					return option;
				}
			});
		}
		setLimiteOffset({ limit: 2, offset: 0 });
		setFiltersParams(finalList);
		if (finalList.every((element) => element.selected == false)) {
			setListActivity([]);
			setShowSeeMore(false);
		} else {
			const actualUrl = createPathParams(finalList);
			const id = getIdUser();
			const base = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/activity`;
			const limit = `limit=${limitOffset.limit}`;
			const offset = `offset=${0}`;
			const url = `${base}?${limit}&${offset}&${actualUrl}`;
			console.log("ENDPOINT: ", url);
			try {
				setLoadingListActivity(true);
				const res = await fetch(url, {
					headers: setHeaders(),
				});
				// console.log("RES ACTIVITY: ", res);
				if (res.status === 200) {
					const response = await res.json();
					console.log("RESPONSE ACTIVITY: ", response.data);
					setListActivity(response.data.activity);
				} else if (res.status === 204) {
					setListActivity([]);
				}
			} catch (error) {
				console.log(error);
			}
			setLoadingListActivity(false);
		}
	};

	return (
		<div className="extractions--activity--main--container">
			<DestinyAccount
				icon={backIcon}
				title="Movimientos"
				options={true}
				onClickIcon={() => {
					setLimiteOffset({ limit: 2, offset: 0 });
					const newFiltersParams = setUpFiltersParams(filtersParams);
					setFiltersParams(newFiltersParams);
					setF(initialChecks);
					handleStep(0);
				}}
				onClickOptions={() => handleStep(5)}
			/>
			<div className="extractions--activity--main--container--filters--params">
				{filtersParams.map((element, index) => {
					return (
						<FilterParam
							key={index}
							element={element}
							removeFilter={removeFilter}
						/>
					);
				})}
			</div>
			<div className="extractions--activity--main--container--counter">
				{listActivity && listActivity.length} resultados
			</div>
			<div
				className={`extractions--activity--main--container--activities
			${!showSeeMore ? "without--space--empty" : ""}
			`}
			>
				<MoneyContainer>
					{listActivity &&
						listActivity.map((activity, index) => (
							<ActivityContainer key={index} activity={activity} />
						))}
				</MoneyContainer>
			</div>
			{/*showSeeMore && (
				<div
					className="extractions--activity--main--container--seemore"
					onClick={getMoreActivities}
				>
					{loadingListActivity ? <MiniSpinner /> : "Ver mas"}
				</div>
			)   REVISAR ESTE CODIGO Y A LO QUE LLAMA. SE ESTA PASANDO MAL LA DATA DEL PAGINADO actual:
				limit: 2
				offset: 0
				page: 1
				__proto__: Object
				next: null --- llega nulo el proximo y ejecuta de nuevo.
				total_pages: 1*/}
		</div>
	);
};

const FilterParam = ({ element, removeFilter }) => {
	if (element.type === "check" && element.selected == true) {
		return (
			<div
				className="extractions--activity--main--container--filters--item"
				onClick={() => removeFilter(element)}
			>
				<div className="extractions--activity--main--container--filters--item--description">
					{element.description}
				</div>
				<div className="extractions--activity--main--container--filters--item--icon">
					x
				</div>
			</div>
		);
	} else if (element.type === "date" && element.value !== null) {
		return (
			<div
				className="extractions--activity--main--container--filters--item"
				onClick={() => removeFilter(element)}
			>
				<div className="extractions--activity--main--container--filters--item--description">
					{`${element.value}`}
				</div>
				<div className="extractions--activity--main--container--filters--item--icon">
					x
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};
