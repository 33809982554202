import React from "react";
import eyeIcon from "../../icons/editProfile/ojos.png";
import hairIcon from "../../icons/editProfile/pelo.png";
import skinIcon from "../../icons/editProfile/piel.png";
import chestIcon from "../../icons/editProfile/pecho.png";
import tatooIcon from "../../icons/editProfile/tatto.png";
import heightIcon from "../../icons/editProfile/altura.png";
import weightIcon from "../../icons/editProfile/peso.png";
import piercingIcon from "../../icons/editProfile/pircings.png";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import { WrongFetchTagsScreen } from "../../commonComponents/wrongFetchTagsScreen/wrongFetchTagsScreen";
import { CustomInputTextSimpleEdit } from "../../commonComponents/customInputTextSimpleEdit/customInputTextSimpleEdit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../accordionSets/accordionSets";
import { useTranslation } from "react-i18next";
import "./accordionFeatures.css";

export const AccordionFeatures = ({
  panel,
  expanded,
  handleChange,
  personalFeatures,
  openModalOptionsFeatures,
  loading,
  onClickRefreshStats,
  featuresStats,
}) => {
  const { t } = useTranslation("global");
  return (
    <Accordion
      square
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
        <AccordionHeader
          panel={panel}
          expanded={expanded}
          title={t("editProfile.traits.title")}
        />
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <div className="edit--options--preferences--loading">
            <PartialSpinner />
          </div>
        ) : !loading && !featuresStats ? (
          <div className="edit--options--preferences--error">
            <WrongFetchTagsScreen
              param={"Stats"}
              onClickRefresh={onClickRefreshStats}
            />
          </div>
        ) : (
          <div className="accordion--features--inputs">
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputHeightPlaceholder")}
              name="height"
              type="text"
              icon={heightIcon}
              value={personalFeatures.height.description}
              onClick={() => openModalOptionsFeatures("height")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputWeightPlaceholder")}
              name="weight"
              type="text"
              icon={weightIcon}
              value={personalFeatures.weight.description}
              onClick={() => openModalOptionsFeatures("weight")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputEyeColorPlaceholder")}
              name="eye"
              type="text"
              icon={eyeIcon}
              value={personalFeatures.eye.description}
              onClick={() => openModalOptionsFeatures("eye")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputHairColorPlaceholder")}
              name="hair"
              type="text"
              icon={hairIcon}
              value={personalFeatures.hair.description}
              onClick={() => openModalOptionsFeatures("hair")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputSkinColorPlaceholder")}
              name="skin"
              type="text"
              icon={skinIcon}
              value={personalFeatures.skin.description}
              onClick={() => openModalOptionsFeatures("skin")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputChestPlaceholder")}
              name="chest"
              type="text"
              icon={chestIcon}
              value={personalFeatures.chest.description}
              onClick={() => openModalOptionsFeatures("chest")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputInkPlaceholder")}
              name="tatoo"
              type="text"
              icon={tatooIcon}
              value={personalFeatures.tatoo.description}
              onClick={() => openModalOptionsFeatures("tatoo")}
            />
            <CustomInputTextSimpleEdit
              placeholder={t("editProfile.traits.inputPiercingPlaceholder")}
              name="piercing"
              type="text"
              icon={piercingIcon}
              value={personalFeatures.piercing.description || ""}
              onClick={() => openModalOptionsFeatures("piercing")}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
