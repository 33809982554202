export const TYPES = {
  SET_AGREEMENT_STEPPER_OPTION: "SET_AGREEMENT_STEPPER_OPTION",
  SET_AGREEMENT_OPTION: "SET_AGREEMENT_OPTION",
  SET_COLLABORATION_OPTION: "SET_COLLABORATION_OPTION",
  /* ********************************************************************** */
  REQUEST_LOADING_COLLABORATIONS: "REQUEST_LOADING_COLLABORATIONS",
  REQUEST_SUCCESS_COLLABORATIONS: "REQUEST_SUCCESS_COLLABORATIONS",
  REQUEST_FAILURE_COLLABORATIONS: "REQUEST_FAILURE_COLLABORATIONS",
  /* ********************************************************************** */
  REQUEST_LOADING_COLLABORATIONS_HOTMERS_ZERO:
    "REQUEST_LOADING_COLLABORATIONS_HOTMERS_ZERO",
  REQUEST_LOADING_COLLABORATIONS_HOTMERS:
    "REQUEST_LOADING_COLLABORATIONS_HOTMERS",
  REQUEST_SUCCESS_COLLABORATIONS_HOTMERS:
    "REQUEST_SUCCESS_COLLABORATIONS_HOTMERS",
  REQUEST_FAILURE_COLLABORATIONS_HOTMERS:
    "REQUEST_FAILURE_COLLABORATIONS_HOTMERS",
  STOP_FETCH_COLLABORATIONS_HOTMERS: "STOP_FETCH_COLLABORATIONS_HOTMERS",
  SET_NAME_PARAM: "SET_NAME_PARAM",
  /* ********************************************************************** */
  REQUEST_LOADING_AGREEMENTS: "REQUEST_LOADING_AGREEMENTS",
  REQUEST_SUCCESS_AGREEMENTS: "REQUEST_SUCCESS_AGREEMENTS",
  REQUEST_FAILURE_AGREEMENTS: "REQUEST_FAILURE_AGREEMENTS",
  /* ********************************************************************** */
  REQUEST_LOADING_AGREEMENTS_HOTMERS: "REQUEST_LOADING_AGREEMENTS_HOTMERS",
  REQUEST_SUCCESS_AGREEMENTS_HOTMERS: "REQUEST_SUCCESS_AGREEMENTS_HOTMERS",
  REQUEST_FAILURE_AGREEMENTS_HOTMERS: "REQUEST_FAILURE_AGREEMENTS_HOTMERS",
  /* ********************************************************************** */
  SET_SELECTED_HOTMERS: "SET_SELECTED_HOTMERS",
  SET_SELECTED_AGREEMENT_HOTMER: "SET_SELECTED_AGREEMENT_HOTMER",
  SET_SELECTED_AGREEMENT_HOTMER_OPTION: "SET_SELECTED_AGREEMENT_HOTMER_OPTION",
  /* ********************************************************************** */
  SET_DECREASE_PERCENTAGES: "SET_DECREASE_PERCENTAGES",
  SET_INCREASE_PERCENTAGES: "SET_INCREASE_PERCENTAGES",
  SET_RESET_PERCENTAGES: "SET_RESET_PERCENTAGES",
  /* ********************************************************************** */
  SET_SELECTED_AGREEMENT: "SET_SELECTED_AGREEMENT",
  SET_SELECTED_AGREEMENT_DEAL: "SET_SELECTED_AGREEMENT_DEAL",
  REQUEST_LOADING_AGREEMENT_DETAIL: "REQUEST_LOADING_AGREEMENT_DETAIL",
  REQUEST_SUCCESS_AGREEMENT_DETAIL: "REQUEST_SUCCESS_AGREEMENT_DETAIL",
  REQUEST_FAILURE_AGREEMENT_DETAIL: "REQUEST_FAILURE_AGREEMENT_DETAIL",
  /* ********************************************************************** */
  REQUEST_LOADING_HOTMER_HISTORY: "REQUEST_LOADING_HOTMER_HISTORY",
  REQUEST_SUCCESS_HOTMER_HISTORY: "REQUEST_SUCCESS_HOTMER_HISTORY",
  REQUEST_FAILURE_HOTMER_HISTORY: "REQUEST_FAILURE_HOTMER_HISTORY",
  /* ********************************************************************** */
  CLEAR_DEALS_SECTION: "CLEAR_DEALS_SECTION",
};
