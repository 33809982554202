import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import mainLogoIcon from "../../icons/main-logo.png";
import { useDispatch } from "react-redux";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { screenEditProfile } from "../../libs/topbar";
import { ResultEditProfile } from "../resultEditProfile/resultEditProfile";
import { DiscardEditProfile } from "../discardEditProfile/discardEditProfile";
import { useHistory, useLocation } from "react-router-dom";
import { setScreenPosition } from "../../redux/actions";
import "./topBar.css";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

export const TopBar = () => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadingUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDiscard, setOpenModalDiscard] = useState(false);
  const [openModalResultEdit, setOpenModalResultEdit] = useState(false);

  const onFinalClick = () => {
    setOpenModalResultEdit(false);
    localStorage.setItem("idUrl", 0);
    window.location.href = "/";
  };

  const goHome = () => {
    if (screenEditProfile(location)) {
      setOpenModalDiscard(true);
    } else {
      dispatch(setScreenPosition(0));
      history.replace("/");
    }
  };

  const acceptChanges = () => alert("UPDATE CHANGES");
  const cancelModal = () => setOpenModal((x) => !x);

  const discardChangesDiscard = () => {
    dispatch(setScreenPosition(0));
    history.replace("/");
  };

  const cancelModalDiscard = () => setOpenModalDiscard(false);

  return (
    <AppBar position="static">
      <LoadingScreen loading={loadingUpdate} />
      <div className="topbar--main--container">
        <LogoHome onClick={goHome} />
      </div>
      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h5"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Guardar Cambios ?
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={acceptChanges}
                  >
                    Si
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={cancelModal}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </CustomModal>
      <CustomModal
        openModal={openModalDiscard}
        setOpenModal={setOpenModalDiscard}
      >
        <DiscardEditProfile
          discardChanges={discardChangesDiscard}
          cancelModal={cancelModalDiscard}
        />
      </CustomModal>

      <CustomModal
        openModal={openModalResultEdit}
        setOpenModal={setOpenModalResultEdit}
      >
        <ResultEditProfile finalClick={onFinalClick} />
      </CustomModal>
    </AppBar>
  );
};

const LogoHome = ({ onClick }) => (
  <div onClick={onClick} className="topbar--main--container--home">
    <img
      src={mainLogoIcon}
      className="topbar--main--container--home"
      alt="..."
    />
  </div>
);
