// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import enlaceIcon from "../../icons/enlace-white.png";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import { BottomSheet } from "react-spring-bottom-sheet";
// import { ShareIcons } from "./ShareIcons"
import profileErrorImg from "../../icons/profile/profile-error-img.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import {
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";

import { useState, useEffect } from 'react'
import "./Share.css";
// FUNCION BASE QUE LEVANTA EL COMPARTIR.

export const ShareInfoOfProfile = (
    // nameComponent,
    // profileInfoToShare,
    // openDrawerShareProfile,
    // cerrar,
    props
) => {
    // LLAMADO A LA FUNCION

    const { openDrawerShareProfile, profileInfoToShare, cerrar } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation("global");

    useEffect(() => {
        setOpen(openDrawerShareProfile)
    }, [openDrawerShareProfile, setOpen])

    const onDismiss = () => {
        // setOpen(false)
        cerrar(false)
    }

    const onErrorProfile = () => {
        if (!myStateProfile.errored) {
            setMyStateProfile({
                src: profileErrorImg,
                errored: true,
            });
        }
    };

    const [myStateProfile, setMyStateProfile] = useState({
        src: profileInfoToShare?.img_profile ? profileInfoToShare?.img_profile : profileDefault,
        errored: false,
    });

    const shareLink = (e, url) => {
        e.preventDefault();
        if (!navigator.share) {
            alert("Navegador no soporta la Web Share API");
            return;
        }
        navigator
            .share({
                url: url,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
    };

    // SHARE ICONS
    const separator = " "
    const hstw = ["Hotmy", "HotmyModel", "ContentCreator"]
    const hsfb = `#HotmyModel`
    const titleShareProfile = t("shareInfoOnProfie.profile.titleShareProfile")
    const titleShareLastPack = t("shareInfoOnProfie.lastPack.titleShareLastPack")
    const titleShareReferralCode = t("shareInfoOnProfie.referral.titleShareReferral")

    return (
        <Box id='shareProfile' className="share-main">
            <BottomSheet
                open={open}
                onDismiss={onDismiss}
                // onClick={cerrar}
                blocking={false}
                // snapPoints={({ minHeight }) => minHeight}
                header={
                    <div className="share-container">
                        <input
                            className="share-icon-high-title"
                            type="text"
                            value={t("shareInfoOnProfie.title")}
                            readOnly
                        />
                        {/* Seccion de Helper ya armada, va a una version 2. */}
                        {/* {helper && (
                            <div
                                className="share-container-help-icon"
                            // onClick={handleClick("bottom-end")}
                            >
                                <HelpOutlineIcon fontSize="small" color="action" />
                            </div>
                        )}
                        {showHelperContent && (
                            <div className="">
                                MOSTRAR que sirve cada icono
                            </div>
                        )} */}
                    </div>
                }
                footer={
                    <Button onClick={onDismiss} className="w-full">
                        {t("shareInfoOnProfie.close")}
                    </Button>
                }
                children={
                    <Box className="share-children-conteiner">
                        {/* CAJITA DE CARGANDO */}
                        {profileInfoToShare && (
                            <>
                                {/* SECCION 1 : COMPARTIR PERFIL PRINCIPAL*/}
                                {/* <div className=""> */}
                                <div className="angry-grid">
                                    <div id="item-0">
                                        <div
                                            className="angry-grid-profile-image">
                                            <img
                                                alt="..."
                                                src={profileInfoToShare?.profile_photo_thumbnail_path ?
                                                    profileInfoToShare?.profile_photo_thumbnail_path :
                                                    profileDefault}
                                                onError={onErrorProfile}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {t("shareInfoOnProfie.profile.commonName")}
                                    </div>
                                    <div className="share-icon-container-subtitle">
                                        hotmy.app/{profileInfoToShare?.display_name}
                                    </div>
                                </div>
                                <div className="share-icon-grid">
                                    {/* <FacebookIcon size={25} round /></FacebookShareButton> */}
                                    <div className="share-icon-enlace">
                                        <img
                                            src={enlaceIcon}
                                            onClick={(e) => shareLink(e, `https://hotmy.app/${profileInfoToShare?.display_name}`)}
                                            width={20}
                                            height={20}
                                        />
                                    </div>
                                    <FacebookShareButton
                                        url={`hotmy.app/${profileInfoToShare?.display_name}`}
                                        title={titleShareProfile}
                                        quote={"Hotmy"}
                                        hashtag={hsfb}
                                        description={"Description"}
                                        className="share-button"
                                    >
                                        <FacebookIcon size={25} round /></FacebookShareButton>
                                    <TelegramShareButton
                                        url={`hotmy.app/${profileInfoToShare?.display_name}`}
                                        title={titleShareProfile}
                                        className="share-button"
                                    >
                                        <TelegramIcon size={25} round /></TelegramShareButton>
                                    <TwitterShareButton
                                        // picture={imgUrl}
                                        url={`hotmy.app/${profileInfoToShare?.display_name}`}
                                        title={titleShareProfile}
                                        // hashtags={["hashtag1", "hashtag2"]}
                                        hashtags={hstw}
                                        className="share-button"
                                    >
                                        <TwitterIcon size={25} round /></TwitterShareButton>
                                    <WhatsappShareButton
                                        // picture={imgUrl}
                                        url={`hotmy.app/${profileInfoToShare?.display_name}`}
                                        title={titleShareProfile}
                                        separator={separator}
                                        // quote={"tu app de contenido."}  Esto no me funciona
                                        hashtag={hsfb}
                                        className="share-button"
                                    >
                                        <WhatsappIcon
                                            size={25}
                                            round
                                        /></WhatsappShareButton>
                                </div>
                                {/* <ShareIcons
                                    className="share-icon-container-icons"
                                    url={`hotmy.app/${profileInfoToShare?.display_name}`}
                                    title={"Te quiero compartir mi Pefil de Hotmy"}
                                /> */}
                                {/* </div> */}
                                {/* SECCION 2 : ULTIMO PACK*/}
                                {/* QUE HACER EN CASO DE QUE NO TENGA UN PACK? NO MOSTRAR NADA.  */}
                                {profileInfoToShare?.lastPacks &&
                                    <>
                                        <div className="angry-grid">
                                            <div id="item-0">
                                                <div
                                                    className="angry-grid-profile-image-last-pack">
                                                    <img
                                                        alt=""
                                                        src={profileInfoToShare?.lastPacks?.ds_url_thumbnail ?
                                                            profileInfoToShare?.lastPacks?.ds_url_thumbnail :
                                                            profileDefault}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {t("shareInfoOnProfie.lastPack.commonName")}
                                            </div>
                                            <div className="share-icon-container-subtitle">
                                                hotmy.app/packView/{profileInfoToShare?.lastPacks?.id_hotmer}/{profileInfoToShare?.lastPacks?.id_pack}
                                                {/* `https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}` */}
                                                {/* https://localhost:3000/packView/8/785 */}
                                            </div>
                                        </div>
                                        <div className="share-icon-grid">
                                            {/* <FacebookIcon size={25} round /></FacebookShareButton> */}
                                            <div className="share-icon-enlace">
                                                <img
                                                    src={enlaceIcon}
                                                    onClick={(e) => shareLink(e, `https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}`)}
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                            <FacebookShareButton
                                                url={`https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}`}
                                                title={titleShareLastPack}
                                                quote={"Hotmy"}
                                                hashtag={hsfb}
                                                description={"Description"}
                                                className="share-button"
                                            >
                                                <FacebookIcon size={25} round /></FacebookShareButton>
                                            <TelegramShareButton
                                                url={`https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}`}
                                                title={titleShareLastPack}
                                                className="share-button"
                                            >
                                                <TelegramIcon size={25} round /></TelegramShareButton>
                                            <TwitterShareButton
                                                // picture={imgUrl}
                                                url={`https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}`}
                                                title={titleShareLastPack}
                                                // hashtags={["hashtag1", "hashtag2"]}
                                                hashtags={hstw}
                                                className="share-button"
                                            >
                                                <TwitterIcon size={25} round /></TwitterShareButton>
                                            <WhatsappShareButton
                                                // picture={imgUrl}
                                                url={`https://hotmy.app/packView/${profileInfoToShare?.lastPacks?.id_hotmer}/${profileInfoToShare?.lastPacks?.id_pack}`}
                                                title={titleShareLastPack}
                                                separator={separator}
                                                // quote={"tu app de contenido."}  Esto no me funciona
                                                hashtag={hsfb}
                                                className="share-button"
                                            >
                                                <WhatsappIcon
                                                    size={25} round
                                                /></WhatsappShareButton>
                                        </div>
                                    </>
                                }
                                {/* SECCION 3 CODIGO DE REFERIDOS */}
                                <div className="angry-grid-witout-image">
                                    <div>
                                        {t("shareInfoOnProfie.referral.commonName")}
                                    </div>
                                    <div className="share-icon-container-subtitle">
                                        hotmy.app/login?codRef={profileInfoToShare?.referral_code}
                                    </div>
                                </div>
                                <div className="share-icon-grid">
                                    {/* <FacebookIcon size={25} round /></FacebookShareButton> */}
                                    <div className="share-icon-enlace">
                                        <img
                                            src={enlaceIcon}
                                            onClick={
                                                (e) => shareLink(e, `https://hotmy.app/login?codRef=${profileInfoToShare?.referral_code}`)
                                            }
                                            width={20}
                                            height={20}
                                        />
                                    </div>
                                    <FacebookShareButton
                                        url={`https://hotmy.app/login?codRef=${profileInfoToShare?.referral_code}`}
                                        title={titleShareReferralCode}
                                        quote={"Hotmy"}
                                        hashtag={hsfb}
                                        description={"Description"}
                                        className="share-button"
                                    >
                                        <FacebookIcon size={25} round /></FacebookShareButton>
                                    <TelegramShareButton
                                        url={`https://hotmy.app/login?codRef=${profileInfoToShare?.referral_code}`}
                                        title={titleShareReferralCode}
                                        className="share-button"
                                    >
                                        <TelegramIcon size={25} round /></TelegramShareButton>
                                    <TwitterShareButton
                                        // picture={imgUrl}
                                        url={`https://hotmy.app/login?codRef=${profileInfoToShare?.referral_code}`}
                                        title={titleShareReferralCode}
                                        // hashtags={["hashtag1", "hashtag2"]}
                                        hashtags={hstw}
                                        className="share-button"
                                    >
                                        <TwitterIcon size={25} round /></TwitterShareButton>
                                    <WhatsappShareButton
                                        // picture={imgUrl}
                                        url={`https://hotmy.app/login?codRef=${profileInfoToShare?.referral_code}`}
                                        title={titleShareReferralCode}
                                        separator={separator}
                                        // quote={"tu app de contenido."}  Esto no me funciona
                                        hashtag={hsfb}
                                        className="share-button"
                                    >
                                        <WhatsappIcon
                                            size={25}
                                            round
                                        /></WhatsappShareButton>
                                </div>
                                {/* <ShareIcons
                                    className="share-icon-container-icons"
                                    url={`hotmy.app/login?codRef=/${profileInfoToShare?.referral_code}`}
                                    title={titleShareReferralCode}
                                /> */}
                            </>
                        )
                        }
                    </Box>
                }
            >
            </BottomSheet >
        </Box >
    );
};
