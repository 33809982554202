import React from "react";
import "./customInputTextIcon.css";

export const CustomInputTextIcon = ({
	placeholder,
	name,
	value,
	onChange,
	type,
	image,
	limit,
	visible = true,
}) => {
	return (

		<div className={`custom--input--text--icon--container ${visible ? '' : 'hidden'}`}>
			<img
				className="custom--input--text--icon--container--image"
				src={image}
				alt="..."
			/>
			<input
				className="custom--input--text--icon--container--input"
				name={name}
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={(e) => {
					if (name === "nameHotmer") {
						const regExp = /^([0-9A-Za-z-_.]{0,18})$/;
						if (regExp.test(e.target.value)) {
							onChange(e);
						}
					} else {
						onChange(e);
					}
				}}
				maxLength={limit}
			/>
		</div>
	);
};
