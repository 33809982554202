/* eslint-disable */
import React from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../accordionSets/accordionSets";
import "./accordionReferralCode.css";
import { useTranslation } from "react-i18next";

export const AccordionReferralCode = ({
	panel,
	expanded,
	handleChange,
	referralCode,
}) => {
	const { t } = useTranslation("global");
	const handleLink = () => {
		let copyText = document.getElementById("referral_code_input");
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /* For mobile devices */
		/* Copy the text inside the text field */
		document.execCommand("copy");
	};

	const shareReferralCode = (e) => {
		e.preventDefault();
		if (!navigator.share) {
			alert("Navegador no soporta la Web Share API");
			return;
		}
		navigator
			.share({
				// title: "Hotmy",
				// text: "Instala Hotmy y mira el mejor contenido",
				url: process.env.PUBLIC_URL + "/login?codRef=" + referralCode,
			})
			.then(() => console.log("Successful share"))
			.catch((error) => console.log("Error sharing", error));
	};

	const onChange = (e) => {};

	return (
		<Accordion
			square
			expanded={expanded === panel}
			onChange={handleChange(panel)}
		>
			<AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
				<AccordionHeader
					panel={panel}
					expanded={expanded}
					title={t("editProfile.referral.title")}
				/>
			</AccordionSummary>
			<AccordionDetails>
				
				<div className="edit--profile--referral--code--container">
					<div className="edit--profile--referral--code--container--link">
						<input
							className="edit--profile--referral--code--container--link--input"
							id="referral_code_input"
							onChange={onChange}
							value={referralCode || (t("editProfile.referral.withOutReferralLink"))}
						/>
					</div>
					<DividerSpace space="XS" />
					<div className="edit--profile--referral--code--container--button">
						<CustomButtonRegister
							disabled={!referralCode}
							onClick={shareReferralCode}
						>
							{t("editProfile.referral.shareButton")}
						</CustomButtonRegister>
					</div>

					<span className="edit--profile--referral--code--container--link--title">
							{t("editProfile.referral.titleContainer")}
						</span>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
