import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { OpsSmallFire } from "../opsSmallFire/opsSmallFire";
import { PackFireBought } from "../packFireBought/packFireBought";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { useTranslation } from "react-i18next";

export const GridSubscribedPacks = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.fire);
  const { loadingSubscribedPacks, errorSubscribedPacks, subscribedPacks } =
    state;
  const history = useHistory();
  const { t } = useTranslation("global");

  const redirectToSearch = () => {
    // history.push(`/search`);
    // dispatch(setScreenPosition(1));
    // dispatch(setTabSearch(1));
    // localStorage.setItem("idUrl", 1);
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={0}>
        {loadingSubscribedPacks ? (
          <PartialSpinner />
        ) : errorSubscribedPacks ? (
          <OpsSmallFire
            title={t("fire.content.packs.error.title")}
            description={t("fire.content.packs.error.description")}
            onClick={() => {}}
          />
        ) : subscribedPacks && subscribedPacks.length !== 0 ? (
          subscribedPacks.map((pack, i) => (
            <PackFireBought key={i} pack={pack} />
          ))
        ) : (
          <OpsSmallFire
            title={t("fire.content.packs.noContent.title")}
            description={t("fire.content.packs.noContent.description")}
            onClick={redirectToSearch}
          />
        )}
      </Grid>
    </Grid>
  );
  // }
};
