import React, { useEffect, useState } from "react";
// import Tooltip from '@mui/material/Tooltip';
import profileDefault from "../../icons/profile/profile-default-img.png";
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useHistory } from "react-router-dom";
import { PopperTooltip } from "../../components/popperTooltip/PopperTooltip";
import tipIcon from "../../icons/a_news/subscriptions/gift_W.svg";
import unLockIconWhite from "../../icons/a_news/subscriptions/unlock_W.svg";
import lockIcon from "../../icons/a_news/subscriptions/lock_B.svg";
import lockIconWhite from "../../icons/a_news/subscriptions/lock_W.svg";
import HelpIcon from "../../icons/a_news/subscriptions/help-B.svg";
import HelpIconWhite from "../../icons/a_news/subscriptions/help-W.svg";
// import opsIcon from "../../icons/fetch/ooops-icon.png";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Typography from "@material-ui/core/Typography";
// import Grid from '@mui/material/Grid';
// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./hotmerFire.css";

export const setWishHotmersList = (data) => ({
  type: "SET_SUCCESS_WISH_HOTMERS",
  payload: data,
});

export const HotmerFireForSearch = ({ hotmer, onClick, onSubscription, tab }) => {
  // const state = useSelector((state) => state.main);
  const { t } = useTranslation("global");

  const [profile, setProfile] = useState({
    src: hotmer.profile_photo_thumbnail_path,
    errored: false,
  });

  const history = useHistory();

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        // src: opsIcon,
        src: profileDefault,
        errored: true,
      });
    }
  };

  const getColorDayLeft = (diasRestantes) => {
    if (diasRestantes === 0) {
      return <span className="dayLeft-critical">-24hs</span>
    }
    else if (diasRestantes <= 5) {
      return <span className="dayLeft-critical">{diasRestantes}</span>
    } else if (diasRestantes < 12) {
      return <span className="dayLeft-few-days">{diasRestantes}</span>
    } else {
      return <span className="dayLeft">{diasRestantes}</span>
    }
  }

  useEffect(() => {
    setProfile({
      src: hotmer.profile_photo_thumbnail_path || profileDefault,
      errored: false,
    });
  }, [hotmer]); // eslint-disable-line

  const clickPresent = (id) => {
    history.push(`/hotmerPresent/${id}/${hotmer.id_content_img_cover_art}`);
  };

  const getTypeofSubscription = (typeOfSubscription) => {
    switch (typeOfSubscription) {
      case '1':
        return <span className="capitalize">{t("subcriptionsFire.typeOf.1month")}</span>
      case '3':
        return <span className="capitalize">{t("subcriptionsFire.typeOf.3month")}</span>
      case '6':
        return <span className="capitalize">{t("subcriptionsFire.typeOf.3month")}</span>
      case '12':
        return <span className="capitalize">{t("subcriptionsFire.typeOf.12month")}</span>
      default:
        return <span className="capitalize">{t("subcriptionsFire.typeOf.default")}</span>
    }
  }

  // Estados de los pagos en base de Datos . 
  // 1	"pending"
  // 2	"approved"
  // 3	"cancelled"
  // 4	"reject"

  // ID STATUS 
  // -- STATUS 10 ES EN PROGRESO.
  // -- STATUS 11 ES RECHAZADO HASTA 24HS.

  return (
    <div className="hotmer--fire--container--search">
      <div className="hotmer-fire-grid-item-content">
        {/* 1 - SUBSCRIPCIONES EN PROCESO  (Se muestra unicamente por 7 dias) */}
        {hotmer?.id_status === 10
          &&
          <div className="hotmer--subscription--grid--in--progress">
            <div>
              <Grid
                container
                wrap="nowrap"
                spacing={1}
                sx={{ overflow: 'hidden' }}
                onClick={onClick}
              >
                <Grid item xs zeroMinWidth>
                  <Typography variant='subtitle2' noWrap className="capitalize">
                    {hotmer.ds_display_name}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Typography style={{ fontSize: '0.9rem' }}
              onClick={onClick}
            >
              {getTypeofSubscription(hotmer?.vl_total_month)}
              <span> </span>
              |
              <span> {t("subcriptionsFire.state.inProgress")}</span>
            </Typography>
            <PopperTooltip
              helper={true}
              icon={HelpIcon}
              alt="Help in progress"
              bigIcon={true}
              popperMessage={t("subcriptionsFire.help.inProgress")}
            />
          </div>
        }
        {/* ESTADO DE PAGO RECHAZADO (Se muestra unicamente por 1 dia)*/}
        {
          hotmer?.id_status === 11 &&
          <div className="hotmer--subscription--grid--in--refuse">
            <div>
              <Grid
                container
                wrap="nowrap"
                spacing={1}
                sx={{ overflow: 'hidden' }}
                onClick={onClick}
              >
                <Grid item xs zeroMinWidth>
                  <Typography variant='subtitle2' noWrap className="white-color capitalize" >
                    {hotmer.ds_display_name}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div>
              <Typography
                style={{ fontSize: '0.9rem', color: 'white' }}
                onClick={onClick}>
                {getTypeofSubscription(hotmer?.vl_total_month)} |
                <span> {t("subcriptionsFire.state.Refuse")}</span>
              </Typography>
            </div>
            <div>
              <PopperTooltip
                helper={true}
                icon={HelpIconWhite}
                alt="Help in progress"
                bigIcon={true}
                popperMessage={t("subcriptionsFire.help.refuse")}
              />
            </div>
          </div>
        }
        {hotmer?.id_status === 1 &&
          // CREADORES ACTIVOS
          <div className="hotmer--subscription--grid">
            <img
              className="hotmer-fire-grid-item-image-rounded"
              src={profile.src}
              alt=".."
              onClick={onClick}
              onError={onErrorProfile}
            />
            <Grid
              container
              wrap="nowrap"
              spacing={1}
              sx={{ overflow: 'hidden' }}
              onClick={onClick}
            // columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid item xs zeroMinWidth>
                <Typography variant='subtitle2' noWrap className="capitalize" >
                  {hotmer?.ds_display_name}
                </Typography>
              </Grid>
            </Grid>
            <div onClick={onClick}>
              <Typography style={{ fontSize: '0.75rem' }}>
                <img className="svg-size"
                  src={lockIcon}
                  alt="Block-Unblock"
                />
                {t("subcriptionsFire.state.blockIn")} {getColorDayLeft(hotmer?.daysLeft)}
                <span> </span>
                {hotmer?.daysLeft > 1 ? `${t("subcriptionsFire.state.days")}` : `${t("subcriptionsFire.state.day")}`}
              </Typography>
              <Typography className="gray-color" style={{ fontSize: '0.85rem' }}>
                {hotmer?.vl_total_month === "1" ?
                  <PopperTooltip
                    helper={true}
                    icon={HelpIcon}
                    alt="Regalo"
                    // popperTitle={"Suscripciones"}
                    popperMessage={t("subcriptionsFire.help.infoSubscription")}
                  />
                  :
                  <PopperTooltip
                    helper={true}
                    icon={HelpIcon}
                    alt="Regalo"
                    // popperTitle={"Suscripciones"}
                    popperMessage={t("subcriptionsFire.help.infoSubscription3more")}
                  />
                }
                {getTypeofSubscription(hotmer?.vl_total_month)}
              </Typography>
            </div>
            <div className="mask--present--icon" onClick={() =>
              clickPresent(hotmer.id_hotmer)
            }>
              <img
                className="hotmer--profile--data--main--container--present--mask1--button--present"
                src={tipIcon}
                alt="Regalo"
                onClick={() =>
                  clickPresent(hotmer.id_hotmer)
                }>
              </img>
            </div>
          </div>

        }
        {(hotmer.id_status === 0 && hotmer.id_hotmer !== 0) &&
          // CREADORES INACTIVOS.
          <div className="hotmer-fire-grid-item-content" >
            <div className="hotmer--subscription--grid--inactive" onClick={onClick}>
              <img
                className="hotmer-fire-grid-item-image-rounded--gray"
                src={profile.src}
                alt=".."
                onClick={onClick}
                onError={onErrorProfile}
              />
              <Grid
                container
                wrap="nowrap"
                spacing={1}
                sx={{ overflow: 'hidden' }}
                onClick={onClick}
              >
                <Grid item xs zeroMinWidth>
                  <Typography variant='subtitle2' noWrap className="capitalize white-color" >
                    {hotmer.ds_display_name}
                  </Typography>
                </Grid>
              </Grid>
              <div>
                <div>
                  <Typography className="white-color">
                    <img className="svg-size"
                      src={lockIconWhite}
                      alt="Block-Unblock"
                    />
                    {t("subcriptionsFire.state.block")}
                  </Typography>
                </div>
                {/* <Typography>
                <img className="svg-size"
                  src={lockIcon}
                  alt="Block-Unblock"
                />
                Block
              </Typography>
              <Typography
              // className="gray-color" style={{ fontSize: '0.9rem' }}
              > New
                <img className="svg-size"
                  src={HelpIcon}
                  alt="Regalo" />
                + 20
                <img className="svg-size"
                  src={HelpIcon}
                  alt="Regalo" />
                + 20
              </Typography> */}
              </div>
              <div className="mask--unlock--icon"
                onClick={onSubscription}>
                <button
                // className="custom--button--register--container red--color--button"
                >
                  <img
                    className="hotmer--unlock--image"
                    src={unLockIconWhite}
                    alt="Subscription"
                  />
                </button>
              </div>
            </div>
          </div>
        }
        {/* {hotmer.id_hotmer === 0 ? (
        <div className="hotmer--fire--container--image">
          <img
            className="hotmer--fire--container--image--white"
            src={profileCircleIcon}
            alt=".."
          />
          <div className="hotmer--fire--container--image--white--profile">
            <div className="hotmer--fire--container--image--white--profile--limit">
              <img
                className="hotmer--fire--container--image--white--profile--limit--photo"
                src={profile.src}
                onError={onErrorProfile}
                alt=".."
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="hotmer--fire--container--image" onClick={onClick}>
          <img
            className="hotmer--fire--container--image--white"
            src={profileCircleIcon}
            alt=".."
          />
          <div className="hotmer--fire--container--image--white--profile">
            <div className="hotmer--fire--container--image--white--profile--limit">
              <img
                className="hotmer--fire--container--image--white--profile--limit--photo"
                src={profile.src}
                onError={onErrorProfile}
                alt=".."
              />
              {tab === 0 ? (
                <>
                  {hotmer.id_status === 0 && (
                    <img
                      className="hotmer--fire--container--image--white--profile--limit--option"
                      src={renewSubsImage}
                      alt=".."
                    />
                  )}
                </>
              ) : (
                <img
                  className="hotmer--fire--container--image--white--profile--limit--option"
                  src={subscribeImage}
                  alt=".."
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="hotmer--fire--container--name">
        {hotmer.ds_display_name}
      </div>
      {tab === 0 ? (
        <div
          className="hotmer--fire--container--remove"
          onClick={handleClick("bottom-end")}
        >
          <div className="hotmer--fire--container--remove-2">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.containerPaper}>
                    <Typography
                      className={classes.typographyTitle}
                      onClick={shareProfile}
                    >
                      {"Compartir"}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      ) : (
        <div
          className="hotmer--fire--container--remove"
          onClick={handleClick("bottom-end")}
        >
          <div className="hotmer--fire--container--remove-2">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.containerPaper}>
                    <Typography
                      className={classes.typographyTitle}
                      onClick={clickAdd}
                    >
                      {"Eliminar"}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
};
