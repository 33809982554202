import "./Topic.css";

export const TopicUpgrade = ({ title, val, color }) => {
    return (
        <div style={{ color, fontSize: "14px" }}>
            <div>
                {`${title} `}                 <span style={{ color: "darkgray" }}>
                    {`${val}`}
                </span>
            </div>

        </div>
    );
};
