/* eslint-disable */
import React from "react";
import expandIcon from "../../icons/darkIcons/expand-icon.png";
import "./extractionsSelect.css";

export const ExtractionsSelect = ({
  visibleValue = true,
  textPosition,
  value = 'Seleccionar',
  subValue = null,
  image,
  setOpenOptions,
  disabled,
}) => {

  return (
    <button
      disabled={disabled}
      className="extractions--select--container"
      onClick={() => setOpenOptions()}
    >
      <div className="extractions--select--container--input">
        {value === "" ? (
          "Seleccionar"
        ) : (
          <div
            className="extractions--select--container--input--content"
          >
            {visibleValue && (
              <div className="wheel--container--element--image--text">
                { (image == null && subValue == null ) ? (
                  <div className="row">
                    <div className="column">
                      {value}
                    </div>
                  </div>
                ):(
                  (image != null && subValue == null ) ? (
                    <div className="row">
                      <div className="column">
                        <img crossOrigin="anonymous" src={image} style={{width: '24px' }} />
                      </div>
                      <div className="column" style={{paddingTop: '15px'}}>
                        {value}
                      </div>
                    </div>
                  ):(
                    (image == null && subValue != null ) ? (
                      <div className="row">
                        <div className="column">
                          {value} ({subValue})
                        </div>
                      </div>
                    ):(
                      <div className="row">
                      <div className="column">
                        <img crossOrigin="anonymous" src={image} style={{width: '24px'}} />
                      </div>
                      <div className="column" style={{paddingTop: '15px'}}> 
                        {value} ({subValue})
                      </div>
                    </div>
                    )
                  )

                )}
              </div>
            )}
          </div>
        )}
        <div className="extractions--select--container--input--icon">
          <div className="extractions--select--container--input--icon--container">
            <img src={expandIcon} alt="..." />
          </div>
        </div>
      </div>
    </button>
  );
};
