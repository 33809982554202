import React, { useState } from "react";
import { setHeaders } from "../../libs/fetch";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import verifiedIcon from "../../icons/verified-icon.png";
import darkFireIcon from "../../icons/dark-fire.png";
import galleryWhiteIcon from "../../icons/profile/photo-icon.png";
import packWhiteIcon from "../../icons/profile/pack-icon.png";
import videoWhiteIcon from "../../icons/profile/video-icon.png";
import tipIcon from "../../icons/a_news/subscriptions/gift_W.svg";
import { DotsOptions } from "../../components/dotsOptions/dotsOptions";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { useTranslation } from "react-i18next";
import { ContentTypeProfileHorizontal } from "../contentTypeProfile/contentTypeProfileHorizontal";
import { LoadingComponent } from "../../commonComponents/loadingComponent/loadingComponent";
import { displaySuccess } from "../toast/toast";
import "./hotmerProfileData.css";

export const HotmerProfileData = ({
  loadingHotmerProfile,
  hotmerProfile,
  profile,
  onErrorProfile,
  clickAdd,
  id,
  cover,
  onErrorCover,
  clickOptionHotmer,
  // getOptionHotmer,
  id_content_group,
  clickPresent,
}) => {
  //SE AGREGO EL CLICK ADD PORQUE NO SE PUEDE CONTROLAR DESDE LA OTRA CLASE, NO APLICA.
  const { t } = useTranslation("global");
  const [loadingClickAdd, setLoadingClickAdd] = useState(false);
  const [successAddHotmer, setSuccessAddHotmer] = useState(false);

  const clickAddHotmer = async () => {
    const id = hotmerProfile.id_user_hotmer;
    setLoadingClickAdd(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      console.log("AGREGAR RES: ", res);
      const response = await res.json();
      if (response.code === 200) {
        setSuccessAddHotmer(true);
        displaySuccess(t("toast.profile.addProfileWishList"));
      } else {
        console.log("FAIL CLICK FOLLOW HOTMER");
      }
    } catch (error) {
      console.log("FAIL CLICK FOLLOW HOTMER");
      console.log(error);
    }
    setLoadingClickAdd(false);
  };

  if (loadingHotmerProfile) {
    return (
      <div className="hotmer--profile--data--main--container">
        <LoadingComponent />;
        {/* <LoadingScreen loading={loadingHotmerProfile} />; */}
      </div>
    );
  } else if (!loadingHotmerProfile && !hotmerProfile) {
    return <div>{"Actualmente no hay contenido para mostrar."}</div>;
  } else {
    return (
      <div className="hotmer--profile--data--main--container">
        <div className="hotmer--profile--data--main--container--header">
          <div className="hotmer--profile--data--main--container--header--name">
            {hotmerProfile.display_name}
          </div>
          <DividerSpace space="XXS" />

          {hotmerProfile.bl_hotmer_verification ? (
            <div className="hotmer--profile--data--main--container--header--verified--icon">
              <img src={verifiedIcon} alt="..." />
            </div>
          ) : (
            <div className="hotmer--profile--data--main--container--header--verified--icon"></div>
          )}
          {/*
					{hotmerProfile && hotmerProfile.bl_hotmer_verification && (
						<div className="hotmer--profile--data--main--container--header--verified--icon">
							<img src={verifiedIcon} alt="..." />
						</div>
					)}*/}
          <DividerSpace space="XXS" />
          <div className="hotmer--profile--data--main--container--header--img--profile">
            <div className="hotmer--profile--data--main--container--header--img--profile--container">
              <img src={profile.src} alt="..." onError={onErrorProfile} />
            </div>
          </div>
          <div className="hotmer--profile--data--main--container--header--options">
            {hotmerProfile && !hotmerProfile.bl_follow_hotmer && (
              <div
                className={`
						hotmer--profile--data--main--container--header--options--add ${
              successAddHotmer
                ? "hotmer--profile--data--main--container--header--options--add--withouttext"
                : ""
            }
						`}
                onClick={() => {
                  if (!hotmerProfile.bl_follow_hotmer) {
                    clickAddHotmer();
                  }
                }}
              >
                {loadingClickAdd ? (
                  <MiniSpinner />
                ) : hotmerProfile.bl_follow_hotmer ? (
                  ""
                ) : (
                  t("post.header.follow")
                )}
              </div>
            )}
            <DotsOptions id={id} hotmerUser={hotmerProfile?.display_name} />
          </div>
        </div>
        <div className="hotmer--profile--data--main--container--image">
          <img
            className="hotmer--profile--data--main--container--image--photo "
            src={cover.src}
            alt="..."
            onError={onErrorCover}
          />
          <div className="hotmer--profile--data--main--container--image--half--circle">
            <div className="hotmer--profile--data--main--container--image--half--circle--container">
              {/* <div className="hotmer--profile--data--main--container--image--half--circle--container--polygon">
                <div className="hotmer--profile--data--main--container--image--half--circle--container--polygon--graph" />
              </div> */}
              {hotmerProfile.id_type_of_role === 2 ? (
                <div className="hotmer--profile--data--main--container--present--mask1">
                  {/*<img src={verifiedIcon} alt="..." />*/}
                  <button className="hotmer--profile--data--main--container--present--mask1--button1">
                    {" "}
                    {t("post.header.options.photographer")}{" "}
                  </button>
                </div>
              ) : (
                <div className="hotmer--profile--data--main--container--present--mask1">
                  {/*Si no es fotografo o Manager no muestro este detalle*/}
                </div>
              )}
              {hotmerProfile.id_type_of_role === 3 ? (
                <div className="hotmer--profile--data--main--container--present--mask1">
                  {/*<img src={verifiedIcon} alt="..." />*/}
                  <button className="hotmer--profile--data--main--container--present--mask1--button1">
                    {" "}
                    {t("post.header.options.agency")}{" "}
                  </button>
                </div>
              ) : (
                <div className="hotmer--profile--data--main--container--present--mask1">
                  {/*Si no es fotografo o Manager no muestro este detalle*/}
                </div>
              )}
            </div>
          </div>
          <div className="hotmer--profile--data--main--container--image--mask--items" id="stadisticsHotmers">
            <div className="home--main--container--hotmers--element--content--statistics--element--profile--hotmer">
              <ContentTypeProfileHorizontal
                icon={galleryWhiteIcon}
                value={hotmerProfile?.total_image}
              />
              <ContentTypeProfileHorizontal
                icon={videoWhiteIcon}
                value={hotmerProfile?.total_video}
              />
              <ContentTypeProfileHorizontal
                icon={packWhiteIcon}
                value={hotmerProfile?.total_pack}
              />
            </div>
          </div>
          {/* EX PELOTAS FEAS */}
          {/* <div className="hotmer--profile--data--main--container--image--mask">
            <div className="hotmer--profile--data--main--container--image--mask--top">
              <ElementCircularData
                description={t("profile.hotmer.circular.photo")}
                value={hotmerProfile.total_image}
              />
              <ElementCircularData
                description={t("profile.hotmer.circular.pack")}
                value={hotmerProfile.total_pack}
              />
            </div>
            <div className="hotmer--profile--data--main--container--image--mask--down">
              <ElementCircularData
                description={t("profile.hotmer.circular.video")}
                value={hotmerProfile.total_video}
              />
            </div>
          </div> */}
          <div
            className={`hotmer--profile--data--main--container--present--mask ${
              hotmerProfile && hotmerProfile.bl_subscriber_hotmer
                ? "over--position--div"
                : ""
            }`}
          >
            <div
              className={`hotmer--profile--data--main--container--present--mask--present ${
                hotmerProfile && hotmerProfile.bl_subscriber_hotmer
                  ? "ask--no--suscription"
                  : "ask--suscription"
              }`}
              onClick={() =>
                clickOptionHotmer(
                  hotmerProfile.bl_subscriber_hotmer,
                  hotmerProfile.id_user_hotmer
                )
              }
            >
              {!hotmerProfile?.bl_subscriber_hotmer && (
                <>
                  <img
                    src={darkFireIcon}
                    alt="..."
                    className="hotmer--profile--data--main--container--present--mask--present--image"
                  />

                  <DividerSpace space="XS" />
                  <div className="hotmer--profile--data--main--container--present--mask--present--text">
                    {/* {getOptionHotmer(hotmerProfile.bl_subscriber_hotmer)}*/}
                    {t("button.subscribe.title")}
                  </div>
                </>
              )}
            </div>
            {/* NUEVO BOTON DE REGALO.  */}
            <div
              className="mask--present--icon"
              onClick={() => clickPresent(hotmerProfile.id_user_hotmer)}
            >
              <img
                className="hotmer--profile--present"
                src={tipIcon}
                alt="Regalo"
              ></img>
            </div>
            {/*</div>*/}
          </div>
        </div>
        <div className="hotmer--profile--data--main--container--message">
          <div className="hotmer--profile--data--main--container--message--text">
            {t("profile.deescriptionArea.placeholder")}
            {/* Suscribite a mi perfil Hotmy */}
          </div>
        </div>
      </div>
    );
  }
};
