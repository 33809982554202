import { useState, useEffect, Fragment } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import imageProfile from "../../icons/fueguito-card-1.png";
import "./ProfileHotmer.css";

export const ProfileHotmer = ({
  full_name,
  nick_name,
  image,
  imageSize,
  typeDeal,
  deal,
}) => {
  const [profile, setProfile] = useState({
    src: image,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setProfile({
      src: image || imageProfile,
      errored: false,
    });
  }, [image]); // eslint-disable-line

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const getDealRol = ({ ds_type_of_role }) => ds_type_of_role;
  const getColorStatus = ({ status }) =>
    status === "rejected" ? "red" : status === "pending" ? "brown" : "green";

  const getStatus = ({ status: s }) =>
    s === "rejected" ? "Rechazado" : s === "pending" ? "Pendiente" : "Aprobado";

  return (
    <div className="profile--hotmer--main--container">
      <img
        className="profile--hotmer--main--container--image"
        onError={onErrorProfile}
        src={profile.src}
        alt="..."
        style={imageSize}
      />
      <div>
        <strong>{full_name}</strong>
        <div>{nick_name}</div>
        {user.rol !== "hotmers" && typeDeal === "request" && (
          <div style={{ paddingTop: "0.2rem", color: getColorStatus(deal) }}>
            {getStatus(deal)}
          </div>
        )}
        {user.rol === "hotmers" && typeDeal === "request" && (
          <Fragment>
            <div style={{ paddingTop: "0.2rem" }}>{getDealRol(deal)}</div>
            <div style={{ color: getColorStatus(deal) }}>{getStatus(deal)}</div>
          </Fragment>
        )}
        {user.rol === "hotmers" && typeDeal === "agreement" && (
          <div style={{ paddingTop: "0.2rem" }}>{getDealRol(deal)}</div>
        )}
      </div>
    </div>
  );
};
