import React from "react";
import photoIcon from "../../icons/profile/photo-icon.png";
import videoIcon from "../../icons/profile/video-icon.png";
import blockedIcon from "../../icons/whiteIcons/blocked-icon.png";
import { useHistory } from "react-router-dom";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { useTranslation } from "react-i18next";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./blockedContentProfile.css";

export const BlockedContentProfile = ({ hotmerProfile, id }) => {
  const history = useHistory();
  const { t } = useTranslation("global");

  const subscribeHotmer = () => history.push(`/hotmerSuscribe/${hotmerProfile.id_user_hotmer}`);

  return (
    <div className="hotmer--profile--main--container--content--blocked">
      <img
        className="hotmer--profile--main--container--content--candado"
        src={blockedIcon}
        alt="..."
      />
      <div className="hotmer--profile--main--container--content--photo--video">
        <div className="hotmer--profile--main--container--content--photo--video--element">
          <img src={photoIcon} alt="..." />
          <div>{hotmerProfile.total_image ? hotmerProfile.total_image : 0}</div>
        </div>
        <div className="hotmer--profile--main--container--content--photo--video--element">
          <img src={videoIcon} alt="..." />
          <div>{hotmerProfile.total_video ? hotmerProfile.total_video : 0}</div>
        </div>
      </div>
      <DividerSpace space="XS" />
      <div style={{ width: "200px", height: "35px" }}>
        <CustomButtonRegister onClick={subscribeHotmer}>
          {t("button.unlock.title")}
        </CustomButtonRegister>
      </div>
    </div>
  );
};
