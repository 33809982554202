/* eslint-disable */
import React from "react";

// Componentes
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Estilos
import { makeStyles } from '@material-ui/core/styles';
import "./mobileWheelSelector.css";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		maxWidth: 400,
		maxHeight: 400,
		backgroundColor: theme.palette.background.paper,
	},
	list_item: {
		padding: '10px 20px 10px 20px',
	},
	image_modal: {
		paddingRight: 10,
		heigth: 40,
		width: 40,
	}
}));

export const MobileWheelSelector = ({
	type,
	title,
	data,
	chooseElement,
	idList,
}) => {

	const onClickListItem = (event, idItem) => {
		let element;
		element = data.find((c, i) => c.id == idItem);
		//console.log("event: ", event);
		//console.log("idItem: ", idItem);
		//console.log("element: ", element);
		chooseElement(element, title);
	}

	const classes = useStyles();

	return (
		<div className="option--country--main--container">
			<div className="option--country--main--container--title">{title}</div>
			<div className="option--country--main--container--list">
				<div className={classes.root}>
					<List>
						{ data && (
							data.map(item => (
								<React.Fragment key={item.id}>
									<ListItem 
										button 
										className={classes.list_item}
										key={item.id}
										onClick={event => onClickListItem(event, item.id)}
									>
										{(item.value != null & item.image == null) ?(
										<ListItemText primary={item.value}></ListItemText>
										):(
											(item.value != null && item.image != null) && (
												<>
												<img  crossOrigin="anonymous" src={item.image} className={classes.image_modal}/>
												<ListItemText primary={item.value}></ListItemText>
												</>
											)
										)}
									</ListItem>
								</React.Fragment>
							))
						)}
					</List>
				</div>
			</div>
		</div>
	);
};