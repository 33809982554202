import { toys } from "../../constants/constants";
import "./Toy.css";

export const Toy = ({ toy }) => {
  const getIconBy = (n) => {
    const toy = toys.find((toy) => toy.id === n);
    return toy.icon;
  };

  return (
    <div className="hotmer--pack--search--main--container--toys--item">
      <img src={getIconBy(toy)} alt="..." />
    </div>
  );
};
