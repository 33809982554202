/* eslint-disable */
import React from "react";
import "./customInputTextIconEdit.css";

export const CustomInputTextIconEdit = ({
	placeholder,
	name,
	value,
	onChange,
	type,
	image,
	maxLength,
	disabled,
}) => {
	return (
		<div className="custom--input--text--icon--edit--container">
			<img
				className="custom--input--text--icon--edit--container--image"
				src={image}
				alt="..."
			/>
			<input
				className={`${!disabled ? "custom--input--text--icon--edit--container--input" : "custom--input--text--icon--edit--container--disabled"}`}
				name={name}
				maxLength={maxLength}
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={(e) => {
					if (name === "ds_phone_number") {
						const regExp = /^[+-]?([0-9]+([0-9]*)?|[0-9]+)$/;
						if (e.target.value === "" || regExp.test(e.target.value)) {
							if (!(e.target.value == "e")) onChange(e);
						}
					} else if (name === "ds_display_name") {
						const regExp = /^([0-9A-Za-z-_.]{0,18})$/;
						if (regExp.test(e.target.value)) {
							onChange(e);
						}
					} else {
						onChange(e);
					}
				}}
				disabled={disabled || false}
			/>
		</div>
	);
};
