export const initialState = {
  loadingHotmers: false,
  errorHotmers: false,
  hotmers: null,
  hotmersLimit: 80, // CAMBIO DE LIMITES
  hotmersOffset: 0,
  stopHotmersFetch: false,
  flagChangeHotmers: false,
  /* ************************************ */
  loadingToys: false,
  errorToys: false,
  toys: null,
  /* ************************************ */
  search: { data: "" },
  /* ************************************ */
  loadingPacks: false,
  errorPacks: false,
  packs: null,
  packsLimit: 40,  // CAMBIO DE LIMITES
  packsOffset: 0,
  stopPacksFetch: false,
  flagChangePacks: false,
  /* ************************************ */
  profileOption: "relevant",
  packOption: "recent",
};
