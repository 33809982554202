/* eslint-disable */
import React, { useEffect, useState } from "react";
import okIcon from "../../icons/payment/ok-green-icon.png";
// import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { SuccessScreen } from "../successScreen/successScreen";
import { CardPriceTitle } from "../../commonComponents/cardPriceTitle/cardPriceTitle";
import { CurrencyOption } from "../../commonComponents/currencyOption/currencyOption";
import { CustomButtonBuy } from "../../commonComponents/customButtonBuy/customButtonBuy";
import { PackAvatarCreator } from '../../components/PackAvatarCreator/PackAvatarCreator'
import { PaymentsSection } from "../../components/paymentsSection/paymentsSection";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { alertMessageSubscription } from "../../libs/subscribe";
import "./packDetail.css";
import { PackContentSummary } from "../../components/PackContentSummary/PackContentSummary";

export const PackDetail = ({ match }) => {
	const [loadingPackData, setLoadingPackData] = useState(false);
	const [packData, setPackData] = useState(null);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [successScreen, setSuccessScren] = useState(false);
	const [currency, setCurrency] = useState(null);
	const [symbol, setSymbol] = useState("$");
	const [value, setValue] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
	const [preferenceId, setPreferenceId] = useState(null);

	const chooseCurrency = (currency) => setCurrency(currency);

	// const buyPack = async () => {
	// 	try {
	// 		const id = match.params.id_pack;
	// 		const idHotmer = match.params.id_hotmer;
	// 		setLoadingSubmit(true);
	// 		const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/pack/buy`;
	// 		const res = await fetch(url, {
	// 			headers: setHeaders(),
	// 			method: "POST",
	// 			body: JSON.stringify({ id_pack: id }),
	// 		});
	// 		console.log("RES BUY: ", res);
	// 		if (res.status === 201) {
	// 			const response = await res.json();
	// 			console.log("RESPONSE BUY: ", response.data);
	// 			setLoadingSubmit(false);
	// 			setSuccessScren(true);
	// 		} else {
	// 			setLoadingSubmit(false);
	// 			alertMessageSubscription();
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 		setLoadingSubmit(false);
	// 		alertMessageSubscription();
	// 	}
	// };

	const initMp = async () => {
		// history.push("/successPaymentPack");
		const id_pack = match.params.id_pack;
		const id_hotmer = match.params.id_hotmer;
		const jsonData = {
			id_pack,
			id_hotmer,
			id_country: currency === "USD" ? 10 : 1,
			id_type_of_payment_method: paymentMethodSelected,
		};
		console.log(jsonData);
		try {
			setLoadingSubmit(true);
			const url = `${process.env.REACT_APP_INITIAL_V2}/payment/direct/pack`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: JSON.stringify(jsonData),
			});
			// console.log("RES PACK: ", res);
			// console.log("RESPONSE PACK: ", response);
			if (res.status == 201) {
				// MERCADO PAGO...
				const response = await res.json();
				if (paymentMethodSelected == 1) {
					console.log("RESPONSE PACK: ", response);
					const idPreference = response.data.id;
					setPreferenceId(idPreference);
					window.location.href = response.data.uri;
				}
				if (paymentMethodSelected == 2) {
					console.log("PAYPAL");
					window.location.href = response.data.uri;
				}
			} else {
				alertMessageSubscription();
			}
		} catch (error) {
			console.log(error);
			// alertMessageSubscription();
		}
		setLoadingSubmit(false);
	};

	const getPackData = async (idHotmer, idPack) => {
		setLoadingPackData(true);
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/pack/${idPack}/preview/payment`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			const response = await res.json();
			// console.log("RES PACK DETAIL: ", res);
			// console.log("RESPONSE PACK DETAIL: ", response);
			if (res.status == 200 && response.code == 200) {
				setPackData(response.data);
				//setCurrency(response.data.global_price.currency.ds_currency);
				setCurrency(response.data.local_price.currency.ds_currency ? response.data.local_price.currency.ds_currency : response.data.global_price.currency.ds_currency);
				//setSymbol(response.data.global_price.currency.ds_symbol);
				setSymbol(response.data.local_price.currency.ds_symbol ? response.data.local_price.currency.ds_symbol : response.data.global_price.currency.ds_symbol);
				//setValue(response.data.global_price.vl_pack_price);
				setValue(response.data.local_price.vl_pack_price ? response.data.local_price.vl_pack_price : response.data.global_price.vl_pack_price);
			}
		} catch (error) {
			console.log("ERROR: ", error);
		}
		setLoadingPackData(false);
	};

	const onClickPaymentMethod = (typeMethod) => {
		setPaymentMethodSelected(typeMethod);
	};

	const onClickRefreshContent = () =>
		getPackData(match.params.id_hotmer, match.params.id_pack);

	useEffect(() => {
		setPaymentMethodSelected(null);
		if (currency) {
			// console.log("CHOOSE CURRENCY: ", currency);
			if (currency === "USD") {
				setCurrency(packData.global_price.currency.ds_currency);
				setSymbol(packData.global_price.currency.ds_symbol);
				setValue(packData.global_price.vl_pack_price);
				setPaymentMethod(packData.global_price.payment_method);
			} else {
				setCurrency(packData.local_price.currency.ds_currency);
				setSymbol(packData.local_price.currency.ds_symbol);
				setValue(packData.local_price.vl_pack_price);
				setPaymentMethod(packData.local_price.payment_method);
			}
		}
	}, [currency]); // eslint-disable-line

	useEffect(() => {
		if (preferenceId) {
			console.log("PREFERENCE ID GENERADO: ", preferenceId);
			const script = document.createElement("script");
			script.src =
				"https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
			script.type = "text/javascript";
			script.dataset.preferenceId = preferenceId;
			const divElement = document.getElementById("mercadopago");
			divElement.style.display = "none";
			divElement.innerHTML = "";
			divElement.appendChild(script);
			setTimeout(() => {
				const x = document.getElementsByClassName("mercadopago-button");
				let clickEvent = new Event("click");
				x[0].dispatchEvent(clickEvent);
				setLoadingSubmit(false);
			}, 5000);
		}
	}, [preferenceId]);

	useEffect(() => {
		getPackData(match.params.id_hotmer, match.params.id_pack);
	}, []); // eslint-disable-line

	if (loadingPackData) {
		return <LoadingScreen loading={loadingPackData} />;
	} else if (!loadingPackData && !packData) {
		return <WrongFetchScreen onClickRefresh={onClickRefreshContent} />;
	} else if (successScreen) {
		return (
			<SuccessScreen
				name={"Titulo del pack..."}
				title={pack.title}
				icon={okIcon}
				titlePayment={"Pago realizado"}
			/>
		);
	} else {
		return (
			<div className="pack--detail--main--container">
				<LoadingScreen loading={loadingSubmit} />
				{packData?.pack_info &&
					<>
						<div className="pack--detail--main--container--hotmer">
							<div className="pack--detail--main--container--hotmer--header">
								Pack de: <span className="pack--detail--main--container--hotmer--name capitalize">{` ${packData.ds_display_name}`}</span>
							</div>
						</div>

						<div>
							{packData?.pack_info?.creator_list &&
								<>
									<div className="">
										<PackAvatarCreator
											arrayCreators={packData?.pack_info?.creator_list}
											showPackPremium={false}
											fixViewToBuyPack={true}
										/>
									</div>
								</>
							}
						</div>
						<div className="pack--detail--main--container--data--info--pack">
							<div className="pack--detail--main--container--hotmer--subscription">
								Titulo: <span className="pack--detail--main--container--hotmer--name">{` ${packData?.pack_info?.ds_title}`}</span>
							</div>
							<div className="pack--detail--main--container--hotmer--subscription">
								Descripcion:<span className="pack--detail--main--container--hotmer--name">{` ${packData?.pack_info?.ds_description}`}</span>
							</div>

							<div className="pack--detail--main--container--hotmer--subscription">
								<div>
								Contenido: 
								</div>
								<PackContentSummary
									quantityPhotos={packData?.pack_info?.count_image}
									quantityVideos={packData?.pack_info?.count_video}
									alignLeft={true}
								/>								
							</div>
						</div>
					</>
				}
				<div className="hotmer--present--main--container--hotmer">
					<div className="hotmer--present--main--container--hotmer--subscription">
						{`1 - Seleccione la moneda de pago`}
					</div>
				</div>
				<div className="pack--detail--main--container--data">
					<div className="pack--detail--main--container--data--currency">
						<div className="pack--detail--main--container--data--currency--title">
							Moneda
						</div>
						{packData.global_price.currency.ds_currency !==
							packData.local_price.currency.ds_currency && (
								<CurrencyOption
									option={packData.local_price.currency.ds_currency}
									currency={currency}
									chooseCurrency={chooseCurrency}
								/>
							)}
						<CurrencyOption
							option={packData.global_price.currency.ds_currency}
							currency={currency}
							chooseCurrency={chooseCurrency}
						/>
					</div>
					<div className="pack--detail--main--container--data--value">
						<div className="pack--detail--main--container--data--value--title">
							{""}
						</div>
						<CardPriceTitle title={"Pack"} symbol={symbol} value={value} />
					</div>
				</div>
				<div className="pack--detail--main--container--payment">
					<PaymentsSection
						onClickPaymentMethod={onClickPaymentMethod}
						paymentMethodSelected={paymentMethodSelected}
						paymentMethod={paymentMethod}
					/>
				</div>
				<div id="mercadopago" />
				{/* <form id="mercadopago"/> */}
				<div className="pack--detail--main--container--buy">
					<CustomButtonBuy onClick={initMp} disabled={!paymentMethodSelected}>
						IR A PAGAR
					</CustomButtonBuy>
				</div>
			</div >
		);
	}
};
