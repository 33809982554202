const getMultimediaTypes = (list, type) => {
  let myList = [];
  if (list !== null) {
    myList = list.map((x) => {
      return {
        ...x,
        type: type,
      };
    });
  }
  return myList;
};

export const joinMultimedia = (data) => {
  const { images, videos } = data;
  const myImages = getMultimediaTypes(images, "image");
  const myVideos = getMultimediaTypes(videos, "video");
  const multimedia = myImages.concat(myVideos);
  return multimedia;
};
