/* eslint-disable */
export const tatoo = [
	{ posee: "Si", id_type_of_tatoo: 1 },
	{ posee: "No", id_type_of_tatoo: 2 },
];

export const piercing = [
	{ posee: "Si", id_type_of_piercing: 1 },
	{ posee: "No", id_type_of_piercing: 2 },
];

export const setNewJson = (response, tatoo, piercing) => {
	const data = {
		...response.data,
		tatoo,
		piercing,
	};
	return data;
};

export const setHeadersNoToken = () => {
	const headers = {
		"Access-Control-Allow-Credentials": true,
		"Content-Type": "application/json",
	};
	return headers;
};

export const setHeaders = () => {
	const myToken = JSON.parse(localStorage.getItem("session"))?.token;
	if (myToken) {
		const headers = {
			"Authorization": `Bearer ${myToken}`,
			"Access-Control-Allow-Credentials": "true",
			"Content-Type": "application/json",
		};
		return headers;
	}
};

export const setHeadersReactQuery = ( tokenSended ) => {
	if (tokenSended) {
		const headers = {
			"Authorization": `Bearer ${tokenSended}`,
			"Access-Control-Allow-Credentials": "true",
			"Content-Type": "application/json",
		};
		return headers;
	}
};

export const setHeadersWithOutToken = () => {
	const headers = {
		"Access-Control-Allow-Credentials": true,
		"Content-Type": "application/json",
	};
	return headers;
};

export const setFormDataHeaders = () => {
	const myToken = JSON?.parse(localStorage.getItem("session"))?.token;
	const headers = {
		Authorization: `Bearer ${myToken}`,
		"Access-Control-Allow-Credentials": true,
		"Content-Type": "multipart/form-data",
	};
	return headers;
};

export const getLastUrl = () => {
	const url = localStorage.getItem("idUrl") || 0;
	const lastUrl = parseInt(url);
	return lastUrl;
};

// GET ARRAY DATA WITHOUT PARAMS
export const getArrayProcedureFetch = async (headers, url) => {
	try {
		const res = await fetch(url, {
			headers,
		});
		//console.log("RES HOTMER PACKS: ", res);
		if (res.status === 200) {
			const response = await res.json();
			return { result: response };
		}
		if (res.status === 204) {
			return { result: [] };
		}
	} catch (error) {
		console.log("ERROR: ", error);
		return { result: null };
	}
};

/* *********************** */
/* *********************** */
/* *********************** */

export const getAllYears = () => {
	let arrayDays = [];
	const today = new Date();
	const lastYear = today.getFullYear();
	for (let i = lastYear; i >= 2010; i--) {
		const id = lastYear - i;
		const value = i;
		const json = { id: String(id), value: String(value) };
		arrayDays = [...arrayDays, json];
	}
	return arrayDays;
};

export const getAvailableYears = () => {
	let arrayDays = [];
	const today = new Date();
	const lastYear = today.getFullYear() - 18;
	for (let i = lastYear; i > 1900; i--) {
		const id = lastYear - i;
		const value = i;
		const json = { id: String(id), value: String(value) };
		arrayDays = [...arrayDays, json];
	}
	return arrayDays;
};

export const month31 = (month) => {
	return (
		month == 1 ||
		month == 3 ||
		month == 5 ||
		month == 7 ||
		month == 8 ||
		month == 10 ||
		month == 12
	);
};

export const month30 = (month) => {
	return month == 4 || month == 6 || month == 9 || month == 11;
};

export const getAvailableMonths = (year) => {
	// console.log("GET AVAILABLE MONTH: ", year);
	let arrayMonths = [];
	let maybeChild;
	let lastMonth;
	const today = new Date();
	if (today.getFullYear() - 18 > parseInt(year.value)) {
		lastMonth = 12;
		maybeChild = false;
	} else {
		lastMonth = today.getMonth() + 1;
		maybeChild = true;
	}
	for (let i = 1; i <= lastMonth; i++) {
		arrayMonths = [...arrayMonths, { id: i, value: i }];
	}
	return { arrayMonths, maybeChild };
};

export const getAvailableDays = (year, option, mayChild) => {
	if (mayChild) {
		const today = new Date();
		const month = today.getMonth() + 1;
		if (month == option) {
			const dateNumber = today.getDate();
			let arrayDaysChild = [];
			for (let i = 1; i <= dateNumber; i++) {
				arrayDaysChild = [...arrayDaysChild, { id: i, value: i }];
			}
			return arrayDaysChild;
		}
	}
	let returnDays;
	if (month31(option)) {
		returnDays = 31;
	} else if (month30(option)) {
		returnDays = 30;
	} else {
		if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
			returnDays = 29;
		} else {
			returnDays = 28;
		}
	}

	let arrayDays = [];
	for (let i = 1; i <= returnDays; i++) {
		arrayDays = [...arrayDays, { id: i, value: i }];
	}
	return arrayDays;
};
