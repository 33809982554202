import React from "react";
//import RefreshIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";
import "./wrongFetchComponent.css";

export const WrongFetchComponent = ({ onClickRefresh }) => {
  const { t } = useTranslation("global");

  return (
    <div className="wrong--fetch--component--container">
      <div className="wrong--fetch--component--container--image"></div>
      <div className="wrong--fetch--component--container--image--message">
        {t("button.noContent.title")}
      </div>
    </div>
  );
};
