import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDcr6pJlWpPnoMBj3TQ20Ce2wIdPwEpUAU",
  authDomain: "hotmy-login.firebaseapp.com",
  databaseURL: "https://hotmy-login.firebaseio.com",
  projectId: "hotmy-login",
  storageBucket: "hotmy-login.appspot.com",
  messagingSenderId: "286377962861",
  appId: "1:286377962861:web:51e0de26a34ed521067e64",
  measurementId: "G-EN9P6EELZQ"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { db, googleAuthProvider, facebookAuthProvider, firebase };

/*TEST

 apiKey: "AIzaSyA18vmIbtbyy8UFefE56lgCS_DGg7zr3Fc",
  authDomain: "hotmy-test-5bf8f.firebaseapp.com",
  projectId: "hotmy-test-5bf8f",
  storageBucket: "hotmy-test-5bf8f.appspot.com",
  messagingSenderId: "446460671604",
  appId: "1:446460671604:web:1f6ad8fcaf5ac23259c321",
  measurementId: "G-ZQY797D67S"
*/
