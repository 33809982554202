import "./uploadFilesProfilePortrait.css";
import React from "react";

export const UploadFilesProfilePortrait = ({
  handleChangeAdd,
  selectedType,
  multiple,
  icon,
  // disabled,
  title = '',
  className = 'upload--files--add--Profile--Portrait--container'
}) => {
  return (
    <div className={`${ className }`} >
      <div className="upload--files--add--Profile--Portrait--container--frame">
        {icon &&    
        <img className="upload--edit--option--container--icon" 
        src={icon} 
        alt="..." />
}
        {title === '' ? (
          <></>
        ):(
          <div className="upload--edit--option--container--title">{title}</div>
        )}
        <input
            type="file"
            multiple={multiple}
            className="upload--files--add--Profile--Portrait--container--frame--input"
            onChange={handleChangeAdd}
            accept={"image/*"}
          />
      </div>
    </div>
  );
};