import { useEffect} from "react";
import paypalIcon from "../../icons/payment/paypal-icon.png";
import creditCardIcon from "../../icons/payment/mercado-pago-icon.png";
import "./paymentCard.css";

export const PaymentCard = ({
	pm,
	paymentMethodSelected,
	onClickPaymentMethod,
	haveOneDefaultPayment,
}) => {

	// DETERMINO SI HAY UN UNICO REGISTRO Y LO ASIGNO.

	useEffect(() => {
		if (haveOneDefaultPayment) {
			onClickPaymentMethod(pm?.id_type_of_payment_method);
		}
	}, [haveOneDefaultPayment, onClickPaymentMethod, pm]);

	const onClickMethod = () =>
		onClickPaymentMethod(pm?.id_type_of_payment_method);

	const methodIcon =
		pm && pm?.ds_payment_method === "mercadopago" ? creditCardIcon : paypalIcon;

	return (
		<button
			onClick={onClickMethod}
			className={`payment--card--main--container ${
				pm?.id_type_of_payment_method === paymentMethodSelected
					? "payment--card--selected"
					: ""
			}`}
		>
			<img src={methodIcon} alt="..." />
		</button>
	);
};
