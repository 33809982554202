import React from "react";
import { DividerSpace } from "../dividerSpace/dividerSpace";
import "./cardPriceTitleSelection.css";
import starIcon from "../../icons/profile/pricing-best-value.png";

export const CardPriceTitleSelection = ({
  id,
  title,
  value,
  selected,
  selectOption,
}) => {
  return (

    <div
      className="card--price--title--selection--main--container"
      onClick={() => selectOption(id)}
    >
      <div
        className={`card--price--title--selection--main--container--header ${selected ? "selected--card--header" : ""
          }`}
      >
        {title}
      </div>
      <div
        className={`card--price--title--selection--main--container--detail ${selected ? "selected--card--detail" : ""
          }`}
      >
        <div
          className={`
        card--price--title--selection--main--container--detail--currency ${selected ? "selected--card--detail--currency" : ""
            }
        `}
        >
          {value.symbol}
        </div>
        <DividerSpace space="XXS" />
        <div className="card--price--title--selection--main--container--detail--value">
          {value.price}
        </div>
      </div>
      <div
        className={`card--price--title--selection--main--container--footer ${selected ? "selected--card--header" : ""
          }
      `}
      >
        {value.discount > 0 ?
          (
            <div>{value.discount}% OFF</div>
          ) : (
            <div>
              {"PAGO ÚNICO"}
              {/* NO MUESTRO UN CARAJO SI NO HAY DESCUENTO */}
            </div>
          )
        }
        {value.bestValue === "true" ?
          (
            <img
              className="card--price--title--selection--main--container--element--fire"
              src={starIcon}
              alt="Mejor Valuado"
            />
          ) : (
            <div>
              {/* NO MUESTRO UN CARAJO el fueguito */}
            </div>
          )
        }


      </div>

    </div>
  );
};
