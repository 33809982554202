import React from "react";
import "./customInputTextSimpleEdit.css";

export const CustomInputTextSimpleEdit = ({
  placeholder,
  name,
  value,
  onChange,
  type,
  icon,
  onClick,
}) => {
  return (
    <div
      className="custom--input--text--simple--edit--container"
      onClick={onClick}
    >
      <div className="custom--input--text--simple--edit--container--icon">
        <img src={icon} alt="..." />
      </div>
      <input
        className="custom--input--text--simple--edit--container--input"
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled
      />
    </div>
  );
};
