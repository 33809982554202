
import { useState } from "react"
import Grid from "@material-ui/core/Grid";
import { Swiper, SwiperSlide } from "swiper/react";
import useExclusivePack from "../../hooks/useExclusivePack";
import "swiper/css";
import "./HotmerGridExclusivePack.css";
import { ExclusivePacks } from "../../views/ExclusivePacks/ExclusivePacks";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";

export const HotmerGridExclusivePack = () => {

    const isCreator = JSON.parse(localStorage?.getItem("session"))?.user?.hotmer;
    const { data: hotmerProfilePromotion, isFetching : isLoadingExclusivePacks, } = useExclusivePack(!isCreator)
    const [indexPack, setIndexPack] = useState<number>();
    const [showPackExclusive, setShowPackExclusive] = useState<boolean>(false);

    const handleSlideChange = (index: number) => {
        setIndexPack(index)
    };

    if (isLoadingExclusivePacks) {
        return (
            <div style={{ height: "30px", margin: '1rem'}}>
                <MiniSpinner />
            </div>
        )
    } else {
        return (
            <>
                {hotmerProfilePromotion &&
                    <div style={{ width: "100%" }}
                        className="grid--pack--main--container"
                    >
                        <Grid
                            // md xs
                            className="grid--swipper-exclusive--packs"
                            spacing={2}
                        >
                            <Swiper
                                onSlideChange={(swiper1) => handleSlideChange(swiper1.realIndex)}
                                slidesPerView={4.5}
                                spaceBetween={15}
                                centeredSlides={true}
                                centerInsufficientSlides
                                // lazy={true}
                                // Determinar si ponemos esto cuando son pocos en true.
                                loop={true}
                                className='mySwiper grid--swipper-exclusive--packs '
                            >
                                {hotmerProfilePromotion?.data?.map((exclusivePacks, index) => (
                                    <SwiperSlide
                                        key={index}
                                        className='grid--swipper-exclusive--packs--conteiner'
                                        onClick={() => setShowPackExclusive((showPackExclusive) => !showPackExclusive)}>
                                        <img
                                            src={exclusivePacks?.ds_url_thumbnail_pack}
                                            alt={`Creador+${exclusivePacks?.id_pack}`}
                                            // loading="lazy"
                                        >
                                        </img>
                                        <div>
                                            <span 
                                                className="grid--swipper-exclusive--packs--conteiner--typography">
                                                {exclusivePacks?.creator_list?.length > 0 ? 
                                                exclusivePacks?.creator_list[0]?.ds_nick_name : exclusivePacks.ds_display_name}
                                            </span>
                                        </div>
                                    </SwiperSlide>
                                ))
                                }
                            </Swiper>
                        </Grid>
                    </div>
                }
                {/* Apertura de los packs exclusivos  */}
                <ExclusivePacks
                    listOfPacks={hotmerProfilePromotion}
                    open={showPackExclusive}
                    cerrarExclusivePacks={() => setShowPackExclusive(false)}
                    idPackToShow={indexPack}
                />
            </>
        );
    }
}