import React from "react";
import editIcon from "../../icons/darkIcons/edit-icon.png";
//import { UploadFilesAdd } from "../../commonComponents/uploadFilesAdd/uploadFilesAdd";
import { UploadFilesProfilePortrait } from "../../commonComponents/uploadFilesProfilePortrait/uploadFilesProfilePortrait";
import { useTranslation } from "react-i18next";
import "./editProfileHeader.css";

export const EditProfileHeader = ({
  profile,
  onErrorProfile,
  handleProfileImage,
  selectedType,
  cover,
  onErrorCover,
  handleCoverImage,
}) => {

  const { t, i18n } = useTranslation("global");

  return (
    <div className="edit--profile--main--container--header">
      <div className="edit--profile--main--container--header--title">
      {t("editProfile.topic.editProfile")}
      </div>
      <div className="edit--profile--main--container--header--float">
        <div className="edit--profile--main--container--header--float--img">
          <img src={profile.src} onError={onErrorProfile} alt="..." />
          <div className="edit--profile--main--container--header--float--img--edit">
            <UploadFilesProfilePortrait
              handleChangeAdd={handleProfileImage}
              selectedType={selectedType}
              multiple={false}
              disabled={false}
              icon={editIcon}
              className={'upload--files--add--Profile--Portrait--container'}
            />
          </div>
        </div>
      </div>
      <div className="edit--profile--main--container--header--body">
        <img
          className="edit--profile--main--container--header--body--cover"
          src={cover.src}
          onError={onErrorCover}
          alt="..."
        />
        <div className="edit--profile--main--container--header--body--float">
          <UploadFilesProfilePortrait
            handleChangeAdd={handleCoverImage}
            selectedType={selectedType}
            multiple={false}
            disabled={false}
            icon={editIcon}
            className={'upload--files--add--Profile--Portrait--container'}
          />
        </div>
      </div>
    </div>
  );
};
