import React from "react";
import { PackImageUnauthorized } from "../../components/packImage/packImageUnauthorized";
import { PackDetails } from "../../components/packDetails/packDetails";
import { PackOptions } from "../../components/packOptions/packOptions";
//import "./packContent.css";

export const PackViewUnauthorized = ({ pack, owner }) => {
  return (
    <div className="hotmer--pack--search--main--container--content">
      <PackImageUnauthorized pack={pack}/>
      <PackDetails pack={pack} />
      <PackOptions pack={pack} owner={owner} />
    </div>
  );
};
