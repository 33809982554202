import { hmyApiWithToken } from "../api/hmyApiWithToken"; //siempre usamos esta hmyapi pasa simplificar las cabeceras. 
import { useQuery } from '@tanstack/react-query'
import { PackExclusive } from '../interfaces/PackExclusive';

const getPacksExclusiveToBuy = async ({ queryKey }): Promise<PackExclusive> => {
    // const id = '2'
    const [_, isCreator, idUser, idCreator] = queryKey
    const myToken = JSON.parse(localStorage.getItem("session"))?.token;
    const { data } = await hmyApiWithToken(myToken).get(`/hotmers/${8}/exclusivePacks`)
    // Esta hardcodeado el usuario 8 
    return data;
    // usa este sp SELECT * FROM public.exclusive()
}

const useExclusivePack = (isCreator? : boolean, idCreator?: number, idUser? : number) => {
    // le tiene que llegar el id de la creadora o el dsname
    const exclusivePacks = useQuery(
        ['getPacksExclusiveByHotmy', isCreator, idCreator, idUser], getPacksExclusiveToBuy,
        {
            staleTime: 1000 * 60 * 60,
            //ESTO LO QUE TE DICES ES QUE PIENSE QUE POR UN TIEMPO X 1 HORA TODA LA DATA QUE ESTAMOS TRAYENDO ES FRESCA Y ESTA CACHEADA. 
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: !! isCreator
            // initialData: 
            //     {
            //         code: 123,
            //         data: filteredPromos,
            //         message: '',
            //         success: true
            //     }
        }
    )
    return exclusivePacks;
}

export default useExclusivePack


// const filteredPromos = [
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             },
//             {
//                 id_creator: 18360,
//                 ds_nick_name: "luna",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/18360/thumbnail/JDJ5JDEwJHRudUEveENsRGtXSFJHZnlPMHU1RS4xcC5Dei5JeTBBWGd0ajNkMDdidW1Ya1R1SHhIUnlh.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     },
//     {
//         tags: [
//             1,
//             2
//         ],
//         id_pack: 785,
//         currency: 4276.8,
//         ds_title: "Pack",
//         vl_price: 12.96,
//         id_hotmer: 8,
//         count_image: 10,
//         count_video: 0,
//         vl_discount: 10,
//         creator_list: [
//             {
//                 id_creator: 20,
//                 ds_nick_name: "rocio",
//                 ds_profile_photo_thumbnail_path: "https://hmymediaserviceprod.ddns.net/uploads/20/thumbnail/JDJ5JDEwJEZJYXU1NDVrUUk1QlV6OVdwdWw3Yy5WRzE1ci53dGc5UWVKZkNxSnRCWEw1WWpmMXouWVRX.jpg"
//             }
//         ],
//         ds_description: "Me gusta jugar con vos",
//         ds_display_name: "carlamolerok1234",
//         ds_currency_code: "ARS",
//         ds_url_img_profile: "https://hmymediaserviceprod.ddns.net/uploads/8/profile/JDJ5JDEwJHJoVmZpWkdEeWtYQzc1bkh3b1AxTWVtTEdlalJRZnNYby9pVElsalh0ZlljYjhnMDlwYlNH.jpg",
//         ds_url_thumbnail_pack: "https://hmymediaserviceprod.ddns.net/uploads/8/thumbnail/JDJ5JDEwJFZPaXZmSHdTajRYM2tLc09mWS9jVWU2YVZnakdjSWxUcTF6cUM5cHFOckF1bmJIcll3VkdT.jpg"
//     }
// ]