/* eslint-disable */
import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import backIcon from "../../icons/darkIcons/back--icon.png";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getIdUser } from "../../libs/login";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DropDownDate } from "../../commonComponents/dropDownDate/dropDownDate";
import { DestinyAccount } from "../../components/destinyAccount/destinyAccount";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { ExtractionTitle } from "../../components/extractionTitle/extractionTitle";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import {
	getAllYears,
	getAvailableDays,
	getAvailableMonths,
	setHeaders,
} from "../../libs/fetch";
import {
	applyDateFilter,
	applyFilterByChecks,
	createFilterParamsState,
	createPathParams,
	sameDates,
} from "../../libs/extractions";
import "./extractionsActivityFilter.css";

export const ExtractionsActivityFilter = ({
	f,
	setF,
	handleStep,
	filtersParams,
	setFiltersParams,
	limitOffset,
	setLimiteOffset,
	loadingListActivity,
	setLoadingListActivity,
	listActivity,
	setListActivity,
}) => {
	const [filterChecks, setFilterChecks] = useState(null);

	const [modalMessage, setModalMessage] = useState("");
	const [openModal, setOpenModal] = useState(false);

	const [selectedType1, setSelectedType1] = useState(null);
	const [titleType1, setTitleType1] = useState(null);

	const [selectedType2, setSelectedType2] = useState(null);
	const [titleType2, setTitleType2] = useState(null);

	const [optionsYears, setOptionsYears] = useState(null);
	const [optionsMonths, setOptionsMonths] = useState(null);
	const [mayChild, setMayChild] = useState(null);
	const [optionsDays, setOptionsDays] = useState(null);

	const [date1, setDate1] = useState({ year: "", month: "", day: "" });
	const [date2, setDate2] = useState({ year: "", month: "", day: "" });

	const [openModalOptions1, setOpenModalOptions1] = useState(false);
	const [optionsList1, setOptionsList1] = useState(null);

	const [openModalOptions2, setOpenModalOptions2] = useState(false);
	const [optionsList2, setOptionsList2] = useState(null);

	const onChange = (e) =>
		setFilterChecks({ ...filterChecks, [e.target.name]: e.target.checked });

	// Funciones para fecha/mes/año DESDE

	const onChooseOption1 = (option, title) => {
		if (title === "Año") {
			setDate1({ ...date1, year: option.value, day: "", month: "" });
			const { arrayMonths, maybeChild } = getAvailableMonths(option);
			setOptionsMonths(arrayMonths);
			setMayChild(maybeChild);
		} else if (title === "Mes") {
			setDate1({ ...date1, month: option.value, day: "" });
			const availableDays = getAvailableDays(
				date1.year,
				option.value,
				mayChild,
			);
			setOptionsDays(availableDays);
		} else {
			setDate1({ ...date1, day: option.value });
		}
		setOpenModalOptions1(false);
	};

	const onChooseType1 = (type) => {	
		if (type === "year") {
			setSelectedType1("Año");
			setTitleType1({ type: "year", name: "año" });
			setOptionsList1(optionsYears);
		} else if (type === "month") {
			setSelectedType1("Mes");
			setTitleType1({ type: "month", name: "mes" });
			setOptionsList1(optionsMonths);
		} else if (type === "day") {
			setSelectedType1("Dia");
			setTitleType1({ type: "day", name: "dia" });
			setOptionsList1(optionsDays);
		}
		setOpenModalOptions1(true);
	};

	// Funciones para fecha/mes/año HASTA

	const onChooseOption2 = (option, title) => {

		console.log("title: ", title);

		if (title === "Año") {
			setDate2({ ...date2, year: option.value, day: "", month: "" });
			const { arrayMonths, maybeChild } = getAvailableMonths(option);
			setOptionsMonths(arrayMonths);
			setMayChild(maybeChild);
		} else if (title === "Mes") {
			setDate2({ ...date2, month: option.value, day: "" });
			const availableDays = getAvailableDays(
				date2.year,
				option.value,
				mayChild,
			);
			setOptionsDays(availableDays);
		} else {
			setDate2({ ...date2, day: option.value });
		}
		setOpenModalOptions2(false);
	};

	const onChooseType2 = (type) => {
		setSelectedType2(type);
		if (type === "year") {
			setSelectedType2("Año");
			setTitleType2({ type: "year", name: "day" });
			setOptionsList2(optionsYears);
		} else if (type === "month") {
			setSelectedType2("Mes");
			setTitleType2({ type: "month", name: "mes" });
			setOptionsList2(optionsMonths);
		} else if (type === "day") {
			setSelectedType2("Dia");
			setTitleType2({ type: "day", name: "dia" });
			setOptionsList2(optionsDays);
		}
		setOpenModalOptions2(true);
	};



	const applyFilters = async () => {
		const newFiltersParams = applyFilterByChecks(filterChecks, filtersParams);
		const filtered2 = applyDateFilter(date1, date2, newFiltersParams);
		console.log("FINAL CHECKS: ", filterChecks);
		console.log("FINAL FILTER: ", filtered2);
		if (filtered2.status === false) {
			const m = "La fecha inicio debe ser estrictamente menor a la fecha final";
			setModalMessage(m);
			setOpenModal(true);
		} else {
			setLimiteOffset({ limit: 2, offset: 0 });
			const newList = filtered2.result;
			/* ******** */
			const actualUrl = createPathParams(newList);
			const id = getIdUser();
			const base = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/activity`;
			const limit = `limit=${limitOffset.limit}`;
			const offset = `offset=${0}`;
			const url = `${base}?${limit}&${offset}&${actualUrl}`;
			try {
				setLoadingListActivity(true);
				const res = await fetch(url, {
					headers: setHeaders(),
				});
				// console.log("RES ACTIVITY: ", res);
				if (res.status === 200) {
					const response = await res.json();
					console.log("RESPONSE ACTIVITY: ", response.data);
					setListActivity(response.data.activity);
				} else if (res.status === 204) {
					setListActivity([]);
				}
			} catch (error) {
				console.log(error);
			}
			setLoadingListActivity(false);
			/* ******** */
			setFiltersParams(filtered2.result);
			setF(filterChecks);
			setFilterChecks(null);
			handleStep(4);
		}
	};

	useEffect(() => {
		const availableYears = getAllYears();
		setOptionsYears(availableYears);
	}, []);

	useEffect(() => {
		if (f) {
			console.log("F: ", f);
			setFilterChecks(f);
		}
	}, [f]);

	useEffect(() => {
		if (filtersParams) {
			const { jsonDateInit, jsonDateFinish } = createFilterParamsState(
				filtersParams,
			);
			setDate1(jsonDateInit);
			setDate2(jsonDateFinish);
		}
	}, [filtersParams]);

	return (
		<div className="extractions--activity--filter--main--container">
			<DestinyAccount
				icon={backIcon}
				title="Filtro"
				options={false}
				onClickIcon={() => {
					// console.log("BACK TO AVTIVITIES: ", filtersParams);
					setFiltersParams(filtersParams);
					setFilterChecks(null);
					handleStep(4);
				}}
			/>
			<MoneyContainer>
				<ExtractionTitle
					title="Filtrar por Tipo de Ingreso"
					helper={true}
					icon={playVideoIcon}
					popperTitle={"Filtros"}
					popperMessage={"Seleccione sus preferencias"}
				/>
				<div className="extractions--activity--filter--main--container--options">
					{/* <FormControlLabel
						control={
							<Checkbox
								checked={f.checkedA}
								onChange={onChange}
								name="checkedA"
							/>
						}
						label="Todas las Transacciones"
					/> */}
					{filterChecks && (
						<>
							<FormControlLabel
								control={
									<Checkbox
										checked={filterChecks.checkedB}
										onChange={onChange}
										name="checkedB"
									/>
								}
								label="Suscripciones"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={filterChecks.checkedC}
										onChange={onChange}
										name="checkedC"
									/>
								}
								label="Packs"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={filterChecks.checkedD}
										onChange={onChange}
										name="checkedD"
									/>
								}
								label="Regalos"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={filterChecks.checkedE}
										onChange={onChange}
										name="checkedE"
									/>
								}
								label="Extracciones"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={filterChecks.checkedF}
										onChange={onChange}
										name="checkedF"
									/>
								}
								label="Referido"
							/>
						</>
					)}
				</div>
				<ExtractionTitle
					title="Filtrar por Fecha"
					helper={false}
					icon={playVideoIcon}
				/>
				<div className="extractions--activity--filter--main--container--dates">
					<div className="extractions--activity--filter--main--container--dates--title">
						Desde
					</div>
					<div className="extractions--activity--filter--main--container--dates--selects">
						<DropDownDate
							type={"year"}
							value={date1.year}
							onChooseType={onChooseType1}
							disabled={false}
							textColor="#000000"
						/>
						<DropDownDate
							type={"month"}
							value={date1.month}
							onChooseType={onChooseType1}
							disabled={date1.year === ""}
							textColor="#000000"
						/>
						<DropDownDate
							type={"day"}
							value={date1.day}
							onChooseType={onChooseType1}
							disabled={date1.month === ""}
							textColor="#000000"
						/>
					</div>
				</div>
				<div className="extractions--activity--filter--main--container--dates">
					<div className="extractions--activity--filter--main--container--dates--title">
						Hasta
					</div>
					<div className="extractions--activity--filter--main--container--dates--selects">
						<DropDownDate
							type={"year"}
							value={date2.year}
							onChooseType={onChooseType2}
							disabled={false}
							textColor="#000000"
						/>
						<DropDownDate
							type={"month"}
							value={date2.month}
							onChooseType={onChooseType2}
							disabled={date2.year === ""}
							textColor="#000000"
						/>
						<DropDownDate
							type={"day"}
							value={date2.day}
							onChooseType={onChooseType2}
							disabled={date2.month === ""}
							textColor="#000000"
						/>
					</div>
				</div>
			</MoneyContainer>
			<div className="extractions--available--main--container--button">
				<CustomButtonRegister
					onClick={applyFilters}
					disabled={
						(JSON.stringify(f) === JSON.stringify(filterChecks) &&
							sameDates(filtersParams, date1, date2)) ||
						(filterChecks &&
							!filterChecks.checkedB &&
							!filterChecks.checkedC &&
							!filterChecks.checkedD)
					}
				>
					APLICAR FILTROS
				</CustomButtonRegister>
			</div>
			<CustomModal
				openModal={openModalOptions1}
				setOpenModal={setOpenModalOptions1}
			>
				<MobileWheelSelector
					title={selectedType1}
					type="onlyText"
					data={optionsList1}
					chooseElement={onChooseOption1}
					idList={"DATEINIT"}
				/>
			</CustomModal>
			<CustomModal
				openModal={openModalOptions2}
				setOpenModal={setOpenModalOptions2}
			>
				<MobileWheelSelector
					title={selectedType2}
					type="onlyText"
					data={optionsList2}
					chooseElement={onChooseOption2}
					idList={"DATEFINISH"}
				/>
			</CustomModal>
			<CustomModal openModal={openModal} setOpenModal={setOpenModal}>
				<MessageIncompleteData message={modalMessage} />
			</CustomModal>
		</div>
	);
};
