import React, { useState } from "react";
import { PackImage } from "../packImage/packImage";
import { PackDetails } from "../packDetails/packDetails";
import { PackOptions } from "../packOptions/packOptions";
import { PacksOfCreators } from "../PacksOfCreators/PacksOfCreators"
import "./packContent.css";

export const PackContent = ({ pack, owner }) => {
  const [openDescription, setOpenDescription] = useState(false);
  return (
    owner === 0 ?
      <>
        {/* son los packs que se le muestran al creador con mas detalle, esto esta pendiente de mejorar */}
        <PacksOfCreators pack={pack} />
      </>
      :
      <div className="hotmer--pack--search--main--container--content">
        <PackImage pack={pack} setOpenDescription={setOpenDescription} />
        <PackDetails pack={pack} />
        <PackOptions pack={pack} owner={owner} />
        <div
          className={`hotmer--pack--search--main--container--content--description--float ${openDescription ? "open--pack--description" : ""
            }`}
          onClick={() => setOpenDescription((x) => !x)}
        >
          {pack.ds_description}
        </div>
      </div>
  );
};
