import './PackPrice.css'

interface packPriceInterface {
    haveDiscount?: boolean
    discount?: number
    price?: number
    // finalPrice?: number
}

export const PackPrice = ({ haveDiscount, discount, price }: packPriceInterface) => {

    const precioFinal = ( precio? : number, descuento? : number) =>{
        const precioFinalCalculado = Number(precio + ((precio * descuento)/100)).toFixed(2)
        return precioFinalCalculado
    }

    return (
        <>
            {haveDiscount ?
                <div className="container-price">
                    <div className="PriceFinal align--flex--left">
                        {'$ '}{price}
                    </div>
                    <div className="PriceWitoutDiscount align--flex--right">{'$ '}{precioFinal(price,discount)}</div>
                    <div className="Discount align--flex--right">
                        {discount}{' %'}
                        <span className='Discount--OFF'>
                            {' OFF'}
                        </span>
                    </div>
                </div>
                :
                <div className="container-price--withOutDiscount">
                    <div className="PriceFinal align--flex--left">
                        {'$ '}{price}
                    </div>
                </div >
            }
        </>
    )

}
