import { useState } from "react";
import commentsIcon from "../../icons/comentarios-card.png";
import packWhiteIcon from "../../icons/grayIcons/pack-icon.png";
import videoWhiteIcon from "../../icons/grayIcons/video-icon.png";
import galleryWhiteIcon from "../../icons/grayIcons/gallery-icon.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { IconLikePost } from "../iconLikePost/iconLikePost";
import { ButtonPresent } from "../buttonPresent/buttonPresent";
import CommentPostUser from "../../views/CommentPostUser/CommentPostUser"
// import { useTranslation } from "react-i18next";
import { ButtonSubscribe } from "../buttonSubscribe/buttonSubscribe";
import { ContentTypeProfile } from "../contentTypeProfile/contentTypeProfile-noclick";
import "./hotmerHomeFooter.css";

const HotmerHomeFooter = ({
  hotmer,
  setLikedButton,
  index,
  // clickComments,
  id_content_group,
}) => {
  const history = useHistory();
  // const { t } = useTranslation("global");

  const [openCommentContent, setOpenCommentContent] = useState();

  // Aca lo manda a una ruta de comments, al clickear en el boton. Se debe levantar el componente de BootonSheet.
  const clickComments = () => {
    const idh = hotmer.id_user_hotmer;
    const idc = id_content_group;
    history.push(`/commentsPost/${idh}/${idc}`);
  };

  const openCommentByCreatorByContent = () => {
    setOpenCommentContent(true)
  }

  let idUser = getIdUser();

  return (
    <>
      <div className="home--main--container--hotmers--element--footer">
        <div className="home--main--container--hotmers--element--footer--likes">
          {hotmer.bl_blur ? (
            <></>
          ) : (
            <div className="home--main--container--hotmers--element--footer--likes--icons">
              <IconLikePost
                liked={hotmer.bl_liked}
                setLikedButton={() => setLikedButton(id_content_group)}
                index={index}
              />
              <DividerSpace space="XS" />
              <img
                className="home--main--container--hotmers--element--footer--likes--icons--comments"
                alt="..."
                // onClick={clickComments}
                onClick={() => setOpenCommentContent((openCommentContent) => !openCommentContent)}
                src={commentsIcon}
              />
            </div>
          )}
          <DividerSpace space="XXS" />
          {/* ELIMINE LOS LIKES Y COMENTARIOS CUANDO NO HAY POSIBILIDAD DE INTERACCION.  */}
          {/* <div className="home--main--container--hotmers--element--footer--likes--results">
          {`${hotmer.total_likes || 0} ${t("post.footer.like")}`}
        </div>
        <DividerSpace space="XXS" />
        <div className="home--main--container--hotmers--element--footer--likes--results">
          {`${hotmer.total_comment || 0} ${t("post.footer.comments")}`}
        </div> */}
        </div>
        {!hotmer.bl_subscriber_hotmer && !hotmer.bl_blur && (
          <div className="home--main--container--hotmers--element--footer--statistics">
            <div className="home--main--container--hotmers--element--footer--statistics--element">
              <ContentTypeProfile
                icon={galleryWhiteIcon}
                value={hotmer.total_image}
              />
            </div>
            <div className="home--main--container--hotmers--element--footer--statistics--element">
              <ContentTypeProfile
                icon={videoWhiteIcon}
                value={hotmer.total_video}
              />
            </div>
            <div className="home--main--container--hotmers--element--footer--statistics--element">
              <ContentTypeProfile
                icon={packWhiteIcon}
                value={hotmer.total_pack}
              />
            </div>
          </div>
        )}
        {hotmer.bl_subscriber_hotmer ? (
          <>
            {hotmer.id_user_hotmer !== idUser && (
              <ButtonPresent
                id_user_hotmer={hotmer.id_user_hotmer}
                id_content_group={id_content_group}
              />
            )}
          </>
        ) : (
          <ButtonSubscribe id_user_hotmer={hotmer.id_user_hotmer} />
        )}
      </div>
      {openCommentContent &&
        <CommentPostUser
          idCreator={hotmer?.id_user_hotmer}
          idContent={id_content_group}
          openCommentPost={openCommentContent}
          cerrar={() => setOpenCommentContent(false)}
        />
      }
    </>
  );
};

export default HotmerHomeFooter;
