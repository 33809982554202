import React from "react";
import "./customInputFile.css";

export const CustomInputFile = ({ classForInput, onChange, name }) => {
  return (
    <div className="custom--input--file--container">
      <input
        type="file"
        name={name}
        className={classForInput}
        onChange={onChange}
        accept="image/*"
      />
    </div>
  );
};
