import { useState } from "react";
import "./customInputTextIconPassword.css";

export const CustomInputTextIconPassword = ({
	placeholder,
	name,
	value,
	onChange,
	type,
	image,
	limit,
	visible = true,
	seePassword = false,
	image2,
}) => {

	// const [password, setPassword] = useState('');

	const [showPassword, setShowPassword] = useState(false);

	// const handlePasswordChange = (event) => {
	// 	setPassword(event.target.value);
	// };

	return (
		<div>
			<div className={`custom--input--text--icon--container ${visible ? '' : 'hidden'}`}>
				<img
					className="custom--input--text--icon--container--image"
					src={image}
					alt="password"
				/>
				<input
					className="custom--input--text--icon--container--input"
					name={name}
					type={showPassword ? 'text' : 'password'}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					maxLength={limit}
				/>
				<img 
				className="custom--input--text--icon--container--image"
				onClick={() => setShowPassword(!showPassword)}	
				src={image2}
				alt="eye"
				/>
			</div>
		</div>
	);
};
