import React, { useEffect, useState } from "react";
// import okIcon from "../../icons/ok-green-icon.png";
import "./activityContainer.css";

export const ActivityContainer = ({ activity }) => {
	// const { type_of_activity, currency, date, value } = activity;
	const [myActivity, setMyActivity] = useState(null);

	useEffect(() => {
		setMyActivity(activity);
	}, [activity]);

	if (!myActivity) {
		return "";
	}
	return (
		<div className="activity--container">
			<div className="activity--container--data">
			<div className="activity--container--grid--part2">
					<div className="activity--container--data--date">{myActivity.date}</div>
					<div className="activity--container--data--currency">
						{myActivity.currency}
					</div>
					<div
						className={`activity--container--data--value ${myActivity.value < 0 ? "danger--value" : ""
							}`}
					>
						$ {myActivity.value}
					</div>
				</div>
				<div className="activity--container--grid--part1">
					<div className="activity--container--data--name">
						{myActivity.type_of_activity}
					</div>
					{/* <img
						className="activity--container--data--pay--check"
						src={okIcon}
						width={12} height={12}
						alt="..."
					/> */}
				</div>
			</div>
		</div>
	);
};
