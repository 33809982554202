import { TYPES } from "./Types";
import { setHeaders } from "../../libs/fetch";

export const getPosts = (limit, offset) => {
  const url = `${process.env.REACT_APP_INITIAL_V2}/users/homepagination?limit=${limit}&offset=${offset}`;
  return async (dispatch) => {
    dispatch({ type: TYPES.REQUEST_LOADING_POSTS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        if (response.data.status === false) {
          dispatch({ type: TYPES.REQUEST_FAILURE_POSTS });
        } else {
          dispatch({
            type: TYPES.REQUEST_SUCCESS_POSTS,
            payload: response.data,
          });
        }
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.STOP_POSTS_FETCH,
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_POSTS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_POSTS });
    }
  };
};

export const setScrollbarPosition = (data) => ({
  type: TYPES.SET_SCROLLBAR_POSITION,
  payload: data,
});
