export const initialState = {
  loadingDataCountry: false,
  dataCountry: null,
  loadingPackTags: false,
  packTags: null,
  submitEditProfile: false,
  loadingSearchHotmers: false,
  searchHotmers: null,
  loadingHomeHotmers: false,
  homeHotmers: null,
  loadingSearchPacks: false,
  searchPacks: null,
  loadingPaymentMethods: false,
  paymentMethods: null,
  initExtraction: {
    account: "",
    realAccount: "",
    imageSource: "",
    destiny: "",
    amount: "",
    ds_currency_code: "",
    idCurrency: "",
    id_account: "",
    id_type_of_payment_method: "",
    imageMethod: "",
  },
  loadingUserExtractions: false,
  userExtractions: null,
  loadingExtractionMethod: false,
  extractionMethod: null,
  loadingOptionsFeatures: false,
  optionsFeatures: null,
  loadingPreferencesGenders: false,
  preferencesGenders: null,
  loadingUserStatistics: false,
  userStatistics: null,
  loadingUserSubscribedHotmers: false,
  userSubscribedHotmers: null,
  loadingUserBoughtPacks: false,
  userBoughtPacks: null,
  loadingUserWishHotmers: false,
  userWishHotmers: null,
  loadingUserWishPacks: false,
  userWishPacks: null,
  loadingToBecomeHotmer: false,
  userAccountCreation: null,
  optionsPreferences: null,
  userHotmerStatus: false,
  tabFireFromDrawer: null,
  tabSearch: null,
  registerFirebaseUser: null,
  loginFirebaseUser: null,
  loadingAuthenticatedUser: false,
  authenticatedUser: null,
  userLogged: false,
  userLoggedData: null,
  loadingUsersData: false,
  usersData: null,
  loadingUserData: false,
  userData: null,
  loadinghotmersHome: false,
  hotmersData: null,
  screenPosition: 0,
  visibleProfileDrawer: false,
  visibleContentDrawer: false,
  discardModalEdit: false,
  discardModalEditRoute: null,
  loadingSubmitContent: false,
  responseSubmitContent: null,
};
