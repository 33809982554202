import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";

export const Accordion = withStyles({
  root: {
    // border: "1px solid #000000",
    padding: "1px 0",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: "1px",
    "&$expanded": {
      minHeight: "1px",
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
      paddingLeft: "10px",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "0.5px solid rgba(0, 0, 0, 0.1)",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}))(MuiAccordionDetails);
