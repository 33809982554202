import React, { useEffect, useState } from "react";
import profileDefault from "../../icons/profile/profile-default-img.png";
import subscribeImage from "../../icons/profile/subscribe-image.png";
import renewSubsImage from "../../icons/profile/renew-subscription-image.png";
import profileCircleIcon from "../../icons/profile/profile-circle-icon.png";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
// import { setWishHotmersList } from "../../redux/fire/fireActions";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import "./hotmerFire.css";
import { displaySuccess } from "../toast/toast";

export const setWishHotmersList = (data) => ({
  type: "SET_SUCCESS_WISH_HOTMERS",
  payload: data,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  containerPaper: {
    borderRadius: "10px",
    background: "#393939",
  },
  typographyTitle: {
    padding: "10px 15px",
    color: "#fafafa",
    textAlign: "left",
    fontSize: "18px",
  },
  typographySubTitle: {
    padding: "5px 15px 10px 15px",
    color: "#e6e6e6",
    width: "250px",
    textAlign: "left",
    fontSize: "13px",
  },
}));

export const HotmerFire = ({ hotmer, onClick, tab }) => {
  const state = useSelector((state) => state.main);
  const { userWishHotmers } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const [profile, setProfile] = useState({
    src: hotmer.profile_photo_thumbnail_path,
    errored: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const shareProfile = (e) => {
    const id = hotmer.id_hotmer;
    if (id > 0) {
      e.preventDefault();
      setOpen(false);
      if (!navigator.share) {
        alert("Navegador no soporta la Web Share API");
        return;
      }

      navigator
        .share({
          // title: "Hotmy",
          // text: "Te quiero compartir mi modelo favorit@",
          url: process.env.PUBLIC_URL + "/hotmerProfile/" + id,
          //url: (document.location.href + "hotmerProfile/" + id),
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      e.preventDefault();
      setOpen(false);
      if (!navigator.share) {
        alert("Navegador no soporta la Web Share API");
        return;
      }
      navigator
        .share({
          // title: "Hotmy",
          // text: "Te quiero compartir mi modelo favorit@",
          url: document.location.href,
          //console.log(process.env.REACT_APP_INITIAL_V2 + "/hotmerProfile/" + id);
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const clickEraseHotmer = async () => {
    const id = hotmer.id_hotmer;
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      console.log("AGREGAR RES: ", res);
      const response = await res.json();
      if (response.code === 200) {
        const list = userWishHotmers?.filter((x) => x.id_hotmer !== id);
        dispatch(setWishHotmersList(list));
        displaySuccess("Perfil de creador eliminado de lista de deseos")
        history.push(`/fire`)
      } else {
        console.log("FAIL CLICK FOLLOW HOTMER");
      }
    } catch (error) {
      console.log("FAIL CLICK FOLLOW HOTMER");
      console.log(error);
    }
  };

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setProfile({
      src: hotmer.profile_photo_thumbnail_path || profileDefault,
      errored: false,
    });
  }, [hotmer]); // eslint-disable-line

  return (
    <div className="hotmer--fire--container">
      {hotmer.id_hotmer === 0 ? (
        <div className="hotmer--fire--container--image">
          <img
            className="hotmer--fire--container--image--white"
            src={profileCircleIcon}
            alt=".."
          />
          <div className="hotmer--fire--container--image--white--profile">
            <div className="hotmer--fire--container--image--white--profile--limit">
              <img
                className="hotmer--fire--container--image--white--profile--limit--photo"
                src={profile.src}
                onError={onErrorProfile}
                alt=".."
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="hotmer--fire--container--image" onClick={onClick}>
          <img
            className="hotmer--fire--container--image--white"
            src={profileCircleIcon}
            alt=".."
          />
          <div className="hotmer--fire--container--image--white--profile">
            <div className="hotmer--fire--container--image--white--profile--limit">
              <img
                className="hotmer--fire--container--image--white--profile--limit--photo"
                src={profile.src}
                onError={onErrorProfile}
                alt=".."
              />
              {tab === 0 ? (
                <>
                  {hotmer.id_status === 0 && (
                    <img
                      className="hotmer--fire--container--image--white--profile--limit--option"
                      src={renewSubsImage}
                      alt=".."
                    />
                  )}
                </>
              ) : (
                <img
                  className="hotmer--fire--container--image--white--profile--limit--option"
                  src={subscribeImage}
                  alt=".."
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="hotmer--fire--container--name">
        {hotmer.ds_display_name}
      </div>
      {tab === 0 ? (
        <div
          className="hotmer--fire--container--remove"
          onClick={handleClick("bottom-end")}
        >
          <div className="hotmer--fire--container--remove-2">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.containerPaper}>
                    <Typography
                      className={classes.typographyTitle}
                      onClick={shareProfile}
                    >
                      {"Compartir"}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      ) : (
        <div
          className="hotmer--fire--container--remove"
          onClick={handleClick("bottom-end")}
        >
          <div className="hotmer--fire--container--remove-2">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.containerPaper}>
                    <Typography
                      className={classes.typographyTitle}
                      onClick={clickEraseHotmer}
                    >
                      {"Eliminar"}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      )}
    </div>
  );
};
