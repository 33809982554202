import React, { useState, useEffect } from "react";
import fireIcon1 from "../../icons/fueguito-card-1.png";
import fireDegIcon from "../../icons/degrade/fire-degrade-icon.png";
// import "./iconLikePost.css";
import "./iconLikePost.scss";

export const IconLikePost = ({ liked, setLikedButton, index }) => {
  let ident = `icon--like--post--container--icon--${index}`;
  const [likedPost, setLikedPost] = useState(null);

  const clickButton = () => {
    let btnImg = document.getElementById(ident);
    if (likedPost || btnImg.classList.contains("is-active")) {
      btnImg.classList.remove("is-active");
    } else {
      btnImg.classList.add("is-active");
    }
    setLikedPost((x) => !x);
    setLikedButton();
  };

  useEffect(() => {
    setLikedPost(liked);
  }, []); // eslint-disable-line

  return (
    <button
      // id="button--like--post"
      id={ident}
      onClick={clickButton}
      className="button like"
    >
      <img
        style={{ height: "30px", width: "30px", border: "none" }}
        id={`icon--like--post--container--icon`}
        src={likedPost ? fireDegIcon : fireIcon1}
        alt="..."
      />
    </button>
  );
};
