import { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { useSelector, useDispatch } from "react-redux";
import { CircularToy } from "../CircularToy/CircularToy";
import { updateToysState } from "../../libs/search";
import { CircularProgress } from "@material-ui/core";
import "./CircularToys.css";

export const CircularToys = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const { loadingToys, errorToys, toys } = state;

  const chooseToy = (toy) => {
    const newToys = updateToysState(toys, toy);
    dispatch({
      type: "REQUEST_SUCCESS_TOYS",
      payload: newToys,
    });
  };

  return (
    <div className="circular--toys--main--container">
      {loadingToys ? (
        <div className="circular--toys--main--container--progress">
          <CircularProgress />
        </div>
      ) : errorToys ? (
        <div>Error toys...</div>
      ) : (
        toys && (
          <Fragment>
            <FormLabel>MOSTRAR</FormLabel>
            <Grid
              container
              justify="center"
              spacing={1}
              style={{ margin: "0.3rem 0 0 0" }}
            >
              {toys.map((toy, i) => (
                <Grid item xs={4} key={i}>
                  <CircularToy
                    key={i}
                    toy={toy}
                    onClick={() => chooseToy(toy)}
                  />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        )
      )}
    </div>
  );
};
