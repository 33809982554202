/* eslint-disable */
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "100%",
    background: " #ffffff",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    width: "50%",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(17),
    background: " #ffffff",
    color: "#7810d9",
    // textTransform: "capitalize",
    fontSize: "14px",
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabSelected: {
    borderBottom: "3px solid #7810d9",
    color: "#54117e",
    fontSize: "12px",
  },
  tab: {
    color: "#000000",
    fontSize: "12px",
  },
}));

export const FireTabs = ({ tab, handleChange, first, second }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StyledTabs value={tab} onChange={handleChange}>
        <StyledTab
          className={tab === 0 ? classes.tabSelected : classes.tab}
          label={first}
        />
        <StyledTab
          className={tab === 1 ? classes.tabSelected : classes.tab}
          label={second}
        />
      </StyledTabs>
    </div>
  );
};
