import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

export const WrongFetchUploadContent = () => {
    const history = useHistory();
    const handledClickUploadContent = () => {
        history.push(`/uploadContent`);
    }
    const { t } = useTranslation("global");

    return (
        <div className="wrong--fetch--component--container">
            <Button component="label"
                variant="contained"
                startIcon={<AddBoxOutlinedIcon />}
                onClick={handledClickUploadContent}
                color="secondary">
                {t("myProfile.actionItems.uploadContent")}
            </Button>
        </div>
    );
};
