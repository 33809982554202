import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import "./Reload.css";

export const Reload = ({ onClick }) => (
  <div className="reload--main--container--error">
    <IconButton onClick={onClick}>
      <ReplayIcon />
    </IconButton>
  </div>
);
