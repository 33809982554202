import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Typography from "@material-ui/core/Typography";
import { CircularButton } from "../CircularButton/CircularButton";
import "./PercentageOption.css";

export const PercentageOption = ({ title, decrease, increase, value }) => (
  <div className="percentage--option--main--container">
    <Typography style={{ fontSize: "15px", textAlign: "center" }}>
      {title}
    </Typography>
    <div className="percentage--option--main--container--controls">
      <CircularButton onClick={decrease}>
        <RemoveIcon fontSize="small" />
      </CircularButton>
      <PercentageCounter value={value} />
      <CircularButton onClick={increase}>
        <AddIcon fontSize="small" />
      </CircularButton>
    </div>
  </div>
);

const PercentageCounter = ({ value }) => {
  return <h2 className="percentage--counter">{value} %</h2>;
};
