/* eslint-disable */
import React, { useEffect, useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import successIcon from "../../icons/fetch/success-icon.png";
import backIcon from "../../icons/darkIcons/back--icon.png";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { DestinyAccount } from "../../components/destinyAccount/destinyAccount";
import { ExtractionTitle } from "../../components/extractionTitle/extractionTitle";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { setInitExtraction } from "../../redux/extractions/extractionsActions";
// import { ExtractionsInput } from "../../commonComponents/extractionsInput/extractionsInput";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { ExtractionsInputResult } from "../../commonComponents/extractionsInputResult/extractionsInputResult";
import { createConfirmExtraction } from "../../libs/extractions";
import { useSelector, useDispatch } from "react-redux";
import "./extractionsFinish.css";

export const ExtractionsFinish = ({ activeStep, handleStep }) => {
  const state = useSelector((state) => state.main);
  const { initExtraction } = state;
  const history = useHistory();
  const dispatch = useDispatch();

  const [iconModal, setIconModal] = useState(null);
  const [messageModal, setMessageModal] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openModalTryAgain, setOpenModalTryAgain] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(null);
  const [preview, setPreview] = useState(null);
  const [init, setInit] = useState(null);

  const getExtractionPreview = async () => {
    setLoadingPreview(true);
    const { amount, ds_currency_code, id_account } = initExtraction;
    setInit(initExtraction);
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/extraction/preview?ds_currency_code=${ds_currency_code}&id_account=${id_account}&vl_amount=${amount}`;
    try {
      const res = await fetch(url, { headers: setHeaders() });
      const response = await res.json();
      if (res.status === 200 && response.code === 200) {
        console.log(response.data);
        setPreview(response.data);
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
    setLoadingPreview(false);
  };

  const acceptClick = () => {
    setOpenModalTryAgain(false);
    setMessageModal("");
    history.replace("/extractions");
  };

  const submitExtraction = async () => {
    setLoadingSubmit(true);
    const id = getIdUser();
    const json = createConfirmExtraction(preview);
    const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/extraction/order`;
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
        body: json,
      });
      const response = await res.json();
      if (res.status === 201 && response.code === 201) {
        const idOrder = response.data.id_extraction_order;
        setMessageModal(
          `Orden Nro ${idOrder} creada satisfactoriamente. En los próximos días se reflejará en su cuenta.`
        );
        setIconModal(successIcon);
        dispatch(
          setInitExtraction({
            account: "",
            realAccount: "",
            imageSource: "",
            destiny: "",
            amount: "",
            ds_currency_code: "",
            idCurrency: "",
            id_account: "",
            id_type_of_payment_method: "",
            imageMethod: "",
          })
        );
      } else {
        setIconModal(opsIcon);
        setMessageModal(
          "Existe una orden de Extracción Pendiente de procesar."
        );
      }
    } catch (error) {
      setIconModal(opsIcon);
      setMessageModal("Extracción Incorrecta.");
      console.log("ERROR: ", error);
    }
    setModalMessageButtonText("Aceptar");
    setOpenModalTryAgain(true);
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (activeStep === 2) {
      getExtractionPreview();
    }
  }, [activeStep]);

  if (loadingPreview || loadingSubmit) {
    return <LoadingScreen loading={loadingPreview || loadingSubmit} />;
  } else if (!loadingPreview && !preview) {
    return (
      <WrongFetchScreen
        onClickRefresh={() => console.log("REFRESH EXTRACTIONS")}
      />
    );
  } else {
    return (
      <div className="extractions--finish--main--container">
        <DestinyAccount
          onClickIcon={() => handleStep(1)}
          icon={backIcon}
          title="Revisa si está todo bien"
        />
        <DividerSpace space="XS" />
        <MoneyContainer>
          <ExtractionTitle
            title="Cuenta de Origen"
            helper={true}
            icon={playVideoIcon}
            popperTitle={"Cuenta de Origen/Destino"}
            popperMessage={
              "Son las cuentas que seleccionaste de origen del dinero y destino. Pueden ser cuentas locales, internacionales o tambien podes transferir dinero desde una cuenta internacional a tu cuenta local"
            }
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsInputResult
              content="flex-start"
              check={true}
              value={init.realAccount}
              image={init.imageSource}
            />
          </div>
          <ExtractionTitle
            title="Monto Seleccionado"
            helper={false}
            icon={playVideoIcon}
            popperTitle={"Monto Seleccionado"}
            popperMessage={
              "Es la cantidad de dinero que selecciono para Extraer"
            }
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsInputResult
              content="flex-start"
              check={true}
              symbol={init.ds_currency_code}
              value={init.amount}
            />
          </div>

          <DividerSpace space="XXS" />
          <ExtractionTitle
            title="Cuenta Destino"
            helper={false}
            icon={playVideoIcon}
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsInputResult
              content="flex-start"
              check={true}
              value={init.destiny}
              image={init.imageMethod}
            />
          </div>
          <DividerSpace space="XXS" />
          <ExtractionTitle
            title="Costo financiero plataforma de pago"
            helper={false}
            secondTitle={preview.bl_conversion == true ? "Tasa Conversión" : ""}
            icon={playVideoIcon}
          />
          <DividerSpace space="XS" />
          <div className="extractions--continue--main--container--finaltials">
            <div className="extractions--continue--main--container--finaltials--field">
              <ExtractionsInputResult
                content="center"
                check={false}
                symbol={"% "}
                value={preview.vl_financial_cost}
              />
            </div>
            <div className="extractions--continue--main--container--finaltials--field">
              {preview.bl_conversion && (
                <ExtractionsInputResult
                  content="center"
                  check={false}
                  symbol={init.ds_currency_code}
                  value={
                    preview.bl_conversion == true
                      ? preview.vl_conversion_rate
                      : ""
                  }
                />
              )}
            </div>
          </div>
          <DividerSpace space="XS" />
          <ExtractionTitle
            title="Vas a Transferir"
            helper={true}
            icon={playVideoIcon}
            popperTitle={"Vas a Transferir"}
            popperMessage={
              "Es el dinero que se acreditara a tu cuenta destino, descontando los costos financieros que generan las plataformas de pago"
            }
          />
          <DividerSpace space="XS" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsInputResult
              content="center"
              colored={true}
              check={true}
              symbol={init.ds_currency_code}
              value={
                init.amount !== ""
                  ? `$ ${preview.vl_withdraw_money_with_tax}`
                  : ""
              }
            />
          </div>
          <DividerSpace space="XS" />
        </MoneyContainer>
        <DividerSpace space="XS" />
        <div className="extractions--available--main--container--button">
          <CustomButtonRegister onClick={submitExtraction}>
            CONFIRMAR
          </CustomButtonRegister>
        </div>
        <DividerSpace space="XS" />
        <CustomModal
          openModal={openModalTryAgain}
          setOpenModal={setOpenModalTryAgain}
          discardExternalEvents={true}
        >
          <OptionTryAgain
            icon={iconModal}
            title={messageModal}
            acceptClick={acceptClick}
            buttonText={modalMessageButtonText}
            whatsApp={false}
          />
        </CustomModal>
      </div>
    );
  }
};
