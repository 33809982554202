import { useState, useEffect } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// import imageProfile from "../../icons/fueguito-card-1.png";
import { connect } from "react-redux";
import { createSelector, createStructuredSelector } from "reselect";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./AgreementsUltimate.css";

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
        percentages,
      }) => ({
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
        percentages,
      })
    ),
  })
)(({ handleBackAgreement, propsDeal }) => {
  const {
    selectedAgreementHotmer: sah,
    percentages,
    selectedAgreementHotmerOption,
  } = propsDeal;
  const { subscriptions: subs, packs, present } = percentages;

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const imageSize = {
    height: "50px",
    width: "50px",
  };

  return (
    <div className="agreements--ultimate--main--container">
      <div className="agreements--ultimate--main--container--card">
        <div className="agreements--ultimate--main--container--card--image">
          {selectedAgreementHotmerOption && (
            <ProfileImage
              full_name={selectedAgreementHotmerOption.ds_full_name}
              nick_name={selectedAgreementHotmerOption.ds_nick_name}
              image={
                selectedAgreementHotmerOption.ds_profile_photo_thumbnail_path
              }
              imageSize={imageSize}
            />
          )}
        </div>
        <Divider variant="middle" style={{ width: "90%" }} />
        <Typography style={{ margin: "0.5rem 0" }}>
          Detalles del acuerdo
        </Typography>
        <div className="agreements--ultimate--main--container--card--percentages--value">
          {user.rol == "agency" && (
            <Typography style={{ textAlign: "center" }}>Comisiones</Typography>
          )}
          {user.rol == "agency" && (
            <Percentage title="Suscripciones" value={subs} />
          )}
          <Percentage title="Packs" value={packs} />
          {user.rol == "agency" && (
            <Percentage title="Regalos" value={present} />
          )}
        </div>
        <Divider variant="middle" style={{ width: "90%" }} />
        <div className="agreements--ultimate--main--container--card--edit">
          <div className="agreements--ultimate--main--container--card--edit--button">
            <CustomButtonRegister color="blue" onClick={handleBackAgreement}>
              Editar
            </CustomButtonRegister>
          </div>
        </div>
      </div>
    </div>
  );
});

const Percentage = ({ title, value }) => (
  <div className="agreements--ultimate--main--container--card--value">
    {`${title} `} <strong>{value} %</strong>
  </div>
);

const ProfileImage = ({ full_name, nick_name, image, imageSize }) => {
  const [profile, setProfile] = useState({
    src: image,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setProfile({
      src: image,
      errored: false,
    });
  }, [image]); // eslint-disable-line

  return (
    <div className="profile--image--container">
      <div className="profile--image--container--image">
        <img src={profile.src} alt="..." onError={onErrorProfile} />
      </div>
      <div>
        <strong>{full_name}</strong>
      </div>
      <div>{nick_name}</div>
    </div>
  );
};
