import React from "react";
import creditCardIcon from "../../icons/darkIcons/credit-card-icon.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./packOptions.css";

export const PackOptions = ({ pack, owner }) => {
  if (owner === 0) {
    // owner 0 es dueño, hotmer viendo sus propios packs "MY PROFILE"
    return <PackSee pack={pack} />;
  } else if (owner === 1) {
    // owner 1 es visitor, puede ver o comprar "SEARCH - HOTMER PROFILE"
    if (pack.bl_buyer === false) {
      return <PackBuy pack={pack} />;
    } else if (pack.bl_buyer === true) {
      return <PackSee pack={pack} />;
    }
  } else if (owner === 2) {
    // owner 2 es visitor, accediendo a packs deseados "DESEADOS"
    return <PackBuy pack={pack} />;
  }
};

const PackBuy = ({ pack }) => {
  const { t } = useTranslation("global");
  const history = useHistory();

  const buy = () =>
    history.push(`/packDetail/${pack.id_hotmer}/${pack.id_pack}`);

  return (
    <div
      className="hotmer--pack--search--main--container--content--buy"
      onClick={buy}
    >
      <div className="hotmer--pack--search--main--container--content--buy--comprar">
        <img src={creditCardIcon} alt="..." />
        <DividerSpace space="XXS" />
        <div className="hotmer--pack--search--main--container--content--buy--comprar--text">
          {t("common.pack.standar.buy")}
        </div>
      </div>
      <DividerSpace space="XS" />
      <div className="hotmer--pack--search--main--container--content--buy--price">
        <div className="hotmer--pack--search--main--container--content--buy--price--dolar--currency">
          {"USD"}
        </div>
        <DividerSpace space="XXS" />
        <div className="hotmer--pack--search--main--container--content--buy--price--dolar--value">
          {pack?.vl_price}
        </div>
      </div>
      {pack?.ds_currency_code !== "USD" &&
        <div>
          <div className="divider--price--currency--options" />
          <div className="hotmer--pack--search--main--container--content--buy--price">
            <div className="hotmer--pack--search--main--container--content--buy--price--ars--currency">
              {pack.ds_currency_code}
            </div>
            <DividerSpace space="XXS" />
            <div className="hotmer--pack--search--main--container--content--buy--price--ars--value">
              {pack.currency}
            </div>
          </div>
        </div>
      }
      <div className="hotmer--pack--search--main--container--content--aprox">
        {t("common.pack.standar.aprox")}
      </div>
    </div>
  );
};

const PackSee = ({ pack }) => {
  const history = useHistory();
  const { t } = useTranslation("global");

  const go = () => history.push(`/packView/${pack.id_hotmer}/${pack.id_pack}`);

  return (
    <div className="pack--see--main--container" onClick={go}>
      <div className="pack--see--main--container--message">
        {t("common.pack.standar.watch")}
      </div>
      <div className="hotmer--pack--search--main--container--content--see--price">
        <div className="hotmer--pack--search--main--container--content--see--price--dolar--currency">
          {"USD"}
        </div>
        <DividerSpace space="XXS" />
        <div className="hotmer--pack--search--main--container--content--buy--price--dolar--value">
          {pack.vl_price}
        </div>
      </div>
      <div className="hotmer--pack--search--main--container--content--see--price-value">
        {t("common.pack.standar.actual")}
      </div>
    </div>
  );
};
