import "./Topic.css";

export const Topic = ({ title, val, color }) => {
  return (
    <div
      className="topic--main--container"
      style={{ color, fontSize: "14px" }}
    >{`${title} ${val}`}</div>
  );
};
