import { Fragment } from "react";
import { ButtonFilters } from "../ButtonFilters/ButtonFilters";
// import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";

export const SearchFilters = ({ children, applyFilters, hideGlobalButton }) => (
  <Fragment>
    {children}
    {!hideGlobalButton && (
      <div className="circular--toys--main--container--apply">
        <ButtonFilters onClick={applyFilters}>APLICAR FILTROS</ButtonFilters>
      </div>
    )}
  </Fragment>
);
