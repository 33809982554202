import { useContext, Fragment } from "react";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { CircularToys } from "../CircularToys/CircularToys";
import { setPackOption } from "../../views/search/Actions";
import { useTranslation } from "react-i18next";

export const PacksOptions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const { packOption } = state;
  const { t } = useTranslation("global");
  const onChange = ({ target }) => dispatch(setPackOption(target.value));

  const radioButtons = [
    { id: 1, value: "recent", label:  t("search.PackFilter.filter1")},
    { id: 2, value: "best_sellers", label: t("search.PackFilter.filter2")},
    { id: 3, value: "sold_monthly", label: t("search.PackFilter.filter3")},
    { id: 4, value: "sold_current_week", label: t("search.PackFilter.filter4")},
  ];

  return (
    <Fragment>
      <CircularToys />
      <Container maxWidth="sm" style={{ paddingTop: "0.5rem" }}>
        <FormLabel component="legend" style={{ paddingBottom: "0.5rem" }}>
          {t("search.PackFilter.titleConteiner")}
        </FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={packOption}
            onChange={onChange}
          >
            {radioButtons.map(({ id, value, label }) => (
              <FormControlLabel
                key={id}
                value={value}
                control={<Radio />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Container>
    </Fragment>
  );
};
