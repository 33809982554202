/* eslint-disable */

export const updateModalContent = (modalIcon, modalMessage, modalButtonText) => {
    return {
        icon: modalIcon,
        message: modalMessage,
        buttonText: modalButtonText
    }
}

const getIdTagsSelected = array => {
    if (array) {
        return array.filter((x) => x.selected == true).map((x) => x.id_tag)
    } else {
        return []
    }
}

const sortNumberList = (list) => list.sort((a, b) => a - b);

const applyStringify = (param) => JSON.stringify(param);

export const jsonUpdate = (packHotmer, packToys) => {
    const ids = getIdTagsSelected(packToys);
    const jsonUpdate = {
        ds_title: packHotmer.ds_title,
        ds_description: packHotmer.ds_description,
        vl_price: parseFloat(packHotmer.vl_price),
        id_tags: ids
    };

    console.log("🚀 ~ file: packUpdate.js ~ line 33 ~ jsonUpdate ~ jsonUpdate", jsonUpdate)
    return JSON.stringify(jsonUpdate)

}

export const comparePackHotmer = (newData, oldData, packToys) => {
    let countChanges = 0;
    for (let key in newData) {
        const newValue = newData[key];
        const oldValue = oldData[key];
        if (newValue != oldValue) {
            countChanges++
        }

    }
    const oldIds = sortNumberList(oldData.id_tags);
    const newIds = sortNumberList(getIdTagsSelected(packToys))

    if (applyStringify(oldIds) != applyStringify(newIds)) {
        countChanges++
    }

    if (countChanges < 1 || !packToys) {
        return true;
    } else {
        return false;
    }
}

// MINIMO Y MAXIMO PERMITIDO PARA EL PRECIO DE UN PACK
export const minPackPrice = process.env.REACT_APP_PACK_PRICE_MIN;
export const maxPackPrice = process.env.REACT_APP_PACK_PRICE_MAX;

export const validateData = (newData) => {
    let countEmpty = 0;
    let pricePack = newData?.vl_price
    const packWithoutIdTags = {
        id_pack: newData?.id_pack,
        ds_title: newData?.ds_title,
        ds_description: newData?.ds_description,
        vl_price: newData?.vl_price,
    }
    if (pricePack) {
        pricePack = parseFloat(pricePack)
    }
    for (let key in packWithoutIdTags) {
        const newValue = String(packWithoutIdTags[key]);
        console.log("newValue", newValue)
        if (newValue != "" || newValue != null) {
            // countNotEmpty++
        } else {
            countEmpty++
        }
    }
    return ((countEmpty > 0) || !(pricePack >= (parseFloat(minPackPrice)) && pricePack <= (parseFloat(maxPackPrice))))
}

export const messageWarningUpdate = `Recuerde completar todos los campos por favor. No olvide que el precio mínimo del pack es de USD ${minPackPrice} y máximo USD ${maxPackPrice}.`;

export const successPackUpdate = "El pack fue actualizado correctamente.";

export const failurePackUpdate = "Error al actualizar pack. Intente mas tarde, si el error persiste comuniquese con support@hotmy.net.";