import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    border: "none !important",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  iconButton: {
    padding: 10,
    color: "gray",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export const HeaderSheet = ({ tab, toggleSheet, firstTitle, secondTitle }) => {
  const classes = useStyles();

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          firstTitle
        )
      case 1:
        return (
          secondTitle
        )
      default:
        return (
          <div>Informacion Adicional</div>
        )
    }
  }

  return (
    <Paper className={classes.root}>
      <IconButton
        className={classes.iconButton}
        onClick={toggleSheet}
        aria-label="menu"
      >
        <CloseIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <Typography style={{ fontWeight: 600, fontSize :"1rem", color: "gray" }}
      >
      {renderSwitch(tab)}
    </Typography>
    </Paper >
  );
};
