import React from "react";
import "./contentTypeProfile.css";

export const ContentTypeProfileHorizontal = ({ icon, value }) => {
	return (
		<div className="home--main--container--hotmers--element--mask--item--horizontal">
			<img
				className="home--main--container--hotmers--icons"
				alt="..."
				src={icon}
                // style={{marginRight: "0.4rem"}}
			/>
			<div className="">
				{value}
			</div>
		</div>
	);
};
