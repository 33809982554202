import Grid from "@material-ui/core/Grid";
import Skeleton from "react-loading-skeleton";

export const SkeletonGrid = () => (
  <Grid container spacing={0}>
    {Array(15)
      .fill()
      .map((d, i) => (
        <Grid item xs={4} key={i}>
          <Skeleton height={150} />
        </Grid>
      ))}
  </Grid>
);
