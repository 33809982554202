import React, { useState } from "react";
import okIcon from "../../icons/ok-green-icon.png";
import selfieIcon from "../../icons/dniselfie.jpg";
import { useSelector, useDispatch } from "react-redux";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { CustomInputFile } from "../../commonComponents/customInputFile/customInputFile";
import { CustomButtonNext } from "../../commonComponents/customButtonNext/customButtonNext";
import { setUserAccountCreation } from "../../redux/actions";
import { incompleteMessageSelfie } from "../../libs/account";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { appendFile, getFile } from "../../libs/upload";
import "./photoSelfie.css";

export const PhotoSelfie = ({ handleNext }) => {
  const state = useSelector((state) => state.main);
  const { userAccountCreation } = state;
  const dispatch = useDispatch();

  const [modalMessage, setModalMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [file, setFile] = useState(null);

  const onChange = (e) => {
    const file = getFile(e);
    if (file) setFile(file);
  };

  const handleSubmit = () => {
    if (!file) {
      setModalMessage(incompleteMessageSelfie);
      setOpenModal(true);
    } else {
      const newState = appendFile(userAccountCreation, file);
      dispatch(setUserAccountCreation(newState));
      handleNext();
    }
  };

  return (
    <div className="photo--selfie--main--container">
      <DividerSpace space="X" />
      <div className="photo--selfie--main--container--title">
        Sacate una selfie con tu DNI
      </div>
      <DividerSpace space="X" />
      <div className="photo--selfie--main--container--inputs">
        <DividerSpace space="X" />
        <div className="photo--selfie--main--container--inputs--header">
          <div className="photo--selfie--main--container--inputs--header--bottom--title">
            IMPORTANTE
          </div>
          <DividerSpace space="M" />
          <div className="photo--selfie--main--container--inputs--header--bottom--subtitle">
            Sostene tu Documento de Identidad cerca de tu rostro.
          </div>
          <DividerSpace space="XXS" />
          <div className="photo--selfie--main--container--inputs--header--bottom--subtitle">
            Que tu rostro se vea frontal y completo.
          </div>
          <DividerSpace space="XXS" />
          <div className="photo--selfie--main--container--inputs--header--bottom--subtitle">
            Sin anteojos, Gorro, ni Cabello tapando el rostro.
          </div>
        </div>
        <DividerSpace space="XXL" />
        <img
          alt="..."
          src={selfieIcon}
          className="photo--selfie--main--container--selfie--image"
        />
        <DividerSpace space="XXL" />
        <div className="file--container--photo--upload--icon">
          <div className="file--container--photo--upload--icon--button">
            <CustomInputFile
              classForInput="custom-file-input-selfie"
              onChange={onChange}
            />
          </div>
          <DividerSpace space="XXS" />
          <DividerSpace space="XXS" />
          {file && (
            <img
              className="file--container--photo--upload--icon--ok"
              src={okIcon}
              alt="..."
            />
          )}
        </div>
        {/* */}
      </div>
      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <MessageIncompleteData message={modalMessage} />
      </CustomModal>
      <DividerSpace space="X" />
      <CustomButtonNext onClick={handleSubmit}>SIGUIENTE</CustomButtonNext>
    </div>
  );
};
