import { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
// import { useState } from 'react'
import { CommentPost } from "../../components/commentPost/commentPost";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { setHeaders } from "../../libs/fetch";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
// import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { BottomSheet } from 'react-spring-bottom-sheet'
import useGetComment from "../../hooks/useComment";
import { ProfileImage } from "../../components/profileImage/profileImage";
import { ButtonAddComment } from "../../components/buttonAddComment/buttonAddComment";
import {
  failurePostComment,
} from "../../libs/comments";
import "./CommentPostUser.css";

interface props {
  idCreator: number | string
  idContent: number
  openCommentPost: boolean
  cerrar: () => void
}

const CommentPostUser = ({ idCreator, idContent, openCommentPost, cerrar }: props) => {

  const { data: dataComments, isLoading, refetch: refetchComments, isRefetching } = useGetComment(idCreator, idContent)

  const [open, setOpen] = useState(false)

  // LLAMADA AL COMPONENTE DE POST. 

  // LLAMADA AL COMPONENTE DE BORRADO. 

  // LOADER DE COMENTARIOS 

  // ABRO SE VA CARGANDO. 

  // AGREGADO DE ICONO DE INFOMACION QUE MUESTRE UN STEP 2. 

  // funciones a revisar para luego cambiar. 

  const [selectedComment, setSelectedComment] = useState(null);
  const [openModalRemoveComment, setOpenModalRemoveComment] = useState(false);
  const [loadingRemoveComment, setLoadingRemoveComment] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [messageResultModal, setMessageResultModal] = useState("");
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [data, setData] = useState({ comment: "" });
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const applyOption = () => {
    confirmRemoveComment();
    setOpenModalRemoveComment(false);
  };

  const cancelOption = () => {
    setOpenModalRemoveComment(false);
    setSelectedComment(null);
    setOpen(true);
  };

  const tryRemoveComment = (comment) => {
    // console.log(id_comment, id_content_group, id_user);
    setSelectedComment(comment);
    setOpen(false);
    setOpenModalRemoveComment(true);
  };

  const publishComment = async () => {
    setLoadingPublish(true);
    const { comment } = data;
    const json = {
      id_content_group: idContent,
      ds_comment: comment
    };
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idCreator}/content/${idContent}/comment`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
        body: JSON.stringify(json),
      });
      const response = await res.json();
      if (res.status === 201 && response.code === 201) {
        refetchComments()
        setLoadingPublish(false);
      }
    } catch (error) {
      console.log("ERROR: ", error);
      setMessageResultModal(failurePostComment);
      setOpenResultModal(true);
      setLoadingPublish(false);
    }
    setData({ comment: "" });
  };

  const confirmRemoveComment = async () => {
    setLoadingRemoveComment(true);
    console.log(selectedComment);
    const { id_comment, id_content_group, id_user } = selectedComment;
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/users/${id_user}/content/${id_content_group}/comment/${id_comment}`;
      const res = await fetch(url, { headers: setHeaders(), method: "DELETE" });
      if (res.status === 200) {
        refetchComments()
        setOpen(true);
      } else {
        setOpenResultModal(true);
        setMessageResultModal("Error al eliminar comentario. Intente mas tarde, si el error persiste comuniquese con support@hotmy.net.");
      }
    } catch (error) {
      setOpenResultModal(true);
      setMessageResultModal("Error. Intente mas tarde por favor.");
      console.warn("ERROR: ", error);
    }
    setSelectedComment(null);
    setLoadingRemoveComment(false);
  };

  // fin de funciones a revisar.

  useEffect(() => {
    setOpen(openCommentPost)
  }, [openCommentPost, setOpen])

  return (
    <>
      <Box id='getComment' className="box-main-Comment-post">
        <BottomSheet
          open={open}
          onDismiss={cerrar}
          blocking={true}
          snapPoints={({ minHeight }) => minHeight}
          header={
            <Typography
              className="title-container">
              Comentarios
            </ Typography>
          }
          footer={
            <>
              <div className="comments--post--main--container--add">
                <div className="comments--post--main--container--add--profile">
                  <ProfileImage />
                </div>
                <div className="comments--post--main--container--add--text">
                  <InputAddComment
                    placeholder={`Comentar publicacion`}
                    name="comment"
                    type="string"
                    value={data?.comment}
                    onChange={onChange}
                    limit={"50"}
                  />
                </div>
                <div className="comments--post--main--container--add--button">
                  <ButtonAddComment
                    loading={loadingPublish}
                    disabled={data.comment === ""}
                    onClick={publishComment}
                  />
                </div>
              </div>
            </>
          }
        >
          {/* Se cargan los comentarios y un loading */}
          <Box sx={{padding:'16px'}}>
          {isLoading || isRefetching ?
            "Loading..."            :
            <Box>
              {dataComments?.data?.map((comment, i) => (
                <CommentPost
                  key={i}
                  loadingRemoveComment={loadingRemoveComment}
                  comment={comment}
                  tryRemoveComment={tryRemoveComment}
                />
              ))}
            </Box>
          }
          </Box>
        </BottomSheet>
        <CustomModal
          openModal={openResultModal}
          setOpenModal={setOpenResultModal}
          discardExternalEvents={null}
          fullScreen={null}
        >
          <MessageIncompleteData message={messageResultModal} />
        </CustomModal>
        <CustomModal
          openModal={openModalRemoveComment}
          setOpenModal={setOpenModalRemoveComment}
          discardExternalEvents={null}
          fullScreen={null}
        >
          <OptionToTrending
            title={"Está seguro que desea eliminar esta comentario ?"}
            acceptClick={applyOption}
            notAcceptclick={cancelOption}
            disabledButtons={null}
            showYesNoButtons={true}
          />
        </CustomModal>
      </Box>
    </>
  )
}

const InputAddComment = ({
  placeholder,
  name,
  value,
  onChange,
  type,
  limit,
}) => {
  return (
    <div className="input-add--comment--main--container">
      <textarea
        className="input-add--comment--main--container--input"
        name={name}
        value={value}
        typeof={type}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={limit}
      />
    </div>
  );
};


export default CommentPostUser