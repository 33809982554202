/* eslint-disable */
import React from "react";
import mainIcon from "../../icons/main-logo.png";
//import fireIcon from "../../icons/dark-fire.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./finishProcess.css";

export const FinishProcess = ({
	title,
	description,
	urlRedirect,
	buttonTitle,
}) => {
	const handleSubmit = () => (window.location.href = urlRedirect);

	return (
		<div className="finish--process--main--container">
			<DividerSpace space="M" />
			<div className="finish--process--main--container--logo">
				<img
					alt="..."
					src={mainIcon}
					className="finish--process--main--container--logo--image"
				/>
			</div>
			<DividerSpace space="M" />
			<div className="finish--process--main--container--inputs">
				<div className="preferences--main--container--inputs--header">
					{/* <img
						alt="..."
						src={fireIcon}
						className="preferences--main--container--inputs--header--image"
					/>
					<DividerSpace space="XS" /> */}
					<div className="preferences--main--container--inputs--header--title">
						{title}
					</div>
				</div>
				<DividerSpace space="M" />
				<DividerSpace space="M" />
				<div className="finish--process--main--container--inputs--message">
					{description}
				</div>
				<DividerSpace space="M" />
				<DividerSpace space="M" />
			</div>
			<DividerSpace space="M" />
			<div className="finish--process--main--container--main--button">
				<CustomButtonRegister onClick={handleSubmit}>
					{buttonTitle}
				</CustomButtonRegister>
			</div>
		</div>
	);
};
