import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../accordionSets/accordionSets";
import { CustomInputTextIconEdit } from "../../commonComponents/customInputTextIconEdit/customInputTextIconEdit";
import aliasIcon from "../../icons/editProfile/profile.png";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import { useTranslation } from "react-i18next";
import "./accordionPersonalDataUser.css";

export const AccordionPersonalDataUser = ({
	panel,
	expanded,
	handleChange,
	// loading,
	personalData,
	handleChangePersonalData,
}) => {
	const { t } = useTranslation("global");
	return (
		<Accordion
			square
			expanded={expanded === panel}
			onChange={handleChange(panel)}
		>
			<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
				<AccordionHeader
					panel={panel}
					expanded={expanded}
					title={t("editProfile.topic.myData")}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<div className="accordion--personal--data--inputs">
					<CustomInputTextIconEdit
						placeholder="Alias"
						name="ds_display_name"
						value={personalData?.ds_display_name.toLowerCase() || ""}
						onChange={handleChangePersonalData}
						type="text"
						image={aliasIcon}
						maxLength="18"
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
