export const mapInitHotmer = (data) => {
	const hotmer = {
		id_user_hotmer: data.id_hotmer,
		bl_hotmer_verification: data.bl_hotmer_verification,
		display_name: data.ds_display_name,
		id_hotmer: data.id_hotmer,
		bl_follow_hotmer: true,
		bl_subscriber_hotmer: true,
	};
	return hotmer;
};

export const generateFooter = (hotmer, postFile) => {
	const dataFooter = {
		total_comment: postFile.total_comments,
		total_likes: postFile.total_likes,
		bl_subscriber_hotmer: hotmer.bl_subscriber_hotmer,
		id_user_hotmer: hotmer.id_user_hotmer,
		bl_liked: postFile.bl_liked,
	};
	//console.log("DATA FOOTER: ", dataFooter);
	return dataFooter;
};

export const mapInitDots = (length) => {
	let array = [];
	for (let i = 1; i <= length; i++) {
		array.push(i);
	}
	return array;
};

export const getDomElement = (e, postFiles, idGroup) => {
	console.log(e.target);
	console.log(postFiles);
	console.log({ idGroup });
	// const idContent = e.target.parentNode.children[1].textContent;
	// const collection = postFiles.find((item) => item.id_content_group == idGroup);
	// const element = collection.data.find(
	// 	(fileItem) => fileItem.id_content == idContent,
	// );
	// return element;
};
