import React from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import ops2Image from "../../icons/fetch/ops-image2.png";
import "./opsSmallFire.css";

export const OpsSmallFire = ({ title, description, onClick }) => {
  return (
    <div className="ops--small--fire--main--container" onClick={onClick}>
      <DividerSpace space="M" />
      <div className="ops--small--fire--main--container--title">{title}</div>
      <div className="ops--small--fire--main--container--image">
        <img src={ops2Image} alt="..." />
      </div>
      <div className="ops--small--fire--main--container--description">
        {description}
      </div>
    </div>
  );
};
