import React, { useEffect, useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import backIcon from "../../icons/darkIcons/back--icon.png";
import successIcon from "../../icons/fetch/success-icon.png";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { DestinyAccount } from "../../components/destinyAccount/destinyAccount";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { ExtractionTitle } from "../../components/extractionTitle/extractionTitle";
import { ExtractionsInput } from "../../commonComponents/extractionsInput/extractionsInput";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { ExtractionsSelect } from "../../commonComponents/extractionsSelect/extractionsSelect";
import { mapInitTypeAccounts } from "../../libs/extractions";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./extractionsContinue.css";

export const ExtractionsContinue = ({ activeStep, handleStep }) => {
	const history = useHistory();

	const [openResultModal, setOpenResultModal] = useState(false);
	const [iconModal, setIconModal] = useState(null);
	const [messageModal, setMessageModal] = useState("");
	const [modalMessageButtonText, setModalMessageButtonText] = useState("");

	const [openModalOptions, setOpenModalOptions] = useState(false);
	const [selectedType, setSelectedType] = useState(null);
	const [extractionMethod, setExtractionMethod] = useState(null);
	const [loadingExtractionMethod, setLoadingExtractionMethod] = useState(false);
	const [loadingCreation, setLoadingCreation] = useState(false);
	const [data, setData] = useState({
		type: "",
		email: "",
		image: "",
	});

	const getExtractionMethod = async () => {
		setLoadingExtractionMethod(true);
		const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/account/extraction/method`;
		try {
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			const response = await res.json();
			if (res.status === 200 && response.code === 200) {
				console.log("CREATE ACCOUNT TYPE: ", response.data);
				setExtractionMethod(mapInitTypeAccounts(response.data));
			} else {
			}
		} catch (error) {
			console.log("ERROR: ", error);
		}
		setLoadingExtractionMethod(false);
	};

	const setOpenOptions = () => setOpenModalOptions((x) => !x);

	const handleSubmit = async () => {
		setLoadingCreation(true);
		const id_type_of_payment_method = selectedType;
		const ds_email = data.email;
		const json = JSON.stringify({ id_type_of_payment_method, ds_email });
		console.log(json);
		const id = getIdUser();
		const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/extraction/method`;
		try {
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: json,
			});
			const response = await res.json();
			if (res.status === 201 && response.code === 201) {
				console.log("CREATE ACCOUNT TYPE: ", response.data);
				// history.push("/extractions");
				setMessageModal(`Creación de cuenta correcta.`);
				setIconModal(successIcon);
			} else {
				setIconModal(opsIcon);
				setMessageModal("Ya existe una Cuenta con ese metodo de pago.");
			}
		} catch (error) {
			console.log("ERROR: ", error);
		}
		setModalMessageButtonText("Aceptar");
		setOpenResultModal(true);
		setLoadingCreation(false);
	};

	const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

	const onChooseOptionType = (e) => {
		console.log(e);
		setData({ ...data, type: e.value, image: e.image });
		setSelectedType(e.id);
		setOpenModalOptions(false);
	};

	const acceptClick = () => {
		setOpenResultModal(false);
		setMessageModal("");
		history.push("/extractions");
	};

	useEffect(() => {
		if (activeStep === 3) {
			getExtractionMethod();
		}
	}, [activeStep]); // eslint-disable-line

	if (loadingExtractionMethod || loadingCreation) {
		return (
			<LoadingScreen loading={loadingExtractionMethod || loadingCreation} />
		);
	} else if (!loadingExtractionMethod && !extractionMethod) {
		return <WrongFetchScreen onClickRefresh={() => getExtractionMethod()} />;
	} else {
		return (
			<div className="extractions--continue--main--container">
				<DestinyAccount
					icon={backIcon}
					onClickIcon={() => handleStep(1)}
					title="Nueva Cuenta"
				/>
				<DividerSpace space="XS" />
				<MoneyContainer>
					<ExtractionTitle
						title="Tipo de Cuenta"
						helper={true}
						icon={playVideoIcon}
						popperTitle={"Cuentas disponibles"}
						popperMessage={"Seleccione una de sus cuentas"}
					/>
					<DividerSpace space="M" />
					<div className="extractions--continue--main--container--select">
						<ExtractionsSelect
							visibleValue={true}
							textPosition={"center"}
							value={data.type}
							image={data.image}
							setOpenOptions={setOpenOptions}
						/>
					</div>
					<DividerSpace space="M" />
					<ExtractionTitle title="Email" helper={false} icon={playVideoIcon} />
					<DividerSpace space="M" />
					<div className="extractions--continue--main--container--select">
						<ExtractionsInput
							placeholder={"model@hotmy.app"}
							name="email"
							type="email"
							value={data.email}
							onChange={onChange}
							maxWidth={"100%"}
							maxLength="80"
							textPosition="start"
							disabled={false}
						/>
					</div>
					<DividerSpace space="M" />
				</MoneyContainer>
				<DividerSpace space="X" />
				<div className="extractions--available--main--container--button">
					<CustomButtonRegister
						disabled={
							data.type === "" ||
							data.email === "" ||
							!data.email.includes("@") ||
							!data.email.includes(".")
						}
						onClick={handleSubmit}
					>
						CONTINUAR
					</CustomButtonRegister>
				</div>
				<CustomModal
					openModal={openModalOptions}
					setOpenModal={setOpenModalOptions}
				>
					<MobileWheelSelector
						type="onlyImages"
						title={"Tipo de Cuenta"}
						data={extractionMethod}
						chooseElement={onChooseOptionType}
						idList={"Tipos"}
					/>
				</CustomModal>
				<CustomModal
					openModal={openResultModal}
					setOpenModal={setOpenResultModal}
					discardExternalEvents={true}
				>
					<OptionTryAgain
						icon={iconModal}
						title={messageModal}
						acceptClick={acceptClick}
						buttonText={modalMessageButtonText}
						whatsApp={false}
					/>
				</CustomModal>
			</div>
		);
	}
};
