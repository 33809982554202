import { hmyApiWithToken } from "../api/hmyApiWithToken"; //siempre usamos esta hmyapi pasa simplificar las cabeceras. 
import { useQuery } from '@tanstack/react-query'
import { exclusiveAgreement } from "../interfaces/ExclusiveAgreement";

const getExclusiveAgreement = async ({ queryKey  }) : Promise <exclusiveAgreement[]> => {
    const [_, idCreator] = queryKey
    // const res = await fetch(url, {
    //     headers: setHeaders(),
    //   });
    const myToken = JSON.parse(localStorage.getItem("session"))?.token;
    const { data } = await hmyApiWithToken(myToken).get<exclusiveAgreement[]>(`/hotmers/${idCreator}/exclusiveAgreement`)  // incluir solo lo que no esta en la predefault en la ruta general de la api. 
    return data;  // devolvemos una interfaz del tipo data.
}

const useExclusiveAgreement = (  creator: number | string) => {
    // le tiene que llegar el id de la creadora o el dsname
    const hotmerExclusiveAgreement = useQuery(
        ['getExclusiveAgreementByIdCreator', creator], getExclusiveAgreement,
        // aca van todos los atributos que se pueden usar en ese useQuery 
        {
                refetchOnWindowFocus: false,
                retry: 2,
                enabled: !!creator,
            // staleTime: 1000 * 60 * 60, 
            // ESTO LO QUE TE DICES ES QUE PIENSE QUE POR UN TIEMPO X 1 HORA TODA LA DATA QUE ESTAMOS TRAYENDO ES FRESCA Y ESTA CACHEADA. 
            // initialData: [
            //     {    
            //         code: 1,
            //         data: {
            //             id_user_hotmer: 123,
            //             bl_hotmer_verification: true
            //         },
            //         message: null,
            //         success: null,
            //     }
            // ]
        }
    );

    return hotmerExclusiveAgreement;
}

export default useExclusiveAgreement