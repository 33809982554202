/* eslint-disable */
import React, { useState } from "react";
import mainIcon from "../../icons/main-logo.png";
import fireIcon from "../../icons/dark-fire.png";
import profileIcon from "../../icons/person-logo.png";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { setHeadersNoToken } from "../../libs/fetch";
import { CustomInputTextIcon } from "../../commonComponents/customInputTextIcon/customInputTextIcon";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import "./passwordRestore.css";

export const PasswordRestore = ({ handleNext }) => {
	const [loadingCreation, setLoadingCreation] = useState(false);
	const [userData, setUserData] = useState({ email: "" });

	const [openModalFail, setOpenModalFail] = useState(false);
	const [messageModalFail, setMessageModalFail] = useState("");

	const onChange = (e) =>
		setUserData({ ...userData, [e.target.name]: e.target.value });

	const handleSubmit = async () => {
		const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (regex.test(userData.email)) {
			setLoadingCreation(true);
			try {
				const url = `${process.env.REACT_APP_AUTH}/reset-password`;
				const res = await fetch(url, {
					headers: setHeadersNoToken(),
					credentials: "include",
					method: "POST",
					body: JSON.stringify({ ds_email: userData.email }),
				});
				console.log("RES LOGIN: ", res);
				if (res.status === 200) {
					handleNext();
				} else {
					setOpenModalFail(true);
					setMessageModalFail("Error: Contacte al administrador, intente mas tarde, si el error persiste comuniquese con support@hotmy.net.");
				}
			} catch (error) {
				console.log("ERROR: ", error);
				setMessageModalFail("Error: Contacte al administrador, intente mas tarde, si el error persiste comuniquese con support@hotmy.net.");
				setOpenModalFail(true);
			}
			setLoadingCreation(false);
		} else {
			setMessageModalFail("Error: Email inválido.");
			setOpenModalFail(true);
		}
	};

	return (
		<div className="create--main--container">
			<LoadingScreen loading={loadingCreation} />
			<DividerSpace space="X" />
			<div className="create--main--container--logo">
				<img
					src={mainIcon}
					alt="..."
					className="create--main--container--logo--image"
				/>
			</div>
			<DividerSpace space="X" />
			<div className="create--main--container--inputs">
				<div className="create--main--container--inputs--header">
					<img
						src={fireIcon}
						alt="..."
						className="create--main--container--inputs--header--image"
					/>
					<DividerSpace space="XS" />
					<div className="create--main--container--inputs--header--title">
						Restablecer Clave de Acceso
					</div>
				</div>
				<DividerSpace space="X" />
				<CustomInputTextIcon
					placeholder="Email"
					name="email"
					type="email"
					value={userData.email.toLowerCase()}
					onChange={onChange}
					image={profileIcon}
				/>
				<DividerSpace space="M" />
			</div>
			<DividerSpace space="XXL" />
			<div className="create--main--container--main--button">
				<CustomButtonRegister onClick={handleSubmit}>
					RESTABLECER
				</CustomButtonRegister>
			</div>
			<DividerSpace space="XS" />
			<CustomModal openModal={openModalFail} setOpenModal={setOpenModalFail}>
				<MessageIncompleteData message={messageModalFail} />
			</CustomModal>
		</div>
	);
};
