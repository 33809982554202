import { useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import profileImg from "../../icons/profile/profile-default-img.png";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { PackContent } from "../packContent/packContent";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { PackSearchToys } from "../PackSearchToys/PackSearchToys";
import { useTranslation } from "react-i18next";
import "./PackSearch.css";

export const PackSearch = ({ pack }) => {
  const history = useHistory();
  const { t } = useTranslation("global");
  const img = pack.profile_photo_path || profileImg;
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [followPack, setFollowPack] = useState(pack.bl_follow);
  const [profile, setProfile] = useState({ src: img, err: false });

  const visitProfile = () => history.push(`/hotmerProfile/${pack.id_hotmer}`);
  const onError = () => !profile.err && setProfile({ src: opsIcon, err: true });

  const addPack = async () => {
    setLoadingAdd(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${pack.id_hotmer}/pack/${pack.id_pack}/follow`;
      const res = await fetch(url, { headers: setHeaders(), method: "POST" });
      if (res.status === 200) setFollowPack(!followPack);
    } catch (error) {
      console.log(error, "FALLO AGREGAR PACK A DESEADOS");
    }
    setLoadingAdd(false);
  };

  return (
    <div className="pack--search--main">
      <div className="pack--search--main--profile" onClick={visitProfile}>
        <img src={profile.src} alt="..." onError={onError} />
      </div>
      <div className="pack--search--main--name">{pack.ds_display_name}</div>
      <div className="pack--search--main--color">
        <div className="pack--search--main--color--title">{pack.ds_title}</div>
        {!followPack && (
          <div className="pack--search--main--color--action" onClick={addPack}>
            {loadingAdd ? <MiniSpinner /> : t("common.pack.standar.follow")}
          </div>
        )}
      </div>
      <PackContent pack={pack} owner={1} />
      <PackSearchToys pack={pack} />
    </div>
  );
};
