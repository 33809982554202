export const TYPES = {
  REQUEST_LOADING_HOTMERS: "REQUEST_LOADING_HOTMERS",
  REQUEST_SUCCESS_HOTMERS: "REQUEST_SUCCESS_HOTMERS",
  REQUEST_FAILURE_HOTMERS: "REQUEST_FAILURE_HOTMERS",
  STOP_HOTMERS_FETCH: "STOP_HOTMERS_FETCH",
  REQUEST_LOADING_TOYS: "REQUEST_LOADING_TOYS",
  REQUEST_SUCCESS_TOYS: "REQUEST_SUCCESS_TOYS",
  REQUEST_FAILURE_TOYS: "REQUEST_FAILURE_TOYS",
  SET_INPUT_SEARCH: "SET_INPUT_SEARCH",
  REQUEST_LOADING_PACKS: "REQUEST_LOADING_PACKS",
  REQUEST_SUCCESS_PACKS: "REQUEST_SUCCESS_PACKS",
  REQUEST_FAILURE_PACKS: "REQUEST_FAILURE_PACKS",
  STOP_PACKS_FETCH: "STOP_PACKS_FETCH",
  SET_INITIAL_PROFILE_REQUEST: "SET_INITIAL_PROFILE_REQUEST",
  SET_INITIAL_PACK_REQUEST: "SET_INITIAL_PACK_REQUEST",
  SET_PROFILE_OPTION: "SET_PROFILE_OPTION",
  SET_PACK_OPTION: "SET_PACK_OPTION",
};
