import React, { useState, useEffect } from "react";
import playIcon from "../../icons/play-icon.png";
import arrowIcon from "../../icons/horizontal-arrow.png";
import ImageGallery from "react-image-gallery";
import playVideoIcon from "../../icons/whiteIcons/play-icon.png";
import { mapInitDots } from "../../libs/hotmerContent";
//import { CustomModal } from "../../commonComponents/customModal/customModal";
//import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { VideoContent, BigPlayButton } from "../videoContent/videoContent";
import { Player, BigPlayButton } from "video-react";
//import { CustomModalVideo } from "../../commonComponents/customModalVideo/customModalVideo";
import "react-image-gallery/styles/css/image-gallery.css";
import "./hotmerContentFiles.css";

export const HotmerContentFiles = ({
	files,
	type,
	onClickSlider,
	idGroup,
	descriptionFiles,
	dtPublishFile
}) => {

	// ESTE COMPONENTE ES EL QUE SE USA EN EL DETALLE DE CONTENIDO DE LA CREADORA CUANDO ACCEDER AL CONTENIDO. 

	// console.log("files", files)

	const [maxSteps, setMaxSteps] = useState(0);
	const [dots, setDots] = useState(0);
	const [hotmerFiles, setHotmerFiles] = useState(null);

	const handleRenderItem = (item, t) => {
		return (
			<div
				className="abba1"
				onClick={(e) => onClickSlider(e, item, type, idGroup)}
			>
				{t === 1 ? (
					<>
						<div className="play-button"></div>
						<img
							src={t === 2 ? item.ds_url_thumbnail : item.ds_url}
							className="image-gallery-image"
							alt="..."
						/>
						{t === 2 && <img className="abba2" src={playVideoIcon} alt="..." />}
					</>
				) : (
					<Player
						autoPlay={false}
						fluid={true}
						width="100%"
						poster={item.ds_url_thumbnail}
						volume={0.5}
						// src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
						src={item.ds_url}
					>
						<BigPlayButton position="center" />
					</Player>
				)}
			</div>
		);
	};

	const mapInitFiles = (list) => {
		const newList = list.map((file) => {
			const { ds_url, ds_url_thumbnail } = file;
			let jsonElement = {
				original: type === 1 ? ds_url : ds_url_thumbnail,
				thumbnail: type === 1 ? ds_url : ds_url_thumbnail,
				renderItem: () => handleRenderItem(file, type),
			};
			return jsonElement;
		});
		return newList;
	};

	useEffect(() => {
		if (files) {
			const length = files.length;
			setMaxSteps(length - 1);
			setDots(mapInitDots(length));
			setHotmerFiles(mapInitFiles(files));
		}
	}, [files]);

	if (hotmerFiles) {
		return (
			<>
				{descriptionFiles ?
					(
						<div className="hotmer--content--files--description">
							{descriptionFiles}
						</div>
					)
					:
					(
						<div className="hotmer--content--files--without--description">
						</div>
					)
				}
				<div className="hotmer--content--files--main--container">
					<ImageGallery
						lazyLoad={true}
						infinite={false}
						showBullets={true}
						items={hotmerFiles}
						showThumbnails={false}
						showPlayButton={false}
						thumbnailPosition="bottom"
						useBrowserFullscreen={false}
						showFullscreenButton={false}
						additionalClass="app-image-gallery"
					// onClick={(e) => onClickSlider(e, type, idGroup)}
					/>
				</div>
			</>
		);
	} else {
		return <></>;
	}
};

const StepArrow = ({ onClick }) => {
	return (
		<div className="step--arrow--main--container">
			<img src={arrowIcon} alt="..." onClick={onClick} />
		</div>
	);
};

const VideoPost = ({ reproduceVideoContent, file }) => {
	return (
		<div className="hotmer--content--files--main--container--video">
			<video
				onClick={() => reproduceVideoContent(file)}
				src={file.ds_url}
				alt="..."
			></video>
			<div className="hotmer--content--files--main--container--video--icon">
				<img src={playIcon} alt="..." />
			</div>
		</div>
	);
};
