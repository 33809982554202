/* eslint-disable */
import React, { useState, useEffect } from "react";
import okIcon from "../../icons/ok-green-icon.png";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { updateList } from "../../libs/upload";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { useDispatch } from "react-redux";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { UploadPackToy } from "../../components/uploadPackToy/uploadPackToy";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { setInitialToys } from "../../libs/search";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { setScreenPosition } from "../../redux/actions";
import { UploadContentHeader } from "../../components/uploadContentHeader/uploadContentHeader";
import { WrongFetchTagsScreen } from "../../commonComponents/wrongFetchTagsScreen/wrongFetchTagsScreen";
import { CustomUploadTextArea } from "../../commonComponents/customUploadTextArea/customUploadTextArea";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { CustomUploadInputText } from "../../commonComponents/customUploadInputText/customUploadInputText";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { CustomInputPriceUpload } from "../../commonComponents/customInputPriceUpload/customInputPriceUpload";
import {
	jsonUpdate,
	validateData,
	comparePackHotmer,
	successPackUpdate,
	failurePackUpdate,
	updateModalContent,
	messageWarningUpdate,
} from "../../libs/packUpdate";
import "./packUpdate.css";

export const PackUpdate = ({ match }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [packHotmer, setPackHotmer] = useState(null);
	const [initialPackHotmer, setInitialPackHotmer] = useState(null);
	const [successPackHotmer, setSuccessPackHotmer] = useState(false);
	const [loadingPackHotmer, setLoadingPackHotmer] = useState(false);
	const [loadingPackToys, setLoadingPackToys] = useState(false);
	const [loadingUpdate, setLoadingUpdate] = useState(false);
	const [packToys, setPackToys] = useState(null);
	const [loadingTax, setLoadingTax] = useState(false);
	// const [tax, setTax] = useState(null);
	const [taxes, setTaxes] = useState(null);
	const [openModalWarning, setOpenModalWarning] = useState(false);
	const [openModalResult, setOpenModalResult] = useState(false);
	const [modalResult, setModalResult] = useState(null);

	const onChange = (e) =>
		setPackHotmer({ ...packHotmer, [e.target.name]: e.target.value });
	const getTaxUpload = async (id) => {
		setLoadingTax(true);
		// const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/tax`;
		const url = `${process.env.REACT_APP_INITIAL_V2}/packs/taxes`;
		try {
			const res = await fetch(url, { headers: setHeaders() });
			if (res.status === 200) {
				const response = await res.json();
				//console.log("SERVICIO TAXES: ", response.data);
				setTaxes(response.data);
			}
		} catch (error) {
			//console.log("TAX ERROR: ", error);
		}
		setLoadingTax(false);
	};

	const getPackToys = async () => {
		setLoadingPackToys(true);
		const url = `${process.env.REACT_APP_INITIAL_V2}/packs/tags`;
		try {
			const res = await fetch(url, { headers: setHeaders() });
			if (res.status === 200) {
				const response = await res.json();
				let resultInitialToys = setInitialToys(response.data);
				const list = await resultInitialToys.map((x) => {
					if (packHotmer.id_tags.includes(x.id_tag)) {
						return { ...x, selected: !x.selected };
					} else {
						return x;
					}
				});
				setPackToys(list);
			}
			setLoadingPackToys(false);
		} catch (error) {
			setLoadingPackToys(false);
		}
	};

	const getPackContent = async (idHotmer, idPack) => {
		try {
			setLoadingPackHotmer(true);
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/content/pack/${idPack}/details`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			if (res.status === 200) {
				const response = await res.json();
				setPackHotmer(response.data);
				setInitialPackHotmer(response.data);
				setSuccessPackHotmer(true);
			}
			setLoadingPackHotmer(false);
		} catch (error) {
			//console.log("ERROR: ", error);
			setLoadingPackHotmer(false);
		}
	};

	const selectToy = (id) => setPackToys(updateList(packToys, id));

	const onClickRefreshGetPack = () => getPackContent(match.params.id_pack);
	const onClickRefreshTags = () => getPackToys();

	const updatePack = async () => {
		if (validateData(packHotmer)) {
			setOpenModalWarning(true);
		} else {
			const jsonData = jsonUpdate(packHotmer, packToys);
			const id_hotmer = match.params.id_hotmer;
			const id_pack = match.params.id_pack;
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id_hotmer}/pack/${id_pack}`;
			//console.log("FINAL FINAL 0",jsonData);
			setLoadingUpdate(true);
			try {
				const res = await fetch(url, {
					headers: setHeaders(),
					method: "PUT",
					body: jsonData,
				});
				const response = await res.json();
				/*console.log(res);
				console.log(response);*/
				if (res.status === 200 && response.code === 200) {
					setModalResult(
						updateModalContent(okIcon, successPackUpdate, "ACEPTAR"),
					);
				} else {
					setModalResult(
						updateModalContent(opsIcon, failurePackUpdate, "ACEPTAR"),
					);
				}
			} catch (error) {
				//console.log("ERROR: ", error);
				setModalResult(
					updateModalContent(opsIcon, failurePackUpdate, "ACEPTAR"),
				);
			}
			setLoadingUpdate(false);
			setOpenModalResult(true);
		}
	};

	const onFinalClick = () => {
		setOpenModalResult(false);
		dispatch(setScreenPosition(0));
		const id_hotmer = match.params.id_hotmer;
		const id_pack = match.params.id_pack;
		history.push(`/packView/${id_hotmer}/${id_pack}`);
	};

	const disabledByContent = () =>
		comparePackHotmer(initialPackHotmer, packHotmer, packToys);
	useEffect(() => {
		if (successPackHotmer) {
			getPackToys();
		}
	}, [successPackHotmer]); // eslint-disable-line

	useEffect(() => {
		getPackContent(match.params.id_hotmer, match.params.id_pack);
		const id = getIdUser();
		getTaxUpload(id);
	}, []); // eslint-disable-line

	if (loadingPackHotmer || loadingUpdate) {
		return <LoadingScreen loading={loadingPackHotmer || loadingUpdate} />;
	} else if (!packHotmer) {
		return <WrongFetchScreen onClickRefresh={onClickRefreshGetPack} />;
	} else {
		return (
			<>
				{taxes && packHotmer && (
					<div className="pack--update--main--container">
						<div className="pack--update--main--container--header">
							<UploadContentHeader />
						</div>
						<div className="pack--update--main--container--form">
							<div className="pack--update--main--container--form--inputs">
								<CustomUploadInputText
									name="ds_title"
									value={packHotmer?.ds_title || ""}
									placeholder={"Titulo"}
									limit={"- 25 caracteres"}
									onChange={onChange}
									type="text"
								/>
								<DividerSpace space="XS" />
								<CustomUploadTextArea
									name="ds_description"
									value={packHotmer?.ds_description || ""}
									placeholder={"Texto contenido"}
									limit={"- 60 caracteres"}
									onChange={onChange}
									type="text"
									disabled={false}
								/>
								<DividerSpace space="XS" />
								<div className="upload--content--main--container--body--content--inputs--toys">
									{loadingPackToys ? (
										<PartialSpinner />
									) : !loadingPackToys && !packToys ? (
										<WrongFetchTagsScreen
											onClickRefresh={onClickRefreshTags}
											param={"Tags"}
										/>
									) : (
										packToys.map((packToy) => (
											<UploadPackToy
												key={packToy?.id_tag}
												packToy={packToy}
												selectToy={selectToy}
											/>
										))
									)}
								</div>
								<DividerSpace space="XS" />
								<CustomInputPriceUpload
									title={"Valor"}
									placeholder="..."
									name={"vl_price"}
									value={parseFloat(packHotmer?.vl_price)}
									onChange={onChange}
									// discount={tax}
									taxes={taxes}
									type="number"
									icon={true}
									colored={false}
									disabled={false}
									maxLength={5}
								/>
								<DividerSpace space="L" />
								<div className="pack--update--main--container--form--button">
									<CustomButtonRegister
										onClick={updatePack}
										disabled={disabledByContent()}
									>
										ACTUALIZAR
									</CustomButtonRegister>
									<DividerSpace space="X" />
								</div>
							</div>
						</div>
						<CustomModal
							openModal={openModalWarning}
							setOpenModal={setOpenModalWarning}
						>
							<MessageIncompleteData message={messageWarningUpdate} />
						</CustomModal>
						{openModalResult && (
							<CustomModal
								openModal={openModalResult}
								setOpenModal={setOpenModalResult}
								discardExternalEvents={true}
							>
								<OptionTryAgain
									icon={modalResult.icon}
									title={modalResult.message}
									buttonText={modalResult.buttonText}
									acceptClick={onFinalClick}
									whatsApp={false}
								/>
							</CustomModal>
						)}
					</div>
				)}
			</>
		);
	}
};
