import React, { useState, useEffect } from "react";
import Step from "@material-ui/core/Step";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import { PhotoDni } from "../photoDni/photoDni";
import { PhotoSelfie } from "../photoSelfie/photoSelfie";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { CreateHotmerContent } from "../createHotmerContent/createHotmerContent";
//import { CreateHotmerFeatures } from "../createHotmerFeatures/createHotmerFeatures";
import { useSelector, useDispatch } from "react-redux";
import {
  getOptionsFeatures,
  getPreferencesGenders,
} from "../../redux/account/accountActions";
import "./createContentInit.css";

export const CreateContentInit = () => {
  const state = useSelector((state) => state.main);
  const { loadingToBecomeHotmer } = state;
  const dispatch = useDispatch();
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState([0, 1, 2]);

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleStepChange = (step) => setActiveStep(step);

  function getStepContent(step, index) {
    switch (index) {
      case 0:
        return <PhotoDni handleNext={handleNext} />;
      case 1:
        return <PhotoSelfie handleNext={handleNext} />;
      case 2:
        //return <CreateHotmerContent handleNext={handleNext} />;
        return <CreateHotmerContent />;
      //case 3:
      // return <CreateHotmerFeatures />;
      default:
        return "Unknown step";
    }
  }

  useEffect(() => {
    dispatch(getPreferencesGenders());
    dispatch(getOptionsFeatures());
  }, []); // eslint-disable-line

  return (
    <div className="create--content--main--container">
      <LoadingScreen loading={loadingToBecomeHotmer} />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        disabled
      >
        {steps.map((step, index) => (
          <Step key={index}>{getStepContent(step, index)}</Step>
        ))}
      </SwipeableViews>
    </div>
  );
};
