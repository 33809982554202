/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import addIcon from "../../icons/darkIcons/add-icon.png";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { BottomMessage } from "../../components/bottomMessage/bottomMessageExtractions";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { DestinyAccount } from "../../components/destinyAccount/destinyAccount";
import { ExtractionTitle } from "../../components/extractionTitle/extractionTitle";
import { ExtractionsInput } from "../../commonComponents/extractionsInput/extractionsInput";
// import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { ExtractionsSelect } from "../../commonComponents/extractionsSelect/extractionsSelect";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import useExclusiveAgreement from "../../hooks/useExclusiveAgreement";
import AgreementsExclusive from "../../components/AgreementsExclusive/AgreementsExclusive"
// import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { useSelector, useDispatch } from "react-redux";
import {
  // getExtractionMethod,
  setInitExtraction,
} from "../../redux/extractions/extractionsActions";
import {
  mapInitMethods,
  mapInitSources,
  changeSource,
  changeDestiny,
} from "../../libs/extractions";
import { useHistory } from "react-router-dom";
import "./extractionsOperate.css";

// MINIMO Y MAXIMO DE VIDEOS
export const minExtractionAllowed = process.env?.REACT_APP_MIN_EXTRACCTION || 20;

// Carga si tiene acuerdos comerciales.

export const ExtractionsOperate = ({ activeStep, handleStep }) => {
  const state = useSelector((state) => state.main);
  const {
    userExtractions,
    initExtraction,
    loadingExtractionMethod,
    extractionMethod: eo,
  } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const [minValue, setMinValue] = useState(-1);
  const [bottomAlert, setBottomAlert] = useState("...");
  const [conversionValue, setConversionValue] = useState(null);
  // const [openWarning, setOpenWarning] = useState(false);
  const [openModalOptions1, setOpenModalOptions1] = useState(false);
  const [openModalOptions2, setOpenModalOptions2] = useState(false);
  const [extractionMethod, setExtractionMethod] = useState(null);
  const [extractionSources, setExtractionSources] = useState(null);

  const [haveExclusiveAgreement, sethaveExclusiveAgreement] = useState(false);  
  // Por default el acuerdo comercial tiene que ser False para que puedan retirar.

  let user;
  try {
    user = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const { data } = useExclusiveAgreement(user?.id)

  // console.log("data", data)

  // console.log("user", user)

  // console.log("data-bl_exclusive_agreement", data?.data?.bl_exclusive_agreement)

  const validationAgrementExclusive = () => {
    if (data?.data?.bl_exclusive_agreement === true) {
      sethaveExclusiveAgreement(true)
    } else {
      sethaveExclusiveAgreement(false)
      handleStep(2);
    }
  }

  console.log("haveExclusiveAgreement",haveExclusiveAgreement)

  const onChange = (e) => {
    let newInitExtraction = initExtraction;
    newInitExtraction = {
      ...newInitExtraction,
      [e.target.name]: e.target.value,
    };
    dispatch(setInitExtraction(newInitExtraction));
  };

  const setOpenOptions1 = () => setOpenModalOptions1((x) => !x);
  const setOpenOptions2 = () => setOpenModalOptions2((x) => !x);

  const onChooseOptionSource = (element, title) => {
    if (title == "Origen") {
      setOpenModalOptions1(false);
    }
    dispatch(setInitExtraction(changeSource(initExtraction, element)));
  };

  const onChooseOptionDestiny = (e) => {
    // if (initExtraction.ds_currency_code === e.ds_currency_code)
    dispatch(setInitExtraction(changeDestiny(initExtraction, e)));
    setOpenOptions2();
  };

  useEffect(() => {
    const { result, conversion } = mapInitSources(
      userExtractions.money_available
    );
    setConversionValue(conversion);
    setExtractionSources(result);
    if (eo) {
      setExtractionMethod(mapInitMethods(eo));
    }
  }, [eo]); // eslint-disable-line

  useEffect(() => {
    if (initExtraction.ds_currency_code !== "") {
      let minValue;
      let message;
      const currencyCode = initExtraction.ds_currency_code;
      if (currencyCode === "ARS") {
        minValue = minExtractionAllowed * conversionValue;
        message = `El monto de extracción mínimo es de ARS ${minValue}`;
      } else {
        minValue = minExtractionAllowed;
        message = `El monto de extracción mínimo es de USD 20`;
      }
      setMinValue(minValue);
      setBottomAlert(message);
    }
  }, [initExtraction.ds_currency_code]); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(
        setInitExtraction({
          account: "",
          realAccount: "",
          imageSource: "",
          destiny: "",
          amount: "",
          ds_currency_code: "",
          idCurrency: "",
          id_account: "",
          id_type_of_payment_method: "",
          imageMethod: "",
        })
      );
    };
  }, []);

  const conditionInput =
    initExtraction.amount === "" ||
    initExtraction.realAccount === "" ||
    initExtraction.ds_currency_code === "" ||
    parseFloat(initExtraction.amount) < minValue ||
    parseFloat(initExtraction.amount) > initExtraction.account;

  const conditionButton =
    initExtraction.amount === "" ||
    initExtraction.realAccount === "" ||
    initExtraction.ds_currency_code === "" ||
    initExtraction.destiny === "" ||
    parseFloat(initExtraction.amount) < minValue ||
    parseFloat(initExtraction.amount) > initExtraction.account;

  if (loadingExtractionMethod) {
    return <LoadingScreen loading={loadingExtractionMethod} />;

    {
      /*
    else if (!loadingExtractionMethod && !eo) {
    return (
      <WrongFetchScreen
        onClickRefresh={() => dispatch(getExtractionMethod())}
      />
    );
    */
    }
  } else {
    return (
      <div className="extractions--operate--main--container">
        <DestinyAccount
          icon={addIcon}
          title="Nueva Cuenta Destino"
          onClickIcon={() =>
            history.push(`/configProfile/${user.id}`)
          }
        //onClickIcon={() => handleStep(3)}  /// ESTO NO ESTABA FUNCIONANDO CORRECTAMENTE REVISAR EL STEPPER
        />
        <DividerSpace space="XS" />
        <MoneyContainer>
          <ExtractionTitle
            title="Dinero Disponible"
            helper={true}
            icon={playVideoIcon}
            popperTitle={"Dinero disponible"}
            popperMessage={
              "Es el dinero que podés usar para transferir a una cuenta"
            }
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsSelect
              textPosition={"flex-start"}
              value={initExtraction.realAccount}
              visibleValue={true}
              image={initExtraction.imageSource}
              setOpenOptions={setOpenOptions1}
              disabled={!extractionSources}
            />
          </div>
          <DividerSpace space="XS" />
          <ExtractionTitle
            title="Cuenta Destino"
            helper={false}
            icon={playVideoIcon}
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsSelect
              textPosition={"flex-start"}
              value={initExtraction.destiny}
              //subValue={(initExtraction.id_type_of_payment_method == 1 ? 'MercadoPago' : 'Paypal')}
              visibleValue={true}
              image={initExtraction.imageMethod}
              setOpenOptions={setOpenOptions2}
              disabled={!extractionMethod}
            />
          </div>
          <BottomMessage
            message={extractionMethod
              ? ""
              : "Proceda a crear una cuenta de pago destino para continuar. ir a [+ Nueva Cuenta Destino] en el borde superior. "
            }
          />
          <DividerSpace space="XS" />
          <ExtractionTitle
            title="Dinero A Transferir"
            helper={false}
            icon={playVideoIcon}
          />
          <DividerSpace space="M" />
          <div className="extractions--continue--main--container--select">
            <ExtractionsInput
              placeholder={""}
              name="amount"
              // type="text"
              colorText="#04B404"
              coloredBorder={!conditionInput}
              fontSize="22px"
              beforeSymbol={"$"}
              afterSymbol={initExtraction.ds_currency_code}
              value={initExtraction.amount || ""}
              onChange={onChange}
              maxWidth={"60px"}
              textPosition="center"
              maxLength="6"
              disabled={initExtraction.ds_currency_code === ""}
            />
          </div>
          <BottomMessage message={bottomAlert} />
        </MoneyContainer>
        <DividerSpace space="M" />
        <div className="extractions--available--main--container--button">
          <CustomButtonRegister
            disabled={conditionButton}
            onClick={() => 
              // {
            //   if (validationAgrementExclusive) {
            //     setOpenWarning(true);
            //   } else {
            //     handleStep(2);
            //   }
            // }
            validationAgrementExclusive()
          }
          >
            CONTINUAR
          </CustomButtonRegister>
        </div>
        <CustomModal
          openModal={openModalOptions1}
          setOpenModal={setOpenModalOptions1}
        >
          <MobileWheelSelector
            title={"Origen"}
            type="combined"
            data={extractionSources}
            chooseElement={onChooseOptionSource}
            idList={"Origen"}
          />
        </CustomModal>
        <CustomModal
          openModal={openModalOptions2}
          setOpenModal={setOpenModalOptions2}
        >
          {extractionMethod && (
            <MobileWheelSelector
              title={"Destino"}
              type="combined"
              data={extractionMethod}
              chooseElement={onChooseOptionDestiny}
              idList={"Destino"}
            />
          )}
        </CustomModal>
        <AgreementsExclusive
          openInformation={haveExclusiveAgreement}
          closeInformation=
          { () => sethaveExclusiveAgreement(false)       
          } 
          data={data?.data}
        />
        {/*
          <CustomModal openModal={openWarning} setOpenModal={setOpenWarning}>
            <MessageIncompleteData
              message={
                "Recuerde seleccionar cuenta, destino y el monto inicial es de $ 10.2"
              }
            />
          </CustomModal>
        */}
      </div>
    );
  }
};
