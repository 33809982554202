import { piercing, setHeaders, setNewJson, tatoo } from "../../libs/fetch";
import {
	LOAD_REQUEST_PREFERENCES_GENDERS,
	SET_SUCCESS_PREFERENCES_GENDERS,
	SET_FAILURE_PREFERENCES_GENDERS,
	/* ******************************************* */
	LOAD_REQUEST_OPTIONS_FEATURES,
	SET_SUCCESS_OPTIONS_FEATURES,
	SET_FAILURE_OPTIONS_FEATURES,
	/* ******************************************* */
} from "./accountActionTypes";

export const getPreferencesGenders = () => {
	const url = `${process.env.REACT_APP_INITIAL_V2}/gender`;
	return async (dispatch) => {
		dispatch({ type: LOAD_REQUEST_PREFERENCES_GENDERS });
		try {
			const res = await fetch(url);
			const response = await res.json();
			if (response.code === 200) {
				console.log("PREFERENCIAS: ", response.data);
				dispatch({
					type: SET_SUCCESS_PREFERENCES_GENDERS,
					payload: response.data,
				});
			} else {
				dispatch({ type: SET_FAILURE_PREFERENCES_GENDERS });
			}
		} catch (error) {
			dispatch({ type: SET_FAILURE_PREFERENCES_GENDERS });
		}
	};
};

/* ******************************************* */
export const getOptionsFeatures = () => {
	const url = `${process.env.REACT_APP_INITIAL_V2}/stats`;
	return async (dispatch) => {
		dispatch({ type: LOAD_REQUEST_OPTIONS_FEATURES });
		try {
			const res = await fetch(url, { headers: setHeaders() });
			const response = await res.json();
			if (response.code === 200) {
				const data = setNewJson(response, tatoo, piercing);
				console.log("STATS: ", data);
				dispatch({
					type: SET_SUCCESS_OPTIONS_FEATURES,
					payload: data,
				});
			} else {
				dispatch({ type: SET_FAILURE_OPTIONS_FEATURES });
			}
		} catch (error) {
			dispatch({ type: SET_FAILURE_OPTIONS_FEATURES });
		}
	};
};
