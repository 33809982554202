import { useState } from "react";
import { Deal } from "../Deal/Deal";
import { connect } from "react-redux";
import { DefaultOption } from "../DefaultOption/DefaultOption";
import { percInitialState } from "../../redux/deals/InitialState";
import { createStructuredSelector, createSelector } from "reselect";
import {
  setSelectedAgreementHotmer,
  setSelectedAgreementHotmerOption,
  resetPercentages,
  getAgreementDetail,
} from "../../redux/deals/Actions";
import "./AgreementsOptions.css";

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        loadingAgreementsHotmers,
        errorAgreementsHotmers,
        agreementsHotmers,
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
      }) => ({
        loadingAgreementsHotmers,
        errorAgreementsHotmers,
        agreementsHotmers,
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
      })
    ),
  }),
  (dispatch) => ({
    chooseHotmer: (hotmer) => dispatch(setSelectedAgreementHotmer(hotmer)),
    chooseHotmer2: (hotmer) =>
      dispatch(setSelectedAgreementHotmerOption(hotmer)),
    getAgreementDetail: (hotmer) => dispatch(getAgreementDetail(hotmer)),
    resetPercentages: (data) => dispatch(resetPercentages(data)),
  })
)(
  ({
    propsDeal,
    chooseHotmer,
    chooseHotmer2,
    resetPercentages,
    getAgreementDetail,
  }) => {
    const {
      loadingAgreementsHotmers,
      errorAgreementsHotmers,
      agreementsHotmers,
      selectedAgreementHotmer,
      selectedAgreementHotmerOption,
    } = propsDeal;
    const [open, setOpen] = useState(false);

    if (loadingAgreementsHotmers) {
      return <div>Loading...</div>;
    } else if (errorAgreementsHotmers) {
      return <div>Error...</div>;
    } else if (agreementsHotmers) {
      return (
        <div className="agreements--options--main--container">
          <DefaultOption data={null} onClick={() => setOpen((x) => !x)} />
          <div
            className={`agreements--options--main--container--list ${
              open ? "open--options--list" : ""
            }`}
          >
            {agreementsHotmers.map((deal, i) => (
              <div
                style={{
                  background:
                    deal.ds_full_name ==
                    selectedAgreementHotmerOption?.ds_full_name
                      ? "gray"
                      : "transparent",
                }}
              >
                <Deal
                  key={i}
                  deal={deal}
                  typeDeal="agreementHotmer"
                  selectAgreementHotmer={() => {
                    const { ds_full_name } = deal;
                    if (
                      ds_full_name !=
                      selectedAgreementHotmerOption?.ds_full_name
                    ) {
                      resetPercentages(percInitialState);
                    }
                    chooseHotmer2(deal);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
);
