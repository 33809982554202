import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { HotmerFireForSearch } from "../hotmerFire/hotmerFireForSearch";
import { OpsBigFire } from "../opsBigFire/opsBigFire";
// import { OpsSmallFire } from "../opsSmallFire/opsSmallFire"
import { useTranslation } from "react-i18next";

export const GridSubscribedHotmers = ({ tab }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.fire);
  const {
    loadingSubscribedHotmers,
    errorSubscribedHotmers,
    subscribedHotmers,
  } = state;

  const history = useHistory();
  const { t } = useTranslation("global");

  const redirectToSearch = () => {
    history.push(`/search`);
    // dispatch(setScreenPosition(1));
    // dispatch(setTabSearch(0));
    // localStorage.setItem("idUrl", 1);
  };

  return (
    <Grid 
    // item xs={12}
    >
      <Grid 
      // container 
      // justify="center" 
      // spacing={1}
      >
        {loadingSubscribedHotmers ? (
          <PartialSpinner />
        ) : errorSubscribedHotmers ? (
          <OpsBigFire
            title={t("fire.content.hotmers.error.title")}
            description={t("fire.content.hotmers.error.description")}
            onClick={() => redirectToSearch}
          />
        ) : subscribedHotmers && subscribedHotmers.length !== 0 ? (
          subscribedHotmers.map((hotmer, i) => (
            <HotmerFireForSearch
              key={i}
              hotmer={hotmer}
              tab={tab}
              onClick={() => {
                history.push(`/${hotmer?.ds_display_name}`);
              }}
              onSubscription={() =>
              {history.push(`/hotmerSuscribe/${hotmer?.id_hotmer}`)}}
            />
          ))
        ) : (
          <OpsBigFire
            title={t("fire.content.hotmers.noContent.title")}
            description={t("fire.content.hotmers.noContent.description")}
            onClick={redirectToSearch}
          />
        )}
      </Grid>
    </Grid>
  );
};

