import React, { useState } from "react";
//import profileDefault from "../../icons/profile/profile-default-img.png";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import verifiedIcon from "../../icons/verified-icon.png";
import { DotsOptions } from "../dotsOptions/dotsOptions";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { useTranslation } from "react-i18next";
import "./hotmerHomeHeader.css";
import { displaySuccess } from "../toast/toast";

const HotmerHomeHeader = ({
  hotmer,
  clickAdd,
  myHotmer,
  profile,
  onErrorProfile,
  id_content_group,
  deletePostHotmer,
  dtPublishFile,
  showDateOfContent
}) => {
  const history = useHistory();
  const { t } = useTranslation("global");

  const visitHotmerPorfile = () =>
    history.push(`/${hotmer?.display_name}`);

  //AGREGAR HOTMER. ACCION DE CABECERA.
  const [loadingClickAdd, setLoadingClickAdd] = useState(false);
  const [successAddHotmer, setSuccessAddHotmer] = useState(false);

  const clickAddHotmer = async () => {
    const id = myHotmer.id_user_hotmer;
    setLoadingClickAdd(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      console.log("AGREGAR RES: ", res);
      const response = await res.json();
      if (response.code === 200) {
        setSuccessAddHotmer(true);
        displaySuccess(t("toast.profile.addProfileWishList"))
      } else {
        console.log("FAIL CLICK FOLLOW HOTMER");
      }
    } catch (error) {
      console.log("FAIL CLICK FOLLOW HOTMER");
      console.log(error);
    }
    setLoadingClickAdd(false);
  };

  return (
    <div className="home--main--container--hotmers--element--header">
      <div
        className="home--main--container--hotmers--element--header--profile"
        onClick={visitHotmerPorfile}
      >
        <img src={profile.src} alt="..." onError={onErrorProfile} />
      </div>
      <div className="home--main--container--hotmers--element--header--name"
      onClick={visitHotmerPorfile}
      >
        {hotmer.display_name}
        
      </div>
      {showDateOfContent &&
        <div className="home--main--container--hotmers--element--header--time">
          {dtPublishFile}
        </div>
      }
      <DividerSpace space="XXS" />
      {hotmer.bl_certificated ? (
        <div className="home--main--container--hotmers--element--header--verified--icon">
          <img src={verifiedIcon} alt="..." />
        </div>
      ) : (
        <div className="home--main--container--hotmers--element--header--verified--icon"></div>
      )}
      <DividerSpace space="XXS" />
      {!hotmer.bl_follow_hotmer && !hotmer.bl_subscriber_hotmer && (
        <div
          className={`
						home--main--container--hotmers--element--header--btn--add ${successAddHotmer
              ? "home--main--container--hotmers--element--header--btn--add--withouttext"
              : ""
            }
						`}
          onClick={() => {
            if (!hotmer.bl_follow_hotmer) {
              clickAddHotmer();
            }
          }}
        >
          {loadingClickAdd ? (
            <MiniSpinner />
          ) : hotmer.bl_follow_hotmer ? (
            ""
          ) : (
            t("post.header.follow")
          )}
        </div>
      )}

      <div className="home--main--container--hotmers--element--header--options">
        <DotsOptions
          id={myHotmer.id_user_hotmer}
          idHotmer={myHotmer.id_hotmer}
          id_content_group={id_content_group}
          deletePostHotmer={deletePostHotmer}
          hotmerUser={myHotmer.display_name}
        />
      </div>
    </div>
  );
};

export default HotmerHomeHeader;
