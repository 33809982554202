import React from "react";
import filtersIcon from "../../icons/filters-icon.png";
import "./destinyAccount.css";

export const DestinyAccount = ({
	icon,
	title,
	onClickIcon,
	options,
	onClickOptions,
}) => {
	return (
		<div className="destiny--acount--main--container">
			<div
				className="destiny--acount--main--container--icon"
				onClick={onClickIcon}
			>
				{icon && <img src={icon} alt="..." />}
			</div>
			<div
				className="destiny--acount--main--container--title"
				onClick={onClickIcon}
			>
				{title}
			</div>
			{options && (
				<div
					className="destiny--acount--main--container--iconOptions"
					onClick={onClickOptions}
				>
					<div className="destiny--acount--main--container--iconOptions--icon">
						<img src={filtersIcon} alt="..." />
					</div>
				</div>
			)}
		</div>
	);
};
