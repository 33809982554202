import React from "react";
import { toys } from "../../constants/constants";
import "./packTags.css";

export const PackTags = ({ packTags }) => {

	return (
		<div className={'pack_tags_row'}>
			{toys.map((toy, index) => (
				<div className={'pack_tags_column'} key={index}>
					<TagImage 
					key={index} 
					toy={toy} 
					packTags={packTags} 
					/>
				</div>
			))}
		</div>
	)
};

const TagImage = ( {toy, packTags} ) => {

	const getIconBy = (n) => {
		let resultToy;
		try {
			resultToy = packTags.find((packToyId) => packToyId === n.id);
			if (resultToy != undefined) {
				resultToy = n.degradeIcon;
			} else {
				resultToy = n.icon;
			}		
		} catch (error) {
			// Aca iria un icono con error
			resultToy = n.icon;			
		}
		return resultToy;
	};
	
	return (
		<div className={'pack_tags_cell'}>
			<img className={'pack_tags_image'} src={getIconBy(toy)} alt="..." />
		</div>
	);
};

