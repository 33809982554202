import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { TYPES } from "./profileActionTypes";

export const getUserStatistics = () => {
  const idUser = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idUser}/statistics`;
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_USER_STATISTICS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.success) {
        dispatch({
          type: TYPES.SET_SUCCESS_USER_STATISTICS,
          payload: response.data[0],
        });
      } else {
        dispatch({ type: TYPES.SET_FAILURE_USER_STATISTICS });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_USER_STATISTICS });
    }
  };
};
