/* eslint-disable */
import { Base64 } from "js-base64";

export const mapInitPaymentData = (loc) => {
	const search = loc.search;
	const search2 = search.substring(1, search.length);
	const search3 = search2.split("&&")[0].split("&");
	const list = search3.map((element) => {
		const e = element.split("=");
		return { [e[0]]: e[1] };
	});
	return list;
};

export const findByKey = (list, pKey) => {
	const result = list.find((e) => Object.keys(e) == pKey);
	console.log(result);
	return result;
};

export const decodeAndParseJson = (extRefData) => {
	const external_reference = Base64.decode(extRefData.external_reference);
	const extRef = JSON.parse(external_reference);
	return extRef;
};
