import React from "react";
import packWhiteIcon from "../../icons/profile/pack-icon.png";
import videoWhiteIcon from "../../icons/profile/video-icon.png";
import blockedWhiteIcon from "../../icons/whiteIcons/blocked-icon.png";
import white18YearsIcon from "../../icons/whiteIcons/18-years-white.png";
import galleryWhiteIcon from "../../icons/profile/photo-icon.png";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { ContentTypeProfile } from "../contentTypeProfile/contentTypeProfile";
import "./hotmerHomeContent.css";

const HotmerHomeContent = ({
  handleClickImage,
  hotmer,
  cover,
  onErrorCover,
}) => {
  return (
    <div
      className="home--main--container--hotmers--element--image"
      onClick={() => handleClickImage(hotmer)}
    >
      <img
        src={cover.src}
        style={{ width: "100%" }}
        //onLoad={imgLoaded} // ACA PODRIA AGREGAR UN STILO DE TRANSICION.
        onError={onErrorCover}
        alt="image-hotmer-home"
      />
      <div className="home--main--container--hotmers--element--image--18">
        <img src={white18YearsIcon} alt="..." />
      </div>
      {!hotmer.bl_subscriber_hotmer && hotmer.bl_blur && (
        <div className="home--main--container--hotmers--element--image--mask">
          <img src={blockedWhiteIcon} alt="..." />
          <DividerSpace space="XS" />
          <div className="home--main--container--hotmers--element--content--statistics">
            <div className="home--main--container--hotmers--element--content--statistics--element">
              <ContentTypeProfile
                icon={galleryWhiteIcon}
                value={hotmer.total_image}
              />
            </div>
            <div className="home--main--container--hotmers--element--content--statistics--element">
              <ContentTypeProfile
                icon={videoWhiteIcon}
                value={hotmer.total_video}
              />
            </div>
            <div className="home--main--container--hotmers--element--content--statistics--element">
              <ContentTypeProfile
                icon={packWhiteIcon}
                value={hotmer.total_pack}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotmerHomeContent;
