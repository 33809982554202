import React, { useState, useRef, useEffect } from "react";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { makeStyles } from "@material-ui/core/styles";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import successIcon from "../../icons/fetch/success-icon.png";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { useTranslation } from "react-i18next";
import "./dotsOptions.css";
//import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  paperOptions: {
    zIndex: 850,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    //outline: "none",
    minHeight: "100px",
    width: "280px",
    padding: "5px",
    border: "1px solid",
    background:"lightgray",
  },
  btnReport: {
    background: "#000000",
    color: "#ffffff",
  },
}));

//Estilo para el combo de reportar.
const useStylesReport = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export const DotsOptions = ({
  id,
  hotmerUser,
  toggleModal,
  id_content_group,
  handleEditPack,
  deletePostHotmer,
  owner,
  idHotmer,
}) => {
  // console.log("HOTMER PARAMETER: ", hotmerUser);
  const classes = useStyles();
  //const history = useHistory();
  const classesReport = useStylesReport();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  //const [openResultModal, setOpenResultModal] = useState(false);
  const [iconModal, setIconModal] = useState(null);
  const [messageModal, setMessageModal] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  //MODAL DE DIALOG CUANDO LLENAS UN REPORTE
  const [openModalReport, setOpenModalReport] = useState(false);
  //MODAL DE INFORMACION OK/NOK CUANDO ENVIAS UN REPORTE
  const [openModalReportResponse, setOpenModalReportResponse] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  //const [loadingCreation, setLoadingCreation] = useState(false);
  const [openModalTryAgain, setOpenModalTryAgain] = useState(false);
  const { t } = useTranslation("global");

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const idUserSession = getIdUser();
  //const [age, setAge] = useState("");
  const [problem, setProblem] = useState("");

  const [data, setData] = useState({ description: "" });
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const handleChange = (event) => setProblem(event.target.value);
  //const handleChange1 = (event) => setProblem(event.target.value);

  const handleCloseReport = () => {
    setOpen(false);
    setOpenModalReport(true);
  };

  const CloseReportForm = () => setOpenModalReport(false);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const acceptClickSendReport = () => {
    setOpenModalReportResponse(false);
    setOpenModalTryAgain(false);
    setMessageModal("");
    //history.push("/");
  };

  const prevOpen = useRef(open);

  const confirmHotmerProblem = () => {
    console.log({
      typeProblem: problem.value,
      data: data,
      hotmer: id,
      // ver que valores son necesarios para esto.
      id_content_group: id_content_group, //
    });
    setOpenModalReport(false);
  };

  const [datacomplaint, setDataComplaint] = useState({
    /*type_report: "",
		observation: "",
		user_report: "",
		content_group: "",*/
  });

  const sendReport = async () => {
    setLoadingSubmit(true);
    const id_type_of_complaint = problem;
    const id_user_destiny = id;
    const ds_observation = data.description; //data.ds_observation;
    //EL ID_CONTENT_GROUP SOLO SE ENVIA CUANDO ESTAS ADENTRO DE UN HOTMER EN SU CONTENIDO, SI ESTAS EN EL HOME NO SE ENVIA.
    const json = JSON.stringify({
      ds_observation,
      id_type_of_complaint,
      id_user_destiny,
      id_content_group,
    });
    console.log(json);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUserSession}/complaint`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
        body: json,
      });
      console.log("RES ENVIAR REPORTE: ", res);
      const response = await res.json();
      if (res.status === 201 && response.code === 201) {
        const idReport = response.data.id_complaint; // DEVUELVO EL ID DE REPORTE O COMPLAINT GENERADO
        console.log("RES ENVIAR REPORTE: ", response);
        setMessageModal(
          `Se genero el Reporte ${idReport} gracias por colaborar con la comunidad.`
        );
        setIconModal(successIcon);
        setOpenModalReport(false);
      } else {
        setIconModal(opsIcon);
        setMessageModal(
          "El reporte no se pudo enviar, intente mas tarde. Mail: support@hotmy.net."
        );
      }
    } catch (error) {
      setIconModal(opsIcon);
      setMessageModal("Fallo al enviar el Reporte");
      console.log(error);
    }
    setModalMessageButtonText("Aceptar");
    setOpenModalReportResponse(true);
    setLoadingSubmit(false);
    setOpenModalReport(false);
  };

  const shareProfile = (e) => {
    if (hotmerUser != "" || hotmerUser != undefined) {
      e.preventDefault();
      setOpen(false);
      if (!navigator.share) {
        alert("Navegador no soporta la Web Share API");
        return;
      }

      navigator
        .share({
          // title: "Hotmy",
          // text: "Te quiero compartir mi modelo favorit@",
          //url: process.env.PUBLIC_URL + "/hotmerProfile/" + id,
          url: process.env.PUBLIC_URL + "/" + hotmerUser,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      e.preventDefault();
      setOpen(false);
      if (!navigator.share) {
        alert("Navegador no soporta la Web Share API");
        return;
      }
      navigator
        .share({
          title: "Hotmy",
          text: "Te quiero compartir mi modelo favorit@",
          url: document.location.href,
          //console.log(process.env.REACT_APP_INITIAL_V2 + "/hotmerProfile/" + id);
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const sharePack = (e) => {
    e.preventDefault();
    setOpen(false);
    if (!navigator.share) {
      alert("Navegador no soporta la Web Share API");
      return;
    }
    navigator
      .share({
        // title: "Hotmy",
        // text: "Te quiero compartir mi pack de Hotmy.app",
        url: document.location.href,
        //console.log(process.env.REACT_APP_INITIAL_V2 + "/hotmerProfile/" + id);
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div className="dots--main--container">...</div>
      </Button>
      <Popper
        className={classes.paperOptions}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={shareProfile}>
                    {t("post.header.options.shareProfile")}
                  </MenuItem>
                  {owner && (
                    <MenuItem onClick={sharePack}>
                      {t("post.header.options.sharePack")}
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleCloseReport}>
                    {t("post.header.options.report")}
                  </MenuItem>
                  {owner && (
                    <MenuItem onClick={handleEditPack}>
                      {t("post.header.options.edit")}
                    </MenuItem>
                  )}
                  {owner && (
                    <MenuItem onClick={toggleModal}>
                      {t("post.header.options.removePack")}
                    </MenuItem>
                  )}
                  {id_content_group && idUserSession === id && (
                    <MenuItem
                      onClick={() => deletePostHotmer(id_content_group)}
                    >
                      {t("post.header.options.removePost")}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CustomModal
        openModal={openModalReport}
        setOpenModal={setOpenModalReport}
        discardExternalEvents={false}
      >
        <div className="report--main--container">
          <FormControl variant="filled" className={classesReport.formControl}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={problem}
              onChange={handleChange}
            >
              <MenuItem value={1}>No cumple con lo prometido</MenuItem>
              <MenuItem value={2}>Es spam</MenuItem>
              <MenuItem value={3}>Lenguaje que incitan al odio</MenuItem>
              <MenuItem value={4}>Actividad sexual</MenuItem>
              <MenuItem value={5}>Fraude/Estafa</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextareaAutosize
              className={classes.textArea}
              aria-label="empty textarea"
              placeholder="Ingresa tu comentario"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            {!loadingSubmit ? (
              <Button
                disabled={problem === ""}
                className={classes.btnReport}
                variant="contained"
                color="primary"
                onClick={sendReport}
              >
                Enviar
              </Button>
            ) : (
              <PartialSpinner />
            )}
          </FormControl>
        </div>
      </CustomModal>
      <CustomModal
        openModal={openModalReportResponse}
        setOpenModal={setOpenModalReportResponse}
        // discardExternalEvents={true}
      >
        <OptionTryAgain
          icon={iconModal}
          title={messageModal}
          acceptClick={acceptClickSendReport}
          buttonText={modalMessageButtonText}
          whatsApp={true}
        />
      </CustomModal>
    </div>
  );
};
