export const initialState = {
  agreementStepperOption: false,
  // agreementOption: "activos",
  agreementOption: "",
  collaborationOption: "",
  /* ***************************************** */
  loadingCollaborations: false,
  errorCollaborations: false,
  collaborations: null,
  /* ***************************************** */
  loadingCollaborationsHotmers: false,
  errorCollaborationsHotmers: false,
  collaborationsHotmers: null,
  limit: 10,
  offset: 0,
  stopFetch: false,
  nameParam: "",
  /* ***************************************** */
  loadingAgreements: false,
  errorAgreements: false,
  agreements: null,
  /* ***************************************** */
  loadingAgreementshotmers: false,
  errorAgreementsHotmers: false,
  agreementsHotmers: null,
  /* ***************************************** */
  selectedHotmers: [],
  selectedAgreementHotmer: null,
  /* ***************************************** */
  photographerPercentage: 50,
  /* ***************************************** */
  percentages: {
    subscriptions: 10,
    packs: 10,
    present: 10,
  },
  /* ***************************************** */
  selectedAgreementDeal: null,
  loadingAgreementDetail: false,
  errorAgreementDetail: false,
  agreementDetail: null,
  /* ***************************************** */
  selectedAgreementHotmerOption: null,
  /* ***************************************** */
  selectedAgreement: null,
  /* ***************************************** */
  loadingHotmerHistory: false,
  errorHotmerHistory: false,
  hotmerHistory: null,
};

export const percInitialState = {
  subscriptions: 10,
  packs: 10,
  present: 10,
};
