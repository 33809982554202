import React from "react";
import expandIcon from "../../icons/darkIcons/expand-icon.png";
import "./customUploadSelect.css";

export const CustomUploadSelect = ({ selectedType, setOpenOptions }) => {
  return (
    <div
      className="custom--upload--select--container"
      onClick={() => setOpenOptions()}
    >
      <div className="custom--upload--select--container--input">
        {!selectedType ? "Seleccionar tipo de Contenido" : selectedType.description}
        <img
          className="custom--upload--select--container--input--icon"
          src={expandIcon}
          alt="..."
        />
      </div>
    </div>
  );
};
