import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const displayWarning = () => {
  toast.warn('👋 Welcome to Copycat!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const displaySuccess = (titleSend) => {

  const title = titleSend ? titleSend : 'Success'

  toast.success(`${title}`, {
    className: "Toastify__toast-container",
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
