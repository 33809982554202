import React from "react";
import "./customButtonBuy.css";

export const CustomButtonBuy = ({ onClick, children, disabled }) => {
	return (
		<button
			className={`custom--button--buy--container ${
				disabled ? "disabled--buy--button" : ""
			}`}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
