import { TYPES } from "./Types";
import { initialState } from "./InitialState";

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /* **************************************************** */
    case TYPES.REQUEST_LOADING_HOTMERS:
      return {
        ...state,
        loadingHotmers: true,
        errorHotmers: false,
        stopHotmersFetch: false,
      };
    case TYPES.REQUEST_SUCCESS_HOTMERS:
      if (state.hotmers) {
        return {
          ...state,
          loadingHotmers: false,
          errorHotmers: false,
          hotmers: [...state.hotmers, ...action.payload],
          hotmersOffset: state.hotmersOffset + state.hotmersLimit,
          stopHotmersFetch: false,
          flagChangeHotmers: action.flagChange,
        };
      } else {
        return {
          ...state,
          loadingHotmers: false,
          errorHotmers: false,
          hotmers: action.payload,
          hotmersOffset: state.hotmersOffset + state.hotmersLimit,
          flagChangeHotmers: action.flagChange,
        };
      }
    case TYPES.REQUEST_FAILURE_HOTMERS:
      return {
        ...state,
        loadingHotmers: false,
        errorHotmers: true,
        stopHotmersFetch: true,
      };
    case TYPES.STOP_HOTMERS_FETCH:
      return {
        ...state,
        loadingHotmers: false,
        stopHotmersFetch: true,
      };
    /* **************************************************** */
    case TYPES.REQUEST_LOADING_TOYS:
      return {
        ...state,
        loadingToys: true,
        errorToys: false,
        toys: null,
      };
    case TYPES.REQUEST_SUCCESS_TOYS:
      return {
        ...state,
        loadingToys: false,
        errorToys: false,
        toys: action.payload,
      };
    case TYPES.REQUEST_FAILURE_TOYS:
      return {
        ...state,
        loadingToys: false,
        errorToys: true,
        toys: null,
      };
    /* **************************************************** */
    case TYPES.SET_INPUT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          [action.payload.name]: action.payload.value,
        },
      };
    /* **************************************************** */
    case TYPES.REQUEST_LOADING_PACKS:
      return {
        ...state,
        loadingPacks: true,
        errorPacks: false,
        stopPacksFetch: false,
      };
    case TYPES.REQUEST_SUCCESS_PACKS:
      if (state.packs) {
        return {
          ...state,
          loadingPacks: false,
          errorPacks: false,
          packs: [...state.packs, ...action.payload],
          packsOffset: state.packsOffset + state.packsLimit,
          stopPacksFetch: false,
          flagChangePacks: action.flagChange,
        };
      } else {
        return {
          ...state,
          loadingPacks: false,
          errorPacks: false,
          packs: action.payload,
          packsOffset: state.packsOffset + state.packsLimit,
          flagChangePacks: action.flagChange,
        };
      }
    case TYPES.REQUEST_FAILURE_PACKS:
      return {
        ...state,
        loadingPacks: false,
        errorPacks: true,
        stopPacksFetch: false,
      };
    case TYPES.STOP_PACKS_FETCH:
      return {
        ...state,
        loadingPacks: false,
        stopPacksFetch: true,
      };
    /* **************************************************** */
    case TYPES.SET_INITIAL_PROFILE_REQUEST:
      return {
        ...state,
        stopHotmersFetch: false,
        hotmersOffset: 0,
        hotmers: null,
      };
    case TYPES.SET_INITIAL_PACK_REQUEST:
      return {
        ...state,
        stopPacksFetch: false,
        packsOffset: 0,
        packs: null,
      };
    /* **************************************************** */
    case TYPES.SET_PROFILE_OPTION:
      return {
        ...state,
        profileOption: action.payload,
      };
    /* **************************************************** */
    case TYPES.SET_PACK_OPTION:
      return {
        ...state,
        packOption: action.payload,
      };
    /* **************************************************** */
    default:
      return state;
  }
};
