import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { HotmerFire } from "../hotmerFire/hotmerFire";
import { OpsBigFire } from "../opsBigFire/opsBigFire";
// import { OpsSmallFire } from "../opsSmallFire/opsSmallFire";
import { useTranslation } from "react-i18next";

export const GridWishHotmers = ({ tab }) => {
  // const dispatch = useDispatch();
  const state = useSelector((state) => state.fire);
  const { loadingWishHotmers, errorWishHotmers, wishHotmers } = state;
  const history = useHistory();
  const { t } = useTranslation("global");

  const redirectToSearch = () => {
    // history.push(`/search`);
    // dispatch(setScreenPosition(1));
    // dispatch(setTabSearch(0));
    // localStorage.setItem("idUrl", 1);
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={1}>
        {loadingWishHotmers ? (
          <PartialSpinner />
        ) : errorWishHotmers ? (
          <OpsBigFire
            title={t("fire.wish.hotmers.error.title")}
            description={t("fire.wish.hotmers.error.description")}
            onClick={() => {}}
          />
        ) : wishHotmers && wishHotmers.length !== 0 ? (
          wishHotmers.map((hotmer, i) => (
            <HotmerFire
              key={i}
              hotmer={hotmer}
              tab={tab}
              onClick={() => history.push(`/${hotmer.ds_display_name}`)}
            />
          ))
        ) : (
          <OpsBigFire
            title={t("fire.wish.hotmers.noContent.title")}
            description={t("fire.wish.hotmers.noContent.description")}
            onClick={redirectToSearch}
          />
        )}
      </Grid>
    </Grid>
  );
};
