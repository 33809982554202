import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./extractionFooter.css";

export const ExtractionFooter = ({
  title,
  helper,
  icon,
  onClick,
  colorTitle,
}) => {
  return (
    <div className="extraction--footer--main--container" onClick={onClick}>
      <div
        className="extraction--footer--main--container--title"
        style={{ color: colorTitle }}
      >
        {title}
      </div>
      {helper && (
        <div className="extraction--footer--main--container--helper">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};
