import React from "react";
//import blackHeartIcon from "../../icons/darkIcons/black-heart-icon.png";
import fireIcon from "../../icons/darkIcons/fire-icon.png";
import playVideoIcon from "../../icons/whiteIcons/play-icon.png";
import "./uploadImagePreview.css";

export const UploadImagePreview = ({
	file,
	index,
	choosePrevFile,
	choosePortadaFile,
}) => {
	return (
		<div className={`upload--image--main--container`}>
			<div
				className={`
        upload--image--main--container--image ${
					file.selectedFile ? "remark--border" : ""
				}
      `}
			>
				{file.type === "image" ? (
					<img
						className="upload--image--main--container--image"
						src={file.file}
						alt="..."
						onClick={() => choosePrevFile(index)}
					/>
				) : (
					<div className="upload--image--main--container--video">
						<video playsInline muted src={file.file} alt="..." />
						<div
							className="upload--image--main--container--video--mask"
							onClick={() => choosePrevFile(index)}
						>
							<img
								className="upload--image--main--container--video--mask--icon"
								src={playVideoIcon}
								alt="..."
							/>
						</div>
					</div>
				)}
				{file.type === "image" && (
					<div
						className="upload--image--main--container--portada"
						onClick={() => choosePortadaFile(file.file)}
					>
						{file.portadaFile && (
							<img
								className="upload--image--main--container--portada--icon"
								src={fireIcon}
								alt="..."
							/>
						)}
					</div>
				)}
			</div>
			{/*
			<div className="upload--image--main--container--size">
				{parseFloat(file.size).toFixed(2)} MB
			</div>
			*/}
		</div>
	);
};
