import React from "react";
//import popUpBackground from "../../icons/modals/popUp--solo.png";
import whatsAppVioletIcon from "../../icons/modals/icons--whatsapp--violet.svg";
//import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import zocaloSuperior from "../../icons/modals/zocalo--superior.png";
import "./optionTryAgain.css";

export const OptionTryAgain = ({ icon, title, buttonText, acceptClick, whatsApp }) => {
  
   let user;
        try {
          user = JSON.parse(localStorage.getItem("session")).user || "";
        } catch (error) {
          //window.location.href = "/login";
          //console.log("sin sesion aun", user);
        }

  const clickWhatsAppSupport = () => {
    try{
      window.open(
        `https://wa.me/5491168714284?text=Me%20gustaría%20recibir%20asesoramiento.%20Se%20Presento%20un%20Error.%20Mi%20Nombre%20de%20usuario%20es%20${user.full_name}%20(${user.id}),%20Les%20dejo%20mi%20consulta%20:`,
        "_blank"
      );
    } catch {
      
    }
  };

  return (
    <div className="open--try--again--main--container">
      <img
        className="open--try--again--main--container--background"
        alt="..."
        src={zocaloSuperior}
      />
      <div className="open--try--again--main--container--content">
        {/*}<DividerSpace space="X" />*/}
        <div className="open--try--again--main--container--content--icon">
          <img src={icon} alt="..." />
        </div>
        <div className="open--try--again--main--container--content--description">
          {title}
        </div>
        {whatsApp === true && (
        <>
            <div className="open--try--again--main--container--content--icon--whatsApp">
            <img src={whatsAppVioletIcon} 
            alt="WhatsApp Chat" 
            onClick={clickWhatsAppSupport}
            />
          </div>
        </>
        )}         
      </div>
      <div
        className="open--try--again--main--container--footer"
        onClick={acceptClick}
      >
        {buttonText}
      </div>
    </div>
  );
};
