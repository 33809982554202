export const LOAD_REQUEST_PREFERENCES_GENDERS =
  "LOAD_REQUEST_PREFERENCES_GENDERS";
export const SET_SUCCESS_PREFERENCES_GENDERS =
  "SET_SUCCESS_PREFERENCES_GENDERS";
export const SET_FAILURE_PREFERENCES_GENDERS =
  "SET_FAILURE_PREFERENCES_GENDERS";
/* ****************************************************** */
export const LOAD_REQUEST_OPTIONS_FEATURES = "LOAD_REQUEST_OPTIONS_FEATURES";
export const SET_SUCCESS_OPTIONS_FEATURES = "SET_SUCCESS_OPTIONS_FEATURES";
export const SET_FAILURE_OPTIONS_FEATURES = "SET_FAILURE_OPTIONS_FEATURES";
