import { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { Reload } from "../Reload/Reload";
import { NoContent } from "../NoContent/NoContent";
// import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import { SkeletonGrid } from "../skeletonCard/SkeletonGrid";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";
import { HotmerGridItem } from "../HotmerGridItem/HotmerGridItem";
import { getHotmers } from "../../views/search/Actions";
import { useSelector, useDispatch } from "react-redux";
import { PaginationLoading } from "../PaginationLoading/PaginationLoading";
import "./profileGridContent.css";

// const useStyles = makeStyles(() => ({
//   profile: {
//     display: "block",
//     alignItems: "left",
//     justifyContent: "center",
//   },
// }));

export const ProfileGridContent = ({ tab }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const {
    loadingHotmers,
    errorHotmers,
    hotmers,
    hotmersLimit,
    hotmersOffset,
    stopHotmersFetch,
    search,
    profileOption,
    flagChangeHotmers,
  } = state;

  const handleScroll = ({ target }) => {
    const { scrollHeight, scrollTop, clientHeight } = target;
    if (stopHotmersFetch || loadingHotmers) return;
    if (scrollHeight - scrollTop <= clientHeight) {
      dispatch(
        getHotmers(
          search,
          profileOption,
          hotmersLimit,
          hotmersOffset,
          flagChangeHotmers
        )
      );
    }
  };

  const profileRequest = () =>
    dispatch(
      getHotmers(
        search,
        profileOption,
        hotmersLimit,
        hotmersOffset,
        flagChangeHotmers
      )
    );

  return (
    <div className="search--profile--grid--content--main--container">
      {loadingHotmers && hotmersOffset === 0 && <SkeletonGrid />}
      {!hotmers && !errorHotmers && stopHotmersFetch && <NoContent />}
      {errorHotmers && hotmersOffset === 0 && (
        <Fragment>
          <Reload onClick={profileRequest} />
          <SimpleMessage message={"Error"} />
        </Fragment>
      )}
      {hotmers && (
        <Scrollbars onScroll={handleScroll}>
          <Grid container justifyContent="center" spacing={1}>
            {hotmers.map((hotmer, i) => (
              // <Grid item xs={4} key={i} className={classes.profile}>
                <Grid key={i} className={"search--profile--grid--content--main--container2"}>
                <HotmerGridItem hc={hotmer} />
              </Grid>
            ))}
          </Grid>
          {loadingHotmers && <PaginationLoading />}
          {errorHotmers && <Reload onClick={profileRequest} />}
          {!errorHotmers && stopHotmersFetch && <NoContent />}
        </Scrollbars>
      )}
    </div>
  );
};
