/* eslint-disable */
// eslint-disable-line
export const emptyField = (field) => field === "";

export const repeatedFields = (a, b) => a == b;

export const objectValues = (obj) => Object.values(obj);

export const incompleteJson = (obj) => {
	const values = objectValues(obj);
	return values.some((value) => emptyField(value));
};

export const incompleteJsonExcept = (obj, exception) => {
	const entries = Object.entries(obj);
	const list = entries.filter((x) => x[0] !== exception);
	return list.some((element) => element[1] === "");
};

export const constantDataRoles = [
	{ id: 1, value: "Modelo" },
	{ id: 2, value: "Fotografo" },
	{ id: 3, value: "Manager" },
	{ id: 4, value: "Agencia" },
];

export const initialStateAccount = {
	// name: "",
	email: "",
	// birthDay: "",
	nationality: "",
	password: "",
	repeatPassword: "",
	nationality: "",
	codRef: "",
	// birthDay: "",
	// name: "",
};

export const initialMiniState = {
	// birthDay: "",
	nationality: "",
	codRef: "",
};

export const initialStateData = {
	nameHotmer: "",
	fullName: "",
	address: "no disponible",
	phoneNumber: "",
	gender: "",
	id_role: "Modelo",
	instagram: "",
};

export const initialStateFeatures = {
	height: "",
	weight: "",
	eye: "",
	hair: "",
	chest: "",
	skin: "",
	tatoo: "",
	piercing: "",
};

//export const conditionsMessage = "<a href = www.hotmy.net/terms>A continuación Clickear para ver los Términos y condiciones.";
export const conditionsMessage = "Para ver los Terminos y condiciones ir a https://www.hotmy.net/terminos-condiciones ";

export const incompleteMessage =
	"Por favor completar todos los campos como así también aceptar los términos y condiciones.";

export const incompleteMessageDni =
	"Por favor recuerde carga ambas fotos, frente y dorso del DNI.";

export const incompleteMessageSelfie =
	"Por favor recuerde que debe subir una selfie con el DNI tal cual inidica la imágen.";

export const incompleteMessageFeatures =
	"Por favor recuerde completar todas las características disponibles.";

export const getId = (optionsStats, valueGroup, field, unit, idType) => {
	let option;
	if (field === "height" || field === "weight" || field === "skin") {
		option = optionsStats[`${field}`].find((x) =>
			valueGroup[`${field}`].includes(x[`${unit}`]),
		);
	} else {
		option = optionsStats[`${field}`].find(
			(x) => x[`${unit}`] == valueGroup[`${field}`],
		);
	}
	const idFinal = option[`${idType}`];
	return idFinal;
};

export const createFeature = (
	idHeight,
	idWeight,
	idEye,
	idHair,
	idChest,
	idSkin,
	idTatoo,
	idP,
) => {
	return {
		id_type_of_height: idHeight,
		id_type_of_weight: idWeight,
		id_type_of_eye_color: idEye,
		id_type_of_hair: idHair,
		id_type_of_chest: idChest,
		id_type_of_skin_tone: idSkin,
		id_type_of_tatoo: idTatoo,
		id_type_of_piercing: idP,
	};
};

export const defineLabel = (option) => {
	const keys = Object.keys(option);
	const lengthKeys = keys.length;
	const difference = lengthKeys - 1;
	if (difference > 1) {
		return `${Object.values(option)[0]} ${Object.keys(option)[0]}
    / 
    ${Object.values(option)[1]} ${Object.keys(option)[1]} `;
	} else {
		return `${Object.values(option)[0]}`;
	}
};

export const getOptionGender = (option) => Object.values(option)[0];

export const defineTitleList = (param) => {
	switch (param) {
		case "height":
			return "Altura";
		case "weight":
			return "Peso";
		case "eye":
			return "Ojos";
		case "hair":
			return "Pelo";
		case "skin":
			return "Piel";
		case "chest":
			return "Pecho";
		case "tatoo":
			return "Tatuajes";
		case "piercing":
			return "Piercing";
	}
};

export const getIdGender = (optionsPreferences, userAccountCreation) => {
	const myGender = optionsPreferences.find(
		(p) => p.value == userAccountCreation.gender,
	);
	return myGender.id_type_of_gender;
};

export const getIdRole = (dataRoles, userAccountCreation) => {
	const myRole = dataRoles.find((p) => p.value == userAccountCreation.id_role);
	return myRole.id;
};

export const createUserJson = (userAccountCreation, myGender, stats) => {
	const userAccountCreationProfile = userAccountCreation.userData;

	const userAccountCreationNewProfile = {
		...userAccountCreationProfile,
		gender: myGender.id_type_of_gender,
	};

	const userAccountCreationNew = {
		...userAccountCreation,
		userData: userAccountCreationNewProfile,
		stats,
	};

	return userAccountCreationNew;
};

export const createUserJsonServer = (user, stats) => {
	const userAccountServerData = new FormData();
	userAccountServerData.append("front", user.file1);
	userAccountServerData.append("reverse", user.file2);
	userAccountServerData.append("selfie", user.file3);
	userAccountServerData.append("profile", JSON.stringify(user.userData));
	userAccountServerData.append("stats", JSON.stringify(stats));
	return userAccountServerData;
};

export const generateStats = (opts, data) => {
	console.log(opts, data);
	const hei = getId(opts, data, "height", "cm", "id_type_of_height");
	const wei = getId(opts, data, "weight", "kg", "id_type_of_weight");
	const eye = getId(opts, data, "eye", "color", "id_type_of_eye_color");
	const hai = getId(opts, data, "hair", "ds_name", "id_type_of_hair");
	const che = getId(opts, data, "chest", "chest", "id_type_of_chest");
	const ski = getId(opts, data, "skin", "tone", "id_type_of_skin_tone");
	const tat = getId(opts, data, "tatoo", "posee", "id_type_of_tatoo");
	const pie = getId(opts, data, "piercing", "posee", "id_type_of_piercing");
	const myJson = createFeature(hei, wei, eye, hai, che, ski, tat, pie);
	const stats = JSON.stringify(myJson);
	return stats;
};

export const createNewUserData = (userData, idGender, idRole) => {
	const newUserData = {
		...userData,
		gender: parseInt(idGender),
		id_role: parseInt(idRole),
	};
	return newUserData;
};

export const createProfile = (userAccountCreation, newUserData) => {
	console.log("JSON CREATE: ", newUserData);
	const userAccountServerData = new FormData();
	userAccountServerData.append("front", userAccountCreation.file1);
	userAccountServerData.append("reverse", userAccountCreation.file2);
	userAccountServerData.append("selfie", userAccountCreation.file3);
	userAccountServerData.append("profile", JSON.stringify(newUserData));
	return userAccountServerData;
};

export const validateEmail = (email) => {
	const pattern =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(email);
};

export const mapInitCountries = (list) => {
	const result = list.map((element) => {
		return {
			...element,
			value: element.ds_country,
			id: element.id_country,
		};
	});
	return result;
};

export const mapInitGenderHotmer = (preferencesGenders) => {
	const result = preferencesGenders.map((element) => {
		return {
			value: element.gender,
			id: element.id_type_of_gender,
			id_type_of_gender: element.id_type_of_gender,
		};
	});
	return result;
};

export const creationBody = (jsonData) => {
	const json = {
		// birthDay: jsonData.birthDay,
		// name: jsonData.name,
		firebase_token: jsonData.firebase_token,
		// device: jsonData.device,
		id_country: jsonData.id_country,
		// id_type_of_genders: list,
		is_new_user: true,
		codRef : jsonData.codRef,
	};
	return JSON.stringify(json);
};

export const createBodyClon = (miniData, loginFirebaseUser) => {
	const json = {
		// birthDay: miniData.birthDay,
		id_country: miniData.nationality.id_country,
		codRef : miniData.codRef,
		// id_type_of_genders: list,
		// name: loginFirebaseUser.name,
		firebase_token: loginFirebaseUser.firebase_token,
		// device: loginFirebaseUser.device,
		is_new_user: loginFirebaseUser.is_new_user,
		
	};
	console.log("JSON POST BODY CLON: ", json);
	return JSON.stringify(json);
};


