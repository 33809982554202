/* eslint-disable */
import React, { useState } from "react";
import addIcon from "../../icons/darkIcons/add-icon.png";
import trashIcon from "../../icons/darkIcons/trash-icon.png";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { DestinyAccount } from "../destinyAccount/destinyAccount";
import { MoneyContainer } from "../moneyContainer/moneyContainer";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import { ExtractionTitle } from "../extractionTitle/extractionTitle";
import { OptionToTrending } from "../optionToTrending/optionToTrending";
import { setUpdatePayments } from "../../redux/extractions/extractionsActions";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../accordionSets/accordionSets";
import { useTranslation } from "react-i18next";
import "./accordionPaymentMethod.css";

export const AccordionPaymentMethod = ({
  panel,
  expanded,
  handleChange,
  loading,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.main);
  const { loadingPaymentMethods, paymentMethods } = state;
  const history = useHistory();
  const id = getIdUser();
  const { t, i18n } = useTranslation("global");

  const removePaymentFromList = (pa) => {
    const pAccounts = paymentMethods.filter((x) => x.id !== pa.id);
    dispatch(setUpdatePayments(pAccounts));
  };

  return (
    <Accordion
      square
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <AccordionHeader
          panel={panel}
          expanded={expanded}
          title={t("configuration.items.payAccount")}
        />
      </AccordionSummary>
      <AccordionDetails>
        {loading && (
          <div className="edit--options--preferences--loading">
            <PartialSpinner />
          </div>
        )}
        <div className="extractions--operate--main--container">
          <DestinyAccount
            icon={addIcon}
            title={"Nueva Cuenta"}
            onClickIcon={() => history.push(`/accountCreation/${id}`)}
            options={false}
            // onClickOptions={() => {}}
          />
          {loadingPaymentMethods ? (
            <PartialSpinner />
          ) : !loadingPaymentMethods && !paymentMethods ? (
            ""
          ) : (
            <>
              {paymentMethods && paymentMethods.length > 0 && (
                <>
                  <MoneyContainer>
                    <ExtractionTitle
                      title="Tipo de cuenta"
                      helper={false}
                      icon={playVideoIcon}
                      popperTitle={"Tipo de Cuenta"}
                      popperMessage={"Cuentas Disponibles."}
                    />
                  </MoneyContainer>

                  <DividerSpace space="M" />
                  <div className="extractions--continue--main--container--payments">
                    {paymentMethods &&
                      paymentMethods.map((pa, i) => (
                        <PaymentAccount
                          key={i}
                          pa={pa}
                          removePaymentFromList={removePaymentFromList}
                        />
                      ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const PaymentAccount = ({ pa, removePaymentFromList }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [modalMessageData, setModalMessageData] = useState("");
  const [openModalRemoveAccount, setOpenModalRemoveAccount] = useState(false);
  const [modalIncompleteData, setModalIncompleteData] = useState(false);
  const [loadingRemoveAccount, setLoadingRemoveAccount] = useState(false);

  const confirmRemoveAccount = async () => {
    setLoadingRemoveAccount(true);
    const id = selectedAccount;
    try {
      const idUser = JSON.parse(localStorage.getItem("session")).user.id;
      const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/account/${id}/finantial/method`;
      const res = await fetch(url, { headers: setHeaders(), method: "DELETE" });
      console.log("RES FINANTIAL: ", res);
      if (res.status === 200) {
        removePaymentFromList(pa);
      } else {
        setModalIncompleteData(true);
        setModalMessageData("Error 102. Intente mas tarde por favor.");
      }
    } catch (error) {
      setModalIncompleteData(true);
      setModalMessageData("Error. Intente mas tarde por favor.");
      console.warn("ERROR 03: ", error);
    }
    setSelectedAccount(null);
    setLoadingRemoveAccount(false);
  };

  const removePaymentAccount = async ({ id }) => {
    setSelectedAccount(id);
    setOpenModalRemoveAccount(true);
  };

  const applyOption = () => {
    confirmRemoveAccount();
    setOpenModalRemoveAccount(false);
  };

  const cancelOption = () => {
    setOpenModalRemoveAccount(false);
    setSelectedAccount(null);
  };

  return (
    <div className="accordion--payment--method--element">
      <div className="accordion--payment--method--element--image">
        <img src={pa.image} alt="..." />
      </div>
      <hr />
      <div className="accordion--payment--method--element--value">
        {pa.value}
      </div>
      <div
        className="accordion--payment--method--element--image--option"
        onClick={() => removePaymentAccount(pa)}
      >
        {loadingRemoveAccount ? (
          <MiniSpinner />
        ) : (
          <img src={trashIcon} alt="..." />
        )}
      </div>
      <CustomModal
        openModal={openModalRemoveAccount}
        setOpenModal={setOpenModalRemoveAccount}
      >
        <OptionToTrending
          title={"Está seguro que desea eliminar esta cuenta ?"}
          acceptClick={applyOption}
          notAcceptclick={cancelOption}
          // disabledButtons={disabledButtons}
        />
      </CustomModal>
    </div>
  );
};
