//import React, { useState, useEffect } from "react"; No se usa uState y UseEffect
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingComponent } from "../../commonComponents/loadingComponent/loadingComponent";
import { WrongFetchComponent } from "../../commonComponents/wrongFetchComponent/wrongFetchComponent";
import "./hotmerGridContentVideo.css";
import { WrongFetchUploadContent } from "../../commonComponents/wrongFetchUploadContent/wrongFetchUploadContent";
import playVideoIcon from "../../icons/whiteIcons/play-icon.png";
import errorVideoIcon from "../../icons/darkIcons/video-icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100vw",
  },
  paper: {
    padding: "0",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridElement: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const HotmerGridContentVideo = ({
  loading,
  content,
  reproduceVideoContent,
  onClickRefresh,
  owner
}) => {
  const classes = useStyles();

  const onErrorVideoThumbnail = (e) => {
    e.target.src = errorVideoIcon;
  };

  if (loading) {
    return (
      <div className="grid--image--main--container--loading">
        <LoadingComponent />
      </div>
    );
  } else if (!loading && !content && owner === 0) {
    return (
      <WrongFetchUploadContent />
    )
    ;
  } else if (!loading && !content) {
    return (
     <div className="grid--image--main--container--loading">
        <WrongFetchComponent onClickRefresh={onClickRefresh} />;
      </div>)
  } else if (!loading && content.length === 0) {
    return (
      <div className="grid--image--main--container--loading">
        <WrongFetchComponent onClickRefresh={onClickRefresh} />;
      </div>
    )
  } else {
    return (
      <div className="grid--video--main--container">
        <Grid container justifyContent="center" spacing={2}>
          {content.map((content, i) => {

            return (
              <Grid
                className={classes.gridElement}
                key={i}
                item
                xs={4}
                sm={4}
                md={3}
                // lg={1}
              >
                <div
                  onClick={() => reproduceVideoContent(content)}
                  className="hotmer--profile--main--container--content--image"
                  alt="..."
                >
                  <img className="hotmer--profile--main--container--content--video" src={content.data[0].ds_url_thumbnail} 
                  onError={(e) => onErrorVideoThumbnail(e)} 
                  />
                  <img className="hotmer--profile--main--container--content--video-icon" src={playVideoIcon}  alt="IconVideo"/>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
};
