/* eslint-disable */
import React, { useState, useEffect } from "react";
import mainIcon from "../../icons/main-logo.png";
//import fireIcon from "../../icons/dark-fire.png";
import profileIcon from "../../icons/person-logo.png";
import emailIcon from "../../icons/email-logo.png";
import candadoIcon from "../../icons/candado-logo.png";
import eyeIcon from "../../icons/ojo.png";
import ubicationIcon from "../../icons/content/ubicacion-icon.png";
import { Conditions } from "../../components/conditions/conditions";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { useSelector } from "react-redux";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
//import { DropDownDate } from "../../commonComponents/dropDownDate/dropDownDate";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { CustomInputTextIcon } from "../../commonComponents/customInputTextIcon/customInputTextIcon";
import { CustomInputTextIconPassword } from "../../commonComponents/customInputTextIconPassword/customInputTextIconPassword";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { CustomInputTextIconForModal } from "../../commonComponents/customInputTextIconForModal/customInputTextIconForModal";
// import {
// 	getAvailableDays,
// 	getAvailableYears,
// 	getAvailableMonths,
// } from "../../libs/fetch";
import {
  conditionsMessage,
  incompleteJson,
  incompleteJsonExcept,
  incompleteMessage,
  initialStateAccount,
  mapInitCountries,
  repeatedFields,
  validateEmail,
  creationBody,
} from "../../libs/account";
import "./createAccount.css";
/*nuevas importaciones*/
import { firebase } from "../../firebase/firebase-config";
// import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { useHistory } from "react-router-dom";
import { setHeadersNoToken } from "../../libs/fetch";

export const CreateAccount = ({ setUserForm, handleNext }) => {
  const state = useSelector((state) => state.main);
  const { dataCountry } = state;
  const history = useHistory();
  const [conditionModal, setConditionModal] = useState(-1);
  const [messageModal, setMessageModal] = useState("");
  // const [showYesNoButtons, setShowYesNoButtons] = useState(true);
  const [createAccountFinishOk, setcreateAccountFinishOk] = useState(null);

  // const dispatch = useDispatch();

  // const [date, setDate] = useState({
  // 	year: "",
  // 	month: "",
  // 	day: "",
  // });

  const [selectedType, setSelectedType] = useState(null);
  // const [openModalSelection, setOpenModalSelection] = useState(false);
  // const [conditionModal, setConditionModal] = useState(-1);
  // const [messageModal, setMessageModal] = useState("");
  // const [showYesNoButtons, setShowYesNoButtons] = useState(true);
  // const [createAccountFinishOk, setcreateAccountFinishOk] = useState(null);
  // const [titleType, setTitleType] = useState(null);
  // const [optionsList, setOptionsList] = useState(null);
  // const [optionsYears, setOptionsYears] = useState(null);
  // const [optionsMonths, setOptionsMonths] = useState(null);
  // const [mayChild, setMayChild] = useState(null);
  // const [optionsDays, setOptionsDays] = useState(null);
  /* *********************************** */
  // const [selectedDate, setSelectedDate] = useState(new Date("2000/11/25"));
  const [loadingCreation, setLoadingCreation] = useState(false);
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  let [userData, setUserData] = useState(initialStateAccount);
  const [checked, setChecked] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const [openModalPicker, setOpenModalPicker] = useState(false);
  const [openModalCountry, setOpenModalCountry] = useState(false);
  // const [fecNac, setFecNac] = useState("");
  const [referralCode, setReferralCode] = useState(null);

  const onChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });
  //console.log("userData: ", userData);
  const onChangeCheck = () => setChecked((x) => !x);
  const changeCheckBox = () => setChecked((x) => !x);

  const openModalConditions = () => {
    setModalMessage(conditionsMessage);
    setOpenModal(true);
  };

  useEffect(() => {
    // console.log("Entre: ");
    // console.log("referralCode 0: ", referralCode);
    if (referralCode === null) {
      getReferralCodeParam();
    }
  }, []); // ante el cambio de estado del referral ejecutar el get

  // const acceptClick = () => {
  // 	if (conditionModal === 0) {
  // 		handleBack();
  // 	}
  // 	setOpenModal(false);
  // };

  // // ARMAR UNA FLECHA PARA VOLVER ATRAS en el login arriba
  // 	const cancelCreation = () => {
  // 		history.push("/login");
  // 		setOpenModal(false);
  // };
  const getReferralCodeParam = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referralCodeParam = urlParams.get("codRef");
    //console.log("Antes de Entrar: ", referralCodeParam);
    if (
      referralCodeParam == null ||
      referralCodeParam == "" ||
      referralCodeParam == undefined ||
      referralCodeParam == "null"
    ) {
      setReferralCode("");
      //console.log("Paso por Setting Vacio: ", referralCodeParam);
    } else {
      setReferralCode(referralCodeParam);
      //console.log("else referralCodeParam: ", referralCodeParam);
      //console.log("referralCode: ", referralCode);
      setUserData({ ...userData, codRef: referralCodeParam });
    }
    //return referralCodeParam;
  };

  const functionOpenModalCountry = () => setOpenModalCountry(true);

  const acceptClick = () => {
    if (conditionModal === 0) {
      handleBack();
    }
    setOpenModal(false);
  };

  // ARMAR UNA FLECHA PARA VOLVER ATRAS en el login arriba
  const cancelCreation = () => {
    history.push("/login");
    setOpenModal(false);
  };

  const handleSubmit = () => {


    // const newBirthDay = date.year + "/" + date.month + "/" + date.day;
    // let userData2 = {
    // 	...userData,
    // 	birthDay: newBirthDay,
    // };
    // setUserData(userData2);
    let userData2 = { ...userData };

    console.log("userData2",userData2)

    if (
      incompleteJsonExcept(userData2, "codRef") ||
      // ||incompleteJson(date)
      !checked
    ) {
      setModalMessage(
        incompleteMessage + " (El código de referido no es obligatorio)"
      );
      setOpenModal(true);
    } else if (!validateEmail(userData2.email)) {
      setModalMessage("El Correo electrónico no es valido.");
      setOpenModal(true);
    } else if (userData2.password.length < 6) {
      setModalMessage("La contraseña debe tener al menos 6 caracteres.");
      setOpenModal(true);
    } else if (!repeatedFields(userData2.password, userData2.repeatPassword)) {
      setModalMessage("Las contraseñas ingresadas no coinciden.");
      setOpenModal(true);
    } else {
      // setUserForm(userData2);
      // handleNext();setUserForm(userData2); /*setea la data */
      try {
        console.log(userData2.email, userData2.password);
        setLoadingCreation(true);
        console.log("Entra a firebase");
        firebase
          .auth()
          .createUserWithEmailAndPassword(userData2.email, userData2.password)
          .then(async (response) => {
            response.user.getIdToken(true).then((idToken) => {
              const jsonFirebaseData = {
                ok: true,
                firebase_token: idToken,
                // nationality: userData2.nationality,
                id_country: userData2.nationality.id_country,
                codRef: userData2.codRef,
                // name: userData2.name,
                // device: getUserDevice(),
                // birthDay: userData2.birthDay,
              };
              console.log("Entra a llamada de Creacion Final");
              creationFinal(jsonFirebaseData);
              //handleNext() //pasa a la pantalla de validacion de mail.
            });
          })
          .catch((e) => {
            console.log(e);
            if (e.code === "auth/email-already-in-use") {
              setModalMessage(
                "Ya existe un usuario registrado con este email en Hotmy. Pruebe Sincronizando su cuenta de mail con Google o Facebook. Si tuvo problemas con el registro comuniquese a support@hotmy.net"
              );
              setOpenModal(true);
              // setConditionModal(0);
              // setMessageModal(
              // 	"Ya existe un usuario con este email en Hotmy. Si tuvo problemas con el registro comuniquese a support@hotmy.net",
              // );
              // setOpenModal(true);
            }
            setLoadingCreation(false);
            /*ACA VOLARLO*/
            if (createAccountFinishOk === false) {
              const user = auth.currentUser;
              deleteUser(user)
                .then(() => {
                  console.log("Borrar usuario mal creado", user);
                  // User deleted.
                })
                .catch((error) => {
                  // An error ocurred
                  console.log("Se fue por el catch");
                  // ...
                });
            }
            // setFirebaseOk({
            // 	ok: false,
            // 	message: e.message,
            // });
          });
      } catch (error) { }
      // handleNext(); /*pasa al proximo formulario del stepper*/
    }
  };

  const creationFinal = async (jsonResponse) => {
    // const choosedOptions = selectedOptions(options);
    // if (jsonResponse.ok == false) {
    // 	setLoadingCreation(false);
    // 	setMessageModalBack(jsonResponse.message);
    // 	setOpenModalBack(true);
    // } else {
    const jsonPost = creationBody(jsonResponse);
    // , choosedOptions);
    console.log("BODY POST: ", jsonPost);
    const url = `${process.env.REACT_APP_AUTH}/login`;
    try {
      const res = await fetch(url, {
        headers: setHeadersNoToken(),
        credentials: "include",
        method: "POST",
        body: jsonPost,
      });
      console.log("RES LOGIN: ", res);
      // const response = await res.json();
      // Anulado...
      /*Obtengo toda la data de la respuesta*/
      console.log("RESPONSE LOGIN POST: ", res);
      console.log(res.status);
      console.log(res.statusText);
      console.log(res.type);
      console.log(res.url);
      if (res.status === 201) {
        // setIconModal(successIcon);
        // setReturnScreen(true);
        handleNext();
        setcreateAccountFinishOk(true);
        console.log("Creacion de Cuenta OK", setcreateAccountFinishOk);
        setModalMessageButtonText("Aceptar");
        setMessageModal(`Creación de cuenta Correcta. Confirme su Mail`);
        //handleNext();
      } else if (res.status === 400) {
        const response = await res.json();
        // setIconModal(opsIcon);
        // setReturnScreen(false)
        // setMessageModal("Operación Mal Parametros.",response.data.message);
        setModalMessage("Operación Mal Parametros.");
        setOpenModal(true);
        console.log("Error de Creacion de Cuenta Status 400", response.data);
        setcreateAccountFinishOk(false);
        // setModalMessageButtonText("Aceptar");
      } else if (res.status === 401) {
        const response = await res.json();
        // setIconModal(opsIcon);
        // setReturnScreen(false);
        setModalMessage("Operacion no valida.");
        setOpenModal(true);
        console.log("Error de Creacion de Cuenta Status 400", res.data);
        setcreateAccountFinishOk(false);
        // setModalMessageButtonText("Aceptar");
      } else {
        setOpenModal(true);
        // setIconModal(opsIcon);
        // setReturnScreen(false);
        setModalMessage("Operación Incorrecta.", response.message);
        // console.log("Error de Creacion de Cuenta Else", response);
        setcreateAccountFinishOk(false);
        // setModalMessageButtonText("Aceptar");
      }
      // if (res.status === 201) {
      // 	handleNext();
      // 	setcreateAccountFinishOk(true) //seteo variable que termino ok.
      // 	//SETEAR VARIABLE QUE SE CREO OK EL USUARIO PARA QUE LA TOME EL FIREBASE.
      // } else {
      // 	setModalMessage("La contraseña debe tener al menos 6 caracteres.",response.message);
      // 	setOpenModal(true);
      // 	// setConditionModal(1);
      // 	// setMessageModal(response.message);
      // 	// setOpenModal(true);
      // 	// console.log(response.message);
      // }
    } catch (error) {
      setConditionModal(1);
      setMessageModal("Se produjo un error.");
      setOpenModal(true);
      console.log(error);
    }
    setLoadingCreation(false);
    setcreateAccountFinishOk(false); //seteo variable que termino ok.
    console.log("Termino para el orto:", createAccountFinishOk);
  };

  // const onChooseType = (type) => setSelectedType(type);

  const chooseCountry = (c, title) => {
    setSelectedCountry(c);
    setUserData({ ...userData, nationality: c });
    setOpenModalCountry(false);
  };

  // const onChooseOption = (option, title) => {
  // 	console.log("option: ", option);
  // 	console.log("title: ", title);
  // 	if (title === "year") {
  // 		setDate({ ...date, [title]: option.value, day: "", month: "" });
  // 		const mth = getAvailableMonths(option);
  // 		console.log("mth: ", mth);
  // 		const { arrayMonths, maybeChild } = mth;
  // 		setOptionsMonths(arrayMonths);
  // 		setMayChild(maybeChild);
  // 	} else if (title === "month") {
  // 		setDate({ ...date, [title]: option.value, day: "" });
  // 		const availableDays = getAvailableDays(date.year, option.value, mayChild);
  // 		setOptionsDays(availableDays);
  // 	} else {
  // 		setDate({ ...date, [title]: option.value });
  // 	}
  // 	setOpenModalSelection(false);
  // };

  useEffect(() => {
    if (dataCountry) {
      setCountries(mapInitCountries(dataCountry));
    }
  }, [dataCountry]);

  // useEffect(() => {
  // 	if (selectedType) {
  // 		console.log("TYPE: ", selectedType);
  // 		if (selectedType === "year") {
  // 			setTitleType({ type: "Año", name: "year" });
  // 			setOptionsList(optionsYears);
  // 		} else if (selectedType === "month") {
  // 			setTitleType({ type: "Mes", name: "month" });
  // 			setOptionsList(optionsMonths);
  // 		} else if (selectedType === "day") {
  // 			setTitleType({ type: "Dia", name: "day" });
  // 			setOptionsList(optionsDays);
  // 		}
  // 		setOpenModalSelection(true);
  // 	}
  // }, [selectedType]);

  // useEffect(() => {
  // 	if (!openModalSelection) {
  // 		setSelectedType(null);
  // 	}
  // }, [openModalSelection]);

  // useEffect(() => {
  // 	const availableYears = getAvailableYears();
  // 	setOptionsYears(availableYears);
  // }, []);

  return (
    <div className="create--main--container">
      <LoadingScreen loading={loadingCreation} />
      <DividerSpace space="X" />
      <div className="create--main--container--logo">
        <img
          src={mainIcon}
          alt="..."
          className="create--main--container--logo--image"
        />
      </div>
      <DividerSpace space="X" />
      <div className="create--main--container--inputs">
        <div className="create--main--container--inputs--header">
          {/* <img
						src={fireIcon}
						alt="..."
						className="create--main--container--inputs--header--image"
					/> */}
          <DividerSpace space="XS" />
          <div className="create--main--container--inputs--header--title">
            Crear Nueva Cuenta
          </div>
        </div>
        <DividerSpace space="M" />
        {/* <CustomInputTextIcon
					placeholder="Nombre y Apellido"
					name="name"
					type="text"
					value={userData.name}
					onChange={onChange}
					image={profileIcon}
					limit={50}
				/>
				<DividerSpace space="M" /> */}
        <CustomInputTextIcon
          placeholder="Email"
          name="email"
          type="email"
          value={userData.email}
          onChange={onChange}
          image={emailIcon}
        />
        <DividerSpace space="M" />
        <CustomInputTextIconPassword
          placeholder="Contraseña"
          name="password"
          type="password"
          value={userData.password}
          onChange={onChange}
          image={candadoIcon}
          image2={eyeIcon}
        />
        <DividerSpace space="L" />
        <CustomInputTextIconPassword
          placeholder="Repetir Contraseña"
          name="repeatPassword"
          type="password"
          value={userData.repeatPassword}
          onChange={onChange}
          image={candadoIcon}
          image2={eyeIcon}
        />
        {/* <CustomInputTextIcon
          placeholder="Repetir Contraseña"
          name="repeatPassword"
          type="password"
          value={userData.repeatPassword}
          onChange={onChange}
          image={candadoIcon}
        /> */}
        <DividerSpace space="L" />
        {/* <div className="custom--input--text--birthday--date">
				Fecha de Nacimiento
			    </div>
				<div className="create--main--container--inputs--birthday">
					<DropDownDate
						type={"year"}
						value={date.year}
						onChooseType={onChooseType}
						disabled={false}
					/>
					<DropDownDate
						type={"month"}
						value={date.month}
						onChooseType={onChooseType}
						disabled={date.year === ""}
					/>
					<DropDownDate
						type={"day"}
						value={date.day}
						onChooseType={onChooseType}
						disabled={date.month === ""}
					/>
				</div>
				<DividerSpace space="M" /> */}
        <CustomInputTextIconForModal
          placeholder="Pais de Residencia"
          name="nationality"
          type="text"
          value={userData.nationality.ds_diminutive || ""}
          onChange={onChange}
          image={ubicationIcon}
          onClick={() => functionOpenModalCountry()}
        //limit={3}
        />
        <DividerSpace space="L" />
        <CustomInputTextIcon
          placeholder="Código Ref. Opcional"
          name="codRef"
          type="text"
          value={userData.codRef}
          onChange={onChange}
          image={profileIcon}
          limit={10}
        />
        <DividerSpace space="M" />
        <Conditions
          checked={checked}
          onChangeCheck={onChangeCheck}
          changeCheckBox={changeCheckBox}
          onClick={openModalConditions}
        />
      </div>
      <DividerSpace space="X" />
      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <MessageIncompleteData message={modalMessage} />
      </CustomModal>
      <CustomModal
        openModal={openModalCountry}
        setOpenModal={setOpenModalCountry}
        discardExternalEvents={false}
      >
        {countries && (
          <MobileWheelSelector
            title={"PAISES"}
            // type="onlyText"
            data={countries}
            idList={"PAISES"}
            chooseElement={chooseCountry}
          />
        )}
      </CustomModal>
      {/* <CustomModal
				openModal={openModalSelection}
				setOpenModal={setOpenModalSelection}
			>
				<MobileWheelSelector
					title={selectedType}
					type="onlyText"
					data={optionsList}
					chooseElement={onChooseOption}
					idList={"DATE"}
				/>
			</CustomModal> */}
      <div className="create--main--container--main--button">
        <CustomButtonRegister onClick={handleSubmit}>
          Crear Cuenta
        </CustomButtonRegister>
      </div>
      <DividerSpace space="XS" />
    </div>
  );
};
