/* eslint-disable */
import mercadoIcon from "../icons/payment/mercadopago_24x24.png";
import payPalIcon from "../icons/payment/paypal_24x24.png";

export const mapInitTypeAccounts = (list) => {
	const result = list.map(({ id_type_of_payment_method, ds_name }) => {
		const imageUrl = ds_name == "Paypal" ? payPalIcon : mercadoIcon;
		return {
			id: id_type_of_payment_method,
			value: ds_name,
			image: imageUrl,
		};
	});
	return result;
};

export const mapInitSources = (jsonData) => {
	const conversion = jsonData.local.vl_conversion_rate;
	let result = [];
	for (var key in jsonData) {
		var field = jsonData[key];
		const element = {
			id: field.id_currency,
			value: `${field.currency} $ ${field.value}`,
			realValue: field.value,
			ds_currency_code: field.currency,
			// image: `https://countryflagsapi.com/png/${field.ds_flag_code}`,
			image: field?.image,
		};
		result.push(element);
	}
	console.log("ORIGIENES: ", result);
	return { result, conversion };
};

export const mapInitMethods = (list) => {
	const result = list.map((element) => {
		const imageUrl =
			element.id_type_of_payment_method == 1 ? mercadoIcon : payPalIcon;
		return {
			id: element.id_account,
			id_type_of_payment_method: element.id_type_of_payment_method,
			id_account: element.id_account,
			value: element.ds_email,
			image: imageUrl,
		};
	});
	//console.log("DESTINOS: ", result);
	return result;
};

export const createConfirmExtraction = (preview) => {
	const {
		ds_currency_origin,
		vl_withdraw_money,
		account_destination,
	} = preview;
	const { id_account } = account_destination;
	return JSON.stringify({
		ds_currency_code: ds_currency_origin,
		vl_amount: vl_withdraw_money,
		id_account,
	});
};

export const changeSource = (initExtraction, e) => {
	let newInitExtraction = initExtraction;
	newInitExtraction = {
		...newInitExtraction,
		account: e.realValue,
		realAccount: e.value,
		idCurrency: e.id,
		ds_currency_code: e.ds_currency_code,
		imageSource: e.image,
	};
	return newInitExtraction;
};

export const changeDestiny = (initExtraction, e) => {
	let newInitExtraction = initExtraction;
	newInitExtraction = {
		...newInitExtraction,
		destiny: e.value,
		id_account: e.id_account,
		id_type_of_payment_method: e.id_type_of_payment_method,
		imageMethod: e.image,
	};
	return newInitExtraction;
};

export const initialDates = {
	day1: "__",
	month1: "__",
	year1: "__",
	day2: "__",
	month2: "__",
	year2: "__",
};

export const generateDate = (day, month, year) =>
	month + "/" + day + "/" + year;

export const reOrderDate = (date) => {
	const [day, month, year] = date.split("/");
	return month + "/" + day + "/" + year;
};

export const getCheckedTypes = (activities, params) => {
	const paramsList = params.map((x) => x.idType);
	let result = [];
	if (paramsList.includes(100)) {
		const resultSubs = activities.filter(
			(act) =>
				act.id_type_of_activity === 1 ||
				act.id_type_of_activity === 2 ||
				act.id_type_of_activity === 3 ||
				act.id_type_of_activity === 4,
		);
		result = [...result, ...resultSubs];
	}
	if (paramsList.includes(6)) {
		const resultPacks = activities.filter(
			(act) => act.id_type_of_activity === 6,
		);
		result = [...result, ...resultPacks];
	}
	if (paramsList.includes(5)) {
		const resultTifs = activities.filter(
			(act) => act.id_type_of_activity === 5,
		);
		result = [...result, ...resultTifs];
	}

	if (paramsList.includes(7)) {
		const resultExtractions = activities.filter(
			(act) => act.id_type_of_activity === 7,
		);
		result = [...result, ...resultExtractions];
	}

	if (paramsList.includes(9)) {
		const resultReferal = activities.filter(
			(act) => act.id_type_of_activity === 9,
		);
		result = [...result, ...resultReferal];
	}

	return result;
};

export const initialChecks = {
	checkedA: false,
	checkedB: true,
	checkedC: true,
	checkedD: true,
	checkedE: true,
	checkedF: true,
};

export const initialFilters = [
	{
		idType: 100,
		description: "Suscripciones",
		name: "bl_subscriptions",
		selected: true,
		type: "check",
	},
	{
		idType: 6,
		description: "Packs",
		name: "bl_packs",
		selected: true,
		type: "check",
	},
	{
		idType: 5,
		description: "Regalos",
		name: "bl_tips",
		selected: true,
		type: "check",
	},
	{
		idType: 7,
		description: "Extracciones",
		name: "bl_extractions",
		selected: true,
		type: "check",
	},
	{
		idType: 9,
		description: "Referido",
		name: "bl_referrals",
		selected: true,
		type: "check",
	},

	{
		idType: 8,
		description: "Desde",
		name: "dt_since",
		selected: false,
		type: "date",
		value: null,
	},
	{
		idType: 8,
		description: "Hasta",
		name: "dt_until",
		selected: false,
		type: "date",
		value: null,
	},
];

export const applyDateFilter = (date1, date2, checkFilterList) => {
	if (date1.day === "" || date2.day === "") {
		return { status: true, result: checkFilterList };
	} else {
		let newFilterDates = checkFilterList;
		const dateInit = generateDate(date1.day, date1.month, date1.year);
		const dateFinish = generateDate(date2.day, date2.month, date2.year);
		if (new Date(dateInit).getTime() <= new Date(dateFinish).getTime()) {
			newFilterDates[3].selected = true;
			newFilterDates[3].value =
				date1.day + "/" + date1.month + "/" + date1.year;
			newFilterDates[4].selected = true;
			newFilterDates[4].value =
				date2.day + "/" + date2.month + "/" + date2.year;
			return { status: true, result: newFilterDates };
		} else {
			return { status: false, result: null };
		}
	}
};

export const applyFilterByChecks = (filterChecks, filtersParams) => {
	const { checkedA, checkedB, checkedC, checkedD, checkedE, checkedF } = filterChecks;

	let newFiltersParams = filtersParams;

	const indexB = newFiltersParams.findIndex(
		(x) => x.description === "Suscripciones",
	);
	newFiltersParams[indexB].selected = checkedB;

	const indexC = newFiltersParams.findIndex((x) => x.description === "Packs");
	newFiltersParams[indexC].selected = checkedC;

	const indexD = newFiltersParams.findIndex((x) => x.description === "Regalos");
	newFiltersParams[indexD].selected = checkedD;

	const indexE = newFiltersParams.findIndex((x) => x.description === "Extracciones");
	newFiltersParams[indexE].selected = checkedE;

	const indexF = newFiltersParams.findIndex((x) => x.description === "Referido");
	newFiltersParams[indexF].selected = checkedF;

	// console.log("FILTER CHECKS: ", filterChecks);
	// console.log("NEW FILTERS: ", newFiltersParams);

	return newFiltersParams;
};

export const createPathParams = (arrayList) => {
	let path = "";
	arrayList.forEach((element) => {
		if (element.type === "check") {
			if (element.selected) {
				path += element.name + "=1&";
			} else {
				path += element.name + "=0&";
			}
		} else {
			if (element.selected) {
				path += element.name + "=" + element.value + "&";
			}
			//  else {
			// 	path += element.name + "=" + null + "&";
			// }
		}
	});
	const finalPath = path.substr(0, path.length - 1);
	return finalPath;
};

export const setUpFiltersParams = (filtersParams) => {
	const newFiltersParams = filtersParams.map((element) => {
		if (element.type === "check") {
			return {
				...element,
				selected: true,
			};
		} else {
			return {
				...element,
				selected: false,
				value: null,
			};
		}
	});
	 console.log("SETUP FILTERS: ", newFiltersParams);
	return newFiltersParams;
};

export const createFilterParamsState = (filtersParams) => {
	let jsonDateInit = { year: "", month: "", day: "" };
	let jsonDateFinish = { year: "", month: "", day: "" };
	console.log("filtersParams: ", filtersParams);
	const dataInit = filtersParams[5];
	const dataFinish = filtersParams[6];

	if (dataInit.selected) {
		const arrayInit = dataInit.value.split("/");
		jsonDateInit = {
			year: arrayInit[2],
			month: arrayInit[1],
			day: arrayInit[0],
		};
	}

	if (dataFinish.selected) {
		const arrayFinish = dataFinish.value.split("/");
		jsonDateFinish = {
			year: arrayFinish[2],
			month: arrayFinish[1],
			day: arrayFinish[0],
		};
	}

	return { jsonDateInit, jsonDateFinish };
};

export const sameDates = (fParams, d1, d2) => {
	const dataInit = fParams[5].value;
	const dataFinish = fParams[6].value;

	const dataInitString = d1.day + "/" + d1.month + "/" + d1.year;
	const dataFinishString = d2.day + "/" + d2.month + "/" + d2.year;

	// console.log("DATE INIT: ", dataInitString);
	// console.log("D1 : ", d1);
	// console.log("DATE FINISH: ", dataFinishString);
	// console.log("D2 : ", d2);

	return dataInit === dataInitString && dataFinish === dataFinishString;
};

export const getNewUrlParams = (filtersParams) => {
	// console.log("FILTER PARAMS: ", filtersParams);
	let url = "";
	const list = filtersParams;
	if (list[0].selected) {
		url += "bl_subscriptions=1&";
	} else {
		url += "bl_subscriptions=0&";
	}
	if (list[1].selected) {
		url += "bl_packs=1&";
	} else {
		url += "bl_packs=0&";
	}
	if (list[2].selected) {
		url += "bl_tips=1&";
	} else {
		url += "bl_tips=0&";
	}
	/*if (list[3].selected) {
		url += "bl_tips=1&";
	} else {
		url += "bl_tips=0&";
	}*/
	
	if (list[3].selected) {
		url += "bl_extractions=1&";
	} else {
		url += "bl_extractions=0&";
	}
	if (list[4].selected) {
		url += "bl_referrals=1&";
	} else {
		url += "bl_referrals=0&";
	}
	//console.log("URL: ", url);
};
