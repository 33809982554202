import { TYPES } from "./Types";
import { initialState } from "./InitialState";

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /* ****************************************************** */
    case TYPES.REQUEST_LOADING_SUBSCRIBED_HOTMERS:
      return {
        ...state,
        loadingSubscribedHotmers: true,
        errorSubscribedHotmers: false,
        subscribedHotmers: null,
      };
    case TYPES.REQUEST_SUCCESS_SUBSCRIBED_HOTMERS:
      return {
        ...state,
        loadingSubscribedHotmers: false,
        errorSubscribedHotmers: false,
        subscribedHotmers: action.payload,
      };
    case TYPES.REQUEST_FAILURE_SUBSCRIBED_HOTMERS:
      return {
        ...state,
        loadingSubscribedHotmers: false,
        errorSubscribedHotmers: true,
        subscribedHotmers: null,
      };
    /* ****************************************************** */
    case TYPES.REQUEST_LOADING_WISH_HOTMERS:
      return {
        ...state,
        loadingWishHotmers: true,
        errorWishHotmers: false,
        wishHotmers: null,
      };
    case TYPES.REQUEST_SUCCESS_WISH_HOTMERS:
      return {
        ...state,
        loadingWishHotmers: false,
        errorWishHotmers: false,
        wishHotmers: action.payload,
      };
    case TYPES.REQUEST_FAILURE_WISH_HOTMERS:
      return {
        ...state,
        loadingWishHotmers: false,
        errorWishHotmers: true,
        wishHotmers: null,
      };
    /* ****************************************************** */
    case TYPES.REQUEST_LOADING_SUBSCRIBED_PACKS:
      return {
        ...state,
        loadingSubscribedPacks: true,
        errorSubscribedPacks: false,
        subscribedPacks: null,
      };
    case TYPES.REQUEST_SUCCESS_SUBSCRIBED_PACKS:
      return {
        ...state,
        loadingSubscribedPacks: false,
        errorSubscribedPacks: false,
        subscribedPacks: action.payload,
      };
    case TYPES.REQUEST_FAILURE_SUBSCRIBED_PACKS:
      return {
        ...state,
        loadingSubscribedPacks: false,
        errorSubscribedPacks: true,
        subscribedPacks: null,
      };
    /* ****************************************************** */
    case TYPES.REQUEST_LOADING_WISH_PACKS:
      return {
        ...state,
        loadingWishPacks: true,
        errorWishPacks: false,
        wishPacks: null,
      };
    case TYPES.REQUEST_SUCCESS_WISH_PACKS:
      return {
        ...state,
        loadingWishPacks: false,
        errorWishPacks: false,
        wishPacks: action.payload,
      };
    case TYPES.REQUEST_FAILURE_WISH_PACKS:
      return {
        ...state,
        loadingWishPacks: false,
        errorWishPacks: true,
        wishPacks: null,
      };
    default:
      return state;
  }
};
