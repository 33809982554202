import { GridSubscribedHotmers } from "../GridSubscribedHotmers/GridSubscribedHotmers";
import { GridWishHotmers } from "../GridWishHotmers/GridWishHotmers";

export const HotmersSection = ({ tab }) => (
  <div className="fire--hotmer--container">
    {tab === 0 ? (
      <GridSubscribedHotmers tab={tab} />
    ) : (
      <GridWishHotmers tab={tab} />
    )}
  </div>
);
