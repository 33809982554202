import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import ImgDefaultError from "../../icons/background/background-hotmer-profile.jpg";
import "./packFireBought.css";

export const PackFireBought = ({ pack }) => {
  const history = useHistory();
  const visitPack = () =>
    history.push(`/packView/${pack.id_hotmer}/${pack.id_pack}`);

  const [packimg, setPackimg] = useState({
    src: pack.ds_url_thumbnail,
    errored: false,
  });

  const onErrorImg = () => {
    if (!packimg.errored) {
      setPackimg({
        src: ImgDefaultError,
        errored: true,
      });
    }
  };

  useEffect(() => {
    setPackimg({
      src: pack.ds_url_thumbnail || ImgDefaultError,
      errored: false,
    });
  }, [pack]); // eslint-disable-line

  return (
    <div className="pack--fire--bought--main--container" onClick={pack.id_status === 1 ? null : visitPack}>
      <div className="pack--fire--bought--main--container--title">
        {pack.ds_title}
      </div>
      <div
        className={!pack?.bl_exclusive ? "pack--fire--bought--main--container--content" : "pack--fire--bought--main--container--content--exclusive"}>
        <div className="pack--fire--bought--main--container--content--image">
          <img src={packimg.src} onError={onErrorImg} alt="..." />
        </div>
        <div className="pack--fire--bought--main--container--content--details">
          <DetailBoughtPack description="fotos" value={pack.count_image} />
          <DividerSpace space="XS" />
          <DetailBoughtPack description="videos" value={pack.count_video} />
        </div>
      </div>
      <div className="pack--fire--bought--main--container--options"></div>
    </div>
  );
};

const DetailBoughtPack = ({ value, description }) => {
  return (
    <div className="detail--bought--pack">
      <div className="detail--bought--pack--value">{value}</div>
      <DividerSpace space="XXS" />
      <div className="detail--bought--pack--description">{description}</div>
    </div>
  );
};
