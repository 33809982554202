/* eslint-disable */
import React, { useEffect, useState } from "react";
import editIcon from "../../icons/darkIcons/edit-icon.png";
import { DividerSpace } from "../dividerSpace/dividerSpace";
import "./customInputPriceSuscription.css";

export const CustomInputPriceSuscription = ({
	placeholder,
	// title,
	publicTitle,
	name,
	disabled,
	value,
	ds_currency,
	tax,
	vl_conversion_rate,
	onChange,
	type,
	maxLength,
	icon,
	colored,
}) => {
	const [calculatedValue, setCalculatedValue] = useState(null);
	/* ******************************************************* */
	const [calculatedDolarFinal, setCalculatedDolarFinal] = useState("...");
	const [calculatedArsFinal, setCalculatedArsFinal] = useState(null);

	const calculateRecargo = (value, t) =>
		(parseFloat(value * (t / 100)) + parseFloat(value)).toFixed(2);

	useEffect(() => {
		setCalculatedValue(value * vl_conversion_rate);
		const recargo = calculateRecargo(value, tax);
		setCalculatedDolarFinal(recargo);
		setCalculatedArsFinal(recargo * vl_conversion_rate);
	}, [value]); // eslint-disable-line

	return (
		<div className="custom--input--price--suscription">
			{/* ********************************************* */}
			<div className="custom--input--price--suscription--title">
				{/* {`${discount}% OFF`} */}
			</div>
			{/* ********************************************* */}
			<div className="custom--input--price--suscription--texts">
				<div
					className={`custom--input--price--suscription--texts--edit ${
						colored ? "colored--input--price" : ""
					}`}
				>
					<div className="custom--input--price--suscription--texts--edit--currency">
						USD
					</div>
					<div className="custom--input--price--suscription--texts--edit--input">
						<input
							placeholder={placeholder}
							name={name}
							type={type}
							disabled={disabled}
							value={value}
							maxLength={maxLength}
							onChange={(e) => {
								const val = e.target.value;
								if (val === "") {
									e.target.value = 0;
									onChange(e);
								} else {
									const regExp = /^\d+(\.?)?(\d{1,2})?$/;
									let regValidation = regExp.test(val);
									if (regValidation === true) {
										const badValue = val.includes("e");
										const badOrder = val[0] == "0";
										if (badValue || badOrder) {
											var pointNum = parseFloat(val);
											e.target.value = pointNum;
										}
										onChange(e);
									}
								}
							}}
						/>
					</div>
					{icon && (
						<div className="custom--input--price--suscription--texts--edit--icon">
							<img src={editIcon} alt="..." />
						</div>
					)}
				</div>
				<div className="custom--input--price--suscription--text--calculate">
					<div className="custom--input--price--suscription--text--calculate--currency">
						{ds_currency}
					</div>
					<div className="custom--input--price--suscription--text--calculate--value">
						{calculatedValue ? `$ ${calculatedValue.toFixed(2)}` : "..."}
					</div>
				</div>
			</div>
			{/* ********************************************* */}
			<div className="custom--input--price--suscription--costs">
				{`+ Gastos financieros ${tax} %`}
			</div>
			{/* ********************************************* */}
			<DividerSpace space="M" />
			{/* ********************************************* */}
			<div className="custom--input--price--suscription--public--title">
				{publicTitle}
			</div>
			{/* ********************************************* */}
			<div className="custom--input--price--suscription--texts2">
				<div
					className={`custom--input--price--suscription--texts2--edit ${
						colored ? "colored--input--price" : ""
					}`}
				>
					<div className="custom--input--price--suscription--texts2--edit--currency">
						USD
					</div>
					<div className="custom--input--price--suscription--texts2--edit--input">
						<input
							placeholder={"..."}
							name={name}
							type={"number"}
							disabled={true}
							value={calculatedDolarFinal ? calculatedDolarFinal : "..."}
							// maxlength={6}
							// onChange={(e) => {
							//   const regExp = /^[1-4]\d{0,5}(.\d{0,2})?$/;
							//   if (e.target.value === "" || regExp.test(e.target.value)) {
							//     onChange(e);
							//   }
							// }}
						/>
					</div>
				</div>
				<div className="custom--input--price--suscription--text--calculate">
					<div className="custom--input--price--suscription--text--calculate--currency">
						{ds_currency}
					</div>
					<div className="custom--input--price--suscription--text--calculate--value">
						{calculatedArsFinal ? `$ ${calculatedArsFinal.toFixed(2)}` : "..."}
					</div>
				</div>
			</div>
			{/* ********************************************* */}
		</div>
	);
};
