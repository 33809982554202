import "./CircularToy.css";

export const CircularToy = ({ toy, onClick }) => {
  let url =
    toy.selected && toy.selected == true
      ? process.env.REACT_APP_SERVER + toy.ds_url_img_enabled
      : process.env.REACT_APP_SERVER + toy.ds_url_img_disabled;
  return (
    <div className="circular--toy--container" onClick={onClick}>
      <img
        className={`circular--toy--container--icon ${
          toy.selected && toy.selected ? "selected--toy" : ""
        }`}
        src={url}
      />
      <div
        className={`circular--toy--container--name ${
          toy.selected && toy.selected ? "selected--name" : ""
        }`}
      >
        {toy.ds_name}
      </div>
    </div>
  );
};
