/* eslint-disable */
import React, { useEffect, useState
  // useRef 
} from "react";
import playVideoIcon from "../../icons/darkIcons/play-video.png";
import { FireTabs } from "../../components/fireTabs/fireTabs";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { BottomMessage } from "../../components/bottomMessage/bottomMessage";
import { MoneyContainer } from "../../components/moneyContainer/moneyContainer";
import { ExtractionMoney } from "../../components/extractionMoney/extractionMoney";
import { RetiredMoney } from "../../components/retiredMoney/retiredMoney";
import { ExtractionTitle } from "../../components/extractionTitle/extractionTitle";
import { ExtractionFooter } from "../../components/extractionFooter/extractionFooter";
import { ActivityContainer } from "../../components/activityContainer/activityContainer";
import { getExtractionMethod } from "../../redux/extractions/extractionsActions";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { useSelector, useDispatch } from "react-redux";
import { WrongFetchTagsScreen } from "../../commonComponents/wrongFetchTagsScreen/wrongFetchTagsScreen";
// import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";
import "react-spring-bottom-sheet/dist/style.css";
import "./extractionsAvailable.css";
//import "./buttomSheet.css";

export const ExtractionsAvailable = ({
  handleStep,
  loadingInitialActivity,
  initialActivity,
  getUserInitialActivity,
  /* ************************************ */
  getUserListActivity,
}) => {
  const state = useSelector((state) => state.main);
  const { userExtractions } = state;
  const dispatch = useDispatch();
 

  const [userData, setUserData] = useState(null);
  console.log(userData);

  const [tab, setTab] = useState(0);

  const handleChange = (event, newTab) => setTab(newTab);

  const onClickRefreshActivity = () => getUserInitialActivity();
  // const [open, setOpen] = useState(true);
  // const focusRef = useRef();
  const { t } = useTranslation("global");

  // useEffect(() => {
  //   // Setting focus is to aid keyboard and screen reader nav when activating this iframe
  //   focusRef.current.focus();
  // }, []);

  useEffect(() => {
    if (userExtractions) {
      setUserData(userExtractions);
    }
  }, [userExtractions]);

  // useEffect(() => {
  // 	// Setting focus is to aid keyboard and screen reader nav when activating this iframe
  // 	focusRef.current.focus();
  //   }, []);

  // const Header = () => (
  // 	<SheetContent alignItems="center" justifyContent="space-between" mb={2}>
  // 	  <Text fontSize="xl" fontWeight="bold" width="fit-content">
  // 		Ahora en Hotmy
  // 	  </Text>
  // 	  <InfoOutlineIcon color="GrayText" w={5} h={5} mr={2} />
  // 	</SheetContent>
  //   );

  //   const Footer = ()  => (

  // 	  {/* <Text fontSize="xl" fontWeight="bold">
  // 		Optional Footer
  // 	  </Text> */}
  // 	  <Button maxWidth="6rem" onClick={cerrar}>
  // 		Done
  // 	  </Button>

  //   );

  //   const cerrar = () =>{
  // 	  console.log("CERRAR")
  //   }

  //   const Body = () => (
  // 	<SheetContent stack py={5}>
  // 	  <Box pb={5} px={4}>
  // 		Pensamos en vos, ahora podes sacar tu dinero en 7 dias y con un minimo de 30usd... aprox 3000. Te escuchamos y queremos ser mejores.
  // 	  </Box>
  // 	</SheetContent>
  //   );
  // animate out on unmount

  return (
    <div className="extractions--available--main--container">
      
      {/*Codigo de promoción de 7 dias*/}
           {/* 
            <BottomSheet
              open={open}
              onDismiss={() => setOpen(false)}
              blocking={false}
              className="extractions--information--main"
              header={
                <input
                  className="extractions--information--header rounded-md border-transparent focus:border-gray-300 focus:ring-0 "
                  type="text"
                  placeholder="Text input field in a sticky header"
                  value={t("extractions.advise.title")}
                  readOnly
                />
              }
              footer={
                <div className="extractions--information--buttons">
                  <button
                    className="extractions--information--button--close"
                    onClick={() => setOpen(!open)}
                  >
                    {" "}
                    X
                  </button>
                  <button
                    className="extractions--information--button--extractM"
                    onClick={() => {
                      dispatch(getExtractionMethod());
                      handleStep(1);
                    }}
                  >
                    {t("extractions.advise.footerTitle")}
                  </button>
                </div>
              }
              //snapPoints={({ maxHeight }) => [maxHeight / 4, maxHeight * 0.4]}
              snapPoints={({ maxHeight }) => [maxHeight / 4, maxHeight * 0.4]}
              //snapPoints={({ maxHeight }) => [90, 300]}
            >
              <div className="data-rsbs-scroll">
                <p>
                  Continuamos con nuestra bonificación, puedes {" "}
                  <span className="extractions--information--retirar--dinero">
                    RETIRAR TU DINERO
                  </span>{" "}
                  en <span className="extractions--information--plata"> 7 DIAS </span>
                  y el {" "}
                  <span className="extractions--information--retirar--dinero">
                    MONTO MÍNIMO
                  </span>{" "}
                  es de{" "}
                  <span className="extractions--information--plata">30 USD </span>{" "}
                  (Aprox 3726 Ars).
                </p>
                <p>
                  <span className="extractions--information--vigencia--promo">
                    Promoción vigente hasta el 30/06/2022.
                  </span>
                </p>
              </div>
            </BottomSheet>
            */}
    {/* FIN DE CODIGO DE PROMOCIÓN De 7 dias*/}



      {/* <BottomSheet
		// ref={ref}
        open={open}
        // onDismiss={onDismiss}
		    onDismiss={() => setOpen(false)}
        defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? snapPoints[1]}
        snapPoints={({ height, minHeight, maxHeight }) => [
          maxHeight - maxHeight / 5,
          Math.min(Math.max(height, minHeight), maxHeight * 0.525)
        ]}
        expandOnContentDrag
        header={"Ahora en Hotmy"}
        footer={<Footer />}
      	>
        <Body />
		</BottomSheet>  */}
      <FireTabs
        first={t("extractions.tabs.titleMoneyAvailable")}
        second={t("extractions.tabs.titleMoneySettle")}
        tab={tab}
        handleChange={handleChange}
      />
      <DividerSpace space="M" />
      {tab === 0 ? (
        <div className="extractions--available--main--container--money">
          <MoneyContainer>
            <ExtractionTitle
              title={t("extractions.moneyAvailable.title")}
              helper={true}
              icon={playVideoIcon}
              popperTitle={t("extractions.moneyAvailable.HelpTitle")}
              popperMessage={t("extractions.moneyAvailable.HelpDetails")}
            />
            {userData && (
              <>
                <ExtractionMoney
                  x={
                    userData.money_available.international ||
                    userData.money_available.internacional
                  }
                />
                <ExtractionMoney x={userData.money_available.local} />
              </>
            )}
          </MoneyContainer>
          <DividerSpace space="M" />
          <div className="extractions--available--main--container--button">
            <CustomButtonRegister
              onClick={() => {
                dispatch(getExtractionMethod());
                handleStep(1);
              }}
            >
              {t("extractions.moneyAvailable.extractionsButton")}
            </CustomButtonRegister>
          </div>
          <DividerSpace space="M" />

          <MoneyContainer>
            <ExtractionTitle
              title={t("extractions.moneyRetired.title")}
              helper={true}
              icon={playVideoIcon}
              popperTitle={t("extractions.moneyRetired.HelpTitle")}
              popperMessage={t("extractions.moneyRetired.HelpDetails")}
            />
            {userData && (
              <>
                <RetiredMoney
                  x={
                    userData.money_retired.international ||
                    userData.money_retired.internacional
                  } y ={
                    userData.money_retired.local ||
                    userData.money_retired.local
                  }
                />
              </>
            )}
          </MoneyContainer>

          <DividerSpace space="M" />


          <MoneyContainer>
            <ExtractionTitle
              title={t("extractions.moneyAvailable.ActitiviesTitle")}
              helper={false}
              icon={playVideoIcon}
            />
            {loadingInitialActivity ? (
              "Cargando..."
            ) : (
              <>
                {!initialActivity && (
                  <div className="extractions--available--main--container--activity">
                    <WrongFetchTagsScreen
                      onClickRefresh={onClickRefreshActivity}
                      param={"Actividad"}
                    />
                  </div>
                )}
                {initialActivity && (
                  <>
                    <ActivityContainer activity={initialActivity[0]} />
                    <ActivityContainer activity={initialActivity[1]} />
                  </>
                )}
                {initialActivity && initialActivity.length < 1 && (
                  <div className="extractions--available--main--container--activity">
                    {t("extractions.moneyAvailable.withOutActitities")}
                    {/* No hay actividad aún. */}
                  </div>
                )}
                {initialActivity && initialActivity.length !== 0 && (
                  <ExtractionFooter
                    title={t("extractions.moneyAvailable.ActitiviesFooter")}
                    colorTitle="#7a10da"
                    helper={true}
                    icon={playVideoIcon}
                    onClick={() => {
                      getUserListActivity();
                      handleStep(4);
                    }}
                  />
                )}
              </>
            )}
            {/*
            
             */}
          </MoneyContainer>
        </div>
      ) : (
        <div className="extractions--available--main--container--money">
          <MoneyContainer>
            <ExtractionTitle
              title={t("extractions.tabs.titleMoneySettle")}
              helper={true}
              icon={playVideoIcon}
              popperTitle={t("extractions.moneySettle.HelpTitle")}
              popperMessage={t("extractions.moneySettle.HelpDetails")}
            />
            <ExtractionMoney
              x={
                userData.money_settle.international ||
                userData.money_settle.internacional
              }
            />
            <ExtractionMoney x={userData.money_settle.local} />
          </MoneyContainer>
          <div className="extractions--available--main--container--message">
            <BottomMessage
              message={t("extractions.moneySettle.footerDetail")}
            />
          </div>
        </div>
      )}
    </div>
  );
};
