import React from "react";
import "./customUploadTextArea.css";

export const CustomUploadTextArea = ({
  placeholder,
  name,
  value,
  onChange,
  type,
  title,
  limit,
  disabled,
}) => {
  return (
    <div className="custom--upload--text--area--container">
      <div className="custom--upload--text--area--container--title">
        {title}
      </div>
      <div className="custom--upload--text--area--container--input">
        <textarea
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          maxLength={60}
          disabled={disabled}
        />
      </div>
      <div className="custom--upload--text--area--container--limit">
        {limit}
      </div>
    </div>
  );
};
