import React from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./extractionMoney.css";

export const ExtractionMoney = ({ x }) => {
	if (x) {
		return (
			<div className="extraction--money--main--container">
				<div className="extraction--money--main--container--value">
					$ {x.value}{" "}
				</div>
				<DividerSpace space="XS" />
				<div className="extraction--money--main--container--currency">
					{x.currency}
				</div>
			</div>
		);
	} else {
		return "";
	}
};
