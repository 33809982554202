/* eslint-disable */
import React, { useEffect, useState } from "react";
import okIcon from "../../icons/payment/ok-green-icon.png";
import { setHeaders } from "../../libs/fetch";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { SuccessScreen } from "../successScreen/successScreen";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { CurrencyOption } from "../../commonComponents/currencyOption/currencyOption";
import { PaymentsSection } from "../../components/paymentsSection/paymentsSection";
import { CustomButtonBuy } from "../../commonComponents/customButtonBuy/customButtonBuy";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { CardPriceTitleSelection } from "../../commonComponents/cardPriceTitleSelection/cardPriceTitleSelection";
import {
	updateCurrencyOptions,
	updateSelectionOptions,
	generateSubscriptionList,
	createJsonSuscribe,
} from "../../libs/subscribe";
import "./hotmerSuscribe.css";

export const HotmerSuscribe = ({ match }) => {
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [successScreen, setSuccessScren] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [modalMessage, setModalMessage] = useState(false);
	const [loadingHotmerProfile, setLoadingHotmerProfile] = useState(false);
	const [hotmerProfile, setHotmerProfile] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
	const [subscribeOptions, setSubscribeOptions] = useState(null);
	const [currency, setCurrency] = useState(null);

	const chooseCurrency = (currency) => setCurrency(currency);

	const subscribeHotmer = async () => {
		const bodyJson = createJsonSuscribe(
			hotmerProfile,
			currency,
			paymentMethodSelected,
			match,
			subscribeOptions,
		);
		console.log("BODY: ", bodyJson);
		try {
			setLoadingSubmit(true);
			const url = `${process.env.REACT_APP_INITIAL_V2}/payment/subscription`;
			console.log("URL SUSCRIBE", url);
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: bodyJson,
			});
			console.log("RES SUBSCRIBE: ", res);
			if (res.status === 201) {
				const response = await res.json();
				console.log("RESPONSE SUBSCRIBE: ", response);
				if (paymentMethodSelected == 1) {
					console.log("MERCADO PAGO");
					window.location.href = response.data.uri;
				}
				if (paymentMethodSelected == 2) {
					console.log("PAYPAL");
					window.location.href = response.data.uri;
				}
			} else if (res.status === 403) {
				setOpenModal(true);
				setModalMessage(
					"La cuenta del usuario de Mercado Pago requiere ser validada.",
				);
			} else {
				setModalMessage("Error: Intente mas tarde por favor.");
				setOpenModal(true);
			}
		} catch (error) {
			setOpenModal(true);
			console.log(error);
			setModalMessage("Error: Intente mas tarde por favor.");
		}
		setLoadingSubmit(false);
	};

	const getHotmerProfile = async (idHotmer) => {
		setLoadingHotmerProfile(true);
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/subscribe/preview/payment`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			const response = await res.json();
			if (res.status === 200 && response.code === 200) {
				// console.log("HOTMER PROFILE DATA: ", response.data);   DESBLOQUEAR ESTE CONSOLE LOG SI QUEREMOS TODA LA DATA DE LA PANTALLA DE PAGOS.
				setHotmerProfile(response.data);
				await setSubscribeOptions(
					generateSubscriptionList(
						response.data.global_price,
						response.data.local_price,
					),
				);
				// console.log(response);
				// por default seteo el precio local en caso de que exista
				setCurrency(response.data.local_price.currency.ds_currency_code? response.data.local_price.currency.ds_currency_code : response.data.global_price.currency.ds_currency_code);
			}
			setLoadingHotmerProfile(false);
		} catch (error) {
			console.log("MURIO...");
			setHotmerProfile(null);
		}
		setLoadingHotmerProfile(false);
	};

	const onClickPaymentMethod = (typeMethod) =>
		setPaymentMethodSelected(typeMethod);

	const selectOption = (id) =>
		setSubscribeOptions(updateSelectionOptions(subscribeOptions, id));

	useEffect(() => {
		setPaymentMethodSelected(null);
		if (currency) {
			if (currency === "ARS") {
				setPaymentMethod(hotmerProfile.local_price.payment_method);
			} else {
				setPaymentMethod(hotmerProfile.global_price.payment_method);
			}
			const newList = updateCurrencyOptions(subscribeOptions, currency);
			setSubscribeOptions(newList);
		}
	}, [currency]); // eslint-disable-line

	useEffect(() => {
		getHotmerProfile(match.params.id_hotmer);
	}, []); // eslint-disable-line

	if (successScreen) {
		return (
			<SuccessScreen
				name={hotmerProfile.display_name}
				title={`Suscripcion ${subscribeOptions.find((x) => x.selected).title}`}
				icon={okIcon}
				titlePayment={"Pago realizado"}
			/>
		);
	} else if (loadingHotmerProfile || loadingSubmit) {
		return <LoadingScreen loading={loadingHotmerProfile || loadingSubmit} />;
	} else if (!loadingHotmerProfile && !hotmerProfile) {
		return <WrongFetchScreen onClick={() => console.log("...")} />;
	} else {
		return (
			<div className="hotmer--suscribe--main--container">
				<div className="hotmer--suscribe--main--container--hotmer">
					<div className="hotmer--suscribe--main--container--hotmer--subscription">
						{`Suscripción: `}
					</div>
					<DividerSpace space="XXS" />
					<div className="hotmer--suscribe--main--container--hotmer--name">
						{`${hotmerProfile ? hotmerProfile.ds_display_name : "..."}`}
					</div>
				</div>
				<div className="hotmer--suscribe--main--container--data">
					<div className="hotmer--suscribe--main--container--data--currency">
						<div className="hotmer--suscribe--main--container--data--currency--title">
							Moneda
						</div>
						{hotmerProfile.global_price.currency.ds_currency_code !==
							hotmerProfile.local_price.currency.ds_currency_code && (
							<CurrencyOption
								option={hotmerProfile.local_price.currency.ds_currency_code}
								currency={currency}
								chooseCurrency={chooseCurrency}
							/>
						)}
						<CurrencyOption
							option={hotmerProfile.global_price.currency.ds_currency_code}
							currency={currency}
							chooseCurrency={chooseCurrency}
						/>
					</div>
					<div className="hotmer--suscribe--main--container--data--value">
						{subscribeOptions.map((option) => {
							const value = option.values.find((x) => x.selected);
							return (
								<CardPriceTitleSelection
									key={option.id}
									id={option.id}
									selected={option.selected}
									title={option.title}
									value={value}
									selectOption={selectOption}
								/>
							);
						})}
					</div>
				</div>
				  {hotmerProfile.suscription_in_process == true ?
				  (		<div className="hotmer--suscribe--main--container--alert">
							{`Tienes un pago iniciado, espere a que finalice la operación en su procesador de pagos para reintentar nuevamente.`}
						</div>
						):(
							<div className="hotmer--suscribe--main--container--payment"></div>
						)
				  }	
				<div className="hotmer--suscribe--main--container--payment">
					<PaymentsSection
						onClickPaymentMethod={onClickPaymentMethod}
						paymentMethodSelected={paymentMethodSelected}
						paymentMethod={paymentMethod}
					/>
				</div>

				<div className="hotmer--suscribe--main--container--buy">
					<CustomButtonBuy
						onClick={subscribeHotmer}
						disabled={!paymentMethodSelected}
					>
						IR A PAGAR
					</CustomButtonBuy>
				</div>
				<div id="paypal-button-container"></div>
				<CustomModal openModal={openModal} setOpenModal={setOpenModal}>
					<MessageIncompleteData message={modalMessage} />
				</CustomModal>
			</div>
		);
	}
};
