/* eslint-disable */
import React from "react";
import "./accordionHeader.css";

export const AccordionHeader = ({ title, panel, expanded }) => {
  return (
    <div
      className={`accordion--header--main--container ${
        expanded == panel ? "expanded--accordion" : ""
      }`}
    >
      {title}
    </div>
  );
};
