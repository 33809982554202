/* eslint-disable */
import React, { useEffect, useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import SkeletonCard from "../skeletonCard/SkeletonCard";
import profileDefault from "../../icons/profile/profile-default-img.png";
import HotmerHomeHeader from "../hotmerHomeHeader/hotmerHomeHeader";
import HotmerHomeFooter from "../hotmerHomeFooter/hotmerHomeFooter";
import HotmerHomeContent from "../hotmerHomeContent/hotmerHomeContent";
import profileDefaultCover from "../../icons/profile/profile-default-cover-home.jpg";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import "./hotmer.css";

export const Hotmer = ({ myHotmer, index }) => {

  // ESTE COMPONENTE ES EL QUE SE USA EN EL HOME. 

  const history = useHistory();

  const [hotmer, setHotmer] = useState(null);
  const [profile, setProfile] = useState(null);
  const [cover, setCover] = useState(null);

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  const onErrorCover = () => {
    if (!cover.errored) {
      setCover({
        src: opsIcon,
        errored: true,
      });
    }
  };

  const handleClickImage = (hotmer) => {
    const { id_user_hotmer } = hotmer;
    const idUserSession = getIdUser();
    if (id_user_hotmer == idUserSession) {
      history.push(`/myProfile/${idUserSession}`);
    } else {
      history.push(`/hotmerProfile/${id_user_hotmer}`);
    }
  };

  const setLikedButton = async (id_content_group) => {
    let total_likes = hotmer.total_likes;
    if (hotmer.bl_liked) {
      total_likes = total_likes - 1;
    } else {
      total_likes = total_likes + 1;
    }
    setHotmer({
      ...hotmer,
      bl_liked: !hotmer.bl_liked,
      total_likes: total_likes,
    });
    try {
      // const idContentGroup = myHotmer.id_content_group;
      const idHotmer = getIdUser();
      //const idHotmer = myHotmer.id_user_hotmer;
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/content/like`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
        body: JSON.stringify({ id_content_group: id_content_group }),
      });
      // console.log("RES LIKE: ", res);
      // console.log("RESPONSE LIKE: ", response);
      // const response = await res.json();

      if (res.status === 201) {
        const response = await res.json();
        if (response.code === 201) {
          console.log("OK, like accepted");
          // return { success: true, message: "Proceso Exitoso. Muchas Gracias" };
        } else {
          console.log("BAD, like not accepted");
          // return { success: false, message: "Proceso Fallido. Intente mas tarde por favor." };
        }
      } else {
        console.log("Respuesta distinta a 201");
        // return { success: false, message: "Proceso Fallido. Intente mas tarde por favor." };
      }  
    } catch (error) {
      console.log("FAIL CLICK LIKE TO HOTMER POST");
    }
  };


  const clickAdd = async () => {
    const id = myHotmer.id_user_hotmer;
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      console.log("AGREGAR RES: ", res);
      const response = await res.json();
      if (response.code === 200) {
        setHotmer({ ...hotmer, bl_follow_hotmer: !hotmer.bl_follow_hotmer });
      } else {
        console.log("FAIL CLICK FOLLOW HOTMER");
      }
    } catch (error) {
      console.log("FAIL CLICK FOLLOW HOTMER");
      console.log(error);
    }
  };

  const clickComments = () => console.log("clickComments");

  useEffect(() => {
    setHotmer(myHotmer);
    setProfile({
      src: myHotmer.img_profile || profileDefault,
      errored: false,
    });
    setCover({
      src: myHotmer.img_cover_art || profileDefaultCover,
      errored: false,
    });
  }, [myHotmer]); // eslint-disable-line

  if (hotmer) {
    return (
      <div
        className="home--main--container--hotmers--element"
        id={`post--number--${hotmer.id_content_group}`}
      >
        <HotmerHomeHeader
          hotmer={hotmer}
          clickAdd={clickAdd}
          myHotmer={myHotmer}
          profile={profile}
          onErrorProfile={onErrorProfile}
        />
        <HotmerHomeContent
          handleClickImage={handleClickImage}
          hotmer={hotmer}
          cover={cover}
          onErrorCover={onErrorCover}
        />
        <HotmerHomeFooter
          hotmer={hotmer}
          setLikedButton={setLikedButton}
          index={index}
          clickComments={clickComments}
          id_content_group={hotmer.id_content_group}
        />
      </div>
    );
  } else {
    return <SkeletonCard />;
  }
};
