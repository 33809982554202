import React, { useEffect, useState } from "react";
import { getDataFiles } from "../../libs/upload";
//import { UploadFiles } from "../../commonComponents/uploadFiles/uploadFiles";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { UploadPackToy } from "../../components/uploadPackToy/uploadPackToy";
import { PartialSpinner } from "../../commonComponents/partialSpinner/partialSpinner";
import { CustomUploadSelect } from "../../commonComponents/customUploadSelect/customUploadSelect";
import { CustomUploadTextArea } from "../../commonComponents/customUploadTextArea/customUploadTextArea";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { WrongFetchTagsScreen } from "../../commonComponents/wrongFetchTagsScreen/wrongFetchTagsScreen";
import { CustomUploadInputText } from "../../commonComponents/customUploadInputText/customUploadInputText";
import { CustomInputPriceUpload } from "../../commonComponents/customInputPriceUpload/customInputPriceUpload";
import { CustomTradeSelect } from "../../commonComponents/customTradeSelect/customTradeSelect";
import "./uploadFormContent.css";

export const UploadFormContent = ({
  data,
  selectedType,
  onChange,
  packToysState,
  setOpenOptions,
  openModalOptions,
  handleFiles,
  selectToy,
  taxes,
  createUpload,
  onClickRefresh,
  loadingPackTags,
  files,
  activeTrades,
  setOpenTradeOptions,
  selectedActiveTrade,
  setSelectedActiveTrade,
}) => {
  const [filesState, setFilesState] = useState(null);

  const disabledByContent = () => {
    let result = false;
    if (selectedType.id < 2) {
      //result = data.description === "";
      // || !files;
    } else {
      result =
        data.title === "" || data.description === "" || data.price === "";
      //|| !files;
    }
    return result;
  };

  useEffect(() => {
    if (files) {
      setFilesState(getDataFiles(files));
    } else {
      setFilesState(null);
    }
  }, [files]);

  return (
    <div className="upload--content--main--container--body">
      <DividerSpace space="XXL" />
      <div className="upload--content--main--container--body--content--inputs">
        <CustomUploadSelect
          name="type"
          value={data.type}
          selectedType={selectedType}
          setOpenOptions={setOpenOptions}
        />
        <DividerSpace space="XXS" />
        {data.type.description === "Pack" && (
          <div>
            <div className="upload--content--main--container--body--content--inputs--alert">
            Se desbloquea con un Pago Único del usuario, el Pack no es parte de la subscripción.
            </div>
          <DividerSpace space="XXS" />
          <CustomUploadInputText
            name="title"
            value={data.title}
            placeholder={"Titulo"}
            limit={"- 25 caracteres"}
            onChange={onChange}
            type="text"
          />
         </div>
        )}
        <DividerSpace space="XXS" />
        {data.type.description === "Video"  && (
          <div className="upload--content--main--container--body--content--inputs--alert">
          Se desbloquea con la Suscripción del Usuario.
          </div>
        )}
        {data.type.description === "Fotos"  && (
          <div className="upload--content--main--container--body--content--inputs--alert">
          Se desbloquea con la Suscripción del Usuario.
          </div>
        )}
        <CustomUploadTextArea
          name="description"
          value={data.description || " "}
          placeholder={"Texto contenido"}
          limit={"- 60 caracteres"}
          onChange={onChange}
          type="text"
          disabled={false}
        />
        <DividerSpace space="XS" />
        {data.type.description === "Pack" && activeTrades && (
          <CustomTradeSelect
            selectedActiveTrade={selectedActiveTrade}
            setSelectedActiveTrade={setSelectedActiveTrade}
            onClick={setOpenTradeOptions}
          />
        )}
        <DividerSpace space="XS" />
        {data.type.description === "Pack" && (
          <div className="upload--content--main--container--body--content--inputs--toys">
            {loadingPackTags ? (
              <PartialSpinner />
            ) : !loadingPackTags && !packToysState ? (
              <WrongFetchTagsScreen
                onClickRefresh={onClickRefresh}
                param={"Tags"}
              />
            ) : (
              packToysState.map((packToy) => (
                <UploadPackToy
                  key={packToy.id_tag}
                  packToy={packToy}
                  selectToy={selectToy}
                />
              ))
            )}
          </div>
        )}
        <DividerSpace space="XS" />
        <div>
          {data.type.description === "Pack" && (
            <CustomInputPriceUpload
              title={"Valor"}
              placeholder="..."
              name={"price"}
              value={data.price}
              onChange={onChange}
              // discount={tax}
              taxes={taxes}
              type="text"
              icon={true}
              colored={false}
              disabled={false}
              maxLength={5}
            />
          )}
        </div>
        <DividerSpace space="XS" />

        {openModalOptions ? (
          <div style={{ width: "10px", height: "8px" }} />
        ) : (
          <div style={{ width: "10px", height: "8px" }}>
            {/*
					
					<UploadFiles
						selectedType={selectedType}
						handleImageChange={handleFiles}
						disabled={openModalOptions || !selectedType}
						multiple={true}
					/>
					 */}
          </div>
        )}

        <DividerSpace space="XS" />
      </div>
      <DividerSpace space="XXS" />
      <div className="upload--content--main--container--body--files--info">
        {filesState ? (
          <>
            {`Imágenes: ${filesState.countImages} / Videos: ${filesState.countVideos}`}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="upload--content--main--container--body--confirm">
        <CustomButtonRegister
          onClick={createUpload}
          disabled={selectedType === null || disabledByContent()}
        >
          SIGUIENTE
        </CustomButtonRegister>
      </div>
    </div>
  );
};
