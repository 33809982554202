import React from "react";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import "./buttonAddComment.css";

export const ButtonAddComment = ({ loading, disabled, onClick }) => {
	return (
		<button
			disabled={disabled}
			className={`button--add--comment--main--container ${
				disabled ? "add--comment--disabled" : ""
			}`}
			onClick={onClick}
		>
			{loading ? <MiniSpinner /> : "Publicar"}
		</button>
	);
};
