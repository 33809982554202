export const TYPES = {
  REQUEST_LOADING_SUBSCRIBED_HOTMERS: "REQUEST_LOADING_SUBSCRIBED_HOTMERS",
  REQUEST_SUCCESS_SUBSCRIBED_HOTMERS: "REQUEST_SUCCESS_SUBSCRIBED_HOTMERS",
  REQUEST_FAILURE_SUBSCRIBED_HOTMERS: "REQUEST_FAILURE_SUBSCRIBED_HOTMERS",
  /* ******************************************************************** */
  REQUEST_LOADING_WISH_HOTMERS: "REQUEST_LOADING_WISH_HOTMERS",
  REQUEST_SUCCESS_WISH_HOTMERS: "REQUEST_SUCCESS_WISH_HOTMERS",
  REQUEST_FAILURE_WISH_HOTMERS: "REQUEST_FAILURE_WISH_HOTMERS",
  /* ******************************************************************** */
  REQUEST_LOADING_SUBSCRIBED_PACKS: "REQUEST_LOADING_SUBSCRIBED_PACKS",
  REQUEST_SUCCESS_SUBSCRIBED_PACKS: "REQUEST_SUCCESS_SUBSCRIBED_PACKS",
  REQUEST_FAILURE_SUBSCRIBED_PACKS: "REQUEST_FAILURE_SUBSCRIBED_PACKS",
  /* ******************************************************************** */
  REQUEST_LOADING_WISH_PACKS: "REQUEST_LOADING_WISH_PACKS",
  REQUEST_SUCCESS_WISH_PACKS: "REQUEST_SUCCESS_WISH_PACKS",
  REQUEST_FAILURE_WISH_PACKS: "REQUEST_FAILURE_WISH_PACKS",
};
