import { Fragment } from "react";
import Step from "@material-ui/core/Step";
import Divider from "@material-ui/core/Divider";
import { BottomSheet } from "react-spring-bottom-sheet";
import imageProfile from "../../icons/fueguito-card-1.png";
import Agreements from "../../components/Agreements/Agreements";
import DealFilters from "../../components/DealFilters/DealFilters";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SwipeableViews from "react-swipeable-views";
import AgreementsOptions from "../../components/AgreementsOptions/AgreementsOptions";
import DealHotmerHistory from "../../components/DealHotmerHistory/DealHotmerHistory";
import AgreementsSelectedPercentage from "../../components/AgreementsSelectedPercentage/AgreementsSelectedPercentage";
import AgreementsUltimate from "../../components/AgreementsUltimate/AgreementsUltimate";
import ColaborationsHotmers from "../../components/ColaborationsHotmers/ColaborationsHotmers";
import ColaborationsRequests from "../../components/ColaborationsRequests/ColaborationsRequests";
import AgreementDealSelected from "../../components/AgreementDealSelected/AgreementDealSelected";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { FireTabs } from "../../components/fireTabs/fireTabs";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { useTranslation } from "react-i18next";
import { percInitialState } from "../../redux/deals/InitialState";
import { useEffect, useState } from "react";
import { CustomButtonRegister } from "../../commonComponents/customButtonRegister/customButtonRegister";
import { createStructuredSelector, createSelector } from "reselect";
import { DealsHelpView } from "../../components/DealsHelpView/DealsHelpView";
import { SearchFilters } from "../../components/SearchFilters/SearchFilters";
import { HeaderSheet } from "../../components/HeaderSheet/HeaderSheet";
import { AgreementsFilter } from "../../components/AgreementsFilter/AgreementsFilter";
import { CollaborationsFilter } from "../../components/CollaborationsFilter/CollaborationsFilter";
import * as Actions from "../../redux/deals/Actions";
import "./Deals.css";

export default connect(
  createStructuredSelector({
    propDeals: createSelector(
      [({ deal }) => deal],
      ({
        agreementOption,
        agreementStepperOption,
        selectedAgreementHotmerOption,
        loadingAgreements,
        loadingCollaborations,
        collaborations,
        selectedHotmers,
        percentages,
        selectedAgreementHotmer,
        selectedAgreementDeal,
        selectedAgreement,
        agreementDetail,
        nameParam,
        limit,
        offset,
      }) => ({
        agreementOption,
        agreementStepperOption,
        selectedAgreementHotmerOption,
        loadingAgreements,
        loadingCollaborations,
        collaborations,
        selectedAgreementDeal,
        agreementDetail,
        selectedHotmers,
        percentages,
        selectedAgreementHotmer,
        selectedAgreement,
        nameParam,
        limit,
        offset,
      })
    ),
  }),
  (dispatch) => ({
    getCollaborations: (rol) => dispatch(Actions.getCollaborations(rol)),
    setSelectedAgreementHotmerOption: (data) =>
      dispatch(Actions.setSelectedAgreementHotmerOption(data)),
    getCollaborationsHotmers: (rol, name, lim, off) =>
      dispatch(Actions.getCollaborationsHotmers(rol, name, lim, off)),
    getAgreements: (rol) => dispatch(Actions.getAgreements(rol)),
    getAgreementsHotmers: (rol) => dispatch(Actions.getAgreementsHotmers(rol)),
    getHotmerHistory: (hotmer) => dispatch(Actions.getHotmerHistory(hotmer)),
    clearDealsSection: () => dispatch(Actions.clearDealsSection()),
    resetPercentages: (data) => dispatch(Actions.resetPercentages(data)),
    chooseHotmer: (data) => dispatch(Actions.setSelectedAgreementHotmer(data)),
    setSelectedHotmers: (data) => dispatch(Actions.setSelectedHotmers(data)),
    setAgreementDetail: (data) => dispatch(Actions.setAgreementDetail(data)),
    setHotmerHistory: (data) => dispatch(Actions.setHotmerHistory(data)),
    setAgreementStepperOption: (data) =>
      dispatch(Actions.setAgreementStepperOption(data)),
    setSelectedAgreementDeal: (data) =>
      dispatch(Actions.setSelectedAgreementDeal(data)),
    setSelectedAgreement: (data) =>
      dispatch(Actions.setSelectedAgreement(data)),
  })
)(
  ({
    propDeals,
    getCollaborations,
    getCollaborationsHotmers,
    getAgreements,
    getAgreementsHotmers,
    getHotmerHistory,
    clearDealsSection,
    resetPercentages,
    chooseHotmer,
    setSelectedHotmers,
    setAgreementDetail,
    setSelectedAgreementDeal,
    setHotmerHistory,
    setSelectedAgreement,
    setSelectedAgreementHotmerOption,
    setAgreementStepperOption,
  }) => {
    const {
      agreementOption,
      agreementStepperOption,
      selectedAgreementHotmerOption,
      loadingAgreements,
      loadingCollaborations,
      collaborations,
      selectedHotmers,
      percentages,
      selectedAgreementHotmer,
      selectedAgreementDeal,
      agreementDetail,
      selectedAgreement,
      nameParam,
      limit,
      offset,
    } = propDeals;

    const initialState = {
      status: false,
      view: "",
    };
    const [loadingCreateCollaboration, setLoadingCreateCollaboration] =
      useState(false);
    const [openSheet, setOpenSheet] = useState(initialState);
    const { t } = useTranslation("global");
    const [tab, setTab] = useState(0);
    const [activeStepAgreements, setActiveStepAgreements] = useState(0);
    const [stepsAgreements] = useState([0, 1, 2, 3]);
    const [activeStepCollaborations, setActiveStepCollaborations] = useState(0);
    const [stepsCollaborations] = useState([0, 1]);
    const [openModal, setOpenModal] = useState(false);
    const [messageModal, setMessageModal] = useState("");
    const theme = useTheme();

    const openFilters = (param) => setOpenSheet({ status: true, view: param });
    const closeBottomSheet = () => setOpenSheet({ status: false, view: "" });
    const applyFilters = () => console.log(agreementOption);

    const handleChange = (event, newTab) => {
      if (tab === newTab) {
        return;
      }
      setSelectedAgreement(null);
      setSelectedHotmers([]);
      resetPercentages(percInitialState);
      setSelectedAgreementDeal(null);
      chooseHotmer(null);
      setHotmerHistory(null);
      setAgreementDetail(null);
      setSelectedAgreementHotmerOption(null);
      setActiveStepAgreements(0);
      setActiveStepCollaborations(0);
      setAgreementStepperOption(false);
      setTab(newTab);
    };

    const handleStepAgreements = (step) => setActiveStepAgreements(step);
    const handleStepCollaborations = (step) =>
      setActiveStepCollaborations(step);
    const handleBackAgreement = () => handleStepAgreements((x) => x - 1);

    const session = localStorage.getItem("session");
    const { user } = JSON.parse(session);

    const applyBack = () => {
      if (tab === 0) {
        if (activeStepAgreements === 3) setActiveStepAgreements((x) => x - 1);
        if (activeStepAgreements === 2) {
          setSelectedAgreementHotmerOption(null);
          if (user.rol !== "photographer") {
            setSelectedAgreementDeal(null);
          }
          if (user.rol === "photographer") {
            setHotmerHistory(null);
          }
          setActiveStepAgreements((x) => x - 1);
          return;
        }
        if (activeStepAgreements === 1) {
          if (user.rol === "agency") {
            setAgreementDetail(null);
          }
          chooseHotmer(null);
          setSelectedAgreementDeal(null);
          resetPercentages(percInitialState);
          setSelectedAgreement(null);
          setAgreementStepperOption(false);
          setSelectedAgreementHotmerOption(null);
          setActiveStepAgreements((x) => x - 1);
        }
      } else {
        if (activeStepCollaborations === 1) {
          setSelectedHotmers([]);
          setActiveStepCollaborations((x) => x - 1);
          setSelectedAgreementDeal(null);
        }
      }
    };

    const stepperClick = async () => {
      const session = localStorage.getItem("session");
      const { user } = JSON.parse(session);
      if (tab === 0) {
        if (activeStepAgreements === 0) {
          setAgreementStepperOption(true);
          setActiveStepAgreements((x) => x + 1);
          return;
        }
        if (activeStepAgreements === 1 && user.rol === "photographer") {
          let selectedObject = selectedAgreementDeal || selectedAgreementHotmer;
          if (selectedObject) {
            getHotmerHistory(selectedObject);
          } else {
          }
          setActiveStepAgreements((x) => x + 1);
          return;
        }
        if (activeStepAgreements === 1) {
          setActiveStepAgreements((x) => x + 1);
          return;
        }
        if (activeStepAgreements === 2) {
          setActiveStepAgreements((x) => x + 1);
          return;
        }

        if (activeStepAgreements === 3) {
          const id_hotmer = selectedAgreementHotmerOption.id_hotmer;
          const id_collaboration =
            selectedAgreementHotmerOption.id_collaboration;
          const response = await Actions.createTradeAgreement(
            id_hotmer,
            id_collaboration,
            percentages,
            user.rol
          );
          const { success, message } = response;
          setMessageModal(message);
          setOpenModal(true);
        }
      } else {
        if (activeStepCollaborations <= 0) {
          setActiveStepCollaborations((x) => x + 1);
        } else {
          setLoadingCreateCollaboration(true);
          const response = await Actions.createCollaborationAgreement(
            selectedHotmers,
            user.rol
          );
          const { success, message } = response;
          setLoadingCreateCollaboration(false);
          setMessageModal(message);
          setOpenModal(true);
        }
      }
    };

    function getStepContentAgreements(step, index) {
      switch (index) {
        case 0:
          return <Agreements handleStepAgreements={handleStepAgreements} />;
        case 1: {
          if (selectedAgreementDeal) {
            return <AgreementDealSelected />;
          } else {
            return <AgreementsOptions />;
          }
        }
        case 2: {
          if (selectedAgreementHotmer || selectedAgreementHotmerOption) {
            return <AgreementsSelectedPercentage />;
          } else {
            return <DealHotmerHistory />;
          }
        }
        case 3:
          return (
            <AgreementsUltimate handleBackAgreement={handleBackAgreement} />
          );
        default:
          return "Unknown step";
      }
    }

    function getStepContentCollaborations(step, index) {
      switch (index) {
        case 0:
          return (
            <ColaborationsRequests
              handleStepCollaborations={handleStepCollaborations}
            />
          );
        case 1:
          return (
            <ColaborationsHotmers
              loadingCreateCollaboration={loadingCreateCollaboration}
            />
          );
        default:
          return "Unknown step";
      }
    }

    const acceptClick = () => (window.location.href = "/deals");

    useEffect(() => {
      if (user.rol != "hotmers") {
        getCollaborations(user.rol);
        getCollaborationsHotmers(user.rol, nameParam, limit, offset);
        getAgreements(user.rol);
        getAgreementsHotmers(user.rol);
      } else {
        getCollaborations(user.rol);
        getAgreements(user.rol);
      }
      return () => {
        clearDealsSection();
      };
    }, []); // eslint-disable-line

    const showBackButton =
      (tab === 0 && activeStepAgreements !== 0) ||
      (tab === 1 && activeStepCollaborations !== 0);

    return (
      <div className="deals--main--container">
        {showBackButton && (
          <div className="deals--main--container--back">
            <ArrowBackIcon onClick={applyBack} />
          </div>
        )}
        <FireTabs
          tab={tab}
          handleChange={handleChange}
          first={t("deals.tabs.first")}
          second={t("deals.tabs.second")}
        />
        <DealFilters
          tab={tab}
          activeStepAgreements={activeStepAgreements}
          activeStepCollaborations={activeStepCollaborations}
          //openDealFilters={toggleBottomSearch}
          //openHelpIcon={openHelpIcon}
          openFilters={openFilters}
        />
        <div className="deals--main--container--views">
          {tab === 0 ? (
            <SwipeableViews
              style={{ height: "100%" }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStepAgreements}
              onChangeIndex={handleStepAgreements}
              enableMouseEvents
              disabled={true}
            >
              {stepsAgreements.map((step, i) => (
                <Step style={{ height: "100%" }} key={i}>
                  {getStepContentAgreements(step, i)}
                </Step>
              ))}
            </SwipeableViews>
          ) : (
            <SwipeableViews
              style={{ height: "100%" }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStepCollaborations}
              onChangeIndex={handleStepCollaborations}
              enableMouseEvents
              disabled={true}
            >
              {stepsCollaborations.map((step, i) => (
                <Step style={{ height: "100%" }} key={i}>
                  {getStepContentCollaborations(step, i)}
                </Step>
              ))}
            </SwipeableViews>
          )}
        </div>
        {(tab === 0 &&
          user.rol === "agency" &&
          agreementDetail &&
          activeStepAgreements === 1) ||
        (tab === 0 &&
          user.rol === "photographer" &&
          agreementDetail &&
          agreementDetail.length === 0 &&
          !agreementStepperOption &&
          activeStepAgreements === 1) ||
        (tab === 0 &&
          user.rol === "photographer" &&
          !selectedAgreementHotmer &&
          !selectedAgreementHotmerOption &&
          activeStepAgreements === 2) ||
        user.rol === "hotmers" ? (
          <></>
        ) : (
          <Fragment>
            <Divider variant="middle" />
            <div className="deals--main--container--apply">
              <div className="deals--main--container--apply--button">
                <CustomButtonRegister
                  disabled={
                    loadingCreateCollaboration ||
                    (tab === 0 && loadingAgreements) ||
                    (tab === 1 && loadingCollaborations) ||
                    (tab === 0 &&
                      activeStepAgreements === 0 &&
                      collaborations &&
                      !collaborations.some((x) => x.status == "accepted") &&
                      user.rol !== "hotmers") ||
                    (tab === 0 &&
                      activeStepAgreements === 0 &&
                      !collaborations &&
                      user.rol !== "hotmers") ||
                    (tab === 0 &&
                      activeStepAgreements === 1 &&
                      !selectedAgreementDeal &&
                      !selectedAgreementHotmer &&
                      !selectedAgreementHotmerOption &&
                      user.rol == "agency") ||
                    (tab === 0 &&
                      activeStepAgreements === 1 &&
                      !selectedAgreementDeal &&
                      !selectedAgreementHotmer &&
                      !selectedAgreementHotmerOption &&
                      user.rol == "photographer") ||
                    (selectedHotmers.length === 0 &&
                      tab === 1 &&
                      activeStepCollaborations === 1)
                  }
                  onClick={stepperClick}
                  color={
                    (tab === 0 && activeStepAgreements === 0 && "blue") ||
                    (tab === 0 && activeStepAgreements === 1 && "red") ||
                    (tab === 0 && activeStepAgreements === 2 && "red") ||
                    (tab === 0 && activeStepAgreements === 3 && "red") ||
                    (tab === 1 && activeStepCollaborations === 0 && "blue") ||
                    (tab === 1 && activeStepCollaborations === 1 && "red")
                  }
                >
                  {tab === 0 &&
                    activeStepAgreements === 0 &&
                    !selectedAgreement &&
                    "Crear Acuerdo Comercial"}
                  {tab === 0 &&
                    activeStepAgreements === 1 &&
                    selectedAgreement &&
                    "Detalles"}
                  {tab === 0 &&
                    activeStepAgreements === 1 &&
                    !selectedAgreement &&
                    "Continuar"}
                  {tab === 0 &&
                    activeStepAgreements === 2 &&
                    !selectedAgreement &&
                    "Continuar"}
                  {tab === 0 &&
                    activeStepAgreements === 3 &&
                    !selectedAgreement &&
                    "Enviar"}
                  {tab === 1 &&
                    activeStepCollaborations === 0 &&
                    !selectedAgreement &&
                    "Crear nueva Colaboracion"}
                  {tab === 1 &&
                    activeStepCollaborations === 1 &&
                    !selectedAgreement &&
                    "Enviar"}
                </CustomButtonRegister>
              </div>
            </div>
          </Fragment>
        )}
        <CustomModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          discardExternalEvents={true}
        >
          <OptionTryAgain
            icon={imageProfile}
            title={messageModal}
            acceptClick={acceptClick}
            buttonText={"Accept"}
            whatsApp={false}
          />
        </CustomModal>
        <BottomSheet
          open={openSheet.status}
          onDismiss={() => setOpenSheet(initialState)}
          blocking={true}
          header={
            openSheet.view === "help" ? (
              <HeaderSheet toggleSheet={closeBottomSheet} />
            ) : (
              <HeaderSheet
                tab={tab}
                toggleSheet={closeBottomSheet}
                firstTitle="FILTRO ACUERDOS"
                secondTitle="FILTRO SOLICITUDES"
              />
            )
          }
        >
          <SearchFilters applyFilters={applyFilters} hideGlobalButton={true}>
            {openSheet.view === "agreements" && (
              <AgreementsFilter close={() => setOpenSheet(initialState)} />
            )}
            {openSheet.view === "collabs" && (
              <CollaborationsFilter close={() => setOpenSheet(initialState)} />
            )}
            {openSheet.view === "help" && (
              <DealsHelpView
                tab={tab}
                agreementStepperOption={agreementStepperOption}
                activeStepAgreements={activeStepAgreements}
                activeStepCollaborations={activeStepCollaborations}
                toggleSheet={closeBottomSheet}
              />
            )}
          </SearchFilters>
        </BottomSheet>
      </div>
    );
  }
);
