import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
//import { blueGrey } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import { withStyles } from "@material-ui/core/styles";
import "./customPreferenceCheck.css";

// const GreenCheckbox = withStyles({
//   root: {
//     color: blueGrey[400],
//     "&$checked": {
//       color: blueGrey[600],
//     },
//   },
// })((props) => <Checkbox color="default" size="medium" {...props} />);

export const CustomPreferenceCheck = ({
  title,
  name,
  checked,
  onChangeCheck,
  editionUser,
}) => {
  return (
    <div className="custom--preference--check--container">
      <div className="custom--preference--check--container--title">{title}</div>
      {editionUser && editionUser === "editionUser" ? (
        <FormControlLabel
          style={{
            marginLeft: "0.05rem",
          }}
          control={
            <Checkbox
              name={name}
              checked={checked}
              onChange={onChangeCheck}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
        />
      ) : (
        <Checkbox
          name={name}
          checked={checked}
          onChange={(e) => {
            console.log(e);
            onChangeCheck(e);
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      )}
      {/* <GreenCheckbox name={name} checked={checked} onChange={onChangeCheck} /> */}
    </div>
  );
};
