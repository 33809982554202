import { useTranslation } from "react-i18next";
import React from "react";
import { AccordionHeader } from "../accordionHeader/accordionHeader";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../accordionSets/accordionSets";
import "./accordionAboutMe.css";

export const AccordionAboutMe = ({
	panel,
	expanded,
	handleChange,
	onChangeAbout,
	value,
}) => {
	const { t } = useTranslation("global");
	return (
		<Accordion
			square
			expanded={expanded === panel}
			onChange={handleChange(panel)}
		>
			<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
				<AccordionHeader
					panel={panel}
					expanded={expanded}
					title={t("editProfile.topic.aboutMy")}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<div className="AccordionDetails-container">
				<h5>{t("editProfile.aboutMy.titleConteiner")}</h5>
				<textarea
					onChange={onChangeAbout}
					name="about"
					type="text"
					placeholder="Descripción que aparecera en tu perfil de Hotmy."
					value={value || ""}
					rows="7"
					maxLength="60"
					cols="40"
					style={{ outline:"auto", margin: "5px 0 0 0 0", padding: "5px", width: "100%" }}
				/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
