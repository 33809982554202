import React from "react";

import { Player, BigPlayButton } from "video-react";

//import playVideoIcon from "../../icons/whiteIcons/play-icon.png";
import "./multimediaPack.css";

export const MultimediaPack = ({
	file,
	reproduceVideoContent,
	showPhotoSwipe,
}) => {

	return (
		<div className="multimedia--main--container">
			{/* aca irian los .dots para despues ponerle acciones pero no hay acciones por el momento. */}
			{/* <div className="multimedia--main--container--dots">...</div> */}
			<div className="multimedia--main--container--dots"></div>
			<div
				className="multimedia--main--container--file">
				{/* onClick={() => {file.type === "video" && reproduceVideoContent(file) }} */}

				{file.type === "image" ? (
					<img src={file.ds_url} alt="..." onClick={() => { showPhotoSwipe(file); }} />
				) : (
					<>
					<Player
						autoPlay={false}
						fluid={true}
						width="100%"
						poster={file.ds_url_thumbnail}
						volume={0.5}
						src={file.ds_url}
					>
						<BigPlayButton position="center" />
					</Player>
					</>
					
					/*
					<>
						<video src={file.ds_url} alt="..." />
						<div className="multimedia--main--container--file--play">
							<img
								className="multimedia--main--container--file--play--icon"
								src={playVideoIcon}
								alt="..."
							/>
						</div>
					</>
					*/
				)}
			</div>
		</div>
	);
};
