import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
// import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import "./PackAvatarCreator.css";

export const PackAvatarCreator = ({
  arrayCreators,
  showPackPremium = true,
  fixViewToBuyPack = false,
}) => {
  // // console.log("arrayCreators", arrayCreators)
  // const history = useHistory();

  // const visitHotmerPorfile1 = (creator) =>
  // history.push(`/${creator}`);

  const history = useHistory();

  const handleClickHotmer = ({ ds_display_name }) => {
    // const uid = getIdUser();
    const url = `/${ds_display_name}`;
    console.log("url", url)
    history(`/${ds_display_name}`);
  };

  return (
    <>
      <div
        className={`${
          !fixViewToBuyPack ? "parent-hotmer" : "parent-grid-buy-pack"
        }`}
      >
        <div>
          {arrayCreators?.length > 1 && (
            <Typography
              className={`${
                !fixViewToBuyPack
                  ? "type-of-creators"
                  : "type-of-creators--gray"
              }`}
            >
              creadores
            </Typography>
          )}
          {arrayCreators?.length === 1 ? (
            <Typography
              className={`${
                !fixViewToBuyPack
                  ? "type-of-creators"
                  : "type-of-creators--gray"
              }`}
            >
              creador
            </Typography>
          ) : null}
        </div>
        <div className="parent2">
          {arrayCreators?.map((creators) => (
            <div
              id={creators?.ds_nick_name}
              onClick={() => {
                history.push(`/${creators?.ds_nick_name}`);
              }}
            >
              <Avatar
                src={creators?.ds_profile_photo_thumbnail_path}
                alt="img"
                className="avatar-creators"
              />
              <Typography className="avatar-names">
                {creators?.ds_nick_name}
              </Typography>
            </div>
          ))}
        </div>
        {showPackPremium && (
          <div>
            <Typography
              style={{
                fontSize: "0.8rem",
                letterSpacing: "2px",
                fontWeight: "bold",
                color: "gold",
              }}
            >
              PACK PREMIUM
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
