import { Home } from "./home";
import { connect } from "react-redux";
import { getPosts, setScrollbarPosition } from "../../redux/home/Actions";
import { selectorPost } from "../../redux/home/Selectors";
import { createStructuredSelector } from "reselect";

export default connect(
  createStructuredSelector({
    post: selectorPost,
  }),
  (dispatch) => ({
    getPosts: (limit, offset) => dispatch(getPosts(limit, offset)),
    setScrollbarPosition: (position) =>
      dispatch(setScrollbarPosition(position)),
  })
)(({ ...props }) => <Home {...props} />);
