import React from "react";
import "./customButtonRegister.css";

export const CustomButtonRegister = ({
  onClick,
  children,
  disabled,
  color = "red",
}) => {
  return (
    <button
      className={`custom--button--register--container 
        ${disabled ? "disabled--button" : ""}
        ${color === "blue" ? "blue--color--button" : "red--color--button"}
      `}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
