import React from "react";
import photoIcon from "../../icons/profile/photo-icon.png";
import videoIcon from "../../icons/profile/video-icon.png";
import { useTranslation } from "react-i18next";
import "./packDetails.css";

export const PackDetails = ({ pack }) => {
  const { t } = useTranslation("global");

  return (
    <div className="hotmer--pack--search--main--container--content--description">
      <div className="hotmer--pack--search--main--container--content--description--title">
        {t("common.pack.standar.title")}
      </div>
      <ContentDescription
        title={t("common.pack.standar.photos")}
        icon={photoIcon}
        value={pack.count_image}
      />
      <ContentDescription
        title={t("common.pack.standar.videos")}
        icon={videoIcon}
        value={pack.count_video}
      />
    </div>
  );
};

const ContentDescription = ({ title, icon, value }) => {
  const getColourByTitle = (title) => {
    if (title === "FOTOS") {
      return "#17c999";
    } else {
      return "#1068bf";
    }
  };

  return (
    <div className="content--search--description--container">
      <div className="content--search--description--container--icon">
        <div className="content--search--description--container--icon--title">
          {title}
        </div>
        <img src={icon} alt="..." />
      </div>
      <div
        style={{ color: getColourByTitle(title) }}
        className="content--search--description--container--value"
      >
        {value}
      </div>
    </div>
  );
};
