/* eslint-disable */
import { mapInitMethods } from "../../libs/extractions";
import { setHeaders } from "../../libs/fetch";
import { getIdUser } from "../../libs/login";
import { TYPES } from "./extractionsActionTypes";

export const getUserExtractions = () => {
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/money`;
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_USER_EXTRACTIONS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES EXTRACTIONS: ", res);
      // console.log("RESPONSE EXTRACTIONS: ", response);
      if (res.status === 200 && response.code === 200) {
        dispatch({
          type: TYPES.SET_SUCCESS_USER_EXTRACTIONS,
          payload: response.data,
        });
      } else {
        dispatch({ type: TYPES.SET_FAILURE_USER_EXTRACTIONS });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_USER_EXTRACTIONS });
    }
  };
};

export const getExtractionMethod = () => {
  const id = getIdUser();
  const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/extraction/method`;
  console.log(url);
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_EXTRACTION_METHODS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      console.log("RES METHOD: ", res);
      const response = await res.json();
      // console.log("RESPONSE METHOD: ", response.data);
      if (res.status === 200 && response.code === 200) {
        dispatch({
          type: TYPES.SET_SUCCESS_EXTRACTION_METHODS,
          payload: response.data,
          // payload: null,
        });
      } else {
        dispatch({ type: TYPES.SET_FAILURE_EXTRACTION_METHODS });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_EXTRACTION_METHODS });
    }
  };
};

export const getPaymentAccounts = () => {
  const idUser = JSON.parse(localStorage.getItem("session")).user.id;
  const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/account/finantial/method`;
  console.log(url);
  return async (dispatch) => {
    dispatch({ type: TYPES.LOAD_REQUEST_PAYMENT_METHODS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      //console.log("RES PAYMENT: ", res);
      if (res.status === 200) {
        const response = await res.json();
        //console.log("RESPONSE PAYMENT: ", response.data);
        const list = mapInitMethods(response.data);
        setTimeout(() => {
          dispatch({
            type: TYPES.SET_SUCCESS_PAYMENT_METHODS,
            payload: list,
          });
        }, 1500);
      } else {
        dispatch({ type: TYPES.SET_FAILURE_PAYMENT_METHODS });
      }
    } catch (error) {
      dispatch({ type: TYPES.SET_FAILURE_PAYMENT_METHODS });
    }
  };
};

export const setInitExtraction = (data) => ({
  type: TYPES.SET_INIT_EXTRACTION,
  payload: data,
});

export const setUpdatePayments = (data) => ({
  type: TYPES.SET_SUCCESS_PAYMENT_METHODS,
  payload: data,
});
