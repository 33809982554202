import { Fragment } from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { useSelector } from "react-redux";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import "./BottomNavItem.css";

export const BottomNavItem = ({ item, position, onClick }) => {
  const state = useSelector((state) => state.main);
  const { loadingSubmitContent } = state;

  return (
    <button className="bottom--nav--main--container--item" onClick={onClick}>
      {item.id == 2 && loadingSubmitContent == true ? (
        <MiniSpinner />
      ) : (
        <Fragment>
          <img
            className="bottom--nav--main--container--item--icon"
            src={item.icon}
            alt="..."
          />
          <DividerSpace space="XXS" />
          <div
            className={`bottom--nav--main--container--item--dot ${
              position === item.id ? "selected--dot" : ""
            }`}
          />
        </Fragment>
      )}
    </button>
  );
};
