import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { DefaultOption } from "../DefaultOption/DefaultOption";
import { PercentageOption } from "../PercentageOption/PercentageOption";
import { decreasePerc, increasePerc } from "../../redux/deals/Actions";
import { createStructuredSelector, createSelector } from "reselect";
import "./AgreementsSelectedPercentage.css";

export default connect(
  createStructuredSelector({
    propsDeal: createSelector(
      [({ deal }) => deal],
      ({
        percentages,
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
      }) => ({
        percentages,
        selectedAgreementHotmer,
        selectedAgreementHotmerOption,
      })
    ),
  }),
  (dispatch) => ({
    decreasePerc: (field) => dispatch(decreasePerc(field)),
    increasePerc: (field) => dispatch(increasePerc(field)),
  })
)(({ propsDeal, decreasePerc, increasePerc }) => {
  const {
    percentages,
    selectedAgreementHotmer,
    selectedAgreementHotmerOption,
  } = propsDeal;

  const decrease = (field) =>
    percentages[`${field}`] != 0 && decreasePerc(field);

  const increase = (field) => {
    percentages[`${field}`] <
      parseInt(process.env.REACT_APP_INITIAL_PERCENTAGE_MAX_AGENCY) &&
      increasePerc(field);
  };

  const session = localStorage.getItem("session");
  const { user } = JSON.parse(session);

  const positions = {
    textAlign: "center",
    padding: "0.5rem 1rem",
    fontSize: "12px",
  };

  return (
    <div className="agreements--selected--main--container">
      {selectedAgreementHotmerOption && (
        <>
          <DefaultOption
            data={selectedAgreementHotmerOption}
            hideOption={true}
          />
          {user.rol === "agency" && (
            <div className="agreements--selected--main--message">
              Coloque el porcentaje de Comisión que desea recibir en cada opción.
            </div>
          )}
        </>
      )}
      <div className="agreements--selected--main--container--bottom">
        {user.rol === "agency" && (
          <PercentageOption
            title={"Subscripciones"}
            decrease={() => decrease("subscriptions")}
            increase={() => increase("subscriptions")}
            value={percentages.subscriptions}
          />
        )}
        <PercentageOption
          title={"packs"}
          decrease={() => decrease("packs")}
          increase={() => increase("packs")}
          value={percentages.packs}
        />
        {user.rol === "agency" && (
          <PercentageOption
            title={"Regalos"}
            decrease={() => decrease("present")}
            increase={() => increase("present")}
            value={percentages.present}
          />
        )}
        <Typography style={positions}>
          {user.rol === "photographer" &&
            "Coloque el porcentaje de comision que el creador de contenido podra seleccionar al momento de crear un Pack"}
        </Typography>
      </div>
    </div>
  );
});
