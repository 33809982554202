import { useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

const AgreementsExclusive = (props) => {

  const { openInformation, closeInformation, data } = props

  const [open, setOpen] = useState(false)

  const onDismiss = () => {
    closeInformation(false)
  }

  useEffect(() => {
    setOpen(openInformation)
  }, [openInformation, setOpen])

  return (
    <>
      <Box id='shareAgrementExclusive' className="share-main">
        <BottomSheet
          open={open}
          onDismiss={onDismiss}
          blocking={true}
          header={
            <Typography
              className="title-container">
              Acuerdos Exclusivos
            </Typography>
          }
          footer={
            <Button onClick={onDismiss} className="w-full">
              cerrar
            </Button>
          }
        >
          <Box sx={{ padding: '15px' }}>
            <Box>
              <Typography variant="subtitle1">
                Actualmente tienes activo un <span className="dayLeft">acuerdo exclusivo con hotmy.</span>
                <Typography variant="subtitle1">
                  No se permiten realizar retiros diarios, el retiro se habilitara a mes vencido.
                </Typography>
              </Typography>
              <Typography variant="subtitle1">
                Tu proximo retiro es a partir del
                <span className="dayLeft"> {data?.dt_pay_cycle_exclusive_agreement ? data?.dt_pay_cycle_exclusive_agreement : 'proximo mes los primeros dias habiles'}
                </span>.
              </Typography>
              <Typography variant="subtitle1">
                Te recomendamos retirar el total del dinero acumulado, ya que se permite un retiro al mes.
              </Typography>

              <Typography variant="subtitle1">
                <a style={{ fontSize: '1rem' }}
                  href="https://www.hotmy.net/exclusive-agreement"
                  target="_blank"
                  rel="noreferrer"
                >Mas info Clickea Aqui</a>
              </Typography>
              {/* <Typography variant="subtitle1">
                Para mas info acerca de tu acuerdo exclusivo. Clickea en el siguiente <a href="https://www.hotmy.net/exclusive-agreement" target="_blank">enlace</a> o contactate con soporte.
              </Typography> */}
            </Box>
          </Box>
        </BottomSheet>
      </Box>
    </>
  )
}

export default AgreementsExclusive