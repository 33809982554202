import React from 'react';
import Skeleton from 'react-loading-skeleton';
// ARMADO DE SKELETO PRINCIPAL PARA EL HOME.
// ES UN ARRAY DE 4 QUE SE ARMA PARA ABAJO, CON 4 SE CUBRE BIEN TODO.  
const SkeletonCard = () => {
  return (
    <div style={{ width: '100%'}}>
      <ul className="list">
        {Array(2)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <div className="card-image">
              <Skeleton circle={true} height={50} width={50} />
              <span></span>
              <span></span>
              <Skeleton height={20} width={`20%`} />
            </div>
              <div className="card-image">
                <Skeleton height={300} width={`100%`} />
              </div>
              <h4 className="card-title">
                <Skeleton height={30} width={`60%`} />
              </h4>
              <div>
              <div>
                  <Skeleton height={10} width={`10%`} />
                  <Skeleton height={10} width={`10%`} />
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SkeletonCard;