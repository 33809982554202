import React from "react";
import "./customInputTextIconForModal.css";

export const CustomInputTextIconForModal = ({
  placeholder,
  name,
  value,
  onChange,
  type,
  image,
  onClick,
}) => {
  return (
    <div
      className="custom--input--text--icon--for--modal--container"
      onClick={onClick}
    >
      <img
        className="custom--input--text--icon--for--modal--container--image"
        src={image}
        alt="..."
      />
      <input
        className="custom--input--text--icon--for--modal--container--input"
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        // disabled
      />
    </div>
  );
};
