import { Toy } from "../Toy/Toy";
import "./PackSearchToys.css";

export const PackSearchToys = ({ pack }) => {
  const { tags } = pack;
  return (
    <div className="pack--search--toys">
      {tags.length === 0 ? (
        <div>Sin Clasificación</div>
      ) : (
        pack.tags.map((toy) => <Toy key={toy} toy={toy} />)
      )}
    </div>
  );
};
