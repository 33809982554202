/* eslint-disable */
import React, { useEffect, useState } from "react";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { ExtractionsFinish } from "../extractionsFinish/extractionsFinish";
import { getUserExtractions } from "../../redux/extractions/extractionsActions";
import { ExtractionsOperate } from "../extractionsOperate/extractionsOperate";
import { ExtractionsActivity } from "../extractionsActivity/extractionsActivity";
import { ExtractionsContinue } from "../extractionsContinue/extractionsContinue";
import { ExtractionsAvailable } from "../extractionsAvailable/extractionsAvailable";
import { useSelector, useDispatch } from "react-redux";
import { ExtractionsActivityFilter } from "../extractionsActivityFilter/extractionsActivityFilter";
import { initialChecks, initialFilters } from "../../libs/extractions";
import "./extractions.css";

export const Extractions = () => {
  const state = useSelector((state) => state.main);
  const { loadingUserExtractions, userExtractions } = state;
  const dispatch = useDispatch();

  const [f, setF] = useState(initialChecks);
  const [filtersParams, setFiltersParams] = useState(initialFilters);

  const [loadingInitialActivity, setLoadingInitialActivity] = useState(false);
  const [initialActivity, setInitialActivity] = useState(null);
  const [limitOffset, setLimiteOffset] = useState({ limit: 20, offset: 0 });
  /* ********************************************************************** */
  const [loadingListActivity, setLoadingListActivity] = useState(false);
  const [listActivity, setListActivity] = useState(null);
  // const [limitOffset, setLimiteOffset] = useState({ limit: 2, offset: 0 });
  /* ********************************************************************** */
  const [activeStep, setActiveStep] = useState(0);
  const handleStep = (index) => setActiveStep(index);

  const getUserInitialActivity = async () => {
    const id = getIdUser();
    const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/activity?limit=${limitOffset.limit}&offset=${limitOffset.offset}&bl_subscriptions=1&bl_packs=1&bl_tips=1&bl_extractions=1&bl_referrals=1`;
    console.log(url);
    try {
      setLoadingInitialActivity(true);
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      // console.log("RES ACTIVITY: ", res);
      if (res.status === 200) {
        const response = await res.json();
        console.log("RESPONSE ACTIVITY: ", response.data);
        setInitialActivity(response.data.activity);
        setLimiteOffset({ limit: 20, offset: 0 });
      } else if (res.status === 204) {
        setInitialActivity([]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingInitialActivity(false);
  };

  const getUserListActivity = async () => {
    const id = getIdUser();
    const base = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/account/activity`;
    const limit = `limit=${limitOffset.limit}`;
    const offset = `offset=${limitOffset.offset}`;
    const bl_subscriptions = `bl_subscriptions=1`;
    const bl_packs = `bl_packs=1`;
    const bl_tips = `bl_tips=1`;
    const bl_extractions = `bl_extractions=1`;
    const bl_referrals = `bl_referrals=1`;
    // const dates = `dt_since=null&dt_until=null`;
    const url = `${base}?${limit}&${offset}&${bl_subscriptions}&${bl_packs}&${bl_tips}&${bl_extractions}&${bl_referrals}`;
    console.log("URL: ", url);
    // setFiltersParams(initialFilters);
    // setF(initialChecks);
    // const id = getIdUser();
    // console.log(url);
    try {
      setLoadingListActivity(true);
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      // console.log("RES ACTIVITY: ", res);
      if (res.status === 200) {
        const response = await res.json();
        // console.log("RESPONSE ACTIVITY: ", response.data);
        setListActivity(response.data.activity);
        setLimiteOffset({ limit: 20, offset: response.data.next.offset }); ///response.data.next.offset este dato esta viniendo nulo.
      } else if (res.status === 204) {
        setListActivity([]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingListActivity(false);
  };

  useEffect(() => {
    getUserInitialActivity();
    dispatch(getUserExtractions());
  }, []); // eslint-disable-line

  if (loadingUserExtractions || loadingInitialActivity) {
    return (
      <LoadingScreen
        loading={loadingUserExtractions || loadingInitialActivity}
      />
    );
  } else if (!loadingUserExtractions && !userExtractions) {
    return (
      <WrongFetchScreen
        onClickRefresh={() => {
          getUserInitialActivity();
          dispatch(getUserExtractions());
        }}
      />
    );
  } else {
    return (
      <div className="extractions--main--container">
        {activeStep == 0 ? (
          <ExtractionsAvailable
            handleStep={handleStep}
            loadingInitialActivity={loadingInitialActivity}
            initialActivity={initialActivity}
            getUserInitialActivity={getUserInitialActivity}
            /* ****************************************** */
            getUserListActivity={getUserListActivity}
          />
        ) : activeStep == 1 ? (
          <ExtractionsOperate activeStep={activeStep} handleStep={handleStep} />
        ) : activeStep == 2 ? (
          <ExtractionsFinish activeStep={activeStep} handleStep={handleStep} />
        ) : activeStep == 3 ? (
          <ExtractionsContinue
            activeStep={activeStep}
            handleStep={handleStep}
          />
        ) : activeStep == 4 ? (
          <ExtractionsActivity
            activeStep={activeStep}
            handleStep={handleStep}
            f={f}
            setF={setF}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            loadingListActivity={loadingListActivity}
            setLoadingListActivity={setLoadingListActivity}
            listActivity={listActivity}
            setListActivity={setListActivity}
            limitOffset={limitOffset}
            setLimiteOffset={setLimiteOffset}
          />
        ) : (
          <ExtractionsActivityFilter
            f={f}
            setF={setF}
            handleStep={handleStep}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            limitOffset={limitOffset}
            setLimiteOffset={setLimiteOffset}
            loadingListActivity={loadingListActivity}
            setLoadingListActivity={setLoadingListActivity}
            listActivity={listActivity}
            setListActivity={setListActivity}
          />
        )}
      </div>
    );
  }
};
