import { useEffect, useState } from "react";
import { defineIcon } from "../../libs/search";
import { SearchTabs } from "../../components/SearchTabs/SearchTabs";
import { BottomSheet } from "react-spring-bottom-sheet";
import { HeaderSheet } from "../../components/HeaderSheet/HeaderSheet";
import { PacksOptions } from "../../components/PacksOptions/PacksOptions";
import { SearchFilters } from "../../components/SearchFilters/SearchFilters";
import { ProfileOptions } from "../../components/ProfileOptions/ProfileOptions";
import { useTranslation } from "react-i18next";
import { InputMainSearch } from "../../components/inputMainSearch/inputMainSearch";
import { PackGridContent } from "../../components/packGridContent/packGridContent";
import { ProfileGridContent } from "../../components/profileGridContent/profileGridContent";
import { useSelector, useDispatch } from "react-redux";
import * as A from "./Actions";
import "react-spring-bottom-sheet/dist/style.css";
import "./search.css";

export const SearchView = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.search);
  const {
    hotmersLimit,
    packsLimit,
    hotmersOffset,
    toys,
    packsOffset,
    search,
    profileOption,
    packOption,
  } = state;
  const { t } = useTranslation("global");

  const [tab, setTab] = useState(0);
  const [openBottomSearch, setOpenBottomSearch] = useState(false);

  const handleChange = (event, newTab) => setTab(newTab);
  const toggleBottomSearch = () => setOpenBottomSearch((x) => !x);

  const applyFilters = () => {
    setOpenBottomSearch(false);
    if (tab === 0) {
      dispatch(A.setInitialProfileRequest());
      const flag = profileOption !== "relevant";
      dispatch(A.getHotmers(search, profileOption, hotmersLimit, 0, flag));
    } else {
      const flag = packOption !== "recent";
      dispatch(A.setInitialPackRequest());
      dispatch(A.getPacks(search, toys, packOption, packsLimit, 0, flag));
    }
  };

  useEffect(() => {
    dispatch(A.setInputSearch(null));
  }, [tab]);

  useEffect(() => {
    dispatch(A.getToys());
    dispatch(
      A.getHotmers(search, profileOption, hotmersLimit, hotmersOffset, false)
    );
    dispatch(
      A.getPacks(search, toys, packOption, packsLimit, packsOffset, false)
    );
  }, []);

  return (
    <div className="search--main--container">
      <InputMainSearch
        tab={tab}
        toggleBottomSearch={toggleBottomSearch}
        applyFilters={applyFilters}
      />
      <SearchTabs
        tab={tab}
        handleChange={handleChange}
        defineIcon={defineIcon}
      />
      <div className="search--main--container--views">
        {tab === 0 && <ProfileGridContent tab={tab} />}
        {tab === 1 && <PackGridContent />}
      </div>
      <BottomSheet
        open={openBottomSearch}
        onDismiss={() => setOpenBottomSearch(false)}
        blocking={true}
        header={
          <HeaderSheet
            tab={tab}
            firstTitle={t("search.ProfileFilter.title")}
            secondTitle={t("search.PackFilter.title")}
            toggleSheet={toggleBottomSearch}
          />
        }
      >
        <SearchFilters applyFilters={applyFilters}>
          {tab === 0 ? <ProfileOptions /> : <PacksOptions />}
        </SearchFilters>
      </BottomSheet>
    </div>
  );
};
