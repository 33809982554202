import "./CircularButton.css";

export const CircularButton = ({ children, disabled, onClick }) => {
  return (
    <button
      className={`circular--counter--button ${
        disabled ? "disabled--circular--button" : ""
      }`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
