import React, { useState } from 'react';
import backgroundImg from "../../icons/background/background-upload.jpg";
import profileDefault from "../../icons/profile/profile-default-img.png";
import "./uploadContentHeader.css"

export const UploadContentHeader = () => {

    let user;
  try {
    user = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const [myStateProfile, setMyStateProfile] = useState({
    src: user.img_profile || profileDefault,
    errored: false,
  });

  const onErrorProfile = () => {
    if (!myStateProfile.errored) {
      setMyStateProfile({
        src: profileDefault,
        errored: true,
      });
    }
  };

    return (
        <div className="upload--content--header--main--container">
            <div className="upload--content--header--main--container--background">
            <img
                className="upload--content--header--main--container--background--image"
                src={backgroundImg}
                alt="..."
            />
            </div>
            <div className="upload--content--header--main--container--profile">
            <div className="upload--content--header--main--container--profile--image">
                <img alt="..." src={myStateProfile.src} onError={onErrorProfile} />
            </div>
            </div>
        </div>
    )
}
