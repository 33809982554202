/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import StyledDemo from "../../components/imageCrop/index";
import profileDefault from "../../icons/profile/profile-default-img.png";
import profileDefaultCover from "../../icons/profile/profile-default-cover-hotmer.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { defineTitleList } from "../../libs/account";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { AccordionAboutMe } from "../../components/accordionAboutMe/accordionAboutMe";
import { EditProfileHeader } from "../../components/editProfileHeader/editProfileHeader";
import { EditProfileOption } from "../../components/EditProfileOption/EditProfileOption";
import { AccordionFeatures } from "../../components/accordionFeatures/accordionFeatures";
import { MobileWheelSelector } from "../../components/mobileWheelSelector/mobileWheelSelector";
import { AccordionPreferences } from "../../components/accordionPreferences/accordionPreferences";
import { AccordionPersonalData } from "../../components/accordionPersonalData/accordionPersonalData";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { AccordionReferralCode } from "../../components/accordionReferralCode/accordionReferralCode";
import { AccordionSubscriptions } from "../../components/accordionSubscriptions/accordionSubscriptions";
import { useSelector, useDispatch } from "react-redux";
import { mapInitPreferences, updateOptionList } from "../../libs/preferences";
import { setDiscardModalEdit, setScreenPosition } from "../../redux/actions";
import {
  piercing,
  setFormDataHeaders,
  setHeaders,
  setNewJson,
  tatoo,
} from "../../libs/fetch";
import {
  mapInitSubscriptions,
  mapInitPersonalData,
  mapInitPersonalFeatures,
  subscriptionMessages,
  subscriptions,
  updateSubscriptions,
  createFeatureOptions,
  initialPersonalFeatures,
  initialPersonalData,
  initialAboutMe,
  handleImage,
  createFormDataUpdate,
  updateLsImage,
} from "../../libs/editProfile";
import "./editProfileHotmer.css";

export const EditProfileHotmer = () => {
  const state = useSelector((state) => state.main);
  const { discardModalEditRoute, submitEditProfile } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const minSubscriptionPrice = 1.5;

  let myUser;
  try {
    myUser = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const [initialTypeCrop, setInitialTypeCrop] = useState(null);
  /* *********************************** */
  const [modalCrop, setModalCrop] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  /* *********************************** */

  const [modalMessageResult, setModalMessageResult] = useState("");
  const [modalMessageButtonText, setModalMessageButtonText] = useState("");
  const [openModalMessageResult, setOpenModalMessageResult] = useState(false);
  const [modalIcon, setModalIcon] = useState(null);
  const [modalIncompleteData, setModalIncompleteData] = useState(false);

  const [modalMessageData, setModalMessageData] = useState("");

  const [id_subscription_price, set_id_subscription_price] = useState(null);
  const [tax, setTax] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeVideo, setSelectedTypeVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingPrefenrences, setLoadingPrefenrences] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [subExpanded, setSubExpanded] = useState("");
  const [userData, setUserData] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [modalOptions, setModalOptions] = useState([]);
  const [openModalOptions, setOpenModalOptions] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [featuresStats, setFeaturesStats] = useState(null);
  const [aboutMe, setAboutMe] = useState(initialAboutMe);
  const [personalData, setPersonalData] = useState(initialPersonalData);
  const [editPreferences, setEditPreferences] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [suscriptions, setSuscriptions] = useState([]);
  const [personalFeatures, setPersonalFeatures] = useState(
    initialPersonalFeatures
  );

  const [profile, setProfile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [cover, setCover] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [video, setVideo] = useState(null);

  const onErrorProfile = () => {
    if (!profile.errored) setProfile({ src: opsIcon, errored: true });
  };

  const onErrorCover = () => {
    if (!cover.errored) setCover({ src: opsIcon, errored: true });
  };

  const onChangeAbout = (e) => {
    const { name, value } = e.target;
    setAboutMe({ ...aboutMe, [name]: value });
  };

  const openModalOptionsFeatures = (param) => {
    setSelectedGroup(param);
    const options = featuresStats[`${param}`];
    const modalOptions = createFeatureOptions(options, param);
    setModalOptions(modalOptions);
    setOpenModalOptions((x) => !x);
  };

  const handleChooseOption = (option) => {
    let newPersonalFeatures = personalFeatures;
    const { name, value, id } = option;
    let subJson = newPersonalFeatures[`${name}`];
    subJson.new_id = id;
    subJson.description = value;
    newPersonalFeatures[`${name}`] = subJson;
    setPersonalFeatures(newPersonalFeatures);
    setOpenModalOptions((x) => !x);
  };

  const handleChangePersonalData = (e) =>
    setPersonalData({ ...personalData, [e.target.name]: e.target.value });

  const handleChangeCheck = (e) =>
    setEditPreferences(updateOptionList(e, editPreferences));

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  const handleSubChange = (panel) => (event, newExpanded) =>
    setSubExpanded(newExpanded ? panel : false);

  const handleChangeSubscription = (e) =>
    setSuscriptions(updateSubscriptions(e, suscriptions));

  const getGendersAndInit = async (list) => {
    if (!list) {
      // console.log("DATA: ", list);
      console.log("EL gender_preference DEL USUARIO RESPONDE NULL");
      return;
    } else {
      setLoadingPrefenrences(true);
      const url = `${process.env.REACT_APP_INITIAL_V2}/gender`;
      try {
        const res = await fetch(url);
        const response = await res.json();
        if (res.status === 200 && response.code === 200) {
          setEditPreferences(mapInitPreferences(list, response.data));
        }
        setLoadingPrefenrences(false);
      } catch (error) {
        console.log("CATCH ERROR: ", error);
        setLoadingPrefenrences(false);
      }
    }
  };

  const getStatsAndInit = async (stats) => {
    setLoadingStats(true);
    const url = `${process.env.REACT_APP_INITIAL_V2}/stats`;
    try {
      const res = await fetch(url, { headers: setHeaders() });
      const response = await res.json();
      if (response.code == 200) {
        const result = setNewJson(response, tatoo, piercing);
        setFeaturesStats(result);
        setPersonalFeatures(mapInitPersonalFeatures(stats));
      } else {
        setFeaturesStats(null);
      }
      setLoadingStats(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setLoadingStats(false);
    }
  };

  const getProfileData = async () => {
    setLoadingData(true);
    try {
      const idUser = JSON.parse(localStorage.getItem("session")).user.id;
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idUser}/configuration`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.code === 200) {
        //console.log("EDIT USER INFORMATION: ", response.data);
        setProfile({
          src: response.data.ds_url_img_profile || profileDefault,
          errored: false,
          changed: false,
        });
        setCover({
          src:
            response.data.ds_url_img_cover_art.ds_url_img_cover_art ||
            profileDefaultCover,
          errored: false,
          changed: false,
        });
        set_id_subscription_price(
          response.data.subscription_price.id_subscription_price
        );
        setSelectedType({ id: 0, description: "Fotos" });
        setSelectedTypeVideo({ id: 1, description: "Videos" });
        setUserData(response.data);
        setPersonalData(mapInitPersonalData(response.data.my_personal_data));
        setSuscriptions(
          mapInitSubscriptions(response.data.subscription_price, subscriptions)
        );
        setAboutMe({ about: response.data.about });
        setTax(response.data.subscription_price.vl_tax);
        getGendersAndInit(response.data.gender_preference);
        getStatsAndInit(response.data.stat.data);
        setReferralCode(response.data.referral.ds_referral_code);
        setLoadingData(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingData(false);
    }
  };

  const discardChanges = () => {
    setOpenModal((x) => !x);
    dispatch(setDiscardModalEdit(null));
    dispatch(setScreenPosition(discardModalEditRoute.url));
    localStorage.setItem("idUrl", discardModalEditRoute.url);
    history.push(discardModalEditRoute.url);
  };

  const cancelModal = () => {
    setOpenModal((x) => !x);
    dispatch(setDiscardModalEdit(null));
  };

  const checkSuscriptionsPrice = (elem) => {
    var price = parseFloat(elem.vl_price);
    return price < minSubscriptionPrice;
  };

  const processEditionUpdate = async (
    profile,
    profileFile,
    cover,
    coverFile,
    userData,
    aboutMe,
    editPreferences,
    personalData,
    personalFeatures,
    featuresStats,
    id_subscription_price,
    suscriptions
  ) => {
    const { result, idPreferences, emptySubs } = createFormDataUpdate(
      profile,
      profileFile,
      cover,
      coverFile,
      userData,
      aboutMe,
      editPreferences,
      personalData,
      personalFeatures,
      featuresStats,
      id_subscription_price,
      suscriptions
    );

    console.log("RESULT Edit: ", result);

    if (!result) {
      localStorage.setItem("idUrl", 0);
      dispatch(setScreenPosition(0));
      history.push("/");
    } else if (idPreferences.length == 0) {
      setModalMessageData("Debe seleccionar al menos una preferencia.");
      setModalIncompleteData(true);
    } else if (
      suscriptions &&
      suscriptions.find(checkSuscriptionsPrice) != undefined
    ) {
      setModalMessageData(
        "El precio minimo para la subscripcion es de " +
          minSubscriptionPrice +
          " USD."
      );
      setModalIncompleteData(true);
    } else if (personalData && personalData.ds_display_name.length == 0) {
      setModalIncompleteData(true);
      setModalMessageData("Debe completar el campo 'ALIAS'.");
    } else if (personalData && personalData.full_name.length == 0) {
      setModalMessageData("Debe completar el campo 'NOMBRE Y APELLIDO'.");
      setModalIncompleteData(true);
    } else {
      setLoadingData(true);
      try {
        const id = getIdUser();
        const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/profile`;
        const response = await axios.post(url, result, {
          headers: setFormDataHeaders(),
        });
        console.log("REPONSE: ", response);
        if (response.status === 201) {
          if (response.data.code == 201) {
            updateLsImage(response, null, personalData);
            localStorage.setItem("idUrl", 0);
            dispatch(setScreenPosition(0));
            history.push("/");
          } else {
            setModalIcon(opsIcon);
            setModalMessageResult("Error 104: Intente mas tarde por favor.");
            setModalMessageButtonText("ACEPTAR");
            setOpenModalMessageResult(true);
          }
        } else {
          console.log("OJALA SALGA POR ACA");
          setModalIcon(opsIcon);
          setModalMessageResult("Error 202: Intente mas tarde por favor.");
          setModalMessageButtonText("ACEPTAR");
          setOpenModalMessageResult(true);
        }
      } catch (error) {
        setModalIcon(opsIcon);
        setModalMessageResult("Error 300: Intente mas tarde por favor.");
        setModalMessageButtonText("ACEPTAR");
        setOpenModalMessageResult(true);
        console.log("ERROR: ", error);
      }
    }
    setLoadingData(false);
  };

  const processEditionUpdate2 = async (profile, profileFile) => {
    const { result } = createFormDataUpdate(
      profile,
      profileFile,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (!result) {
      localStorage.setItem("idUrl", 0);
      dispatch(setScreenPosition(0));
      history.push("/");
    } else {
      try {
        const id = getIdUser();
        const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/profile`;
        const response = await axios.post(url, result, {
          headers: setFormDataHeaders(),
        });
        console.log("REPONSE: ", response);
        if (response.status === 201) {
          if (response.data.code == 201) {
            updateLsImage(response, profile, null);
            localStorage.setItem("idUrl", 0);
            dispatch(setScreenPosition(0));
            history.push("/");
          } else {
            setModalIcon(opsIcon);
            setModalMessageResult("Error 104: Intente mas tarde por favor.");
            setModalMessageButtonText("ACEPTAR");
            setOpenModalMessageResult(true);
          }
        } else {
          setModalIcon(opsIcon);
          setModalMessageResult("Error 202: Intente mas tarde por favor.");
          setModalMessageButtonText("ACEPTAR");
          setOpenModalMessageResult(true);
        }
      } catch (error) {
        setModalIcon(opsIcon);
        setModalMessageResult("Error 300: Intente mas tarde por favor.");
        setModalMessageButtonText("ACEPTAR");
        setOpenModalMessageResult(true);
        console.log("ERROR: ", error);
      }
    }
  };

  const processEditionUpdate3 = async (cover, coverFile) => {
    const { result } = createFormDataUpdate(
      null,
      null,
      cover,
      coverFile,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (!result) {
      localStorage.setItem("idUrl", 0);
      dispatch(setScreenPosition(0));
      history.push("/");
    } else {
      try {
        const id = getIdUser();
        const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/profile`;
        const response = await axios.post(url, result, {
          headers: setFormDataHeaders(),
        });
        console.log("REPONSE: ", response);
        if (response.status === 201) {
          if (response.data.code == 201) {
            updateLsImage(response, null, null);
            localStorage.setItem("idUrl", 0);
            dispatch(setScreenPosition(0));
            history.push("/");
          } else {
            setModalIcon(opsIcon);
            setModalMessageResult("Error 104: Intente mas tarde por favor.");
            setModalMessageButtonText("ACEPTAR");
            setOpenModalMessageResult(true);
          }
        } else {
          setModalIcon(opsIcon);
          setModalMessageResult("Error 202: Intente mas tarde por favor.");
          setModalMessageButtonText("ACEPTAR");
          setOpenModalMessageResult(true);
        }
      } catch (error) {
        setModalIcon(opsIcon);
        setModalMessageResult("Error 300: Intente mas tarde por favor.");
        setModalMessageButtonText("ACEPTAR");
        setOpenModalMessageResult(true);
        console.log("ERROR: ", error);
      }
    }
  };

  const refreshGenders = () => getGendersAndInit(userData.gender_preference);
  const onClickRefreshStats = () => getStatsAndInit(userData.stat.data);
  const onClickRefresh = () => getProfileData();
  const acceptClick = () => setOpenModalMessageResult(false);

  const handleProfileImage = async (e) => {
    setInitialTypeCrop("profile");
    if (e.target.files && e.target.files.length > 0) {
      setModalCrop(true);
      const f = e.target.files[0];
      let imageDataUrl = await readFile(f);
      setNewFile(f);
      const prevF = URL.createObjectURL(f);
      setProfile((actual) => {
        return { ...profile, src: actual.src, changed: false };
      });
      setProfileFile(null);
      setImageSrc(imageDataUrl);
    }
  };

  const handleCoverImage = async (e) => {
    setInitialTypeCrop("cover");
    /* *********************************************************** */
    if (e.target.files && e.target.files.length > 0) {
      setModalCrop(true);
      const f = e.target.files[0];
      let imageDataUrl = await readFile(f);
      setNewFile(f);
      const prevF = URL.createObjectURL(f);
      setCover((actual) => {
        return { ...profile, src: actual.src, changed: false };
      });
      setCoverFile(null);
      setImageSrc(imageDataUrl);
    }
  };

  const setImageFromCrop = (f, cropImage) => {
    if (initialTypeCrop === "profile") {
      setProfile({ ...profile, src: cropImage, changed: true });
      setProfileFile(cropImage);
      processEditionUpdate2({ src: cropImage, changed: true }, cropImage);
    } else {
      setCover({ ...profile, src: cropImage, changed: true });
      setCoverFile(cropImage);
      processEditionUpdate3({ src: cropImage, changed: true }, cropImage);
    }
  };

  const closeCropImage = () => {
    if (initialTypeCrop === "profile") {
      setProfile((actualImage) => {
        return { ...profile, src: actualImage.src, changed: false };
      });
      setProfileFile(null);
    } else {
      setCover((actualImage) => {
        return { ...profile, src: actualImage.src, changed: false };
      });
      setCoverFile(null);
    }
    setModalCrop(false);
  };

  const confirmOption = () => {
    processEditionUpdate(
      null,
      null,
      null,
      null,
      userData,
      aboutMe,
      editPreferences,
      personalData,
      personalFeatures,
      featuresStats,
      id_subscription_price,
      suscriptions
    );
  };

  const onClickCancel = () => {
    dispatch(setScreenPosition(0));
    localStorage.setItem("idUrl", 0);
    history.push("/");
  };

  useEffect(() => {
    getProfileData();

    return () => {
      set_id_subscription_price(null);
      setSelectedType(null);
      setSelectedTypeVideo(null);
      setUserData(null);
      setSelectedGroup(null);
      setFeaturesStats(null);
      setProfile(null);
      setProfileFile(null);
      setCover(null);
      setCoverFile(null);
      setVideo(null);
      setEditPreferences(null);
    };
  }, []);

  if (loadingData) {
    return <LoadingScreen loading={loadingData} />;
  } else if (!userData) {
    return <WrongFetchScreen onClickRefresh={onClickRefresh} />;
  } else {
    return (
      <div className="edit--profile--main--container">
        <div className="edit--profile--visitor--main--container--cancel">
          <EditProfileOption onClick={onClickCancel}>
            <CloseIcon />
          </EditProfileOption>
        </div>
        <div className="edit--profile--visitor--main--container--accept">
          <EditProfileOption onClick={confirmOption}>
            <CheckIcon />
          </EditProfileOption>
        </div>
        <StyledDemo
          initialTypeCrop={initialTypeCrop}
          setImageFromCrop={setImageFromCrop}
          modalCrop={modalCrop}
          setModalCrop={setModalCrop}
          newFile={newFile}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          closeCropImage={closeCropImage}
        />
        <EditProfileHeader
          profile={profile}
          onErrorProfile={onErrorProfile}
          handleProfileImage={handleProfileImage}
          selectedType={selectedType}
          cover={cover}
          onErrorCover={onErrorCover}
          handleCoverImage={handleCoverImage}
        />
        <AccordionPreferences
          panel={"panel1"}
          expanded={expanded}
          handleChange={handleChange}
          loading={loadingPrefenrences}
          editPreferences={editPreferences}
          handleChangeCheck={handleChangeCheck}
          onClickRefreshGenders={refreshGenders}
        />
        <AccordionAboutMe
          panel={"panel2"}
          expanded={expanded}
          handleChange={handleChange}
          onChangeAbout={onChangeAbout}
          value={aboutMe.about}
        />
        <AccordionSubscriptions
          expanded={expanded}
          panel={"panel3"}
          handleChange={handleChange}
          suscriptions={suscriptions}
          subExpanded={subExpanded}
          tax={tax}
          handleSubChange={handleSubChange}
          handleChangeSubscription={handleChangeSubscription}
          subscriptionMessages={subscriptionMessages}
        />
        <AccordionPersonalData
          panel={"panel4"}
          expanded={expanded}
          handleChange={handleChange}
          personalData={personalData}
          handleChangePersonalData={handleChangePersonalData}
        />
        {/*<AccordionVideoPresentation
					panel={"panel5"}
					expanded={expanded}
					selectedType={selectedTypeVideo}
					handleChange={handleChange}
					handleChangeVideo={handleChangeVideo}
				/>*/}
        <AccordionReferralCode
          panel={"panel6"}
          expanded={expanded}
          handleChange={handleChange}
          referralCode={referralCode}
        />
        <AccordionFeatures
          expanded={expanded}
          panel={"panel7"}
          handleChange={handleChange}
          personalFeatures={personalFeatures}
          featuresStats={featuresStats}
          openModalOptionsFeatures={openModalOptionsFeatures}
          loading={loadingStats}
          onClickRefreshStats={onClickRefreshStats}
        />
        <CustomModal
          openModal={openModalOptions}
          setOpenModal={setOpenModalOptions}
        >
          <MobileWheelSelector
            title={defineTitleList(selectedGroup)}
            type="onlyText"
            data={modalOptions}
            idList={"FEATURES"}
            chooseElement={handleChooseOption}
          />
        </CustomModal>

        <CustomModal
          openModal={modalIncompleteData}
          setOpenModal={setModalIncompleteData}
          discardExternalEvents={false}
        >
          <MessageIncompleteData message={modalMessageData} />
        </CustomModal>

        {/* <CustomModal
					openModal={modalIncompleteData}
					setOpenModal={setModalIncompleteData}
					discardExternalEvents={false}
				>
					<MessageIncompleteData
						message={
							"Recuerde que es obligatorio completar todas las suscripciones y seleccionar al menos un género."
						}
					/>
				</CustomModal> */}

        <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
          <OptionToTrending
            title={"¿ Guardar los Cambios ?"}
            acceptClick={discardChanges}
            notAcceptclick={cancelModal}
          />
        </CustomModal>

        <CustomModal
          openModal={openModalMessageResult}
          setOpenModal={setOpenModalMessageResult}
          discardExternalEvents={true}
        >
          <OptionTryAgain
            icon={modalIcon}
            acceptClick={acceptClick}
            discardExternalEvents={true}
            title={modalMessageResult}
            buttonText={modalMessageButtonText}
            whatsApp={true}
          />
        </CustomModal>
      </div>
    );
  }
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
