import React, { useState, useEffect } from "react";
import profileError from "../../icons/profile/profile-error-img.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import profileDefaultCover from "../../icons/profile/profile-default-cover-hotmer.png";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { setHeaders } from "../../libs/fetch";
import { CustomTabs } from "../../components/customTabs/customTabs";
import { VideoContent } from "../../components/videoContent/videoContent";
import { MyProfileData } from "../../components/myProfileData/myProfileData";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { MultimediaHotmer } from "../../components/multimediaHotmer/multimediaHotmer";
import { CustomModalVideo } from "../../commonComponents/customModalVideo/customModalVideo";
import { getUserStatistics } from "../../redux/profile/profileActions";
import { setScreenPosition } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import "./myProfile.css";

export const MyProfile = ({ match }) => {
  const state = useSelector((state) => state.main);
  const { loadingUserStatistics, userStatistics } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  /* ********************************************* */
  let user;
  try {
    user = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }
  /* ********************************************* */
  const [myStateProfile, setMyStateProfile] = useState({
    src: user.img_profile === null ? profileDefault : user.img_profile,
    // src_cover_img : (user.profile.ds_url_img_cover_art === null || user.profile.ds_url_img_cover_art === "") ? profileDefaultCover : user.profile.ds_url_img_cover_art,
    errored: false,
  });

  // const [myStateCover, setMyStateProfileCover] = useState({
  //   src_cover_img : (user.profile.ds_url_img_cover_art === null || user.profile.ds_url_img_cover_art === "") ? profileDefaultCover : user.profile.ds_url_img_cover_art,
  //   errored: false,
  // });

  /* ********************************************* */
  const [hotmerId, setHotmerId] = useState(null);
  /* ********************************************* */
  const [openVideoModal, setOpenVideoModal] = useState(false);
  /* ********************************************* */
  const [loadingData, setLoadingData] = useState(false);
  const [cover, setCover] = useState(null);

  const [loadingHotmerProfile, setLoadingHotmerProfile] = useState(false);
  const [hotmerProfile, setHotmerProfile] = useState(null);

  /* ********************************************* */
  const [loadingImages, setLoadingImages] = useState(false);
  const [images, setImages] = useState(null);
  /* ********************************************* */
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [videos, setVideos] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  /* ********************************************* */
  const [loadingHotmerPacks, setLoadingHotmerPacks] = useState(false);
  const [hotmerPacks, setHotmerPacks] = useState(null);
  /* ********************************************* */
  const [tab, setTab] = useState(0);
  const handleChange = (event, newTab) => setTab(newTab);

  const onErrorCover = () => {
    if (!cover.errored) setCover({ src: profileDefaultCover, errored: true });
  };

  const getHotmerProfile = async () => {
    const id = JSON.parse(localStorage.getItem("session")).user.id;
    setLoadingHotmerProfile(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/profile/full`;

      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.code === 200) {
        setCover(response?.data?.img_cover_art);
        setHotmerProfile(response?.data);
      }
      setLoadingHotmerProfile(false);
    } catch (error) {
      //console.log("MURIO...");
      setLoadingHotmerProfile(false);
    }
  };

  /*const getProfileData = async () => {
    setLoadingData(true);
    try {
      const idUser = JSON.parse(localStorage.getItem("session")).user.id;
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idUser}/configuration`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.code === 200) {
        setCover({
          src:
            response.data.ds_url_img_cover_art.ds_url_img_cover_art ||
            profileDefaultCover,
          errored: false,
          changed: false,
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingData(false);
    }
  };*/

  const getHotmerPacks = async (idHotmer) => {
    const id = JSON.parse(localStorage.getItem("session")).user.id;
    setLoadingHotmerPacks(true);
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2
        }/hotmers/${id}/content/packs?limit=${10}&offset=${0}`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES PACK: ", res);
      // console.log("RESPONSE PACK: ", response.data);
      if (response.code === 200) {
        setHotmerPacks(response.data);
      }
      setLoadingHotmerPacks(false);
    } catch (error) {
      setLoadingHotmerPacks(false);
    }
  };

  const getHotmerImages = async () => {
    const id = JSON.parse(localStorage.getItem("session")).user.id;
    try {
      setLoadingImages(true);
      const url = `${process.env.REACT_APP_INITIAL_V2
        }/hotmers/${id}/content/image?limit=${10}&offset=${0}`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES IMAGE: ", res);
      // console.log("RESPONSE IMAGE: ", response.data);
      if (response.code === 200) {
        setImages(response.data);
      }
      setLoadingImages(false);
    } catch (error) {
      console.log(error);
      setLoadingImages(false);
    }
  };

  const getHotmerVideo = async () => {
    const id = JSON.parse(localStorage.getItem("session")).user.id;
    try {
      setLoadingVideos(true);
      const url = `${process.env.REACT_APP_INITIAL_V2
        }/hotmers/${id}/content/video?limit=${10}&offset=${0}`;
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      // console.log("RES VIDEO: ", res);
      // console.log("RESPONSE VIDEO: ", response.data);
      if (response.code === 200) {
        setVideos(response.data);
      }
      setLoadingVideos(false);
    } catch (error) {
      console.log(error);
      setLoadingVideos(false);
    }
  };

  const handleClick = (option) => {
    if (option === "edit") {
      const id = JSON.parse(localStorage.getItem("session")).user.id;
      history.push(`/editProfileHotmer/${id}`);
      dispatch(setScreenPosition(4));
      localStorage.setItem("idUrl", 4);
    } else {
      history.push(`/extractions`);
    }
  };

  const reproduceVideoContent = (content) => {
    setTimeout(() => setOpenVideoModal((x) => !x), 50);
    setSelectedVideo(content);
  };

  const handleCloseView = () => {
    reproduceVideoContent(null);
  };

  const onErrorProfile = () => {
    if (!myStateProfile.errored) {
      setMyStateProfile({
        src: profileError,
        errored: true,
      });
    }
  };

  // const onErrorCover = () => {
  //   if (!myStateCover.errored) {
  //     setMyStateProfileCover({
  //       src_cover_img: profileDefaultCover,
  //       errored: true,
  //     });
  //   }
  // };

  const onClickRefreshStatistics = () => dispatch(getUserStatistics());
  const onClickRefreshImages = () => getHotmerImages();
  const onClickRefreshVideos = () => getHotmerVideo();
  const onClickRefreshPacks = () => getHotmerPacks();

  useEffect(() => {
    const params = JSON.stringify(match.params);
    // console.log("PARAMS: ", params);
    if (params !== "{}") {
      history.push("/myProfile");
    } else {
      setLoadingHotmerProfile(true);
      const id = getIdUser();
      setHotmerId(id);
      getHotmerProfile(match.params.id_hotmer);
      dispatch(getUserStatistics());
      getHotmerImages();
      getHotmerVideo();
      getHotmerPacks();
    }
    return () => {
      setHotmerId(null);
      setHotmerProfile(null);
      setImages(null);
      setVideos(null);
      setSelectedVideo(null);
      setHotmerPacks(null);
      dispatch({ type: "SET_FAILURE_USER_STATISTICS" });
    };
  }, []); // eslint-disable-line

  if (
    loadingImages &&
    loadingVideos &&
    loadingHotmerPacks &&
    loadingData &&
    loadingUserStatistics &&
    loadingHotmerProfile

  ) {
    return (
      <LoadingScreen
        loading={
          loadingImages &&
          loadingVideos &&
          loadingHotmerPacks &&
          loadingData &&
          loadingUserStatistics &&
          loadingHotmerProfile
        }
      />
    );
  } else {
    return (
      <div className="my--profile--main--container">
        <MyProfileData
          hotmerProfile={hotmerProfile}
          loadingUserStatistics={loadingUserStatistics}
          userStatistics={userStatistics}
          myStateProfile={myStateProfile}
          //myStateCover={myStateCover}
          onErrorProfile={onErrorProfile}
          onErrorCover={onErrorCover}
          handleClick={handleClick}
          onClickRefreshStatistics={onClickRefreshStatistics}
          cover={cover}
        />
        <div className="hotmer--profile--main--container--tabs">
          <CustomTabs tab={tab} handleChange={handleChange} />
        </div>
        <MultimediaHotmer
          tab={tab}
          handleChange={handleChange}
          loadingImages={loadingImages}
          images={images}
          loadingVideos={loadingVideos}
          videos={videos}
          loadingPacks={loadingHotmerPacks}
          packs={hotmerPacks}
          reproduceVideoContent={reproduceVideoContent}
          id_hotmer={hotmerId}
          owner={0} // Owner = 0 es el propio usuario.
          onClickRefreshImages={onClickRefreshImages}
          onClickRefreshVideos={onClickRefreshVideos}
          onClickRefreshPacks={onClickRefreshPacks}
        />
        <CustomModalVideo
          openModalVideo={openVideoModal}
          setOpenModalVideo={setOpenVideoModal}
          handleCloseView={handleCloseView}
        >
          <VideoContent selectedVideo={selectedVideo} />
        </CustomModalVideo>
      </div>
    );
  }
};
