/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Cropper from "react-easy-crop";
import Typography from "@material-ui/core/Typography";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { getCroppedImg } from "./canvasUtils";
import { CustomModal } from "../../commonComponents/customModal/customModal";
// import Dialog from "@material-ui/core/Dialog";
// import ImgDialog from "./ImgDialog";
// import { getOrientation } from "get-orientation/browser";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	flex: {
		flex: 1,
	},
	imgContainer: {
		position: "relative",
		flex: 1,
		padding: 16,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		maxWidth: "100%",
		maxHeight: "100%",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	controls: {
		paddingBottom: "30px",
		width: "100%",
		padding: "0 30px",
		textAlign: "center",
	},
	cropContainer: {
		position: "relative",
		width: "100%",
		height: 250,
		background: "#333",
		[theme.breakpoints.up("sm")]: {
			height: 400,
		},
	},
	mainCropContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		height: "100%",
		width: "100%",
		position: "relative",
		background: "#ffffff",
	},
	btnAccept: {
		position: "absolute",
		bottom: "20px",
		left: 0,
		width: "100%",
		textAlign: "center",
	},
	sliderContainer: {
		width: "100%",
		padding: "0 30px",
	},
	cropButton: {
		width: "100%",
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slider direction="up" ref={ref} {...props} />;
});

const Demo = ({
	initialTypeCrop,
	// classes,
	setImageFromCrop,
	modalCrop,
	setModalCrop,
	newFile,
	imageSrc,
	setImageSrc,
	closeCropImage,
}) => {
	const classes = useStyles();

	const [cropShape, setCropShape] = useState("round");
	const [aspect, setAspect] = useState(1);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
			setImageFromCrop(newFile, croppedImage);
			setImageSrc(null);
			setZoom(1);
			setCrop({ x: 0, y: 0 });
			setModalCrop(false);
		} catch (e) {
			console.error(e);
		}
	}, [imageSrc, croppedAreaPixels]);

	const onClose = useCallback(() => {
		setCroppedImage(null);
	}, []);

	useEffect(() => {
		if (initialTypeCrop) {
			if (initialTypeCrop === "profile") {
				setCropShape("round");
				setAspect(1);
			} else {
				setCropShape("square");
				setAspect(4 / 3);
			}
		}
	}, [initialTypeCrop]);

	return (
		<div>
			{newFile && (
				<CustomModal
					openModal={modalCrop}
					setOpenModal={setModalCrop}
					discardExternalEvents={true}
					fullScreen={true}
				>
					<div className={classes.mainCropContainer}>
						{/* ************************************ */}
						<div className={classes.cropContainer}>
							<Cropper
								image={imageSrc}
								crop={crop}
								zoom={zoom}
								cropShape={cropShape}
								aspect={aspect}
								//cropSize={{ width: 295, height: 160 }}
								onCropChange={setCrop}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
							/>
						</div>
						<div className={classes.sliderContainer}>
							<Typography
								variant="overline"
								classes={{ root: classes.sliderLabel }}
							>
								Zoom
							</Typography>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								classes={{ root: classes.slider }}
								onChange={(e, zoom) => setZoom(zoom)}
							/>
						</div>
						{/* ************************************ */}
						<div className={classes.controls}>
							<Button
								onClick={showCroppedImage}
								variant="contained"
								color="Default"
								classes={{ root: classes.cropButton }}
							>
								Confirmar Resultado
							</Button>
						</div>
						<div className={classes.controls}>
							<Button
								onClick={() => {
									setZoom(1);
									setCrop({ x: 0, y: 0 });
									closeCropImage();
								}}
								variant="outlined"
								color="Default"
								classes={{ root: classes.cropButton }}
							>
								Cancelar
							</Button>
						</div>
					</div>
				</CustomModal>
			)}
		</div>
	);
};

const StyledDemo = withStyles(styles)(Demo);

export default StyledDemo;
