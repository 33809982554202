import { useState, useEffect } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import Divider from "@material-ui/core/Divider";
import imageProfile from "../../icons/fueguito-card-1.png";
import { makeStyles } from "@material-ui/core/styles";
import "./ActiveTrade.css";

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 28,
    margin: 4,
  },
}));

export const ActiveTrade = ({ trade, onClick }) => {
  const [profile, setProfile] = useState({
    src: trade?.ds_profile_photo_thumbnail_path,
    errored: false,
  });

  const classes = useStyles();

  const onErrorProfile = () => {
    if (!profile.errored) {
      setProfile({
        src: opsIcon,
        errored: true,
      });
    }
  };

  const imageSize = {
    height: "34px",
    width: "34px",
  };

  useEffect(() => {
    setProfile({
      src: trade?.ds_profile_photo_thumbnail_path || imageProfile,
      errored: false,
    });
  }, [trade]); // eslint-disable-line

  return (
    <div className="active--trade--container" onClick={onClick}>
      <img
        className="active--trade--container--image"
        onError={onErrorProfile}
        src={profile.src}
        alt="..."
        style={imageSize}
      />
      <div>
        <div className="active--trade--container--name">
          <strong> {trade ? trade.ds_full_name : "Sin Acuerdo Comercial"}</strong>
        </div>
        <div className="active--trade--container--rol">
          {trade ? trade.ds_type_of_role : ""}
        </div>
      </div>
      <div className="active--trade--container--percent">
        <Divider className={classes.divider} orientation="vertical" />
        <div>{trade ? trade.vl_rate : "0"} %</div>
      </div>
    </div>
  );
};

// <div>{`${trade ? trade.vl_rate "%": "-1"} ` }</div>
