import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./loadingComponent.css";

export const LoadingComponent = () => {
  return (
    <div className="loading--component--main--container">
      <CircularProgress color="inherit" />
    </div>
  );
};
