import { hmyApiWithToken } from "../api/hmyApiWithToken"; //siempre usamos esta hmyapi pasa simplificar las cabeceras. 
import { useQuery } from '@tanstack/react-query'
import { PerformanceCreator } from '../interfaces/PerformanceCreator';

const getPerformance = async ({ queryKey }): Promise<PerformanceCreator> => {
    const [_, idCreator] = queryKey
    const myToken = JSON.parse(localStorage.getItem("session"))?.token;
    const { data } = await hmyApiWithToken(myToken).get(`/hotmers/${idCreator}/performanceCreator`)
    return data;
    // "SELECT * FROM public.sp_hotmer_performance($id_hotmer)";
}

const usePerformanceCreator = (isCreator? : boolean, idCreator?: number) => {
    const performanceOfCreator = useQuery(
        ['getPerformanceCreator', idCreator, isCreator], getPerformance,
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: !! isCreator
        }
    )
    return performanceOfCreator;
}

export default usePerformanceCreator