/* eslint-disable */
import { firebase } from "../firebase/firebase-config";
import { getUserDevice } from "../functions/login";

export const setInitialOption = (x) => {
	return {
		...x,
		checked: false,
	};
};

export const mapInitialStates = (list) => {
	const newList = list.map((x) => setInitialOption(x));
	return newList;
};

export const updateOption = (event, option) => {
	if (option.gender === event.target.name) {
		return {
			...option,
			checked: !option.checked,
		};
	} else {
		return option;
	}
};

export const updateOptionList = (event, list) => {
	return list.map((element) => updateOption(event, element));
};

export const mapInitPreferences = (response, preferences) => {
	const newList = preferences.map((element) => {
		if (response.includes(element.id_type_of_gender)) {
			return {
				...element,
				checked: true,
			};
		} else {
			return {
				...element,
				checked: false,
			};
		}
	});
	return newList;
};

export const mapInitPreferencesNoHotmer = (genders, preferences) => {
	const newList = preferences.map((element) => {
		if (genders.includes(element.id_type_of_gender)) {
			return {
				...element,
				checked: true,
			};
		} else {
			return {
				...element,
				checked: false,
			};
		}
	});
	return newList;
};

export const getUserPreferences = () =>
	JSON.parse(localStorage.getItem("session")).user.preference || [1, 2];

export const incompleteMessage =
	"Por favor, asegúrese de seleccionar al menos un género para ofrecerle una buena experiencia de usuario.";

export const selectedOptions = (options) => {
	const selected = options.filter((x) => x.checked);
	const ids = selected.map((x) => x.id_type_of_gender);
	return ids;
};

export const createBodyJson = (list) => {
	return JSON.stringify({
		id_type_of_genders: list,
	});
};

export const createBodyClon = (miniData, list, loginFirebaseUser) => {
	const json = {
		birthDay: miniData.birthDay,
		id_country: miniData.nationality.id_country,
		id_type_of_genders: list,
		name: loginFirebaseUser.name,
		firebase_token: loginFirebaseUser.firebase_token,
		device: loginFirebaseUser.device,
		is_new_user: loginFirebaseUser.is_new_user,
		codRef : loginFirebaseUser.codRef,
	};
	console.log("JSON POST: ", json);
	return JSON.stringify(json);
};

export const creationBody = (jsonData, list) => {
	const json = {
		birthDay: jsonData.birthDay,
		name: jsonData.name,
		firebase_token: jsonData.firebase_token,
		device: jsonData.device,
		id_country: jsonData.id_country,
		id_type_of_genders: list,
		is_new_user: true,
		codRef : jsonData.codRef,
	};
	return JSON.stringify(json);
};

export const updateUserPreferences = (choosedOptions) => {
	const newPreferences = choosedOptions.map((x) => x.id_type_of_gender);
	const session = JSON.parse(localStorage.getItem("session"));
	let user = session.user;
	user = {
		...user,
		preference: newPreferences,
	};
	session.user = user;
	localStorage.setItem("session", JSON.stringify(session));
};

export const createWithFirebase = (userForm) => {
	console.log("USER FORM: ", userForm);
	firebase
		.auth()
		.createUserWithEmailAndPassword(userForm.email, userForm.password)
		.then(async (response) => {
			console.log("FIREBASE RESPONSE: ", response);
			await response.user.updateProfile({ displayName: userForm.name });
			const firebase_token = response.user.b.b.g;
			console.log("FIREBASE TOKEN: ", firebase_token);
			return {
				ok: true,
				name: userForm.name,
				firebase_token: firebase_token,
				device: getUserDevice(),
				birthDay: userForm.birthDay,
				nationality: userForm.nationality.ds_diminutive,
				id_country: userForm.nationality.id_country,
				codRef : userForm.codRef,
			};
		})
		.catch((e) => {
			console.log("FIREBASE PROBLEM: ", e);
			return {
				ok: false,
				message: e.message,
			};
		});
};

export const gendersOptionsData = [
	{ gender: "Femenino", id_type_of_gender: 1 },
	{ gender: "Masculino", id_type_of_gender: 2 },
	{ gender: "Otros", id_type_of_gender: 3 },
];
