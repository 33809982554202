import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./partialSpinner.css";

export const PartialSpinner = () => {
  return (
    <div className="partial--spinner--container">
      <CircularProgress fontSize="small" color="inherit" />
    </div>
  );
};
