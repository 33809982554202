import React from "react";
import "./uploadEditOption.css";

export const UploadEditOption = ({ title, icon }) => {
  return (
    <div className="upload--edit--option--container">
      {icon && (
        <div className="upload--edit--option--container--icon">
          <img src={icon} alt="..." />
        </div>
      )}
      <div className="upload--edit--option--container--title">{title}</div>
    </div>
  );
};
