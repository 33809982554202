const setInitialToy = (toy) => {
  return {
    ...toy,
    selected: false,
  };
};

export const initialState = { name: "" };

export const setInitialToys = (arrayToys) =>
  arrayToys.map((toy) => setInitialToy(toy));

const updateToyState = (toy, myToy) => {
  if (toy.id_tag === myToy.id_tag) {
    return {
      ...toy,
      selected: !toy.selected,
    };
  }
  return toy;
};

export const updateToysState = (arrayToys, myToy) =>
  arrayToys.map((t) => updateToyState(t, myToy));

export const anySelected = (list) => list.every((t) => !t.selected);

export const defineIcon = (t, value, degIcon, normalIcon) => {
  if (t === value) {
    return degIcon;
  } else {
    return normalIcon;
  }
};

const containtsTo = (x, name) =>
  x.ds_display_name.toLowerCase().includes(name.toLowerCase());

export const getFilteredHotmers = (list, search) => {
  const newList = list.filter((x) => containtsTo(x, search.data));
  return newList;
};

export const getCountSelectedToys = (newToyList) => {
  return newToyList.filter((x) => x.selected === true).length;
};

export const getFilteredInputHotmers = (searchHotmers, search) => {
  if (search.name === "") {
    return searchHotmers;
  } else {
    return searchHotmers.filter((x) =>
      x.ds_display_name.toLowerCase().includes(search.name.toLowerCase())
    );
  }
};

// const toyBelongsToSelectedToys = (t, list) =>
//   list
//     .filter((x) => x.selected)
//     .map((element) => element.id_tag)
//     .includes(t);

const toysSelectionIncludePack = (list, pack) => {
  const selectedIds = list.filter((x) => x.selected).map((x) => x.id_tag);
  return selectedIds.every((sid) => pack.tags.includes(sid));
};

const packIncludesBySelectedToysAndInput = (pack, name, list) => {
  // pack.tags.some((t) => toyBelongsToSelectedToys(t, list))
  const result =
    toysSelectionIncludePack(list, pack) &&
    (pack.ds_title.toLowerCase().includes(name.toLowerCase()) ||
      pack.ds_display_name.toLowerCase().includes(name.toLowerCase()));
  return result;
};

export const packIncludesBySelectedToys = (pack, list) => {
  // pack.tags.some((t) => toyBelongsToSelectedToys(t, list));
  return toysSelectionIncludePack(list, pack);
};

const filteredInput = (pack, name) => {
  return (
    pack.ds_title.toLowerCase().includes(name.toLowerCase()) ||
    pack.ds_display_name.toLowerCase().includes(name.toLowerCase())
  );
};

export const getFilteredPacks = (packList, search, toyList) => {
  const countSelected = getCountSelectedToys(toyList);
  if (countSelected === 0) {
    if (search.data === "") {
      return packList;
    } else {
      return packList.filter((pack) => filteredInput(pack, search.data));
    }
  } else {
    if (search.data === "") {
      return packList.filter((pack) =>
        packIncludesBySelectedToys(pack, toyList)
      );
    } else {
      return packList.filter((pack) =>
        packIncludesBySelectedToysAndInput(pack, search.data, toyList)
      );
    }
  }
};

export const successAddMessage = "El pack ha sido agregado exitosamente.";

export const failureAddMessage =
  "La acción no se realizó correctamente. Pruebe de nuevo o bien puede intentar mas tarde.";
