import { TYPES } from "./Types";
import { setHeaders } from "../../libs/fetch";

export const getSubscribedHotmers = (idUser) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/content/subscribed`;
    dispatch({ type: TYPES.REQUEST_LOADING_SUBSCRIBED_HOTMERS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_SUBSCRIBED_HOTMERS,
          payload: response.data,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_SUBSCRIBED_HOTMERS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_SUBSCRIBED_HOTMERS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_SUBSCRIBED_HOTMERS });
    }
  };
};

export const getWishHotmers = (idUser) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/follow/hotmer`;
    dispatch({ type: TYPES.REQUEST_LOADING_WISH_HOTMERS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_WISH_HOTMERS,
          payload: response.data,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_WISH_HOTMERS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_WISH_HOTMERS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_WISH_HOTMERS });
    }
  };
};

export const getSubscribedPacks = (idUser) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/content/pack`;
    dispatch({ type: TYPES.REQUEST_LOADING_SUBSCRIBED_PACKS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_SUBSCRIBED_PACKS,
          payload: response.data,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_SUBSCRIBED_PACKS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_SUBSCRIBED_PACKS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_SUBSCRIBED_PACKS });
    }
  };
};

export const getWishPacks = (idUser) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/follow/pack`;
    dispatch({ type: TYPES.REQUEST_LOADING_WISH_PACKS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_WISH_PACKS,
          payload: response.data,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.REQUEST_SUCCESS_WISH_PACKS,
          payload: [],
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_WISH_PACKS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: TYPES.REQUEST_FAILURE_WISH_PACKS });
    }
  };
};

export const deletePack = async (id_hotmer, id_pack) => {
  try {
    const params = `hotmers/${id_hotmer}/pack/${id_pack}/follow`;
    const url = `${process.env.REACT_APP_INITIAL_V2}/${params}`;
    const res = await fetch(url, {
      headers: setHeaders(),
      method: "POST",
    });
    const response = await res.json();
    if (response.code === 200) {
      return {
        success: true,
        title: "Eliminación exitosa.",
      };
    } else {
      return {
        success: false,
        title: "Error. Intente mas tarde por favor..",
      };
    }
  } catch (error) {
    return {
      success: false,
      title: "Error. Intente mas tarde por favor..",
    };
  }
};
