import { TYPES } from "./Types";
import { setHeaders } from "../../libs/fetch";
import { setInitialToys } from "../../libs/search";
import { getIdUser } from "../../libs/login";

export const getHotmers = (
  search,
  profileOption,
  limit,
  offset,
  flagChange
) => {
  return async (dispatch) => {
    const idUser = getIdUser();
    const { data } = search;
    const params = `filter_text=${data}&filter_by=${profileOption}&limit=${limit}&offset=${offset}`;
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/search/hotmer?${params}`;
    dispatch({ type: TYPES.REQUEST_LOADING_HOTMERS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_HOTMERS,
          payload: response.data,
          flagChange,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.STOP_HOTMERS_FETCH,
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_HOTMERS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_HOTMERS });
    }
  };
};

export const getToys = () => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_INITIAL_V2}/packs/tags`;
    dispatch({ type: TYPES.REQUEST_LOADING_TOYS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      const response = await res.json();
      if (response.code === 200) {
        const packToysList = setInitialToys(response.data);
        setTimeout(() => {
          dispatch({
            type: TYPES.REQUEST_SUCCESS_TOYS,
            payload: packToysList,
          });
        }, 5000);
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_TOYS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_TOYS });
    }
  };
};

export const setInputSearch = (e) => {
  if (e) {
    return {
      type: TYPES.SET_INPUT_SEARCH,
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    };
  } else {
    return {
      type: TYPES.SET_INPUT_SEARCH,
      payload: {
        name: "data",
        value: "",
      },
    };
  }
};

export const getPacks = (
  search,
  toys,
  packOption,
  limit,
  offset,
  flagChange
) => {
  return async (dispatch) => {
    const { data } = search;
    let ids = "";
    if (toys) {
      ids = toys.filter((x) => x.selected === true).map((x) => x.id_tag);
    } else {
      ids = [];
    }
    const stringIds = JSON.stringify(ids);
    const idTags = stringIds.substring(1, stringIds.length - 1);
    const idUser = getIdUser();
    const params = `id_tags=${idTags}&filter_by=${packOption}&filter_text=${data}&limit=${limit}&offset=${offset}`;
    const url = `${process.env.REACT_APP_INITIAL_V2}/users/${idUser}/search/pack?${params}`;
    dispatch({ type: TYPES.REQUEST_LOADING_PACKS });
    try {
      const res = await fetch(url, {
        headers: setHeaders(),
      });
      if (res.status === 200) {
        const response = await res.json();
        dispatch({
          type: TYPES.REQUEST_SUCCESS_PACKS,
          payload: response.data,
          flagChange,
        });
      } else if (res.status === 204) {
        dispatch({
          type: TYPES.STOP_PACKS_FETCH,
        });
      } else {
        dispatch({ type: TYPES.REQUEST_FAILURE_PACKS });
      }
    } catch (error) {
      dispatch({ type: TYPES.REQUEST_FAILURE_PACKS });
    }
  };
};

export const setInitialProfileRequest = () => ({
  type: TYPES.SET_INITIAL_PROFILE_REQUEST,
});

export const setInitialPackRequest = () => ({
  type: TYPES.SET_INITIAL_PACK_REQUEST,
});
export const setProfileOption = (value) => ({
  type: TYPES.SET_PROFILE_OPTION,
  payload: value,
});

export const setPackOption = (value) => ({
  type: TYPES.SET_PACK_OPTION,
  payload: value,
});
