/* eslint-disable */
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import profileDefault from "../../icons/profile/profile-default-img.png";
import HotmerHomeHeader from "../../components/hotmerHomeHeader/hotmerHomeHeader";
import HotmerHomeFooter from "../../components/hotmerHomeFooter/hotmerHomeFooter";
import { getIdUser } from "../../libs/login";
import { setHeaders } from "../../libs/fetch";
import { VideoContent } from "../../components/videoContent/videoContent";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { CustomModalVideo } from "../../commonComponents/customModalVideo/customModalVideo";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { HotmerContentFiles } from "../../components/hotmerContentFiles/hotmerContentFiles";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
	generateFooter,
	getDomElement,
	mapInitHotmer,
} from "../../libs/hotmerContent";
import "./hotmerContent.css";
// import useMediaContent from "../../hooks/useMediaContent";
// import useMediaContentInfinite from "../../hooks/useMediaContentInfinite";

export const HotmerContent = ({ match }) => {
	// HABILITAR CUANDO HAGA EL PAGINADO. 
	// const { mediaContent, page, nextPage } = useMediaContent(match?.params?.id_hotmer)
	// const { mediaContentInfinite, page, nextPage, prevPage } = useMediaContentInfinite({ creator: match?.params?.id_hotmer })
	// console.log("mediaContentInfinite", mediaContentInfinite)
	// console.log("data", mediaContent.data.data.hotmer)
	// console.log("page", page)
	const [loadedCompleted, setLoadedCompleted] = useState(false);
	const [openModalSelection, setOpenModalSelection] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [loadingRemoveAccount, setLoadingRemoveAccount] = useState(false);
	const [openModalRemovePost, setOpenModalRemovePost] = useState(false);
	const [selectedPostId, setSelectedPostId] = useState(null);
	const [loadingData, setLoadingData] = useState(false);
	const [profile, setProfile] = useState(null);
	const [hotmer, setHotmer] = useState(null);
	const [postFiles, setPostFiles] = useState(null);

	const onErrorProfile = () => {
		if (!profile.errored) {
			setProfile({
				src: opsIcon,
				errored: true,
			});
		}
	};

	const clickComments = () => console.log("clickComments");

	const clickAdd = async () => {
		// const id = hotmer.id_user_hotmer;
		// try {
		//   const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/follow`;
		//   const res = await fetch(url, {
		//     headers: setHeaders(),
		//     method: "POST",
		//   });
		//   console.log("AGREGAR RES: ", res);
		//   const response = await res.json();
		//   if (response.code === 200) {
		//     console.log("OK CLICK FOLLOW HOTMER");
		//     console.log("AGREGAR RESPONSE: ", response);
		//     setHotmer({ ...hotmer, bl_follow_hotmer: !hotmer.bl_follow_hotmer });
		//   } else {
		//     console.log("FAIL CLICK FOLLOW HOTMER");
		//   }
		// } catch (error) {
		//   console.log("FAIL CLICK FOLLOW HOTMER");
		//   console.log(error);
		// }
	};

	const confirmRemovePost = async () => {
		setLoadingRemoveAccount(true);
		const idContent = selectedPostId;
		const id = getIdUser();
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/content/${idContent}`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "DELETE",
			});
			console.log("REMOVE RES: ", res);
			// const response = await res.json();
			if (res.status === 200) {
				const newPostFiles = postFiles.filter(
					(x) => x.id_content_group !== idContent,
				);
				setPostFiles(newPostFiles);
			} else {
				// console.log("FAIL DELETE POST");
				setOpenModal(true);
				console.log("Error al eliminar el post. Intente mas tarde, si el error persiste comuniquese con support@hotmy.net");
			}
		} catch (error) {
			console.log("FAIL DELETE POST");
			setOpenModal(true);
			console.log(error);
		}
		setSelectedPostId(null);
		setLoadingRemoveAccount(false);
	};

	const deletePostHotmer = (id) => {
		setSelectedPostId(id);
		setOpenModalRemovePost(true);
	};

	const applyOption = () => {
		confirmRemovePost();
		setOpenModalRemovePost(false);
	};

	const cancelOption = () => {
		setOpenModalRemovePost(false);
		setSelectedPostId(null);
	};

	const setLikedButton = async (id_content_group) => {
		const idHotmer = match.params.id_hotmer;
		try {
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/content/like`;
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "POST",
				body: JSON.stringify({ id_content_group: id_content_group }),
			});
			const response = await res.json();
			console.log(res);
			console.log(response);
			if (response.code === 201) {
				const index = postFiles.findIndex(
					(post) => post.id_content_group == id_content_group,
				);
				console.log(index);
				let newPostFiles = postFiles;
				let total_likes = newPostFiles[index].total_likes;
				console.log(postFiles[index].total_likes);
				let booleanLike = newPostFiles[index].bl_liked;
				if (booleanLike) {
					total_likes = total_likes - 1;
				} else {
					total_likes = total_likes + 1;
				}
				newPostFiles[index].total_likes = total_likes;
				newPostFiles[index].bl_liked = !booleanLike;
				setPostFiles(newPostFiles);
				setHotmer({ ...hotmer, bl_liked: !hotmer.bl_liked });
			} else {
				console.log("RESPONSE NOT ACCEPTED");
			}
		} catch (error) {
			console.log("FAIL CLICK LIKE TO HOTMER POST");
		}
	};


	// trae la info principal del contenido con el query param del Id del creador.
	const getHotmerContent = async (idHotmer) => {
		setLoadingData(true);
		const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/content/home`;
		try {
			const res = await fetch(url, { headers: setHeaders() });
			const response = await res.json();
			if (response.code === 200) {
				setProfile({
					src: response?.data?.hotmer.img_profile || profileDefault,
					errored: false,
				});
				setHotmer(mapInitHotmer(response.data.hotmer));
				setPostFiles(response.data.content);
			}
			setLoadingData(false);
		} catch (error) {
			setLoadingData(false);
			console.log("ERROR: ", error);
		}
	};

	const onClickRefresh = () => getHotmerContent(match.params.id_hotmer);

	const onClickSlider = (e, item, type, idGroup) => {
		setLoadedCompleted(true);
		if (type === 1) {
			setSelectedImage(item.ds_url);
			setOpenModalSelection(true);
		} else {
			setSelectedVideo(item);
		}
	};

	const loadImageCompleted = () => {
		console.log("IMAGE COMPLETADA");

		const modalContainer = document.getElementById(`modal--container--image--gallery`);

		const subDiv = modalContainer.getElementsByClassName("MuiDialog-paper")[0];
		subDiv.classList.add("modal--dialog--now--classe2");

		const objDiv = document.getElementsByClassName("react-transform-component")[0];
		objDiv.classList.add("modal--dialog--now--classe3");
		objDiv.onclick = function () { handleCloseView(); };

		const imgModal = document.getElementById("img--transform--component--modal");
		imgModal.classList.add("modal--dialog--now--classe--img");

		setLoadedCompleted(false);
	};

	const handleCloseView = () => {
		setOpenModalSelection(false);
		setSelectedImage(null);
	};

	useEffect(() => {
		if (postFiles) {
			let idcgs = localStorage.getItem("id_content_group");
			if (idcgs) {
				const divId = `hotmer--content--main--container--element--${idcgs}`;
				if (divId) {
					setTimeout(() => {
						try {
							let myElement = document.getElementById(divId);
							const topPosition = myElement.offsetTop;
							const generalId = `hotmer--content--main--container`;
							document.getElementById(generalId).scrollTop = topPosition;
						} catch (error) {
							console.log("...");
						}
					}, 500);
				}
			}
		}
	}, [postFiles]); // eslint-disable-line

	// console.log("postFile",postFiles)

	useEffect(() => {
		getHotmerContent(match.params.id_hotmer);
		// setProfile({
		// 	src: response?.data?.hotmer.img_profile || profileDefault,
		// 	errored: false,
		// });
		// setHotmer(mapInitHotmer(response.data.hotmer));
		// setPostFiles(response.data.content);
		return () => localStorage.removeItem("id_content_group");
	}, []); // eslint-disable-line

	if (loadingData || loadingRemoveAccount) {
		return <LoadingScreen loading={loadingData || loadingRemoveAccount} />;
	} else if (!loadingData && !postFiles) {
		return <WrongFetchScreen onClickRefresh={onClickRefresh} />;
	} else {
		return (
			<div
				className="hotmer--content--main--container"
				id="hotmer--content--main--container"
			>
				{
					postFiles.map((postFile, index) => {
						const dataFooter = generateFooter(hotmer, postFile);
						// console.log("POST: ", postFile);
						return (
							<div
								key={postFile.id_content_group}
								id={`hotmer--content--main--container--element--${postFile.id_content_group}`}
								className="hotmer--content--main--container--element"
							>
								<div className="hotmer--content--main--container--element--header">
									<HotmerHomeHeader
										hotmer={hotmer}
										clickAdd={clickAdd}
										myHotmer={hotmer}
										profile={profile}
										onErrorProfile={onErrorProfile}
										id_content_group={postFile?.id_content_group}
										deletePostHotmer={deletePostHotmer}
										dtPublishFile={dayjs(postFile?.dt_publish).format('DD/MM/YYYY')}
										showDateOfContent={true}
									/>
								</div>
								<div className="hotmer--content--main--container--element--content">
									<HotmerContentFiles
										files={postFile?.data}
										idGroup={postFile?.id_content_group}
										descriptionFiles={postFile?.ds_comment}
										dtPublishFile={postFile?.dt_publish}
										type={postFile?.id_type_of_content}
										onClickSlider={onClickSlider}
										selectedVideo2={selectedVideo}
									/>
								</div>
								<div className="hotmer--content--main--container--element--footer">
									<HotmerHomeFooter
										hotmer={dataFooter}
										setLikedButton={setLikedButton}
										index={index}
										id_content_group={postFile.id_content_group}
										clickComments={clickComments}
									/>
								</div>
							</div>
						);
					})}

				{/* *************************************************** */}
				{selectedVideo && (
					<CustomModalVideo
						openModalVideo={openVideoModal}
						setOpenModalVideo={setOpenVideoModal}
					>
						<VideoContent selectedVideo={selectedVideo} />
					</CustomModalVideo>
				)}
				{/* *************************************************** */}
				<CustomModalVideo
					id={`modal--container--image--gallery`}
					openModalVideo={openModalSelection}
					fullScreen={true}
					setOpenModalVideo={setOpenModalSelection}
					handleCloseView={handleCloseView}
				>
					{selectedImage && (
						<TransformWrapper
							options={{
								limitToBounds: true,
								transformEnabled: true,
								disabled: false,
								limitToWrapper: false,
							}}
							pinch={{ disabled: false }}
							doubleClick={{ disabled: false }}
							wheel={{
								wheelEnabled: true,
								touchPadEnabled: true,
								limitsOnWheel: false,
							}}
							pan={{
								disabled: false,
								lockAxisX: false,
								lockAxisY: false,
								velocityEqualToMove: true,
								velocity: true,
							}}
						>
							<TransformComponent>
								{loadedCompleted && <LoadingScreen loading={loadedCompleted} />}
								<img
									onLoad={loadImageCompleted}
									id={`img--transform--component--modal`}
									src={selectedImage}
									alt="hotmer-image"
								/>
							</TransformComponent>
						</TransformWrapper>
					)}
				</CustomModalVideo>

				<CustomModal openModal={openModal} setOpenModal={setOpenModal}>
					<MessageIncompleteData
						message={"Ocurrio un error. Intente mas tarde por favor."}
					/>
				</CustomModal>

				<CustomModal
					openModal={openModalRemovePost}
					setOpenModal={setOpenModalRemovePost}
				>
					<OptionToTrending
						title={"Está seguro que desea eliminar este contenido ?"}
						acceptClick={applyOption}
						notAcceptclick={cancelOption}
					// disabledButtons={disabledButtons}
					/>
				</CustomModal>
			</div>
		);
	}
};
