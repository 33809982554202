import React, { useState } from "react";
import okIcon from "../../icons/ok-green-icon.png";
import dniIcon from "../../icons/dniwhite.png";
// import mainIcon from "../../icons/main-logo.png";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { CustomInputFile } from "../../commonComponents/customInputFile/customInputFile";
import { CustomButtonNext } from "../../commonComponents/customButtonNext/customButtonNext";
import { appendFiles, getFile } from "../../libs/upload";
import { incompleteMessageDni } from "../../libs/account";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { setUserAccountCreation } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import "./photoDni.css";

export const PhotoDni = ({ handleNext }) => {
  const state = useSelector((state) => state.main);
  const { userAccountCreation } = state;
  const dispatch = useDispatch();

  const [files, setFiles] = useState({ file1: null, file2: null });

  const [modalMessage, setModalMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const onChange = (e) => {
    const file = getFile(e);
    if (file) setFiles({ ...files, [e.target.name]: file });
  };

  const handleSubmit = () => {
    if (!files.file1 || !files.file2) {
      setModalMessage(incompleteMessageDni);
      setOpenModal(true);
    } else {
      const newState = appendFiles(userAccountCreation, files);
      dispatch(setUserAccountCreation(newState));
      handleNext();
    }
  };

  return (
    <div className="photo--dni--main--container">
      <DividerSpace space="M" />
      <div className="photo--dni--main--container--title">
        Sacale una foto a tu DNI
      </div>
      <DividerSpace space="M" />
      <div className="photo--dni--main--container--inputs">
        <div className="photo--dni--main--container--inputs--header">
          <div className="photo--dni--main--container--inputs--header--title">
            {/*Primero el <strong>frente</strong> y luego el <strong>dorso</strong>*/}
            Es importante este paso para poder verificar la{" "}
            <strong>mayoría de edad</strong>, evitar perfiles falsos y para la
            protección del contenido generado. Esta información esta encriptada
            y no sera difundida o reenviada.
          </div>
        </div>
        <DividerSpace space="X" />
        <img
          alt="..."
          src={dniIcon}
          className="photo--dni--main--container--dni--image"
        />
        <DividerSpace space="X" />
        <div className="file--container--photo--upload--icon">
          <div className="file--container--photo--upload--icon--button">
            <CustomInputFile
              classForInput="custom-file-input-frente"
              name="file1"
              onChange={onChange}
            />
          </div>
          <DividerSpace space="XXS" />
          <DividerSpace space="XXS" />
          {files.file1 && (
            <img
              className="file--container--photo--upload--icon--ok"
              src={okIcon}
              alt="..."
            />
          )}
        </div>
        <DividerSpace space="X" />
        <div className="file--container--photo--upload--icon">
          <div className="file--container--photo--upload--icon--button">
            <CustomInputFile
              classForInput="custom-file-input-dorso"
              name="file2"
              onChange={onChange}
            />
          </div>
          <DividerSpace space="XXS" />
          <DividerSpace space="XXS" />
          {files.file2 && (
            <img
              className="file--container--photo--upload--icon--ok"
              src={okIcon}
              alt="..."
            />
          )}
        </div>
        <DividerSpace space="X" />
        <div className="photo--dni--main--container--inputs--header--bottom--title">
          IMPORTANTE
        </div>
        <DividerSpace space="XXS" />
        <DividerSpace space="XXS" />
        <div className="photo--dni--main--container--inputs--header--bottom--subtitle">
          Ingresa tu Documento de Identidad <strong>Vigente</strong>.
        </div>
        <DividerSpace space="XXS" />
        <DividerSpace space="XXS" />
        <div className="photo--dni--main--container--inputs--header--bottom--subtitle">
          La imágen <strong>NO</strong> debe estar editada.
        </div>
        <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
          <MessageIncompleteData message={modalMessage} />
        </CustomModal>
      </div>
      <DividerSpace space="M" />
      <CustomButtonNext onClick={handleSubmit}>SIGUIENTE</CustomButtonNext>
    </div>
  );
};
