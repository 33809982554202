/* eslint-disable */
import React, { useEffect, useState } from "react";
import opsIcon from "../../icons/fetch/ooops-icon.png";
import verifiedIcon from "../../icons/verified-icon.png";
//import Icon from "@material-ui/core/Icon";
//import CloseIcon from "@material-ui/icons/Close";
//import ZoomInIcon from "@material-ui/icons/ZoomIn";
//import ZoomOutIcon from "@material-ui/icons/ZoomOut";
//import { grey } from "@material-ui/core/colors";
import { setHeaders } from "../../libs/fetch";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { DotsOptions } from "../../components/dotsOptions/dotsOptions";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import { VideoContent } from "../../components/videoContent/videoContent";
import { ButtonPresent } from "../../components/buttonPresent/buttonPresent";
//import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import SkeletonCard from "../../components/skeletonCard/SkeletonCard-View-Pack";
import { joinMultimedia } from "../../libs/packView";
import { MultimediaPack } from "../../components/multimediaPack/multimediaPack";
import { ButtonSubscribe } from "../../components/buttonSubscribe/buttonSubscribe";
import { CustomModalVideo } from "../../commonComponents/customModalVideo/customModalVideo";
import { OptionToTrending } from "../../components/optionToTrending/optionToTrending";
// import { WrongFetchScreen } from "../../commonComponents/wrongFetchScreen/wrongFetchScreen";
import { PackViewUnauthorized } from "../../commonComponents/packViewUnauthorized/packViewUnauthorized";
import { PackTags } from "../../components/packTags/packTags";
import { getIdUser, isHotmerUser } from "../../libs/login";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./packView.css";

export const PackView = ({ match }) => {
	const history = useHistory();
	const [title, setTitle] = useState("Desea inhabilitar el pack ?");
	const [disabledButtons, setDisabledButtons] = useState(false);
	const [selectedPack, setSelectedPack] = useState(null);
	const [openModal, setOpenModal] = useState(false);

	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [hotmerPack, setHotmerPack] = useState(null);
	const [multimedia, setMultimedia] = useState(null);
	const [hotmerUser, setHotmerUser] = useState(false);
	const [openSwipe, setOpenSwipe] = useState(false);
	const [fileSwipe, setFileSwipe] = useState(null);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openModalSelection, setOpenModalSelection] = useState(false);
	const [loadedCompleted, setLoadedCompleted] = useState(false);

	const [localUrl, setLocalUrl] = useState(window.location.href);
	

	const handleCloseView = () => {
		//setOpenModalSelection(false);
		setSelectedImage(null);
	};

	const [profile, setProfile] = useState({
		src: null,
		errored: false,
	});

	const handleDeletePack = async () => {
		setDisabledButtons(true);
		setTitle("Procesando...");
		const id = getIdUser();
		const id_pack = match.params.id_pack;
		const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/pack/${id_pack}`;
		try {
			const res = await fetch(url, {
				headers: setHeaders(),
				method: "DELETE",
			});
			const response = await res.json();
			console.log("RES: ", res);
			if (res.status === 200 && response.code === 200) {
				setTitle("Inhabilitación exitosa.");
				setTimeout(() => {
					setOpenModal(false);
					setTitle("Desea inhabilitar el pack ?");
					// window.location.href = "/";
				}, 400);
			} else {
				setTitle("Error: Intente mas tarde por favor.");
				setTimeout(() => {
					setOpenModal(false);
					setTitle("Desea inhabilitar el pack ?");
				}, 400);
			}
			// console.log(("RESPONSE: ", response));
		} catch (error) {
			console.log("ERROR: ", error);
			setTitle("Error: Intente mas tarde por favor.");
			console.log("FALLO AGREGAR PACK A DESEADOS");
			setTimeout(() => {
				setOpenModal(false);
				setTitle("Desea inhabilitar el pack ?");
			}, 400);
		}
	};

	const getPackContent = async (idHotmer, idPack) => {
		try {
			setLoading(true);
			const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${idHotmer}/content/pack/${idPack}`;
			const res = await fetch(url, {
				headers: setHeaders(),
			});
			// console.log("RES PACK: ", res);
			const response = await res.json();
			if (response.code === 200) {
				console.log("RESPONSE PACK", response.data);
				
				setHotmerPack(response.data);

				if (response.data.bl_authorized == false) {
					console.log("NO TIENE PERMISOS");	
				} else {
					console.log("SI TIENE PERMISOS");	
					setMultimedia(joinMultimedia(response.data));
				}

				setProfile({
					src:
						response.data.hotmer.profile_photo_path === null
							? opsIcon
							: response.data.hotmer.profile_photo_path,
					errored: false,
				});
				
			} else {
				setHotmerPack(null);
			}
			setLoading(false);
		} catch (error) {
			setHotmerPack(null);
			setLoading(false);
		}
	};

	const reproduceVideoContent = (content) => {
		setSelectedVideo(content);
		setTimeout(() => setOpenVideoModal((x) => !x), 500);
	};

	const onErrorProfile = () => {
		if (!profile.errored) {
			setProfile({
				src: opsIcon,
				errored: true,
			});
		}
	};

	const applyOption = () => handleDeletePack();

	const cancelOption = () => {
		setOpenModal(false);
		setSelectedPack(null);
	};

	const toggleModal = (id_hotmer, id_pack) => {
		setOpenModal((x) => !x);
		setSelectedPack({ id_hotmer, id_pack });
		setDisabledButtons(false);
	};

	const handleEditPack = () => {
		const id_pack = match.params.id_pack;
		const id_hotmer = match.params.id_hotmer;
		history.push(`/packUpdate/${id_hotmer}/${id_pack}`);
	};

	const onClickRefreshGetPack = () =>
		getPackContent(match.params.id_hotmer, match.params.id_pack);

	const zoomImage = (direction) => {
		const id = "swipe--view--main--container--image--img";
		const divElement = document.getElementById(id);
		var delta = 50 * direction;
		var positionInfo = divElement.getBoundingClientRect();
		divElement.style.width = positionInfo.width + delta + "px";
		divElement.style.height = positionInfo.height + delta + "px";
	};

	const showPhotoSwipe = (file) => {
		setSelectedImage(file.ds_url);
		setOpenSwipe((status) => !status);
	};

	const closeSwipe = () => {
		setSelectedImage(null);
		setOpenSwipe(false);
	};

	// ------------------------------------------------------
	// Para evitar el back
	window.history.pushState(null, null, location.href);
    window.onpopstate = function() {
		
			//event.prevent
			history.go(1);
		
	};
	// ------------------------------------------------------

	const loadImageCompleted = () => {
		console.log("IMAGE COMPLETADA");

		const modalContainer = document.getElementById("modal--container--image--gallery--pack");

		const subDiv = modalContainer.getElementsByClassName("MuiDialog-paper")[0];
		subDiv.classList.add("modal--dialog--now--classe2");

		const objDiv = document.getElementsByClassName("react-transform-component")[0];
		objDiv.classList.add("modal--dialog--now--classe3");
		objDiv.onclick = function() { closeSwipe(); };

		const imgModal = document.getElementById("img--transform--component--modal");
		imgModal.classList.add("modal--dialog--now--classe--img");

		setLoadedCompleted(false);
	};

	useEffect(() => {
		setHotmerUser(isHotmerUser());
		getPackContent(match.params.id_hotmer, match.params.id_pack);
		return () => {
			setOpenVideoModal(false);
			setSelectedVideo(null);
			setLoading(false);
			setHotmerPack(null);
			setMultimedia(null);
		};
	}, []); // eslint-disable-line

	const visitHotmerPorfile = () =>
		history.push(`/${hotmerPack?.hotmer?.ds_display_name}`);
	
	if (loading) {	return ( 
		<div className="home--main--container">
		 <SkeletonCard loading={loading} />;
		</div>
		);
	} else if (!hotmerPack && !loading) {
		return ( 
		<div className="home--main--container">
		<SkeletonCard loading={loading} />;
	   </div>
	   );
	//	//return <WrongFetchScreen onClickRefresh={onClickRefreshGetPack} />;
	} else {
		return (
			<div className="pack--view--main--container">
				<div className="pack--view--main--container--header">
					<div className="pack--view--main--container--header--container">
						<div
							className="pack--view--main--container--header--thumbnail"
							onClick={visitHotmerPorfile}
						>
							<img src={profile.src} alt="..." onError={onErrorProfile} />
						</div>
					</div>
					<div className="pack--view--main--container--header--container">
						<div className="pack--view--main--container--header--name">
							<div className="pack--view--main--container--header--name--text">
								{hotmerPack.hotmer.ds_display_name}
							</div>
							<DividerSpace space="XXS" />
							<div className="pack--view--main--container--header--name--icon">
								{hotmerPack.bl_subscribe && (
									<img src={verifiedIcon} alt="..." />
								)}
							</div>
						</div>
					</div>
					<div className="pack--view--main--container--header--container">
						{!hotmerPack.bl_owner && (
							<>
								{(hotmerPack.bl_subscribe & hotmerPack.bl_authorized ) ? (
									<ButtonPresent id_user_hotmer={hotmerPack.hotmer.id_hotmer} />
								) : (
									<ButtonSubscribe
										id_user_hotmer={hotmerPack.hotmer.id_hotmer}
									/>
								)}
							</>
						)}
					</div>
					<div className="pack--view--main--container--header--container">
						<div className="pack--view--main--container--header--dots">
							<DotsOptions
								id={hotmerPack.hotmer.id_hotmer}
								hotmerUser={hotmerUser}
								owner={hotmerPack.bl_owner}
								// handleDeletePack={handleDeletePack}
								toggleModal={toggleModal}
								selectedPack={selectedPack}
								handleEditPack={handleEditPack}
							/>
						</div>
					</div>
				</div>

				<div className="pack--view--main--container--body">
					<div className="pack--view--main--container--body--text">
						<div className="pack--view--main--container--body--text--title">
							<div className="pack--view--main--container--body--text--title--left">
								{hotmerPack.ds_title}
							</div>
						</div>
						{/*!hotmerPack.bl_authorized && (
							<PackTags/>
						)*/}
						<div className="pack--view--main--container--body--text--description">
							{hotmerPack.ds_description}
						</div>
					</div>
					<div className="pack--view--main--container--body--content">
						<div className="pack--view--main--container--body--content--images">
							{!hotmerPack.bl_authorized ? 
							(
								<>
									<PackViewUnauthorized
									pack={hotmerPack.pack}
									owner={1}/>
									<PackTags packTags={hotmerPack.pack.tags}/>
								</>
							):(
								multimedia && (
									multimedia.length !== 0 && 
									(
										multimedia.map((file, i) => (
												<MultimediaPack
													key={i}
													file={file}
													reproduceVideoContent={reproduceVideoContent}
													showPhotoSwipe={showPhotoSwipe}
												/>)
										)
									)
								)

							)}
						</div>
					</div>
				</div>

				<CustomModalVideo
					id={`modal--container--image--gallery--pack`}
					openModalVideo={openSwipe}
					fullScreen={true}
					setOpenModalVideo={setOpenSwipe}
					handleCloseView={closeSwipe}
				>
					{selectedImage && (
						<TransformWrapper
							options={{
								limitToBounds: true,
								transformEnabled: true,
								disabled: false,
								limitToWrapper: false,
							}}
							pinch={{ disabled: false }}
							doubleClick={{ disabled: false }}
							wheel={{
								wheelEnabled: true,
								touchPadEnabled: true,
								limitsOnWheel: false,
							}}
							pan={{
								disabled: false,
								lockAxisX: false,
								lockAxisY: false,
								velocityEqualToMove: true,
								velocity: true,
							}}
						>
							<TransformComponent>
								{loadedCompleted && <LoadingScreen loading={loadedCompleted} />}
								<img
									onLoad={loadImageCompleted}
									id={`img--transform--component--modal`}
									src={selectedImage}
									alt="hotmer-image"
								/>
							</TransformComponent>
						</TransformWrapper>
					)}
				</CustomModalVideo>

				<CustomModal openModal={openModal} setOpenModal={setOpenModal}>
					<OptionToTrending
						title={title}
						acceptClick={applyOption}
						notAcceptclick={cancelOption}
						disabledButtons={disabledButtons}
					/>
				</CustomModal>

				<CustomModalVideo
					openModalVideo={openVideoModal}
					setOpenModalVideo={setOpenVideoModal}
				>
					<VideoContent selectedVideo={selectedVideo} />
				</CustomModalVideo>
			</div>
		);
	}
};
