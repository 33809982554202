/* eslint-disable */
import React, { useState } from "react";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
import { toys } from "../../constants/constants";
import { setHeaders } from "../../libs/fetch";
import { getIdUser } from "../../libs/login";
import { PackContent } from "../packContent/packContent";
import "./hotmerOwnPack.css";

export const HotmerOwnPack = ({ pack, owner }) => {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [successAdd, setSuccessAdd] = useState(false);

  const getTitle = ({ ds_title }) => ds_title.toUpperCase();

  const addPack = async () => {
    setLoadingAdd(true);
    const id_pack = pack.id_pack;
    const id = getIdUser();
    try {
      const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/${id}/pack/${id_pack}/follow`;
      const res = await fetch(url, {
        headers: setHeaders(),
        method: "POST",
      });
      const response = await res.json();
      if (response.code === 200) {
        // removePackFromListByAdd(id_hotmer, id_pack, 0);
        setSuccessAdd(true);
      }
    } catch (error) {
      console.log(error, "FALLO AGREGAR PACK A DESEADOS");
    }
    setLoadingAdd(false);
  };

  return (
    <div className="hotmer--own--pack--main--container">
      {owner !== 0 ?
        <div className="hotmer--own--pack--main--container--header">
          <div className="hotmer--own--pack--main--container--header--left">
            {getTitle(pack)}
          </div>
          <div
            className={`
            hotmer--own--pack--main--container--header--right-2 ${successAdd ? "successed--right2" : ""
              }
            `}
            onClick={() => {
              if (!pack.bl_follow) {
                addPack();
              }
            }}
          >
            {loadingAdd ? <MiniSpinner /> : pack.bl_follow ? "" : "AGREGAR"}
          </div>
        </div>
        : null
      }
      {/* ****************************************************************** */}
      <PackContent pack={pack} owner={owner} />
      {/* ****************************************************************** */}
      {owner !== 0 &&
        <div className="hotmer--own--pack--main--container--toys">
          {pack.tags.map((toy) => (
            <Toy key={toy} toy={toy} />
          ))}
        </div>
      }
    </div>
  );
};

const Toy = ({ toy }) => {
  const getIconBy = (n) => {
    const toy = toys.find((toy) => toy.id === n);
    return toy.icon;
  };

  return (
    <div className="hotmer--own--pack--main--container--toys--item">
      <img src={getIconBy(toy)} alt="..." />
    </div>
  );
};
