/* eslint-disable */
import React, { useState, useEffect } from "react";
import { getIdUser } from "../../libs/login";
import { useHistory } from "react-router-dom";
import { ActiveTrade } from "../../components/ActiveTrade/ActiveTrade";
import { CustomModal } from "../../commonComponents/customModal/customModal";
import { LoadingScreen } from "../../commonComponents/loadingScreen/loadingScreen";
import { setInitialToys } from "../../libs/search";
//import { OptionTryAgain } from "../../components/optionTryAgain/optionTryAgain";
import { UploadFormContent } from "../uploadFormContent/uploadFormContent";
//import { setScreenPosition } from "../../redux/actions";
import { UploadContentHeader } from "../../components/uploadContentHeader/uploadContentHeader";
import { UploadHandleContent } from "../uploadHandleContent/uploadHandleContent";
import { MessageIncompleteData } from "../../commonComponents/messageIncompleteData/messageIncompleteData";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setFormDataHeaders, setHeaders } from "../../libs/fetch";
import {
  getPackTags,
  submitHotmerContentFiles,
} from "../../redux/upload/uploadActions";
import {
  createUploadServerPhoto,
  initialStateUpload,
  createUploadServerVideo,
  createUploadServerPack,
  options,
  updateList,
  updateEndpoint,
  countImagesFiles,
  createPreviews,
  updatePreviews,
  removeFiles,
  updatePrevs,
  updatePortada,
  handleAddInitialFiles,
  reInitialize,
  successUploadMessage,
  failureUploadMessage,
  alertMessagePack,
  handleInitialFiles,
  alertPhotoMessage,
  alertVideoMessage,
  alertPhotoWithoutVideoMessage,
  alertPhotoWithVideoMayorTwoMessage,
  alertPhotoWithVideoMayorZero,
  getDataFiles,
  alertPackMessage,
  minPhotoAllowed,
  maxPhotoAllowed,
  minVideoAllowed,
  maxVideoAllowed,
  minPackPhotoAllowed,
  maxPackPhotoAllowed,
  minPackVideoAllowed,
  maxPackVideoAllowed,
  minPackPhotoWithoutVideoAllowed,
  minPackPhotoWithVideoMayorTwo,
  minPackPhotoWithVideoMayorZero,
  minPackPrice,
  maxPackPrice,
  getValidationUser,
} from "../../libs/upload";
import "./uploadContent.css";

export const UploadContent = () => {
  const state = useSelector((state) => state.main);
  const {
    loadingPackTags,
    packTags,
    loadingSubmitContent,
    responseSubmitContent,
  } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("global");
  
  const msgInProgress = (t("toast.uploadContent.contentInProgress"))
  const msgFinish = (t("toast.uploadContent.contentSuccessOK"))

  let user;
  try {
    user = JSON.parse(localStorage.getItem("session")).user || "";
  } catch (error) {
    window.location.href = "/login";
  }

  const [modalShow, setModalShow] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [activeTrades, setActiveTrades] = useState(null);
  const [openTradeOptions, setOpenTradeOptions] = useState(false);
  const [selectedActiveTrade, setSelectedActiveTrade] = useState(null);


  const [tab, setTab] = useState(0);
  const [loadingTax, setLoadingTax] = useState(false);
  const [taxes, setTaxes] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState(initialStateUpload);
  const [loadingPackToys, setLoadingPackToys] = useState(false);
  const [packToys, setPackToys] = useState(null);
  const [initialPackToys, setInitialPackToys] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [alertFinalConfirm, setAlertFinalConfirm] = useState("");
  const [alertRestrictionsContent, setalertRestrictionsContent] = useState("");
  const [filesPreview, setFilesPreview] = useState(null);
  const [files, setFiles] = useState(null);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [messageModalWarning, setMessageModalWarning] = useState("");
  const [finalUrl, setFinalUrl] = useState("");
  let [formDataServer, setFormDataServer] = useState(null);

  const setOpenOptions2 = () => {
    setOpenOptions((x) => !x);
    setModalShow((x) => !x);
  };

  const setSelectedTypeOption = (option) => {
    if (option.id == 0) {
      setAlertFinalConfirm(alertPhotoMessage);
      setalertRestrictionsContent(
        ` Prohibido el contenido sexualmente explícito.\n Ver politicas de uso.`
      );
    } else if (option.id == 1) {
      setAlertFinalConfirm(alertVideoMessage);
      setalertRestrictionsContent(
        ` Prohibido el contenido sexualmente explícito. \n  Ver politicas de uso.`
      );
    } else {
      setAlertFinalConfirm(
        `- Imágenes: cantidad máxima ${maxPackPhotoAllowed} \n  
				 - Videos: Cantidad máxima ${maxPackVideoAllowed} ( duración maxima 1 min cada video)`
      );
      setalertRestrictionsContent(
        ` Las portadas del pack no pueden ser explicitas. \n - Ver politicas de uso.`
      );
    }
    setSelectedType(option);
    setData({ ...data, type: option, title: "", description: "", price: "" });
    setFiles(null);
    setFilesPreview(null);
  };

  const existsInArray = (value, arr) => {
    var status = false;
    for (var j = 0; j < arr.length; j++) {
      if (
        arr[j].name == value.name &&
        arr[j].size == value.size &&
        arr[j].type == value.type &&
        arr[j].lastModified == value.lastModified
      ) {
        status = true;
        break;
      }
    }
    return status;
  };

  const handleChangeAdd = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files) {
        const resultFiles = handleAddInitialFiles(e);
        let fileName = resultFiles[0].name;
        let newFiles;
        let uPreview;
        let fileNotExiste = true;
        let portadaFilename;

        if (files == null) {
          newFiles = resultFiles;
          uPreview = updatePreviews([], newFiles);
          portadaFilename = uPreview[0].name;
          const initPortada = updatePortada(uPreview, portadaFilename);
          setFilesPreview(initPortada);
        } else {
          const actualFiles = [...files];
          newFiles = [...files];
          for (var i = 0; i < resultFiles.length; i++) {
            fileNotExiste = !existsInArray(resultFiles[i], actualFiles);
            if (fileNotExiste) {
              newFiles.push(resultFiles[i]);
            }
          }
          fileName = newFiles[0].name;
          uPreview = updatePreviews([], newFiles);
          portadaFilename = getPortadaFile(uPreview);
          setFilesPreview(updatePortada(uPreview, portadaFilename));
        }
        setFiles(newFiles);
      } else {
        return;
      }
    }
  };

  const getPortadaFile = () => {
    let portadaFilename = filesPreview.find((x) => x.portadaFile == true);
    if (portadaFilename === undefined) {
      portadaFilename = "";
    } else {
      portadaFilename = portadaFilename.name;
    }
    return portadaFilename;
  };

  const getPortadaFileBlob = () => {
    let portadaFilename = filesPreview.find((x) => x.portadaFile == true);
    if (portadaFilename === undefined) {
      portadaFilename = "";
    } else {
      portadaFilename = portadaFilename.file;
    }
    return portadaFilename;
  };

  const removeSelection = () => {
    const { newFiles, newFilesPreview } = removeFiles(files, filesPreview);
    setFiles(newFiles);
    setFilesPreview(newFilesPreview);
  };

  const choosePrevFile = (i) => setFilesPreview(updatePrevs(filesPreview, i));

  const choosePortadaFile = (name) => {
    const portadaFile = getPortadaFileBlob();
    if (files.length > 1 ) {
      if (portadaFile != name){
        setFilesPreview(updatePortada(filesPreview, name))
      }
    }
  };

  const handleFiles = (e) => setFiles(handleInitialFiles(e, files));

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const selectToy = (id) => setPackToys(updateList(packToys, id));

  const onClickRefresh = () => dispatch(getPackTags());

  const getTaxUpload = async (id) => {
    setLoadingTax(true);
    const url = `${process.env.REACT_APP_INITIAL_V2}/packs/taxes`;
    try {
      const res = await fetch(url, { headers: setHeaders() });
      //console.log("RES TAXES: ", res);
      if (res.status === 200) {
        const response = await res.json();
        //console.log("SERVICIO TAXES: ", response.data);
        setTaxes(response.data);
      }
    } catch (error) {
      console.log("TAX ERROR: ", error);
    }
    setLoadingTax(false);
  };

  const getPackToys = async () => {
    setLoadingPackToys(true);
    const url = `${process.env.REACT_APP_INITIAL_V2}/packs/tags`;
    try {
      const res = await fetch(url, { headers: setHeaders() });
      const response = await res.json();
      if (res.status === 200 && response.code === 200) {
        const resultInitialToys = setInitialToys(response.data);
        setInitialPackToys(resultInitialToys);
        setPackToys(resultInitialToys);
        // console.log("INITIAL TOY LIST", resultInitialToys);
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
    setLoadingPackToys(false);
  };

  const setOpenModalOptions = () => {
    setOpenOptions(false);
    setModalShow(false);
  };

  const confirmSubmitUpload = async () => {
    const { countImages, countVideos } = getDataFiles(files);
    let formDataServer2;
    if (selectedType.id === 0) {
      // IMAGES
      if (countImages < minPhotoAllowed || countImages > maxPhotoAllowed) {
        setMessageModalWarning(alertPhotoMessage);
        setOpenModalWarning(true);
        return;
      } else {
        const portadaFileName = filesPreview.find(x => x.portadaFile == true).name
        formDataServer2 = createUploadServerPhoto(data, files, portadaFileName);
      }
    }
    if (selectedType.id === 1) {
      // VIDEOS
      if (countVideos < minVideoAllowed || countVideos > maxVideoAllowed) {
        setMessageModalWarning(alertVideoMessage);
        setOpenModalWarning(true);
        return;
      } else {
        formDataServer2 = createUploadServerVideo(data, files);
      }
    }
    if (selectedType.id === 2) {
      //PACK

      if (
        (countImages == 0 && countVideos == 0) ||
        countImages < minPackPhotoAllowed ||
        countImages > maxPackPhotoAllowed ||
        countVideos < minPackVideoAllowed ||
        countVideos > maxPackVideoAllowed
      ) {
        setMessageModalWarning(alertPackMessage);
        setOpenModalWarning(true);
        return;
      }
          if (
            (countVideos == 0  && countImages < minPackPhotoWithoutVideoAllowed)
          ) {
            setMessageModalWarning(alertPhotoWithoutVideoMessage);
            setOpenModalWarning(true);
            return;
          }
          else if (
            (countVideos == 1  && countImages < minPackPhotoWithVideoMayorZero) 
            )
           {
            setMessageModalWarning(alertPhotoWithVideoMayorZero);
            setOpenModalWarning(true);
            return;
          } else if (
            (countVideos > 1  && countImages < minPackPhotoWithVideoMayorTwo)
          ) {
            setMessageModalWarning(alertPhotoWithVideoMayorTwoMessage);
            setOpenModalWarning(true);
            return;
          
        } else {
        const portadaFileName = filesPreview.find(
          (x) => x.portadaFile == true
        ).name;

        const id_trade_agreement = selectedActiveTrade
          ? selectedActiveTrade.id_trade_agreement
          : null;
        formDataServer2 = createUploadServerPack(
          data,
          packToys,
          files,
          portadaFileName,
          id_trade_agreement
        );
      }
    }
    dispatch(submitHotmerContentFiles(finalUrl, formDataServer2,msgInProgress, msgFinish ));
    setSelectedActiveTrade(null);
    setData(initialStateUpload);
    setPackToys(packToys);
    setSelectedType(null);
    setFiles(null);
    setTab(0);
    //dispatch(setScreenPosition(0));
  };

  const createUpload = () => {
    let url = "";
    let formDataServer = null;
    if (selectedType.id === 0) {
      // IMAGE
      url = updateEndpoint("image");
      setFinalUrl(url);
      formDataServer = createUploadServerPhoto(data, files, undefined);
      setFormDataServer(formDataServer);
    } else if (selectedType.id === 1) {
      // VIDEOS
      url = updateEndpoint("video");
      setFinalUrl(url);
      formDataServer = createUploadServerVideo(data, files);
      setFormDataServer(formDataServer);
    } else {
      // PACKS
      //const thumbnail = countImagesFiles(files);
      const price = parseFloat(data.price);
      const acceptedPrice = price >= minPackPrice && price <= maxPackPrice;
      //if (thumbnail === 0 || !acceptedPrice) {
      if (!acceptedPrice) {
        setMessageModalWarning(alertMessagePack);
        setOpenModalWarning(true);
        return;
      } else {
        url = updateEndpoint("pack");
        setFinalUrl(url);
        formDataServer = createUploadServerPack(data, packToys, files, null);
        setFormDataServer(formDataServer);
      }
    }
    setFilesPreview(createPreviews(files, 0));
    setTab(1);
  };

  const getValidationUserPack = async () => {
    const response = await getValidationUser();
    setActiveTrades(response);
  };

  useEffect(() => {
    setSelectedActiveTrade(null);
    if (data.type.id === 2) {
      setData((object) => ({ ...object, price: "10" }));
    }
    if (packToys !== null && selectedType !== null) {
      setPackToys(reInitialize(packToys));
    }
  }, [selectedType]); // eslint-disable-line

  useEffect(() => {
    if (files !== null) {
      if (files.length === 0) {
        setTab(0);
        setAlertFinalConfirm("");
        setalertRestrictionsContent("");
        setFiles(null);
        setFilesPreview(null);
        setSelectedType(null);
        setData(initialStateUpload);
        setPackToys(initialPackToys);
      }
    }
  }, [files]);

  /*
  useEffect(() => {
    if (responseSubmitContent !== null) {
      if (responseSubmitContent === true) {
        setIconModalUpload(okIcon);
        setMessageModalUpload("Contenido subido correctamente");
      } else {
        setIconModalUpload(opsIcon);
        setMessageModalUpload(
          "Se produjo un error al subir el contenido. Si persiste contactar a support@hotmy.net"
        );
      }
      setModalShow(true);
      setOpenResult(true);
    }
  }, [responseSubmitContent]); 
   */

  useEffect(() => {
    const session = localStorage.getItem("session");
    const { user } = JSON.parse(session);
    if (user.rol === "hotmers") {
      getValidationUserPack();
    }
    getPackToys();
    const id = getIdUser();
    getTaxUpload(id);
    return () => {
      dispatch({ type: "SET_FAILURE_TOYS" });
      setSelectedType(null);
      setPackToys(null);
      // setIconModalUpload(null);
      // setModalMessageResult("");
      setFilesPreview(null);
      setFiles(null);
    };
  }, []); // eslint-disable-line

  if ((loadingPackToys && loadingTax) || loadingSubmit) {
    return <LoadingScreen loading={loadingPackToys && loadingTax} />;
  } else {
    return (
      <div className="upload--content--main--container">
        <LoadingScreen loading={loadingSubmit} />
        <UploadContentHeader />
        {tab === 0 ? (
          <UploadFormContent
            activeTrades={activeTrades}
            setOpenOptions={setOpenOptions2}
            data={data}
            selectedType={selectedType}
            onChange={onChange}
            packToysState={packToys}
            openModalOptions={openOptions}
            handleFiles={handleFiles}
            selectToy={selectToy}
            taxes={taxes}
            createUpload={createUpload}
            onClickRefresh={onClickRefresh}
            loadingPackTags={loadingPackTags}
            selectedActiveTrade={selectedActiveTrade}
            setSelectedActiveTrade={setSelectedActiveTrade}
            files={files}
            setOpenTradeOptions={() => {
              setModalShow(true);
              setOpenTradeOptions((x) => !x);
            }}
          />
        ) : (
          <UploadHandleContent
            selectedType={selectedType}
            handleChangeAdd={handleChangeAdd}
            removeSelection={removeSelection}
            alertFinalConfirm={alertFinalConfirm}
            alertRestrictionsContent={alertRestrictionsContent}
            filesPreview={filesPreview}
            choosePrevFile={choosePrevFile}
            choosePortadaFile={choosePortadaFile}
            confirmSubmitUpload={confirmSubmitUpload}
          />
        )}

        <CustomModal
          openModal={modalShow}
          setOpenModal={setModalShow}
          discardExternalEvents={true}
        >
          {openOptions && (
            <FileOptions
              options={options}
              setSelectedTypeOption={setSelectedTypeOption}
              setOpenModalOptions={setOpenModalOptions}
            />
          )}
          {/* 
            {openResult && (
              <OptionTryAgain
                icon={iconModalUpload}
                title={messageModalUpload}
                acceptClick={onFinalClick}
                buttonText={"ACEPTAR"}
              />
            )}
         */}
          {openTradeOptions && (
            <div style={{ width: "80vw", maxWidth: "300px" }}>
              {activeTrades &&
                activeTrades.map((trade, index) => (
                  <ActiveTrade
                    key={index}
                    trade={trade}
                    onClick={() => {
                      setSelectedActiveTrade(trade);
                      setOpenTradeOptions(false);
                      setModalShow(false);
                    }}
                  />
                ))}
            </div>
          )}
        </CustomModal>
        {/* ******************************************* */}
        <CustomModal
          openModal={openModalWarning}
          setOpenModal={setOpenModalWarning}
        >
          <MessageIncompleteData message={messageModalWarning} />
        </CustomModal>
      </div>
    );
  }
};

const FileOptions = ({
  options,
  setSelectedTypeOption,
  setOpenModalOptions,
}) => (
  <div className="custom--upload--select--container--options">
    {options.map((option) => (
      <div
        className="custom--upload--select--container--options--item"
        key={option.id}
        onClick={() => {
          setSelectedTypeOption(option);
          setOpenModalOptions((x) => !x);
        }}
      >
        {option.description}
      </div>
    ))}
  </div>
);
