import { hmyApiWithToken } from "../api/hmyApiWithToken";
import { useQuery } from '@tanstack/react-query'
import { Comments } from '../interfaces/Comments';

const getComment = async ({ queryKey  }) : Promise <Comments> => {
    const [_, idCreator, idContent] = queryKey
    const myToken = JSON.parse(localStorage.getItem("session"))?.token;
    const { data } = await hmyApiWithToken(myToken).get<Comments>(`/hotmers/${idCreator}/content/${idContent}/comment`)
    return data;
    // history.push(`/commentsPost/${idh}/${idc}`); 
    // https://hmybackprod.ddns.net/api/v2/hotmers/9276/content/4134/comment
}

const useGetComment = (  creator: number | string, idContent : number) => {
    const getCommentPost = useQuery(
        ['getCommentByIdCreatorByIdContent', creator, idContent], getComment,
        {
            refetchOnWindowFocus: false,
            retry: 2,
            enabled: !!idContent,
          }
    );

    return getCommentPost;
}

export default useGetComment