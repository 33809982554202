/* eslint-disable */
import React, { useState } from "react";
import { FinishProcess } from "../finishProcess/finishProcess";
import { PasswordRestore } from "../passwordRestore/passwordRestore";
import "./stepperPasswordRestore.css";

export const StepperPasswordRestore = () => {
	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () =>
		setActiveStep((prevActiveStep) => prevActiveStep + 1);

	return (
		<div className="stepper--acount--main--container">
			{activeStep === 0 ? (
				<PasswordRestore handleNext={handleNext} />
			) : (
				<FinishProcess
					title="Restablecer Clave de Acceso."
					description="Por favor verifique su correo electrónico para acceder al enlace de reinicio de clave."
					urlRedirect="/login"
					buttonTitle="ACEPTAR"
				/>
			)}
		</div>
	);
};
