import React from "react";
import { DividerSpace } from "../../commonComponents/dividerSpace/dividerSpace";
import "./iconTopic.css";

export const IconTopic = ({ icon, value }) => {
  return (
    <div className="total--icon--main--container">
      <div className="total--icon--main--container--description">
        <img src={icon} alt="..." />
      </div>
      <DividerSpace space="XXS" />
      <div className="total--icon--main--container--value">{value}</div>
    </div>
  );
};
